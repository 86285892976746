import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import InputLabel from '../../../components/FormFields/InputLabel';
import NotificationSnackbar from '../../Modal/NotificationSnackbar';
import { changePassword, resetError } from '../../../actions/index';

const styles = {
  formControl: {
    margin: '.5em 0',
    width: '100%',

    '@media (max-width: 1150px)': {
      minWidth: 'calc(50% - 1em)',
    },
    
  },
  outlinedInput: {
    minWidth: 300,
    maxWidth: '100%',
    '@media (max-width: 985px)': {
      minWidth: 'unset',
    },
  },
  button: {
    marginLeft: '.5em',
    minWidth: '6em',
    marginTop: '1.5em',
    borderRadius: 30,
    '@media (max-width: 767px)': {
      marginTop: 10,
      height:50
    }
  },
  imageInput: {
    '@media (max-width: 1150px)': {
      top: '-5em',
    },
    '@media (max-width: 750px)': {
      top: '-2em',
    },
  },
  links: {
    width: '50%',
  },
  securityFormContainer: {
    width: '50%',
    margin: '0 auto',
    '@media (max-width:767px)' : {
      width: '100%'
    }
  },
  securityResetBtn: {
    marginLeft: 0,
  },
  currentPasswordInput: {
    borderRadius: 30,
  },
};

const SuccessMessage = props => {
  const { setSuccess } = props;
  return (
    <Box
      style={{
        width: '50%',
        margin: '30px auto',
        padding: '30px',
        backgroundColor: 'rgba(62, 168, 178, 0.2)',
        textAlign: 'center',
        borderRadius: 25,
      }}
    >
      <Typography variant="h4" style={{fontWeight: "bold", lineHeight: 3}}>Reset Password Confirmation</Typography>
      <Typography variant="body1">{setSuccess ? setSuccess.message : ""}</Typography>
    </Box>
  );
};

const CustomTextField = withStyles({
  root: {
    
    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:after': {
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      borderRadius: 30,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        backgroundColor: 'rgba(0, 0, 0, 0.06)',
        borderRadius: 30,
      },
      '&:hover fieldset': {
        border: 'rgb(62, 168, 178) 1px solid'
      },
      '&.Mui-focused fieldset': {
      },
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
      marginRight: 0,
      fontStyle: 'italic',
      display: 'flex',
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1.2em',
      margin: '3px',
    },
    '& + .MuiFormHelperText-root': {
      marginLeft: 0,
      marginRight: 0,
      fontStyle: 'italic',
      display: 'flex',
      color: '#f44336',

      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: '1.2em',
        margin: '3px',
      },
    }
  },
})(TextField);

const ResetPassword = (props) => {
  const {
    dispatch,
    classes,
    error,
    user,
    setSuccess
  } = props;


  const defaultFields = {
    email: user ? user.email : '',
    password: '',
    newPassword: '',
    confirmPassword: ''
  };

  

  const [fields, setFields] = useState(defaultFields);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [passErr, setPassErr] = useState({
    newPass: false,
    confPass: false
  });
  const [passErrText, setPassErrText] = useState({
    newPassErrText: fields.newPassword,
    confPassErrText: fields.confirmPassword
  });
  const [currentPassErr, setCurrentPassErr] = useState(false);

  const handleNotificationErrorOpen = () => setOpen(true);
  const handleNotificationErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      console.log(event);
      return;
    }
    setOpen(false);
  };
  const isResetFeilds =  useSelector(state => state.resetChangePassword);
   
  useEffect(()=>{
    if(isResetFeilds) {
      setFields(defaultFields)
    }
  },[isResetFeilds])

  const updateField = (field, value) => {
    const newFields = { ...fields, [field]: value.trim() };
    setFields(newFields);
    validateFields(field, newFields);    
  };
  

  const handleCurrentPassFocus = () => {
    dispatch(resetError());
  };

  const passwordRegexLowercase = /(?=.*[a-z])/;
  const passwordRegexUppercase = /(?=.*[A-Z])/;
  const passwordRegexNumeric = /(?=.*[0-9])/;
  const passwordRegexSpecChar = /(?=.*[!@#$%^&*'"])/;
  const passwordRegexLength = /(?=.{7, 128})/;

  const validateFields = (field = null, newFields = fields) => {
    const newErrors = { ...errors };
    setErrors({});

    const validation = {
      email: () => {
        const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
        newFields.email = user.email;

        if (!newFields.email || !newFields.email.trim()) return 'Login email is required';
        if (!emailRegex.test(newFields.email)) return 'Login email is invalid';
        return null;
      },
      password: () => {
        if (!newFields.password || !newFields.password.trim()) return (<><InfoOutlined fontSize="small" /> This field is required</>); 
        return null;
      },
    };

    if (field) {
      const error = validation[field] && validation[field]();
      if (error) {
        newErrors[field] = error;
      } else {
        delete newErrors[field];
      }
    } else {
      Object.keys(validation).forEach((key) => {
        const error = validation[key]();
        if (error) {
          newErrors[key] = error;
        } else {
          delete newErrors[key];
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const comparePassword = (pass, confirmPass) => {
    if (pass === "") {
      setPassErr({
        newPass: true,
        confPass: false
      });
      setPassErrText({
        newPassErrText: "This field is required",
        confPassErrText: ""
      });
    } else if (pass.length < 7 || pass.length > 128) {
      setPassErr({
        newPass: true,
        confPass: false
      });
      setPassErrText({
        newPassErrText: "Please try again.Use 7 to 128 characters with a mix of capital letters, letters, numbers & symbols.",
        confPassErrText: ""
      });
    } else if (
          !passwordRegexLowercase.test(pass)
          || !passwordRegexUppercase.test(pass)
          || !passwordRegexNumeric.test(pass)
          || !passwordRegexSpecChar.test(pass)
          || passwordRegexLength.test(pass)
    ) {
      setPassErr({
        newPass: true,
        confPass: false
      });
      setPassErrText({
        newPassErrText: "Password is not strong.Use 7 or more characters with a mix of capital letters, letters, numbers & symbols.",
        confPassErrText: ""
      });
    } else if (pass !== "" && confirmPass === "") {
      setPassErr({
        newPass: false,
        confPass: true
      });
      setPassErrText({
        newPassErrText: "",
        confPassErrText: "This field is required"
      });
    } else if (pass == confirmPass) {
      setPassErr({
        newPass: false,
        confPass: false
      });
      setPassErrText({
        newPassErrText: "",
        confPassErrText: ""
      });
    } else {
      setPassErr({
        newPass: false,
        confPass: true
      });
      setPassErrText({
        newPassErrText: "",
        confPassErrText: "Password didn't match."
      });
    }
  };

  const fetchValue = e => {
    if (e.target.id == "newPassInput") {
      setFields({
        ...fields,
        newPassword: (e.target.value).trim(),
        confirmPassword: fields.confirmPassword
      });
    } else if (e.target.id == "confirmPassInput") {
      setFields({
        ...fields,
        newPassword: fields.newPassword,
        confirmPassword: (e.target.value).trim()
      });
    }
  };

  // For state updates that require
  // checking initial and subsequent renders
  const useDidUpdateEffect = () => {
    const didMountRef = useRef(true);

    useEffect(() => {
      didMountRef.current = false;
    }, []);

    return didMountRef.current;
  };

  const updateEffect = useDidUpdateEffect();

  useEffect(() => {
    if (updateEffect) {
      setPassErr({
        newPass: false,
        confPass: false
      });
      setPassErrText({
        newPassErrText: "",
        confPassErrText: ""
      });
    } else if (fields.password === "" && fields.newPassword === "" && fields.confirmPassword === "") {
      setPassErr({
        newPass: false,
        confPass: false
      });
      setPassErrText({
        newPassErrText: "",
        confPassErrText: ""
      });
    } else if (fields.password !== "" && fields.newPassword === "" && fields.confirmPassword === "") {
      setPassErr({
        newPass: false,
        confPass: false
      });
      setPassErrText({
        newPassErrText: "",
        confPassErrText: ""
      });
    } else {
      comparePassword(fields.newPassword, fields.confirmPassword);
    }
  }, [
    passErr.newPass,
    passErr.confPass,
    passErrText.newPassErrText,
    passErrText.confPassErrText,
    fields.password,
    fields.newPassword,
    fields.confirmPassword,
  ]);

  useEffect(() => {
    if (!updateEffect && error && error.visible) {
      setCurrentPassErr(true);
      setFields({
        ...fields,
        password: ''
      });
    } else {
      setCurrentPassErr(false);
    }
  }, [currentPassErr, fields.password, error.visible]);

  const checkpasswordChangeResult = () => {
    if (error && error.visible) {
      handleNotificationErrorOpen();
    }
  };

  const handleResetPassword = () => {
    if (!validateFields()) return;
    dispatch(changePassword(fields));
    checkpasswordChangeResult();
   // setFields(defaultFields)
  }

  const fieldsChanged = () => Object.keys(defaultFields)
    .reduce((acc, key) => (
      acc || JSON.stringify(defaultFields[key]) !== JSON.stringify(fields[key])
    ), false);

  const fieldFilled = () => Object.values(fields).some((val)=>val == '')

  let renderComponent;

  if (error && error.description) {
    renderComponent = <NotificationSnackbar
      error={error}
      notifySnackbarOpen={open}
      handleNotifySnackbarClose={handleNotificationErrorClose}
    />;
  }

  

  return (
    <React.Fragment>
      <form className={classes.root}>
        <h4>Password Change</h4>

        {renderComponent}

        {setSuccess !== null
          ? <SuccessMessage setSuccess={setSuccess} />
          : null
        }
        
        <Box
          className={classes.securityFormContainer}
          display="flex"
          flexDirection="column"
          m=".5em"
        >
          <Grid
            container
            spacing={0}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel label="Login email" />
                <CustomTextField
                  variant="outlined"
                  disabled
                  className={classes.outlinedInput}
                  value={user ? user.email : '...'}
                  type="email"
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel label="Current Password" required />
                <CustomTextField
                  variant="outlined"
                  className={clsx(classes.outlinedInput, classes.currentPasswordInput)}
                  onChange={e => updateField('password', e.target.value)}
                  onFocus={handleCurrentPassFocus}
                  value={fields.password}
                  error={!!errors.password || currentPassErr}
                  type="password"
                  autoComplete="current-password"
                  inputProps={{ placeholder: 'Current Password' }}
                  helperText={errors.password}
                />
              </FormControl>

              {<FormControl className={classes.formControl}>
                <InputLabel label="New Password" required />
                <CustomTextField
                  id="newPassInput"
                  variant="outlined"
                  className={classes.outlinedInput}
                  onChange={e => fetchValue(e)}
                  value={fields.newPassword}
                  error={passErr.newPass}
                  type="password"
                  autoComplete="new-password"
                  inputProps={{ placeholder: 'New Password' }}
                />
                {passErr.newPass &&
                  <FormHelperText>
                    <InfoOutlined fontSize="small" /> {passErrText.newPassErrText}
                  </FormHelperText>
                }
              </FormControl>}

              {<FormControl className={classes.formControl}>
                <InputLabel label="Confirm Password" required />
                <CustomTextField
                  id="confirmPassInput"
                  variant="outlined"
                  className={classes.outlinedInput}
                  onChange={e => fetchValue(e)}
                  value={fields.confirmPassword}
                  error={passErr.confPass}
                  type="password"
                  autoComplete="new-password"
                  inputProps={{ placeholder: 'Confirm Password' }}
                />
                {passErr.confPass &&
                  <FormHelperText>
                    <InfoOutlined fontSize="small" /> {passErrText.confPassErrText}
                  </FormHelperText>
                }
              </FormControl>}

              <Box display="flex" justifyContent="flex-start" flexDirection="column">
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  className={clsx(classes.button, classes.securityResetBtn)}
                  onClick={handleResetPassword}
                  disabled={Object.keys(errors).length > 0 || fieldFilled() || !fieldsChanged() || passErr.newPass || passErr.confPass}
                >Submit</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default withStyles(styles)(ResetPassword);

