import axios from 'axios';
import jwt from 'jsonwebtoken';
import {
  v4 as uuidv4
} from 'uuid';
import { startLoading, stopLoading, setError, resetError } from './index';
import { isLatestLicenseAccepted } from './developerLicense';

import { devportalMidUrl, userId, projectsEdgeLicense } from '../configs/config';

export function setProjects(data) {
  return { type: 'SET_PROJECTS', data };
}

export function setProjectsStatus(data) {
  return { type: 'SET_PROJECTS_STATUS', data };
}

export function setCurrentProject(data) {
  return { type: 'SET_CURRENT_PROJECT', data };
}

export function resetCurrentProject() {
  return { type: 'RESET_CURRENT_PROJECT' };
}

export function setEdgeLicenseExpiryDate() {
  const edgeLicenseData = jwt.decode(projectsEdgeLicense);
  if (edgeLicenseData) {
    const {
      exp
    } = edgeLicenseData;
    const expDate = new Date(0);
    expDate.setUTCSeconds(exp);
    const strExpDate = new Date(expDate).toLocaleDateString('en-US', {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    })
    return { type: 'SET_EDGE_LICENSE_EXPIRY_DATE', exp: strExpDate };
  }
  return undefined;
}

export function fetchAllProjects() {
  return (dispatch) => {
    dispatch(startLoading('fetchProjects'));
    dispatch(setEdgeLicenseExpiryDate());
    dispatch(resetError());
    return axios.get(`${devportalMidUrl}/mID/v1/users/${userId}/apps`, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
      }
    })
      .then((res) => {
        dispatch(isLatestLicenseAccepted());
        dispatch(setProjects(res.data.data.reverse()));
        dispatch(stopLoading('fetchProjects'));
      })
      .catch(err => dispatch(setError('We were unable to fetch your projects.', err.response)));
  };
}

export function fetchProjectById(appId) {
  return (dispatch) => {
    dispatch(startLoading('fetchProjectById'));
    dispatch(resetError());
    return axios.get(`${devportalMidUrl}/mID/v1/users/${userId}/apps/${appId}`, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
      }
    })
      .then((res) => {
        dispatch(setCurrentProject(res.data.data));
        dispatch(stopLoading('fetchProjectById'));
      })
      .catch(err => dispatch(setError('We were unable to fetch your project.', err.response)));
  };
}

export function createProject(payload) {

  payload.client_uri = 'https://example.com/';
  payload.redirect_uris = [`com.example.${uuidv4()}://callback`]; // appeasing validation gods -- won't be used anyways

  return (dispatch) => {
    dispatch(startLoading('createProject'));
    dispatch(resetError());
    return axios.post(`${devportalMidUrl}/mID/v1/users/${userId}/apps`, payload, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
      }
    })
      .then(() => dispatch(fetchAllProjects()))
      .then(() => dispatch(stopLoading('createProject')))
      .catch(err => dispatch(setError('We were unable to create your project.', err.response)));
  };
}

export function updateProject(appId, payload) {
  return (dispatch) => {
    dispatch(startLoading('updateProject'));
    dispatch(resetError());
    return axios.put(`${devportalMidUrl}/mID/v1/users/${userId}/apps/${appId}`, payload, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
      }
    })
      .then(() => {
        dispatch(stopLoading('updateProject'));
      })
      .catch(err => dispatch(setError('We were unable to update your project.', err.response)));
  };
}

export function deleteProject(appId) {
  return (dispatch) => {
    dispatch(startLoading('deleteProject'));
    dispatch(resetError());
    return axios.delete(`${devportalMidUrl}/mID/v1/users/${userId}/apps/${appId}`, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
      }
    })
      .then(() => {
        dispatch(stopLoading('deleteProject'));
      })
      .catch(err => dispatch(setError('We were unable to delete your project.', err.response)));
  };
}
