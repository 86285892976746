import React from "react";
import { SvgIcon } from "@material-ui/core";

function DeveloperDocs(props) {
  return (
    <SvgIcon
      {...props}
      x="0px"
      y="0px"
      width="24" height="24" viewBox="0 0 24 24" 
       fill="none" xmlns="http://www.w3.org/2000/svg"
      style={{color: "rgba(0, 0, 0, 0)"}}
    >
<g clipPath="url(#clip0_3338_7178)">
<path d="M4.98999 8.37V3.96C4.98999 3.16 5.65999 2.5 6.47999 2.5H17.59C18.41 2.5 19.08 3.15 19.08 3.96V10.74" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M16.63 4.90991H7.44" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M16.63 7.05981H11.23" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M11.69 21.4099H3.37003C2.94003 21.4099 2.59003 21.0599 2.59003 20.6299V9.68993C2.59003 8.98993 3.15003 8.42993 3.85003 8.42993H8.03003C8.66003 8.42993 9.24003 8.74993 9.61003 9.25993C10.03 9.82993 10.11 10.7399 11.47 10.8799L20.46 10.8499C21.08 10.8499 21.58 11.3499 21.58 11.9699V15.4699" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M19.18 17.4151C19.18 15.8715 17.9287 14.6201 16.385 14.6201C14.8414 14.6201 13.59 15.8715 13.59 17.4151C13.59 18.9588 14.8414 20.2101 16.385 20.2101C17.9287 20.2101 19.18 18.9588 19.18 17.4151Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M15.26 17.6401C15.26 16.9601 15.82 16.4001 16.5 16.4001" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M18.4301 19.3801L20.5101 21.4601" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
</g>
<defs>
<clipPath id="clip0_3338_7178">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
    </SvgIcon>
  );
}

export default DeveloperDocs;
