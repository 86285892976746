import React from 'react';
import { withStyles } from '@material-ui/styles';

import PasswordReset from './PasswordReset';

const styles = {
  root: {
    flexGrow: 1,
  },
};

const Security = ({ classes, dispatch, user, error, setSuccess }) => (
  <div className={classes.root}>
    <PasswordReset
      dispatch={dispatch}
      user={user}
      error={error}
      setSuccess={setSuccess}
    />
  </div>
);

export default withStyles(styles)(Security);
