import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import Helmet from 'react-helmet';
import { ThemeProvider } from '@material-ui/styles';
import { fetchUser } from '../../actions/user';
import theme from '../../theme';
import Header from './Header';
import Drawer from './Drawer/Index';
import Footer from './Footer';
import NotificationSnackbar from '../Modal/NotificationSnackbar';
import SessionExpiryModal from '../Modal/SessionExpiryModal';
import bgGraphic from '../../assets/images/bg-message-head.png'

const styles = () => ({
  loader: {
    height: '100vh',
  },
  contentContainer: {
    display: 'flex',
    height: '100vh',
  },
  drawer: {
    //
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowX: 'hidden',
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.93) 24.17%, #EEEEEE 100%)'
  },
  content: {
    position: 'relative',
    flex: 1,
    width: '100%',
    margin: '0 auto',
    '@media (max-width: 1366px) and (min-width:767px)': {
      padding: '0 2em',
    },
    '@media (max-width:767px)': {
      padding: '0 1em',
    },

    '& > div': {
      maxWidth: '1150px',
      padding: '0',
      margin: '0 auto'

     
    },

    '& > div:first-child': {
      marginTop: 90,

      '@media (min-width: 768px) and (max-width: 960px)': {
        marginTop: 60
      },

      '@media (max-width: 580px)': {
        marginTop: 120,
      },
    },
  },
  transitionMessage: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',

    alignItems: 'center',
    background: 'linear-gradient(270deg, rgba(62,168,178,0.09845945214023111) 0%, rgba(62,168,178,0) 100%)',

    '& h6': {
      fontWeight: 'bold',
      color: 'rgba(0, 0, 0, 0.85)',
    },

    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  bgGraphic: {
    display: 'block',
    width: '100%',
    maxWidth: '75px',
    height: '75px',
    backgroundImage: `url(${bgGraphic})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  button: {
    minWidth: '6em',
    borderRadius: 30,
    margin: '1em 1.5em 1em 0'
  },
  messageRedirectText: {
    flexGrow: 1,
    textAlign: 'justify',
    padding: 15,
  },
  messageRedirectBtn: {
    minWidth: 245,
  },
  btncloseTransitionMsg: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  subtitleHighlight: {
    color: '#2faab1',
  },
});

const LoggedIn = () => {
  return sessionStorage.getItem('devportal_access_token') !== null;
}

const LayoutComponent = (props) => {
  const {
    dispatch, classes, children, className, error, fixed, user, sessionExpiry
  } = props;

  const [open, setOpen] = useState(false);

  const handleNotificationErrorOpen = () => setOpen(true);
  const handleNotificationErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      console.log(event);
      return;
    }
    setOpen(false);
  };


  useEffect(() => {
      dispatch(fetchUser());

  }, [dispatch]);

  useEffect(() => {
    if (error && typeof error.description === "string") {
      handleNotificationErrorOpen();
    }
  }, [error]);

  useEffect(() => {
    if (fixed) {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
    }
    return () => {
      document.querySelector('html').style.overflow = 'auto';
      document.querySelector('body').style.overflow = 'auto';
    };
  });

  return (
    <ThemeProvider theme={theme}>
      {process.env.NODE_ENV !== 'test' && (
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600&display=swap" rel="stylesheet" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          <script src="https://kit.fontawesome.com/b328d56cac.js" crossOrigin="anonymous" />
        </Helmet>
      )}

     

      <div className={classes.contentContainer}>
        {
          LoggedIn()
            ? <Drawer user={user} className={classes.drawer} />
            : ""
        }
        
        <div className={classes.contentWrapper}>
          <div className={clsx(classes.content, className || '')}>
            {children}
          </div>

          {
            LoggedIn()
              ? <Footer />
              : ""
          }
        </div>
      </div>

      {
        LoggedIn() && (error && typeof error.description === "string")
          ? <NotificationSnackbar
              error={error}
              notifySnackbarOpen={open}
              handleNotifySnackbarClose={handleNotificationErrorClose}
            />
          : ""
      }

      <SessionExpiryModal
        dispatch={dispatch}
        sessionExpiry={sessionExpiry}
      />
      {/* Header should be down here for layout issues, there should be a better way to deal with this!!! */}
      <Header user={user} dispatch={dispatch} />
    </ThemeProvider>
  );
};

export default connect(state => ({
  user: state.user,
  error: state.error,
  sessionExpiry: state.sessionExpiry
}))(withStyles(styles)(LayoutComponent));
