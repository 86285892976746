import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Card,
  CardHeader,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';

import { connect } from 'react-redux';

import CopyButton from './CopyButton';
import IdTokenButton from './IdTokenButton';
import { Cancel } from '@material-ui/icons';
import { cyan } from '@material-ui/core/colors';
import InfoIcon from '../Icons/Info'

const styles = theme => ({
  icon_info: {
    position: 'relative',
    top: '-10px',
    padding:0,
    marginLeft:5,
    backgroundColor: 'transparent',
    '& svg' : {
      height:20,
      fill: 'transparent'
    }
  },
  icon_client_id: {
    height:20,
    fill: 'transparent'
  },
  container_align : {
    margin: '0 auto',
    padding: '0 1em'
  },
  infoContainer: {
    position: 'relative',    
    padding:10,
    margin: '0 0 20px 0',
    borderRadius: 10,
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',

    '@media (max-width: 750px)': {
      padding: '1em',
    },
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '1em 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  infoLabel: {
    width: '10em',
    padding: '6px 0',
    fontWeight: 400,
  },
  infoFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  infoField: {
    flex: 1,
    // maxWidth: 500,
    marginBottom: '.5em',
  },
  copyButton: {
    marginLeft: '.5em',
  },
  buttonIcon: {
    marginRight: '.25em',
  },
  buttonIconSmall: {
    height: '.9em',
    width: '.9em',
  },
  tokenButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputLabelTitle: {
    fontSize: 24,
  },
  inputLabelIcon: {
    width: '.7em',
    height: '.7em',
  },
  popperContainer: {
    maxWidth: 320,
    maxHeight: 280,
    overflowY: 'auto',
    backgroundColor: cyan[50],
  },
  popperInfoText: {
    padding: '0 1em 1em 1em',
  },
});

const ProjectInfo = ({ classes, sandbox, project, developerIdToken, developerSharedIdToken }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [placement, setPlacement] = useState();

  const handleClickTooltip = newPlacement => e => {
    setAnchorEl(e.currentTarget);
    setOpenPopper(prev => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClickAway = () => setOpenPopper(false);

  return (<>
  <div className={classes.container_align} >
    <Paper className={classes.infoContainer} elevation={4}>
      
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box className={classes.infoWrapper}>
          <Typography className={classes.infoLabel}>Client ID<Tooltip
            title="Click to view"
            placement="left"
          >
            <IconButton
              aria-label="info"
              type="button"
              className={classes.icon_info}
              onClick={handleClickTooltip("right-start")}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>

            <Popper
              open={openPopper}
              anchorEl={anchorEl}
              placement={placement}
            >
              <Card className={classes.popperContainer}>
                <CardHeader
                  title="Client ID"
                  action={
                    <IconButton aria-label="close" onClick={() => setOpenPopper(false)}>
                      <Cancel />
                    </IconButton>
                  }
                />
                <Box className={classes.popperInfoText}>
                  <Typography gutterBottom variant="body1">
                    <InfoIcon className={classes.icon_client_id} /> Client ID is an authentication identifier organizing the microservice associated with your project.
                  </Typography>
                </Box>
              </Card>
            </Popper>
          </Typography>

          <Box display="flex" alignItems="center" flex={1}>
            <TextField
              defaultValue={project.client_id}
              className={classes.infoField}
              disabled
            />
            <CopyButton copyString={project.client_id} />
          </Box>
        </Box>
      </ClickAwayListener>
      
      {/* {
        project.redirect_uris && project.redirect_uris.length > 0 &&
        <Box className={classes.infoWrapper}>
          <Typography className={classes.infoLabel}>Redirect URIs</Typography>
          <Box className={classes.infoFieldContainer}>
            {project.redirect_uris.map(uri => (
              <Box display="flex" alignItems="flex-start" key={uri}>
                <TextField
                  defaultValue={uri}
                  className={classes.infoField}
                  disabled
                />
                <CopyButton copyString={uri} />
              </Box>
            ))}
          </Box>
        </Box>
      } */}
      {
        <div className={classes.tokenButtonsContainer}>
          <IdTokenButton sandbox={sandbox} project={project} />
        </div>
      }
      {
        developerIdToken &&
        <>
          <Box className={classes.infoWrapper}>
            <Typography className={classes.infoLabel}>ID Token</Typography>
            <Box display="flex" alignItems="flex-start" flex={1}>
              <TextField
                value={developerIdToken}
                className={classes.infoField}
                helperText={"The ID token will expire within 1 month."}
                multiline
                disabled
              />
              <CopyButton copyString={developerIdToken} />
            </Box>
          </Box>
        </>
      }
      {
        developerSharedIdToken &&
        <>
          <Box className={classes.infoWrapper}>
            <Typography className={classes.infoLabel}>Shared ID Token</Typography>
            <Box display="flex" alignItems="flex-start" flex={1}>
              <TextField
                value={developerSharedIdToken}
                className={classes.infoField}
                helperText={"The Shared Id Token will expire within 24 hour."}
                multiline
                disabled
              />
              <CopyButton copyString={developerSharedIdToken} />
            </Box>
          </Box>
        </>
      }
    </Paper>
    </div>
  </>);
};

export default connect((state) => ({
  developerIdToken: state.developerIdToken,
  developerSharedIdToken: state.developerSharedIdToken,
}))(withStyles(styles)(ProjectInfo));
