import React, { useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  withStyles
} from '@material-ui/core';

import SEO from '../components/SEO';
import GitHubIcon from '../assets/images/github_icon.svg';
import SupportPanelIcon from '../assets/images/SupportPanelIcon.png';
import SupportStackoverflow from '../assets/images/SupportStackoverflow.png';
import SupportDevDocs from '../assets/images/SupportDevDocs.png';
import { devportalPublicUrl } from '../configs/config'

const styles = () => ({
  toolbar: {
    opacity: 1,
    transition: 'opacity 400ms',
  },
  show_on_tablet: {
    display: 'none',
    '@media (min-width:767px) and (max-width:1080px)' : {
      display: 'block'
    }
  },
  hide_on_tablet: {
    display: 'block',
    '@media (min-width:767px) and (max-width:1080px)' : {
      display: 'none'
    }
  },
  card: {
    height: 450,
    borderRadius: 10,
    boxShadow: '0 0 10px rgba(0, 0, 25, 0.07)',
    '& button[type=button]': {
      borderRadius: 25,
      background: 'none',
      border: 0,
      cursor: 'default'
    }
  },
  cardActionArea: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',

    '& > a': {
      display: 'block',
      height: '100%',
      width: '100%',
    },
    '& > a:hover': {
      fontWeight: 700,
      borderWidth: 2
    }
  },
  media: {
    marginTop: 25,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    width: '100%',
  },
  support: {
    // background: 'linear-gradient(#CFE8E6, #C3FFFB)',
    padding: 0,
  },
  supportImage: {
    height: 103,
    width: 231,
  },
  supportContentText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    '& h4': {
      fontSize: '20px',
      fontFamily: 'Nunito',
      fontWeight: '700',
      lineHeight: '27px',
      marginBottom: 10,
      '@media (max-width: 768px)': {
        fontSize: 18
      },
    },
    '& h2': {
      fontSize: '36px',
      fontFamily: 'Nunito',
      fontWeight: '700',
      lineHeight: '49px',
      fontStyle: ' normal'
    },
    '& p': {
      fontSize: '16px',
      fontFamily: 'Nunito',
      fontWeight: '400',
      lineHeight: '23px',
      textAlign: 'center',
      color: '#000000',
      marginBottom: 35,
      minHeight: 42,
      '@media (max-width: 768px)': {
        fontSize: 15
      },
    },
    '& div a': {
      border: '1px solid #3EA8B2',
      borderRadius: '65px',
      padding: '11px 50px',
      height: 50,
      fontSize: 16,
      display: 'flex',
      justifyContent: 'center',
      color: '#3EA8B2',
      minWidth: 260
    },
    '& div a:hover': {
      fontWeight: 700,
      borderWidth: 2
    }
  },
  supportContentText_tab: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
 
    '& p': {
      fontSize: '17px',
      fontFamily: 'Nunito',
      fontWeight: '700',
      lineHeight: '1.6',
      textAlign: 'center',
      padding: '80px 60px 20px',
      color: '#000000',
      marginBottom: 35,
      minHeight: 42,
      '@media (max-width: 768px)': {
        fontSize: 15
      },
    },
    '& p + p': {
      fontWeight: '400'
    },
    '& div a': {
      border: '1px solid #3EA8B2',
      borderRadius: '65px',
      padding: '11px 50px',
      height: 50,
      fontSize: 16,
      display: 'flex',
      justifyContent: 'center',
      color: '#3EA8B2',
      minWidth: 260
    },
    '& div a:hover': {
      fontWeight: 700,
      borderWidth: 2
    }
  },
  supportBottom: {
    width: '100%',
    padding: 35,
    background: '#FFFFFF',
    borderRadius: 10,
    marginTop: 15,
    boxShadow: '0 0 10px rgba(0, 0, 25, 0.07)',
    '@media (max-width: 768px)': {
      padding: 25,
    },
  },
  popperContainer: {
    padding: '24px 24px 30px',
    maxWidth: 320,
    maxHeight: 280,
    overflowY: 'scroll',
  },
  copyText: {
    height: 50,
    width: '100%',
    paddingTop: 12,
    borderStyle: 'none',
    textAlign: 'center',
    fontSize: '1em',
    fontWeight: '300',
    lineHeight: '1.334',
    resize: 'none',
  },
  emailDisplay: {
    width: '100%'
  },
  supportButton: {
    paddingTop: 20,
    '& a': {
      border: '1px solid #3EA8B2',
      borderRadius: '65px',
      padding: '11px 90px',
      height: 50,
      display: 'block',
      justifyContent: 'center',
      color: '#3EA8B2',
      fontSize: 16,
      textAlign: 'center'
    },
    '& a:hover': {
      fontWeight: 700,
      borderWidth: 2
    }
  },
  supportHeading: {

    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '27px',
    color: '#000000',
    fontWeight: 700,
    paddingBottom: 10,
    '@media (max-width: 768px)': {
      fontSize: 18
    },
  },
  supportSubHed: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '25px',
    color: '#000000',
    fontWeight: 400,
    '@media (max-width: 768px)': {
      fontSize: 15
    },
  },
  title_head: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: 36,
    '@media (min-width: 768px)': {
      paddingTop: 68
    },
    '@media (max-width:767px)': {
      fontSize: 24,
      paddingTop: 40,
      paddingBottom: 20
    }
  },
  faq_text: {
    '& a': {
      color: '#3EA8B2',
      textDecoration: 'underline',
      fontWeight: 400,
      '&:hover': {
        color: '#3EA8B2',
        textDecoration: 'underline',
        fontWeight: 700
      }
    }
  }

});

const LoggedIn = () => {
  return sessionStorage.getItem('devportal_access_token') !== null;
}

const SupportMain = props => {
  const { classes } = props;

  const textAreaRef = useRef(null);

  if (!LoggedIn()) {
    return (
      <>
        <Redirect to={`/`} />
      </>
    )
  }

  let timeout;

  window.addEventListener('blur', function (e) {
    e.preventDefault();
    window.clearTimeout(timeout);
  });



  return (
    <>
      <SEO title="Home" />


      <Box sx={{ flexGrow: 1 }}>
        <h2 className={classes.title_head}>support and help</h2>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <Card className={classes.card}>
              <Card
                className={classes.cardActionArea}

              >

                <CardContent className={clsx(classes.media, classes.support)}>
                  <img src={SupportPanelIcon} alt="" />
                </CardContent>
                <CardContent className={classes.supportContentText}>
                  <Typography gutterBottom variant="h4">
                    hybrid edgeCloud community
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    join and contribute to the community around the hybrid edgeCloud platform and related projects.                    </Typography>
                  <Typography className={classes.github_text} style={{
                    fontWeight: 700,
                    fontSize: 18
                  }}><img src={GitHubIcon} alt="" /> GitHub community</Typography>
                  <Box className={classes.email_button}>
                    <a
                      href="https://github.com/edgeEngine/Community"
                      target="_blank"
                      rel="noreferrer"
                    >

                      join the community</a>
                  </Box>

                </CardContent>
              </Card>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <Card className={classes.card}>
              <Card className={classes.cardActionArea}>

                <CardContent className={clsx(classes.media, classes.support)}>
                  <img src={SupportDevDocs} alt="" />
                </CardContent>
                <CardContent className={classes.supportContentText}>
                  <Typography gutterBottom variant="h4">
                    contact an edgeExpert
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    get help by providing the edgeExperts with a clear subject title and clear supporting documents.
                  </Typography>
                  <Typography>
                    support.sdk@mimik.com
                  </Typography>
                  <Box className={classes.email_button}>
                    <a href="mailto:support.sdk@mimik.com" target="_blank" rel="noreferrer" ref={textAreaRef}>let’s send an email</a>
                  </Box>

                  <Box p={2} />
                  {/* 
                    <Typography
                      variant="body2"
                      color="primary"
                    >
                      <a
                        href="https://devdocs.mimik.com/api"
                        target="_blank"
                        rel="noreferrer"
                      >API References</a>
                    </Typography>
                    <Typography variant="body2" color="primary">
                      <a
                        href="https://devdocs.mimik.com/faqs"
                        target="_blank"
                        rel="noreferrer"
                      >FAQ</a>
                    </Typography>
                    <Typography variant="body2" color="primary">
                      <a
                        href="https://devdocs.mimik.com/troubleshooting"
                        target="_blank"
                        rel="noreferrer"
                      >Troubleshooting Questions</a>
                    </Typography> */}
                </CardContent>

              </Card>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <Card className={classes.card}>
              <Card className={classes.cardActionArea}>

                <CardContent className={clsx(classes.media, classes.support)}>
                  <img src={SupportStackoverflow} alt="" />
                </CardContent>
                <CardContent className={classes.supportContentText}>
                  <Typography gutterBottom variant="h4">
                    explore the documentation site
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    try using the search feature in developer documentation, or check out most popular pages.
                  </Typography>
                  <Typography className={classes.faq_text} style={{
                    textDecoration: 'underline'
                  }}> <a href="https://devdocs.mimik.com/faqs" target="_blank" rel="noreferrer">frequently asked questions</a></Typography>
                  <Box className={classes.email_button}>
                    <a href="http://devdocs.mimik.com/" target="_blank" rel="noreferrer">let’s explore the docs </a>
                  </Box>

                </CardContent>

              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} xl={4} className={classes.show_on_tablet}>
            <Card className={classes.card}>
              <Card className={classes.cardActionArea}>


                <CardContent className={classes.supportContentText_tab}>
                  <Typography className={classes.supportHeading}>looking for community support regarding edgeEngine edition for your platform of choice?
                  </Typography>
                  <Typography className={classes.supportSubHed}>participate in edgeEngine discussion groups</Typography>
                  <Typography className={classes.supportButton} >
              <a
                href={devportalPublicUrl + "/community/"}
                target="_blank"
                rel="noreferrer"
              >explore</a>
            </Typography>

                </CardContent>

              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.hide_on_tablet}>
        <Grid className={classes.supportBottom} container>
          <Grid item xs={12} md={8}>
            <Typography className={classes.supportHeading}>looking for community support regarding edgeEngine edition for your platform of choice?
            </Typography>
            <Typography className={classes.supportSubHed}>participate in edgeEngine discussion groups</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.supportButton} >
              <a
                href={devportalPublicUrl + "/community/"}
                target="_blank"
                rel="noreferrer"
              >explore</a>
            </Typography>
          </Grid>
        </Grid>
      </Box>

    </>
  );
};

export default connect(() => ({

}))(withStyles(styles)(SupportMain));
