import React, { useState } from 'react';
import { withStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardHeader,
  ClickAwayListener,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { cyan, red, green } from '@material-ui/core/colors';

import { connect } from 'react-redux';

import CopyButton from './CopyButton';
import SandboxStatus from './SandboxStatus';
import SandboxCreateAccount from './SandboxCreateAccount';
import SandboxLoginAccount from './SandboxLoginAccount';
import DeleteSandboxForm from '../SandboxSettings/DeleteSandboxForm';
import { InfoOutlined } from '@material-ui/icons';

const styles = theme => ({
  infoContainer: {
    margin: '2em auto !important',
    padding: '2em 3em !important',
    borderRadius: 10,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.12)',

    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },

    '@media (max-width: 1366px)': {
      margin: '2em 1em !important',
    },

    '@media (max-width: 1300px)': {
      margin: '2em 0 !important',
    },

    '@media (max-width: 768px)': {
      padding: '1em 1em !important',
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  deleteContainer: {
    margin: '2em auto !important',
    padding: '2em 3em !important',
    borderRadius: 10,
    border: `1px solid ${red[700]}`,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.12)',

    '@media (max-width: 1366px)': {
      margin: '2em 1em !important',
    },

    '@media (max-width: 1300px)': {
      margin: '2em 0 !important',
    },

    '@media (max-width: 768px)': {
      padding: '1em 1em !important',
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '1em 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  infoLabel: {
    width: '10em',
    padding: '6px 15px 0 0',
    fontWeight: 400,
  },
  infoLabel2: {
    width: 'auto',
   
  },
  infoFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  infoField: {
    flex: 1,
    marginBottom: '.5em',

    '& input': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  button: {
    width: '12em',
    minWidth: '2em',
    marginTop: '1.5em',
    borderRadius: 30,
  },
  copyButton: {
    marginLeft: '.5em',
  },
  buttonIcon: {
    marginRight: '.25em',
  },
  buttonIconSmall: {
    height: '.9em',
    width: '.9em',
  },
  tokenButtonsWrapper: {
    width: '100%',
    minHeight: 75
  },
  tokenButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    transition: 'transform 0.8s',
    opacity: 1,
    height: 75,
  },
  loginButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.8s',
    opacity: 1,
    height: 'auto',
  },
  buttonsContainerHidden: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    transition: 'transform 0.8s',
    opacity: 0,
    height: 0,
  },
  generateButton: {
    width: '8em',
    background: green[700],
    color: '#fff',

    borderRadius: 30,
    '&:hover': {
      background: green[800],
    },
    '@media (max-width: 550px)': {
      alignSelf: 'flex-end',
      marginTop: '1em',
    },
  },
  popperContainer: {
    maxWidth: 320,
    maxHeight: 280,
    overflowY: 'auto',
    backgroundColor: cyan[100],
    borderRadius: 10,
  },
  popperInfoText: {
    padding: '0 1em 1em 1em',
  },
  infoIconStyle: {
    padding: 0,
    position: 'relative',
    top: '-5px'
  },
  infoContainerClientID: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width:767px)' : {
      marginTop: '30px !important'
    }
  },
  infoContainerEdgeLisc: {
    position: 'relative',

    '& .MuiInput-root': {
      width: '95%',
    },
  },
  fontBold: {
    fontWeight: 'bold',
    '@media (max-width:767px)' : {
      marginBottom: 40
    }
  },
  edgeLicenseCopy: {
    position: 'absolute',
    top: '1em',
    right: '3em',
  },
});

const SandboxInfo = (props) => {
  const { classes, sandbox, dispatch, history } = props;

  // const [sandboxToken, setSandboxToken] = useState(null);
  const [createFormVisible, setCreateFormVisible] = useState(false);
  const [loginFormVisible, setLoginFormVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [openPopper2, setOpenPopper2] = useState(false);
  const [placement, setPlacement] = useState();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const hideLoginForm = () => setLoginFormVisible(false);
  const hideCreateForm = () => setCreateFormVisible(false);

  const setToken = (token) => {
    console.log(token);
    setLoginFormVisible(false);
    setCreateFormVisible(false);
  }

  const handleClickTooltip = newPlacement => e => {
    setAnchorEl(e.currentTarget);
    setOpenPopper(prev => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const handleClickTooltip2 = newPlacement => e => {
    setAnchorEl2(e.currentTarget);
    setOpenPopper2(prev => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClickAway = () => setOpenPopper(false);
  const handleClickAway2 = () => setOpenPopper2(false);


  if (sandbox && sandbox.status && sandbox.status !== 'deployed') return (
    <>
      <Paper className={classes.deleteContainer} elevation={4}>
        <Box >
          <DeleteSandboxForm
            dispatch={dispatch}
            sandbox={sandbox}
            onSubmit={() => history.push(`/hybrid_projects`)}
          />
        </Box>
      </Paper>
    </>
  );

  return (
    <>
      <Paper className={clsx(classes.infoContainer, classes.infoContainerClientID)}>
        <ClickAwayListener onClickAway={handleClickAway2}>
          <Typography className={clsx(classes.infoLabel, classes.infoLabel2)}>Client ID<Tooltip
            title="Click to view"
            placement="left"
          >
            <IconButton
              className={classes.infoIconStyle}
              aria-label="info"
              color="primary"
              type="button"
              onClick={handleClickTooltip2("right-start")}
            >
              <InfoOutlined
                fontSize="small"
              />
            </IconButton>
          </Tooltip>

            <Popper
              open={openPopper2}
              anchorEl={anchorEl2}
              placement={placement}
            >
              <Card className={classes.popperContainer}>
                <CardHeader
                  title={<><InfoOutlined /> Client ID</>}
                />
                <Box className={classes.popperInfoText}>
                  <Typography gutterBottom variant="body1">
                    Client ID is an authentication identifier organizing the microservice associated with your project.
                  </Typography>
                </Box>
              </Card>
            </Popper>
          </Typography>
        </ClickAwayListener>

        <Box display="flex" alignItems="flex-start" flex={1}>
          <TextField
            defaultValue={sandbox.clientId}
            className={classes.infoField}
            disabled
          />
        </Box>

        <Box color="primary.main">
          <CopyButton copyString={sandbox.clientId} /> Copy
        </Box>
      </Paper>

      <Paper className={classes.infoContainer}>
        <Box >
          <SandboxStatus sandbox={sandbox} dispatch={dispatch} />
        </Box>
      </Paper>

      <Paper className={clsx(classes.infoContainer, classes.infoContainerEdgeLisc)}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Typography className={clsx(classes.infoLabel, classes.infoLabel2, classes.fontBold)}>Edge License<Tooltip
            title="Click to view"
            placement="left"
          >
            <IconButton
              className={classes.infoIconStyle}
              aria-label="info"
              color="primary"
              type="button"
              onClick={handleClickTooltip("right-start")}
            >
              <InfoOutlined
                fontSize="small"
              />
            </IconButton>
          </Tooltip>

          <Popper
            open={openPopper}
            anchorEl={anchorEl}
            placement={placement}
          >
            <Card className={classes.popperContainer}>
              <CardHeader
                  title={<><InfoOutlined /> Edge License</>}
              />
              <Box className={classes.popperInfoText}>
                <Typography gutterBottom variant="body1">
                  You need the edge license to run edgeEngine, deploy edge microservices and authenticate users.
                </Typography>
              </Box>
            </Card>
          </Popper>
        </Typography>
        </ClickAwayListener>
        
        <Box display="flex" alignItems="flex-start" flex={1}>
          <TextField
            defaultValue={sandbox.edgeLicense}
            className={classes.infoField}
            disabled
          />

          <Box color="primary.main" className={classes.edgeLicenseCopy}>
            <CopyButton copyString={sandbox.edgeLicense} /> Copy
          </Box>
        </Box>
      </Paper>

      <Paper className={classes.deleteContainer}>
        <Box >
          <DeleteSandboxForm
            dispatch={dispatch}
            sandbox={sandbox}
            onSubmit={() => history.push(`/hybrid_projects`)}
          />
        </Box>
      </Paper>

      <Dialog
        fullScreen={fullScreen}
        // fullWidth="true"
        maxWidth="xl"
        open={createFormVisible}
        onClose={hideCreateForm}
        aria-labelledby="account create form"
      >
        <DialogContent>
          <SandboxCreateAccount sandbox={sandbox} setToken={(token) => setToken(token)} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        // fullWidth="true"
        maxWidth="xl"
        open={loginFormVisible}
        onClose={hideLoginForm}
        aria-labelledby="login form"
      >
        <DialogContent>
          <SandboxLoginAccount sandbox={sandbox} setToken={(token) => setToken(token)} />
        </DialogContent>
      </Dialog>
    </>
  )
};

export default connect((state) => ({
  developerIdToken: state.developerIdToken,
  developerSharedIdToken: state.developerSharedIdToken,
}))(withStyles(styles)(SandboxInfo));
