/* 

This method will be completely removed soon.

*/

import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { OpenInNew } from '@material-ui/icons';

import { getOauthDeeplink } from '../../actions/index';


const styles = (theme) => ({
    buttonIcon: {
        marginRight: '.25em',
    },
    infoField: {
        flex: 1,
        maxWidth: 500,
        marginBottom: '.5em',
    },
    infoLabel: {
        width: '10em',
        padding: '6px 0',
        fontWeight: 400,
    },
    infoWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        margin: '1em 0',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'unset',
        },
    },
});

const OpenOAuthToolButton = ({ classes, dispatch, oauthDeeplink }) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const checkCutomProtocol = () => {
        setOpen(true);
    }

    const handleOpenTool = () => {
        setTimeout(() => {
            setOpen(false);
        }, 200);
        window.open(`${oauthDeeplink}`, '_blank');
    }

    useEffect(() => {
        dispatch(getOauthDeeplink());
    }, [dispatch]);

    return (
        <>
            <Box className={classes.infoWrapper}>
                <Button
                    variant="contained"
                    onClick={checkCutomProtocol}
                    color="primary"
                    className={clsx(classes.button, 'link')}
                >
                    <OpenInNew className={clsx(classes.buttonIcon, classes.buttonIconSmall)} />Open OAuth Tool
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                >
                    <DialogTitle id="alert-dialog-title">
                        {<span style={{ fontSize: "1.5em" }}>This method is deprecated and will be removed soon.</span>}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ width: "100%" }}>
                            <p style={{ textAlignVertical: "center", textAlign: "center", paddingTop: "10px" }}>
                                <Button onClick={handleOpenTool} variant="outlined" color="primary">
                                    <OpenInNew />Open OAuth Tool
                                </Button>
                            </p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
};

export default connect(state => ({
    oauthDeeplink: state.oauthDeeplink,
}))(withStyles(styles)(OpenOAuthToolButton));
