import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import {
  swaggerhubUrl
} from '../../configs/config';
import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Link,
  TableRow,
  Typography
} from '@material-ui/core';
import { CheckCircle, HighlightOff } from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';

import CopyButton from './CopyButton';
import { getSandboxStatus } from '../../actions/sandboxes';
import Loader from '../Loader';
import iconSwaggerLogo from '../../assets/images/iconSwaggerLogo.png';

const styles = theme => ({
  root: {
    marginTop: '4em',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '3em 0',
    width: '100%',
  },
  statusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    minWidth: 'calc(300px - 2em)',
    margin: '1em',
  },
  statusImgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 200,
  },
  statusImg: {
    height: '100%',
  },
  statusTitle: {
    fontSize: '1.1em',
  },
  fontBold: {
    fontWeight: 'bold',
  },
  table: {
    '& > thead > tr > th': {
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      borderBottom: '1px solid rgb(0, 0, 0)',
    },
  },
  copyURL: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chipBtn: {
    borderRadius: 5,
    padding: 5,
    fontSize: 12,
    cursor: 'pointer'
  },
  urlContent: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 210,
  },
});

const IconSwaggerLogo = () => {
  return (<img alt="API Documentation" src={iconSwaggerLogo} />);
};

const SandboxStatus = (props) => {
  const {
    classes, sandbox, dispatch, loading, status,
  } = props;
  const getMappedName = (e) => {
    const  documentationNames = [{
      name : 'assessment',
      mappedName : 'Assessment'
    },{
      name : 'eventscheduler',
      mappedName : 'EventScheduler'
    },{
      name : 'mfd',
      mappedName : 'mFD'
    },{
      name : 'mid',
      mappedName : 'mID'
    },{
      name : 'mpo',
      mappedName : 'mPO'
    },{
      name : 'notification',
      mappedName : 'Notification'
    },{
      name : 'trackers',
      mappedName : 'Trackers'
    }]
    let mappedName = ''
    for(let eachNames of documentationNames) {
      if(eachNames.name == e) {
        mappedName = eachNames.mappedName
      }
    } 
    return mappedName
  }

  const getDocumentationUrl = (serviceInfo) => {
    if (serviceInfo.status === 200) {
      return `${swaggerhubUrl}/${getMappedName(serviceInfo.data.data.name)}/${serviceInfo.data.data.swaggerFileVersion}`
    } else {
      return `#`
    }
  }

  const getDocumentationName = (serviceInfo) => {
    if (serviceInfo.status === 200) {
      return `${getMappedName(serviceInfo.data.data.name)}`
    } else {
      return null
    }
  }
  const getStatusIcon = (status) => {
    if (status === 200) {
      return <CheckCircle style={{ color: green[700] }} />;
    } else {
      return <HighlightOff style={{ color: red[700] }} />;
    }
  }

  const useInterval = (callback, delay) => {
    const intervalRef = useRef(null);
    const savedCallback = useRef(callback);
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      const tick = () => savedCallback.current();
      if (typeof delay === 'number') {
        intervalRef.current = window.setInterval(tick, delay);
        return () => window.clearInterval(intervalRef.current);
      }
    }, [delay]);
    return intervalRef;
  }

  if (sandbox && sandbox.status && sandbox.status === 'deployed') {
    useInterval(() => {
      dispatch(getSandboxStatus(sandbox.id, sandbox.services));
    }, 60000);
  }

  useEffect(() => {
    if (sandbox && sandbox.status && sandbox.status === 'deployed') {
      dispatch(getSandboxStatus(sandbox.id, []));
      setTimeout(() => {
        dispatch(getSandboxStatus(sandbox.id, sandbox.services));
      }, 800)
    } else {
      dispatch(getSandboxStatus(sandbox.id, []));
    }
  }, [dispatch, sandbox]);

  const getCurrentTime = () => {
    const current = new Date();
    return current.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    });
  }

  const getCurrentDate = () => {
    const current = new Date();

    return current.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric"
    });
  };

  if (!sandbox || !status || status.length === 0 || loading.includes('getSandboxStatus')) return (
    <>
      <Typography className={classes.infoLabel}>System status as of {getCurrentTime()}</Typography>
      <Typography className={clsx(classes.infoLabel, classes.fontBold)}>{getCurrentDate()}</Typography>
      <Loader short />
    </>
  );

  return (
    <>
      <Typography className={classes.infoLabel}>System status as of {getCurrentTime()}</Typography>
      <Typography className={clsx(classes.infoLabel, classes.fontBold)}>{getCurrentDate()}</Typography>

      {status && status.length > 0 ?
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>API documentation</TableCell>
                <TableCell>Base URL</TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell component="th" scope="row">
                    {getDocumentationName(row)}
                  </TableCell>
                  <TableCell>
                     <Link target="_blank" href={getDocumentationUrl(row)}>
                      <Chip
                        className={classes.chipBtn}
                        icon={<IconSwaggerLogo />}
                        label="API Documentation"
                        color="primary"
                      />
                    </Link>
                  </TableCell>
                  <TableCell className={classes.urlContent}>{row.serviceUrl.replace('/admin/healthcheck', '').replace('/healthcheck', '')}</TableCell>
                  <TableCell>
                    <Box
                      className={classes.copyURL}
                      color="primary.main"
                    >
                      <CopyButton copyString={row.serviceUrl.replace('/admin/healthcheck', '').replace('/healthcheck', '')} /> <Typography variant="body2">Copy</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{getStatusIcon(row.status)}</TableCell>
                  <TableCell align="right">{row.status} [{row.statusText}]</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer >
        : ''}
    </>
  );

};

export default connect(state => ({
  loading: state.loading,
  status: state.sandboxesStatus,
}))(withStyles(styles)(SandboxStatus));
