export const defaultState = null;

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'SET_MFA_TOKEN':
      return action.value;
    case 'RESET_MFA_TOKEN':
      return defaultState;
    default:
      return state;
  }
}
