import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Refresh from '@material-ui/icons/Refresh';

import errorImage from '../../assets/images/working.png';

const styles = () => ({
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: 300,
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  button: {
    margin: '0 .25em',
  },
  buttonIcon: {
    marginRight: '.25em',
    width: '.9em',
    height: '.9em',
  },
  paper: {
    position: 'relative',
    minWidth: 500,
    padding: '2em',
  },
  header: {
    fontSize: '1.3em',
    fontWeight: 400,
  },
  subheader: {
    fontSize: '1.1em',
  },
});

const ErrorModal = ({ classes, history: { goBack }, error }) => (
  <Dialog
    open={error.visible && error.description.trim() !== ''}
    classes={{ paper: classes.paper }}
  >
    <Typography className={classes.header}>
      {error.statusCode && error.title
        ? `${error.statusCode} ${error.title}`
        : 'Something went wrong...'
      }
    </Typography>
    <Typography className={classes.subheader}>
      {error.description}
    </Typography>

    <div className={classes.imageWrapper}>
      <img
        className={classes.image}
        src={errorImage}
        alt={error.description}
      />
    </div>

    <Typography>
      If you continue to experience issues, please{' '}
      <a
        href={`${process.env.REACT_APP_PLATFORM_URL}/support`}
        className="link"
      >
        contact support.
      </a>
    </Typography>

    <Box display="flex" justifyContent="space-between" mt="1em" >
      <Button
        color="primary"
        className={classes.button}
        onClick={goBack}
      >
        <ArrowBack className={classes.buttonIcon} />
        Go Back
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => window.location.reload()}
      >
        <Refresh className={classes.buttonIcon} />
        Refresh Page
      </Button>
    </Box>
  </Dialog>
);

export default withRouter(withStyles(styles)(ErrorModal));
