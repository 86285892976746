import React, { useLayoutEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SharedKey from '../Icons/sharedKey'

import { getDeveloperSharedIdToken } from '../../actions/index';
// import CopyButton from './CopyButton';


const styles = (theme) => ({
    button: {
        borderRadius: 30,
        '&:hover' : {
            border: '2px solid #3EA8B2',
            fontWeight: 500,
            backgroundColor: '#ffffff',
            boxSizing: 'border-box'
        }
    },
    buttonIcon: {
        marginRight: '.25em',
    },
    infoField: {
        flex: 1,
        maxWidth: 500,
        marginBottom: '.5em',
    },
    infoLabel: {
        width: '10em',
        padding: '6px 0',
        fontWeight: 400,
    },
    infoWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        margin: '1em 0',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'unset',
        },
        '@media (max-width:767px)' : {
            margin:0
        }
    },
});

const SharedIdTokenButton = ({ classes, project, dispatch }) => {
    useLayoutEffect(() => {
        dispatch(getDeveloperSharedIdToken(undefined));
    }, [project, dispatch]);
    return (
        <Box className={classes.infoWrapper}>
            <Button 
                className={classes.button}
                variant="outlined"
                color="secondary"
                size="large"
                onClick={() => dispatch(getDeveloperSharedIdToken(project.client_id))}>
                <SharedKey className={classes.buttonIcon} />  Get Shared ID Token
            </Button>
        </Box>
    )
};


export default connect(() => ({}))(withStyles(styles)(SharedIdTokenButton));
