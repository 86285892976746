export const defaultState = {
  title: null,
  message: null,
  description: null,
  statusCode: null,
  visible: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'SET_ERROR': {
      const { error } = action;
      const data = error && error.data ? error.data : {};
      return {
        description: action.description,
        message: data.message,
        title: data.title,
        statusCode: data.statusCode,
        visible: true,
      };
    }
    case 'RESET_ERROR':
      return defaultState;
    default:
      return state;
  }
}
