import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function Info(props) {
  return (
    <SvgIcon
      {...props}
      width="16" height="16" viewBox="0 0 16 16" fill="transparent"
    >
      <g clipPath="url(#clip0_2319_8789)">
        <circle cx="8" cy="8" r="7.33333" stroke="#3EA8B2" strokeWidth="1.33333" />
        <path d="M7.99946 5.33084C7.66613 5.33084 7.24968 5.24772 6.99946 4.99751C6.66613 4.66417 6.66613 3.99751 6.66613 3.85099C6.66613 3.46737 6.78024 3.17366 7.00847 2.96987C7.24871 2.76607 7.57904 2.66417 7.99946 2.66417C8.88835 2.66417 9.33279 3.05978 9.33279 3.85099C9.33279 4.6422 9.1346 5.33084 7.99946 5.33084ZM7.99946 13.6641C7.6391 13.6641 7.36282 13.5562 7.17063 13.3404C6.97844 13.1246 6.88234 12.8249 6.88234 12.4413V6.99683C6.88234 6.18164 7.25472 5.77405 7.99946 5.77405C8.75622 5.77405 9.1346 6.18164 9.1346 6.99683V12.4413C9.1346 12.8249 9.0385 13.1246 8.84631 13.3404C8.65412 13.5562 8.37183 13.6641 7.99946 13.6641Z" fill="#3EA8B2" stroke="white" strokeWidth="0.666667" />
      </g>
      <defs>
        <clipPath id="clip0_2319_8789">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default Info;