import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import DrawerLeft from '../../Icons/DrawerLeft'
import DrawerRight from '../../Icons/DrawerRight'
import theme from '../../../theme';

const styles = {
  drawerToggle: {
    position: 'absolute',
    top: '50%',
    marginTop:40,
    right: '-1em',
    background: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.1), 0px 6px 10px 0px rgba(0,0,0,0.07), 0px 1px 18px 0px rgba(0,0,0,0.06)',
    zIndex: theme.zIndex.permanentDrawer + 1,
    '&:hover': {
      background: '#efefef',
    },
  },
  drawerPaper: {
    position: 'relative',
    height: '100%',
    paddingTop: '1em',
    overflow: 'visible',
    border: 'none',
    '@media (max-width:767px)' : {
      flexDirection :'column-reverse'
    }
  },
  drawerOpen: {
    width: 250,
    '@media (min-width:767px) and (max-width:1024px)' : {
      width: 240
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 110,
  },
};

const DrawerComponent = ({ classes, children, drawerOpen, closeDrawer, toggleDrawer }) => (
  <Drawer
    className={clsx({
      [classes.drawerOpen]: drawerOpen,
      [classes.drawerClose]: !drawerOpen,
    })}
    classes={{
      paper: clsx({
        [classes.drawerPaper]: true,
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen,
      }),
    }}
    anchor="left"
    variant="permanent"
    open={drawerOpen}
    onClose={closeDrawer}
  >
    <Fab
      className={classes.drawerToggle}
      size="small"
      onClick={toggleDrawer}
    >
      {drawerOpen ? <DrawerLeft /> : <DrawerRight />}
    </Fab>
    {children}
  </Drawer>
);

export default withStyles(styles)(DrawerComponent);
