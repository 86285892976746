import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/styles';
import { Box, Card, CardContent, CardHeader, Grid, Toolbar, Typography } from '@material-ui/core'; // Link
import { CardMembership } from '@material-ui/icons'; // SystemUpdateAlt

import SEO from '../components/SEO';
import { fetchCertificates } from '../actions/lms';
import Loader from '../components/Loader';

const styles = {
  toolbar: {
    opacity: 1,
    transition: 'opacity 400ms',
  },
  toolbarHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  card: {
    width: 305,
    boxShadow: '0px 8px 16px #0000001f',
  },
  cardHeader: {
    textAlign: 'center',
    background: '#F0F0F0F0'
  },
  button: {
    marginRight: '.5em',
  },
  buttonIcon: {
    marginRight: '.25em',
  },
  buttonIconSmall: {
    height: '.9em',
    width: '.9em',
  },
};

const LoggedIn = () => {
  return sessionStorage.getItem('devportal_access_token') !== null;
}

const getFormattedDate = (dateEpoch) => {
  if (isNaN(dateEpoch)) {
    return '';
  } else {
    const date = new Date(0);
    date.setUTCSeconds(dateEpoch);
    return date.toLocaleDateString();
  }
};

const MyCertificates = (props) => {
  const {
    dispatch,
    classes,
    certificates
  } = props;

  if (!LoggedIn()) {
    return (
      <>
        <Redirect to={`/`} />
      </>
    )
  }

  let timeout;

  window.addEventListener('blur', function (e) {
    e.preventDefault();
    window.clearTimeout(timeout);
  })

  useEffect(() => {
    console.log(certificates);

    dispatch(fetchCertificates());
  }, [dispatch]);

  if (!certificates) {
    console.log('certificates not found');
    return <Loader fullPage />;
  }

  return (
    <React.Fragment>
      <SEO title="Certificates" />
      <Toolbar
        disableGutters
        className={classes.toolbar}
      >
        <Typography variant="h1">Certificates</Typography>
      </Toolbar>

      <Box sx={{ flexGrow: 1 }} pt={4}>
        {certificates !== null ? (<Grid container spacing={2}>
          <Grid item m={4}>
            <Card className={classes.card}>
              <CardHeader
                title={<CardMembership />}
                className={classes.cardHeader}
              />

              <CardContent>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="nowrap"
                  justifyContent="center"
                >
                  <div>
                    <Typography
                      variant="caption"
                      align="center"
                    >Certified in</Typography>
                    <Typography
                      variant="h4"
                      align="center"
                    >{certificates.title}</Typography>
                  </div>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="nowrap"
                  justifyContent="space-between"
                >
                  <div>
                    <Typography variant="caption">Earned on:</Typography>
                    <Typography>{getFormattedDate(certificates.date_earned)}</Typography>
                  </div>
                  {/* <Link
                    className={classes.links}
                    href={certificates.link}
                  >
                    <SystemUpdateAlt />
                  </Link> */}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>) : ('')}
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  certificates: state.certificate
});

export default connect(mapStateToProps)(withStyles(styles)(MyCertificates));
