import axios from 'axios';
import {
    startLoading,
    stopLoading,
    setError,
    resetError
} from './index';

import {
    devportalAssessmentUrl
} from '../configs/config';

const LICENSE_TYPE = 'DeveloperAgreement';

export function getLicense(data) {
    return {type: 'GET_LICENSE', data};
}

export function setCurrentLicense(data) {
    return { type: 'SET_CURRENT_LICENSE', data };
  }
  
  export function resetLicense(data) {
    return { type: 'RESET_LICENSE', data };
  }

function getAllLicensesCall() {
    return axios.get(`${devportalAssessmentUrl}/assessmentengine/v1/assessments?type=${LICENSE_TYPE}`, {
        headers: {
            'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
        }
    })
}

function getLatestLicenseCall() {
    return axios.get(`${devportalAssessmentUrl}/assessmentengine/v1/assessments?type=${LICENSE_TYPE}&version=latest`, {
        headers: {
            'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
        }
    });
}

function getLicenseResponseCall(version) {
    return axios.get(`${devportalAssessmentUrl}/assessmentengine/v1/users/me/assessmentResponses?type=${LICENSE_TYPE}&version=${version}`, {
        headers: {
            'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
        }
    });
}

function postLicenseResponseCall(assessment, accepted) {
    const body = {
        userId: 'me',
        assessmentId: assessment.id,
        responses: {
            agree: accepted
        }
    };
    return axios.post(`${devportalAssessmentUrl}/assessmentengine/v1/users/me/assessmentResponses`, body, {
        headers: {
            'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
        }
    });
}

// Get only latest agreement
export function getLatestAgreement() {
    return dispatch => {
        dispatch(startLoading('getLatestAgreement'));
        dispatch(resetError());

        getLatestLicenseCall()
            .then((result) => {
                const agreementItem = result.data.data;
                const agreementInfo = []; 
                let agrementVerified = null
                
                getLicenseResponseCall(result.data.data[0].version)
                    .then(res => {
                        const versionItem = res.data.data;
                       
                        if (Object.keys(versionItem[0]).length > 0) {
                            agreementInfo.push(versionItem);
                            agrementVerified = (agreementItem && agreementItem.length) == (agreementInfo && agreementInfo.length)
                        }

                        dispatch(getLicense({
                            agreementItem,
                            agreementInfo,
                            agrementVerified
                        }))
                    })
                    .catch(() => dispatch(getLicense({
                        agreementItem,
                        agreementInfo,
                        agrementVerified
                    })))

                dispatch(stopLoading('getAgreements'));
            })
            .catch(err => dispatch(setError('We were unable to fetch your terms of service agreements.', err.response)));

    };
}

// Get all available agreements
export function getAgreements() {
    return dispatch => {
        dispatch(startLoading('getAgreements'));
        dispatch(resetError());

        getAllLicensesCall()
            .then(result => {
                const agreementList = result.data.data;

                const agreementInfo = [];

                agreementList.map((item) => {
                    getLicenseResponseCall(item.version)
                        .then(res => {
                            const versionItem = res.data.data;

                            agreementInfo.push(versionItem);

                            dispatch(getLicense({
                                agreementList,
                                agreementInfo
                            }));
                        })
                        .catch(err => dispatch(setError('We were unable to fetch your information regarding terms of service agreements.', err.response)));
                });

                dispatch(stopLoading('getAgreements'));
            })
            .catch(err => dispatch(setError('We were unable to fetch your terms of service agreements.', err.response)));
    };
}

export function isLatestLicenseAccepted() {
    return (dispatch) => {
        // console.log('isLatestLicenseAccepted');
        dispatch(startLoading('isLatestLicenseAccepted'));
        // dispatch(resetError());
        getLatestLicenseCall()
            .then((result) => {
                // console.dir(result);
                if (result && result.data && Object.keys(result.data).length > 0) {
                    getLicenseResponseCall(result.data.data[0].version)
                    .then((response) => {
                        // console.dir(response);
                        if (response && response.data && response.data.data.length == 0) {
                         //   console.log(result.data.data[0]);
                            dispatch(setCurrentLicense(result.data.data[0]));
                            dispatch(stopLoading('isLatestLicenseAccepted'));
                        }
                    })
                    .catch(err => {
                        dispatch(setError('We were unable to fetch your developer agreement status.', err.response))
                        dispatch(stopLoading('isLatestLicenseAccepted'));
                    });                    
                }

                
            })
            .catch(err => dispatch(setError('We were unable to fetch your developer agreement status.', err.response)));
    }
}

export function postLicenseResponse(data, accepted) {
    return (dispatch) => {
        dispatch(startLoading('postLicenseResponse'));
        dispatch(resetError());
        if (data && data.id && data.type && data.type === LICENSE_TYPE) {
            postLicenseResponseCall(data, accepted)
            .then(() => {
                // console.dir(result);
                dispatch(resetLicense());
                dispatch(getLatestAgreement())
                dispatch(stopLoading('postLicenseResponse'));
            })
            .catch(err => dispatch(setError('We were unable to update your developer agreement status.', err.response)));
        } else {
            dispatch(setError('We were unable to update your developer agreement status.', 'Invalid response'));
        }
    }
}

export function postLicenseProfileResponse(data, accepted, dialogClose) {
    return (dispatch) => {
        dispatch(startLoading('postLicenseResponse'));
        dispatch(resetError());
        if (data && data.id && data.type && data.type === LICENSE_TYPE) {
            postLicenseResponseCall(data, accepted)
            .then(() => {
                dispatch(resetLicense());
                dispatch(getLatestAgreement())
                dialogClose()
                dispatch(stopLoading('postLicenseResponse'));
            })
            .catch(err => dispatch(setError('We were unable to update your developer agreement status.', err.response)));
        } else {
            dispatch(setError('We were unable to update your developer agreement status.', 'Invalid response'));
        }
    }
}