import React from 'react';
import { withStyles } from '@material-ui/styles';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Error from '@material-ui/icons/Error';
import red from '@material-ui/core/colors/red';
import { Box } from '@material-ui/core';

const styles = () => ({
  errMessageContainer: {
    maxWidth: '75%',
    margin: '0 auto 1em',

    '@media (max-width: 767px)': {
      margin: '0 1.5em 1em',
      maxWidth: '300px',
    },
  },
  errorMessage: {
    padding: '1em 2em',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '15px',
    position: 'relative',
    color: red[600],
  },
  errorMessageIcon: {
    position: 'absolute',
    top: 'calc(50% - 0.75em)',
    left: '-0.75em',
    width: '1.5em',
    height: '1.5em',
    marginRight: '.75em',
  },
});

const FormErrors = ({ classes, errors }) => (
  Object.keys(errors).map(key => (
    <Collapse key={key} in={!!errors[key] && typeof errors[key] === 'string'}>
      <Box className={classes.errMessageContainer}>
        <Typography className={classes.errorMessage} variant="body1" m="1em">
          <Error className={classes.errorMessageIcon} />
          {typeof errors[key] === 'string' ? errors[key] : ''}
        </Typography>
      </Box>
    </Collapse>
  ))
);

export default withStyles(styles)(FormErrors);
