import React from 'react';
import { connect } from 'react-redux';
// import { Redirect } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import { fetchUser } from '../actions/user';
import logout from '../actions/logout';
import ErrorModal from '../components/Modal/ErrorModal';

const LogoutPage = (props) => {
  const {
    dispatch, error,
  } = props;

  let renderComponent;
  if (error && error.description) {
    renderComponent = <ErrorModal error={error} />;
  }
  
  logout();
  // Must be after logout
  dispatch(fetchUser());

  return (
    <>
      {renderComponent}
      <Redirect to={`/login`} />
    </>
  )
};

export default connect((state) => ({
  error: state.error,
}))(LogoutPage);

