import React from 'react';
import {
  Box,
  Button,
  Dialog,
  Grid,
  Typography,
  withStyles
} from '@material-ui/core';

const styles = (theme) => ({
  dialogSubtitle: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,

  },
  imageUplaoadDialog: {

    '& .MuiDialog-paperWidthSm': {
      maxWidth: '50%',

      '@media (max-width: 1410px)': {
        maxWidth: '90%',
      },
    },
  },
  imageUploadContainer: {
    position: 'relative',
  },
  btnSection: {
    position: 'absolute',
    bottom: 40,
    right: 0,
    width: '41.666667%',
    padding: 8,
    '@media (max-width: 750px)': {
      width: '100%',
      position: 'relative',
      bottom: 0
    },
  },
  button: {
    minWidth: '6em',
    borderRadius: 30,
    margin: '1em 0 0',
  },
});

const ImageInputModal = props => {
  const {
    classes,
    children,
    dialogOpen,
    handleClose,
    disableSaveBtn,
    onUpload,
    cropped,
  } = props;

  const updateImageURL = () => {
    if (typeof onUpload === 'function') onUpload(cropped);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        className={classes.imageUplaoadDialog}
      >
        <Box
          padding="2em"
        >
          <Grid
            container
            spacing={2}
            className={classes.imageUploadContainer}
          >
            <Grid item md={12}>
              <Typography
                className={classes.dialogSubtitle}
                variant="subtitle2"
                gutterBottom
              >
                Profile Photo
              </Typography>
            </Grid>

            {children}

            <Box className={classes.btnSection}>
              <Button
                color="primary"
                variant="contained"
                fullWidth={true}
                size="large"
                className={classes.button}
                onClick={updateImageURL}
                disabled={disableSaveBtn}
              >Save</Button>

              <Button
                className={classes.button}
                variant="outlined"
                fullWidth={true}
                size="large"
                onClick={() => handleClose()}
              >Cancel</Button>
            </Box>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(ImageInputModal);