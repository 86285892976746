import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { InfoOutlined } from '@material-ui/icons';
import cyan from '@material-ui/core/colors/cyan';
import {
  Box,
  Card,
  CardHeader,
  ClickAwayListener,
  IconButton,
  Popper,
  Tooltip,
  Typography
} from '@material-ui/core';

const styles = {
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '.25em 0',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'capitalize',
    color: '#333',
  },
  inputLabelTitle: {
    fontSize: 24,
  },
  popperContainer: {
    maxWidth: 320,
    maxHeight: 280,
    overflowY: 'auto',
    backgroundColor: cyan[100],
    borderRadius: 10,
  },
  infoIconStyle: {
    padding: 0,
    position: 'relative',
    top: '-5px'
  },
};

const InputLabel = props => {
  const {classes, label, required, tooltip, infoText, infoTitle} = props;
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [placement, setPlacement] = useState();

  const handleClickTooltip = newPlacement => e => {
    setAnchorEl(e.currentTarget);
    setOpenPopper(prev => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClickAway = () => setOpenPopper(false);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Typography className={classes.inputLabel}>
        {label}{required && '*'}
        {tooltip && (
          <>
            <Tooltip
              title={tooltip}
              placement="left"
            >
              <IconButton
                className={classes.infoIconStyle}
                aria-label="info"
                color="primary"
                type="button"
                onClick={handleClickTooltip("right-start")}
              >
                <InfoOutlined
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>

            <Popper
              open={openPopper}
              anchorEl={anchorEl}
              placement={placement}
            >
              <Card className={classes.popperContainer}>
                <CardHeader
                  title={<><InfoOutlined /> {infoTitle}</>}
                />
                
                <Box>{infoText}</Box>
              </Card>
            </Popper>
          </>
        )}
      </Typography>
    </ClickAwayListener>
  )
};

export default (withStyles(styles)(InputLabel));
