export default function toasts(state = {}, action) {

  switch(action.type) {
    case "TOAST_SUCCESS":
      return {
        ...state,
        successToastOpen: true,
        successToastText: action.message
      };
    case "CLEAR_TOAST":
      return {
        ...state,
        successToastOpen: false,
        errorToastOpen: false,
        infoToastOpen: false,
      }; 
    default: return state;
  }
}