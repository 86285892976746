import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
// import Divider from '@material-ui/core/Divider';
// import { Home as Projects } from '@material-ui/icons';
// import Account from '@material-ui/icons/AccountCircle';
// import Dashboard from '@material-ui/icons/Dashboard';
// import Settings from '@material-ui/icons/Settings';

import PermanentDrawer from './PermanentDrawer';
import TemporaryDrawer from './TemporaryDrawer';
import MenuItem from './MenuItem';
import ExtraLinks from './ExtraLinks';
import Home from '../../Icons/Home';
import EdgeProjects from '../../Icons/EdgeProjects';
import HybridProjects from '../../Icons/HybridProjects';
import MyTraining from '../../Icons/MyTraining';
import MyCertificates from '../../Icons/MyCertificates';
import Downloads from '../../Icons/Downloads';
import SupportLogo from '../../Icons/Support';
import LearnIcon from '../../Icons/Learn';
import DeveloperIcon from '../../Icons/Developer';
import DeveloperDocsIcon from '../../Icons/DeveloperDocs';
import { devportalPublicUrl, enableLms } from '../../../configs/config';

import theme from '../../../theme';
import logo from '../../../assets/images/mimik-logo.png';

const styles = {
  drawer: {
    background: '#FF0000',
    marginTop: -50,
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '@media (max-width:1100px)' : {
      justifyContent: 'inherit',
      height: 'auto',
      borderBottom: '#ccc 1px solid'
    }
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '12px 0',
    '@media (max-width:1100px)' : {
      display: 'none'
    }
  },
  logo: {
    maxWidth: 92,
  },
  extraList: {

    borderTop: '#ffffff 6px solid',
    borderBottom: '#ffffff 6px solid',
    paddingTop: 6,
    paddingBottom: 6,
    backgroundColor: '#E8E8E8',
    '& div:hover': {
      color: 'rgba(47, 170, 177, 1)',
      background: 'none',
      '& svg path': {
        stroke: 'rgba(47, 170, 177, 1)',
        '&.fill_colour': {
          fill: 'rgba(47, 170, 177, 1)'
        }
      }
    }
  },
  border_outer: {
    marginTop: 130,
    borderTop: '#3EA8B2 2px solid',
    borderBottom: '#3EA8B2 2px solid',
    '@media (max-width:1100px)' : {
      marginTop: 30,
      display: 'none'
    },
  },
  homeList: {
    '& div:hover': {
      color: 'rgba(47, 170, 177, 1)',
      background: 'none',
      '& svg path': {
        stroke: 'rgba(47, 170, 177, 1)',
      }
    } 
  },

  mainList: {
    paddingLeft: 14,
  },
  menuItem: {

  },
  bottomLogoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  bottomLogo: {
    fontSize: 90,
  }
};

const DrawerComponent = ({ classes, history, user }) => {
  const drawerSession = window.sessionStorage.getItem('drawerOpen') !== 'false';
  const getDrawer = () => window.innerWidth >= theme.breakpoints.values.xl ? PermanentDrawer : TemporaryDrawer;
  const { pathname } = history.location;
  const [Drawer, setDrawer] = useState(getDrawer());
  const [drawerOpen, setDrawerOpen] = useState(Drawer === TemporaryDrawer ? false : drawerSession);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const closeDrawer = () => setDrawerOpen(false);

  const extraLinks = [
    { title: 'Developer Portal', url: `${devportalPublicUrl}`, icon: DeveloperIcon, external: true },
    { title: 'Developer Docs', url: `https://devdocs.mimik.com/`, icon: DeveloperDocsIcon, external: true },
    { title: 'Learn', url: `${devportalPublicUrl}/complementary-courses/`, icon: LearnIcon, external: true }
  ];

  var headerLinks = [
    { title: 'Home', url: `/home`, icon: Home },
    { title: 'Edge Projects', url: `/projects`, icon: EdgeProjects, external: false },
    { title: 'Hybrid Projects', url: `/hybrid_projects`, icon: HybridProjects, external: false },
    { title: 'My Training', url: `/training`, icon: MyTraining, external: false },
    { title: 'My Certificates', url: `/certificates`, icon: MyCertificates, external: false },
    { title: 'Support', url: `/support`, icon: SupportLogo, external: false },
    { title: 'Downloads', url: `/downloads`, icon: Downloads, external: false },
  ];

  if (!enableLms) {
    headerLinks = [
      { title: 'Home', url: `/home`, icon: Home },
      { title: 'Edge Projects', url: `/projects`, icon: EdgeProjects, external: false },
      { title: 'Hybrid Projects', url: `/hybrid_projects`, icon: HybridProjects, external: false },
      { title: 'Support', url: `/support`, icon: SupportLogo, external: false },
      { title: 'Downloads', url: `/downloads`, icon: Downloads, external: false },
    ];
  }

  const handleLink = (url, external) => {
    if (external) {
      if (url !== pathname) {
        window.open(url, '_blank');

      }
    } else {
      if (url !== pathname) history.push(url);
      if (Drawer === TemporaryDrawer) closeDrawer();
    }
  };
  

  const handleResize = () => {
    const newDrawer = getDrawer();
    if (newDrawer !== Drawer) {
      setDrawer(newDrawer);
      setDrawerOpen(newDrawer === PermanentDrawer);
    }
  };

  useEffect(() => {
    window.sessionStorage.setItem('drawerOpen', drawerOpen);
  }, [drawerOpen]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <Drawer
      drawerOpen={drawerOpen}
      closeDrawer={closeDrawer}
      toggleDrawer={toggleDrawer}
      user={user}
      history={history}
      className={classes.drawer}
    >


      <div className={classes.drawerContent}>
      
        <div className={classes.logoWrapper} >
        
          <Link to={`/`}>
            <Box display="flex" alignItems="flex-end" color="#000" mb="-5px">
              <img
                src={logo}
                alt="mimik logo"
                className={classes.logo}
              />
            </Box>
          </Link>
        </div>
        <div>

          <List className={classes.homeList}>
            {headerLinks.map(
              headerLink => { 
                return (
                <MenuItem
                  key={headerLink.url}
                  item={headerLink}
                  variant={drawerOpen ? 'full' : 'small'}
                  selected={pathname.search(headerLink.url.slice(0, -1)) == 0}
                  onClick={() => handleLink(headerLink.url, headerLink.external)}
                  custom={headerLink.custom}
                  className={classes.menuItem}
                />
              ) }
            )}
          </List>
          <Box className={classes.border_outer}>
            <List className={classes.extraList}>
              {extraLinks.map(
                extraLink => (
                  <ExtraLinks
                    key={extraLink.url}
                    item={extraLink}
                    variant={drawerOpen ? 'full' : 'small'}
                    selected={pathname.includes(extraLink.url.slice(0, -1))}
                    onClick={() => handleLink(extraLink.url, extraLink.external)}
                    custom={extraLink.custom}
                    className={classes.extraItem}
                  />
                )
              )}
            </List>
          </Box>
        </div>

        <div className={classes.bottomLogoWrapper}></div>

      </div>
    </Drawer>
  );
};

export default connect(() => ({
  // currentSandbox: state.currentSandbox,
}))(withRouter(withStyles(styles)(DrawerComponent)));
