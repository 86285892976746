import React from "react";
import { SvgIcon } from "@material-ui/core";

function Developer(props) {
  return (
    <SvgIcon
      {...props}
      x="0px"
      y="0px"
      width="24" height="24" viewBox="0 0 24 24"
      fill="none" xmlns="http://www.w3.org/2000/svg"
      style={{ color: "rgba(0, 0, 0, 0)" }}
    >
      <g clipPath="url(#clip0_3338_7164)">
        <path d="M2.58002 6.3999H21.34" stroke="black" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M9.99 11.08C9.99 11 9.95999 10.93 9.89999 10.87C9.84999 10.83 9.77 10.79 9.63 10.86L5.59 12.78C5.44 12.85 5.37 12.95 5.37 13.09C5.37 13.23 5.44 13.32 5.59 13.4L9.63 15.32C9.63 15.32 9.72 15.35 9.76 15.35C9.82 15.35 9.86 15.33 9.89 15.3C9.95 15.25 9.97999 15.18 9.97999 15.09C9.97999 15.03 9.95 14.94 9.83 14.88L6.03 13.09L9.83 11.3C9.96 11.24 9.97999 11.15 9.97999 11.09L9.99 11.08Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M12.97 9.94997C12.91 9.94997 12.83 9.99997 12.78 10.13L10.77 15.9C10.73 16.03 10.77 16.1 10.82 16.15C10.87 16.2 10.93 16.22 10.99 16.22C11.01 16.22 11.02 16.22 11.04 16.22C11.1 16.21 11.18 16.17 11.22 16.04L13.23 10.27C13.27 10.14 13.23 10.07 13.18 10.02C13.12 9.95997 13.04 9.93997 12.96 9.94997H12.97Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M18.4 12.77L14.37 10.85C14.24 10.78 14.16 10.82 14.11 10.86C14.05 10.92 14.01 10.99 14.02 11.07C14.02 11.13 14.05 11.22 14.17 11.28L17.97 13.07L14.17 14.86C14.05 14.92 14.03 15 14.02 15.06C14.02 15.14 14.05 15.22 14.11 15.27C14.14 15.29 14.18 15.32 14.24 15.32C14.28 15.32 14.32 15.32 14.37 15.29L18.41 13.37C18.56 13.29 18.63 13.19 18.63 13.06C18.63 12.93 18.55 12.82 18.41 12.75L18.4 12.77Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M4.52003 4.83998C4.75751 4.83998 4.95003 4.64746 4.95003 4.40998C4.95003 4.1725 4.75751 3.97998 4.52003 3.97998C4.28254 3.97998 4.09003 4.1725 4.09003 4.40998C4.09003 4.64746 4.28254 4.83998 4.52003 4.83998Z" fill="black" />
        <path d="M6.02998 4.87001C6.26746 4.87001 6.45998 4.67749 6.45998 4.44001C6.45998 4.20253 6.26746 4.01001 6.02998 4.01001C5.79249 4.01001 5.59998 4.20253 5.59998 4.44001C5.59998 4.67749 5.79249 4.87001 6.02998 4.87001Z" fill="black" />
        <path d="M7.55 4.87001C7.78748 4.87001 7.98 4.67749 7.98 4.44001C7.98 4.20253 7.78748 4.01001 7.55 4.01001C7.31251 4.01001 7.12 4.20253 7.12 4.44001C7.12 4.67749 7.31251 4.87001 7.55 4.87001Z" fill="black" />
        <path d="M17.02 21.41H3.37001C2.89001 21.41 2.51001 21.05 2.51001 20.61V3.27998C2.51001 2.83998 2.90001 2.47998 3.37001 2.47998H20.57C21.05 2.47998 21.43 2.83998 21.43 3.27998V19.57" stroke="black" strokeMiterlimit="10" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_3338_7164">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default Developer;
