const defaultState = 'panel1';

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'SET_PROFILE_CURRENT_TAB_OPEN':
      return action.data;
    default:
      return state;
  }
}
