import axios from 'axios';
import queryString from 'query-string';
import parseUrl from 'parse-url';
import * as Promise from 'bluebird';
import {
  startLoading,
  stopLoading,
  setError,
  resetError
} from './index';
import { isLatestLicenseAccepted } from "./developerLicense";

import {
  sandboxerUrl,
  userId,
  policyId
} from '../configs/config';

const BASE_PATH = 'sandboxer/v1';

export function setSandboxes(data) {
  return {
    type: 'SET_SANDBOXES',
    data
  };
}

export function setSandboxesStatus(data) {
  return {
    type: 'SET_SANDBOXES_STATUS',
    data
  };
}

export function setSandboxDeleteStatus() {
  return { type: 'SET_SANDBOX_DELETE_STATUS', data: true };
}
export function resetSandboxDeleteStatus() {
  return { type: 'RESET_SANDBOX_DELETE_STATUS'};
}

export function setProfileCurrentTabOpen(data) {
  return { type: 'SET_PROFILE_CURRENT_TAB_OPEN', data: data };
}

export function resetChangePasswordFeilds() {
  return { type: 'RESET_CHANGE_PASSWORD_FEILDS', data: true};
}
 
export function setCurrentSandbox(data) {
  return {
    type: 'SET_CURRENT_SANDBOX',
    data
  };
}

export function resetCurrentSandbox() {
  return {
    type: 'RESET_CURRENT_SANDBOX'
  };
}

export function fetchAllSandboxes() {
  return (dispatch) => {
    dispatch(startLoading('fetchSandboxes'));
    dispatch(resetError());
    return axios.get(`${sandboxerUrl}/${BASE_PATH}/sandboxes?userId=${userId}`, {
        headers: {
          'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
        }
      })
      .then((res) => {
        // console.log('*********** SANDBOXES ************');
        // console.dir(res);
        dispatch(isLatestLicenseAccepted());
        dispatch(setSandboxes(res.data.data.reverse()));
        dispatch(stopLoading('fetchSandboxes'));
      })
      .catch(err => dispatch(setError('We were unable to fetch your Hybrid Projects.', err.response)));
  };
}

export function fetchSandboxById(appId) {
  return (dispatch) => {
    dispatch(startLoading('fetchSandboxById'));
    dispatch(resetError());
    return axios.get(`${sandboxerUrl}/${BASE_PATH}/sandboxes/${appId}`, {
        headers: {
          'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
        }
      })
      .then((res) => {
        // console.log('*********** CURRENT SANDBOX ************');
        // console.dir(res);

        getSandboxFederatedAccessToken(res.data.data)
          .then((response) => {
            sessionStorage.setItem('federated_access_token', response.data.access_token);
            dispatch(setCurrentSandbox(res.data.data));
            dispatch(stopLoading('fetchSandboxById'));
          })
          .catch(() => {
            sessionStorage.setItem('federated_access_token', null);
            dispatch(setCurrentSandbox(res.data.data));
            dispatch(stopLoading('fetchSandboxById'));
          });
      })
      .catch(err => dispatch(setError('We were unable to fetch your Hybrid Project.', err.response)));
  };
}

export function createSandbox(payload) {
  payload.userId = userId;
  return (dispatch) => {
    dispatch(startLoading('createSandbox'));
    dispatch(resetError());
    return axios.post(`${sandboxerUrl}/${BASE_PATH}/sandboxes`, payload, {
        headers: {
          'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
        }
      })
      .then((res) => {
        dispatch(setCurrentSandbox(res.data.data));
        dispatch(stopLoading('createSandbox'));
      })
      .catch(err => dispatch(setError('We were unable to create your Hybrid Project.', err.response)));
  };
}

export function updateSandbox(appId, payload) {
  return (dispatch) => {
    dispatch(startLoading('updateSandbox'));
    dispatch(resetError());
    return axios.put(`${sandboxerUrl}/${BASE_PATH}/sandboxes/${appId}?userId=${userId}`, payload, {
        headers: {
          'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
        }
      })
      .then(() => {
        dispatch(stopLoading('updateSandbox'));
      })
      .catch(err => dispatch(setError('We were unable to update your Hybrid Project.', err.response)));
  };
}

export function deleteSandbox(appId) {
  return (dispatch) => {
    dispatch(startLoading('deleteSandbox'));
    dispatch(resetError());
    return axios.delete(`${sandboxerUrl}/${BASE_PATH}/sandboxes/${appId}`, {
        headers: {
          'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
        }
      })
      .then(() => {
        dispatch(stopLoading('deleteSandbox'));
      })
      .catch(err => dispatch(setError('We were unable to delete your Hybrid Project.', err.response)));
  };
}

function getPackageUrls(id, services) {
  const urls = services.map((service) => {
    const baseUrl = service.url.replace(parseUrl(service.url).pathname ,'');
    return `${baseUrl}/healthcheck`;
  })
  return urls;
}

function getServiceUrl(serviceName, services) {
  const theService = services.filter((service) => service.name === serviceName);
  return theService[0].url;
}

export function getSandboxStatus(id, services) {
  return (dispatch) => {
    dispatch(startLoading('getSandboxStatus'));
    dispatch(setSandboxesStatus([]));
    const endpoints = getPackageUrls(id, services);

    Promise.some(
        endpoints.map((endpoint) => axios.get(endpoint, {
          validateStatus: function (status) {
            return status < 599; // Resolve only if the status code is less than 599
          }
        })),
        endpoints.length
      )
      .then((results) => {
        let status = results
          .filter((service) => service !== undefined);
        status = status.map((service) => {
          if (!service.data.data) {
            service.data.data = service.data;
          }
          const serviceData = Object.assign({serviceUrl: getServiceUrl(service.data.data.name, services)}, service);
          return serviceData;
        })
        status.sort((a, b) => {
          if (a && a.data && a.data.data && a.data.data.name && b && b.data && b.data.data && b.data.data.name) {
            if (a.data.data.name > b.data.data.name) {
              return 1;
            } else {
              return -1;
            }
          } else {
            return 0;
          }
        });
        // console.log('*** status ***');
        // console.dir(status);
        dispatch(setSandboxesStatus(status));
        dispatch(stopLoading('getSandboxStatus'));
      })
      .catch(Promise.AggregateError, (err) => {
        err.map((error) => {
          // console.log(error.message);
          const errData = {
            config: error.config,
            status: 500,
            statusText: error.message,
          }
          return errData;
        })
        // console.log('*** error status ***');
        // console.dir(err);
        dispatch(setSandboxesStatus(err));
        dispatch(stopLoading('getSandboxStatus'));
      })
  }
}

const getMidTokenUrl = (sandbox) => {
  const Urls = sandbox.services.filter((service) => service.name === 'mid').map((service) => service.url.replace('/mID/v1', ''));
  return `${Urls[0]}/token`;
}

const getSandboxScopes = (sandbox) => {
  let scope = 'openid create:app delete:app read:app email';
  const scope1 = 'read:me update:me';
  const scope2 = 'read:events create:event update:event read:trackers update:tracker delete:tracker';
  const scope3 = 'create:device delete:device create:notification read:notifications read:notification delete:notification';
  switch (sandbox.packageName) {
    case 'package1':
      return `${scope} ${scope1}`

    case 'package2':
      return `${scope} ${scope1} ${scope2}`

    case 'package3':
      return `${scope} ${scope1} ${scope2} ${scope3}`
    default:
      break;
  }
}

export function createSandboxAccountStep1(sandbox, fields) {
  const {
    newEmail
  } = fields;
  const formDataIdTokenSignIn = {
    grant_type: 'mimik:email_password',
    email: newEmail,
    client_id: sandbox.clientId,
    action: 'register',
    web_flow: true
  };

  return axios.post(getMidTokenUrl(sandbox), queryString.stringify(formDataIdTokenSignIn), {
    validateStatus: function (status) {
      return status < 500; // Resolve only if the status code is less than 500
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export function createSandboxAccountStep2(sandbox, fields, mfa) {
  const {
    newPassword,
    code: bindingCode
  } = fields;
  const {
    mfa_token: mfaToken,
    oob_code: oobCode
  } = mfa;
  const formDataIdTokenSignIn = {
    grant_type: 'mimik:email_password',
    password: newPassword,
    client_id: sandbox.clientId,
    mfa_token: mfaToken,
    oob_code: oobCode,
    binding_code: bindingCode,
    scope: getSandboxScopes(sandbox),
    web_flow: true
  };

  return axios.post(getMidTokenUrl(sandbox), queryString.stringify(formDataIdTokenSignIn), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export function getSandboxAccessToken(sandbox, data) {
  const {
    email,
    password
  } = data;
  const formDataIdTokenSignIn = {
    grant_type: 'mimik:email_password',
    email,
    password,
    client_id: sandbox.clientId,
    scope: getSandboxScopes(sandbox),
    action: 'login',
    web_flow: true
  };

  return axios.post(getMidTokenUrl(sandbox), queryString.stringify(formDataIdTokenSignIn), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function getSandboxFederatedAccessToken(sandbox) {
  const formDataIdTokenSignIn = {
    grant_type: 'trust_token_signin',
    token: sessionStorage.getItem('devportal_access_token'),
    client_id: sandbox.clientId,
    scope: getSandboxScopes(sandbox),
    policy_id: policyId,
    action: 'login',
    web_flow: true
  };

  return axios.post(getMidTokenUrl(sandbox), queryString.stringify(formDataIdTokenSignIn), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })

}