import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function SharedKey(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 36 18"
      xmlns="http://www.w3.org/2000/svg"
    >
       <mask id="path-1-inside-1_4779_15781" fill="white">
<path fillRule="evenodd" clipRule="evenodd" d="M18.5757 11C18.1087 11 17.7096 11.3255 17.5659 11.7699C16.3977 15.3852 13.0044 18 9.00024 18C4.02968 18 0.000244141 13.9706 0.000244141 9C0.000244141 4.02944 4.02968 0 9.00024 0C12.6688 0 15.8247 2.19494 17.2263 5.3432C17.3987 5.73038 17.7715 6 18.1953 6H35C35.5523 6 36 6.44772 36 7V10C36 10.5523 35.5523 11 35 11H31.72C31.1677 11 30.72 11.4477 30.72 12V14C30.72 14.5523 30.2723 15 29.72 15H27.32C26.7677 15 26.32 14.5523 26.32 14V12C26.32 11.4477 25.8723 11 25.32 11H18.5757ZM14 9.04993C13.9732 11.7884 11.745 14 9.00024 14C6.23882 14 4.00024 11.7614 4.00024 9C4.00024 6.23858 6.23882 4 9.00024 4C11.745 4 13.9732 6.21164 14 8.95007V9.04993Z"/>
</mask>
<path d="M14 9.04993L15 9.05971V9.04993H14ZM14 8.95007H15L15 8.94029L14 8.95007ZM17.2263 5.3432L16.3128 5.74992L17.2263 5.3432ZM17.5659 11.7699L16.6144 11.4624L17.5659 11.7699ZM9.00024 19C13.4508 19 17.2198 16.0933 18.5175 12.0774L16.6144 11.4624C15.5757 14.677 12.5579 17 9.00024 17V19ZM-0.999756 9C-0.999756 14.5228 3.4774 19 9.00024 19V17C4.58197 17 1.00024 13.4183 1.00024 9H-0.999756ZM9.00024 -1C3.4774 -1 -0.999756 3.47715 -0.999756 9H1.00024C1.00024 4.58172 4.58197 1 9.00024 1V-1ZM18.1399 4.93648C16.5835 1.44056 13.078 -1 9.00024 -1V1C12.2596 1 15.0659 2.94932 16.3128 5.74992L18.1399 4.93648ZM35 5H18.1953V7H35V5ZM37 10V7H35V10H37ZM31.72 12H35V10H31.72V12ZM31.72 14V12H29.72V14H31.72ZM27.32 16H29.72V14H27.32V16ZM25.32 12V14H27.32V12H25.32ZM18.5757 12H25.32V10H18.5757V12ZM9.00024 15C12.294 15 14.9678 12.346 15 9.05971L13 9.04015C12.9786 11.2307 11.196 13 9.00024 13V15ZM3.00024 9C3.00024 12.3137 5.68654 15 9.00024 15V13C6.79111 13 5.00024 11.2091 5.00024 9H3.00024ZM9.00024 3C5.68654 3 3.00024 5.68629 3.00024 9H5.00024C5.00024 6.79086 6.79111 5 9.00024 5V3ZM15 8.94029C14.9678 5.65396 12.294 3 9.00024 3V5C11.196 5 12.9786 6.76933 13 8.95985L15 8.94029ZM13 8.95007V9.04993H15V8.95007H13ZM27.32 12C27.32 10.8954 26.4246 10 25.32 10V12H25.32H27.32ZM27.32 14H25.32C25.32 15.1046 26.2154 16 27.32 16V14ZM29.72 14V16C30.8246 16 31.72 15.1046 31.72 14H29.72ZM31.72 10C30.6154 10 29.72 10.8954 29.72 12H31.72V10ZM35 7H37C37 5.89543 36.1046 5 35 5V7ZM16.3128 5.74992C16.6271 6.45591 17.3325 7 18.1953 7V5C18.1994 5 18.1965 5.00202 18.1847 4.99388C18.1711 4.98448 18.1528 4.96555 18.1399 4.93648L16.3128 5.74992ZM35 10V12C36.1046 12 37 11.1046 37 10H35ZM18.5175 12.0774C18.5284 12.0435 18.5477 12.0199 18.5633 12.0078C18.5705 12.0022 18.5751 12.0004 18.5762 12C18.5767 11.9998 18.5767 11.9998 18.5763 11.9999C18.5759 12 18.5756 12 18.5757 12V10C17.6237 10 16.875 10.6561 16.6144 11.4624L18.5175 12.0774Z" fill="black" mask="url(#path-1-inside-1_4779_15781)"/>
    </SvgIcon>
  );
}

export default SharedKey;