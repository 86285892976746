export function showSuccessToast(message) {
  return dispatch => {
    dispatch({ type: "TOAST_SUCCESS", message })
  }
}

export function clearToast() {
  return dispatch => {
    dispatch({ type: "CLEAR_TOAST" })
  }; 
}
