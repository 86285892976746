import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import { refreshDevportalToken, logoutReset } from '../../actions/index';
// import { logout } from '../../pages/Logout';
import logout from '../../actions/logout';

const styles = () => ({
  paper: {
    position: 'relative',
    minWidth: 500,
    padding: '2em',
  },
  header: {
    fontSize: '1.3em',
    fontWeight: 400,
  },
  subheader: {
    fontSize: '1.1em',
  },
  close: {
    position: 'absolute',
    top: '0.1em',
    right: '0.1em',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1em',
  },
  button: {
    marginLeft: '.5em',
  },
});

const SessionExpiry = ({ classes, dispatch, sessionExpiry }) => {
  const [sessionExpiryDialog, showSessionExpiryDialog] = useState(false);

  const closeDialog = (event, reason) => { 
    if (reason !== 'backdropClick') {
      showSessionExpiryDialog(false); 
    }
  }

  const refresh = () => dispatch(refreshDevportalToken());

  useEffect(() => {
    const checkSession = () => {
      if (!sessionExpiry) return;
      const now = new Date().getTime();
      const expiry = sessionExpiry;
      if (expiry <= now) {
        dispatch(logoutReset());
        logout();
      } else if (expiry - 60000 <= now) {
        showSessionExpiryDialog(true);
      } else {
        showSessionExpiryDialog(false);
      }
    };

    checkSession();
    const interval = setInterval(checkSession, 5000);
    return () => clearInterval(interval);
  }, [sessionExpiry]);

  return (
    <Dialog
      open={sessionExpiryDialog}
      classes={{ paper: classes.paper }}
      onClose={closeDialog}
      // disableBackdropClick
      disableEscapeKeyDown
    >
      <Typography className={classes.header}>Your session is about to expire...</Typography>
      <Typography className={classes.subheader}>
        Refresh the session to remain logged in.
      </Typography>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          onClick={logout}
        >Logout</Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={refresh}
        >
          Refresh Session
        </Button>
      </div>

    </Dialog>
  );
};

export default withStyles(styles)(SessionExpiry);
