import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function DrawerRight(props) {
  return (
    <SvgIcon 
    {...props}
    viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M6.94548 20.4267C6.87273 20.3542 6.815 20.2679 6.77562 20.173C6.73623 20.0781 6.71596 19.9764 6.71596 19.8736C6.71596 19.7708 6.73623 19.6691 6.77562 19.5742C6.815 19.4793 6.87273 19.393 6.94548 19.3205L15.7689 10.4986L6.94548 1.67673C6.79878 1.53003 6.71637 1.33106 6.71637 1.1236C6.71637 0.916138 6.79878 0.717171 6.94548 0.570473C7.09218 0.423777 7.29114 0.341362 7.49861 0.341362C7.70607 0.341362 7.90503 0.423777 8.05173 0.570473L17.4267 9.94548C17.4995 10.018 17.5572 10.1043 17.5966 10.1992C17.636 10.2941 17.6562 10.3958 17.6562 10.4986C17.6562 10.6014 17.636 10.7031 17.5966 10.798C17.5572 10.8929 17.4995 10.9792 17.4267 11.0517L8.05173 20.4267C7.97916 20.4995 7.89295 20.5572 7.79803 20.5966C7.70312 20.636 7.60137 20.6562 7.49861 20.6562C7.39584 20.6562 7.29409 20.636 7.19918 20.5966C7.10426 20.5572 7.01805 20.4995 6.94548 20.4267Z" fill="#3EA8B2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M0.695478 20.4267C0.622724 20.3542 0.565001 20.2679 0.525616 20.173C0.486231 20.0781 0.465958 19.9764 0.465958 19.8736C0.465958 19.7708 0.486231 19.6691 0.525616 19.5742C0.565001 19.4793 0.622724 19.393 0.695478 19.3205L9.51892 10.4986L0.695478 1.67673C0.622841 1.60409 0.565222 1.51785 0.525911 1.42295C0.4866 1.32804 0.466368 1.22633 0.466368 1.1236C0.466368 1.02088 0.4866 0.919157 0.525911 0.824251C0.565222 0.729345 0.622841 0.643112 0.695478 0.570475C0.768116 0.497839 0.854349 0.440218 0.949254 0.400908C1.04416 0.361597 1.14588 0.341364 1.2486 0.341364C1.35133 0.341364 1.45305 0.361597 1.54795 0.400908C1.64286 0.440218 1.72909 0.497839 1.80173 0.570475L11.1767 9.94548C11.2495 10.018 11.3072 10.1043 11.3466 10.1992C11.386 10.2941 11.4062 10.3958 11.4062 10.4986C11.4062 10.6014 11.386 10.7031 11.3466 10.798C11.3072 10.8929 11.2495 10.9792 11.1767 11.0517L1.80173 20.4267C1.72916 20.4995 1.64295 20.5572 1.54803 20.5966C1.45312 20.636 1.35137 20.6562 1.2486 20.6562C1.14584 20.6562 1.04409 20.636 0.949177 20.5966C0.854262 20.5572 0.76805 20.4995 0.695478 20.4267Z" fill="#3EA8B2"/>
</SvgIcon>

  );
}

export default DrawerRight;
