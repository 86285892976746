import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function Bundle(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 33.87 21.86"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>
          {
            ".d{fill:#df810e;}.d,.e,.f,.g,.h,.i,.j,.k,.l{stroke:#010101;stroke-linecap:round;stroke-linejoin:round;}.d,.e,.f,.g,.h,.i,.j,.l{stroke-width:.3px;}.m{fill:#90ced4;}.n{fill:#cfdd84;}.o,.f{fill:#b8d053;}.e{fill:#f6a546;}.p{fill:#fabf7a;}.g{fill:#f18912;}.q{fill:#fcd6ad;}.h,.r{fill:#59bac1;}.i{fill:#a5c721;}.j{fill:#0aa8af;}.k{fill:#0e9ba2;stroke-width:.3px;}.s{fill:#f2f2f2;}.l{fill:#99b91e;}"
          }
        </style>
      </defs>
      <g id="a" />
      <g id="b">
        <g id="c">
          <g>
            <g>
              <path
                className="h"
                d="M7.2,4.53L16.02,.15l8.82,4.38v.58l-8.82,4.38L7.2,5.12v-.59Z"
              />
              <path
                className="j"
                d="M7.2,4.53l8.82,4.38,8.82-4.38V13.31l-8.85,4.38L7.2,13.31V4.53Z"
              />
              <path
                className="k"
                d="M16.02,17.68V8.91L7.26,4.56h-.06V13.31l8.79,4.38h.03Z"
              />
              <path
                className="m"
                d="M11.16,2.88l8.3,4.13,1.69-.83L12.83,2.05l-1.66,.83Z"
              />
              <path
                className="r"
                d="M19.64,7.36l1.79-.88v2.76l-.48-.08-.51,.62-.8-.06v-2.36Z"
              />
              <path
                className="s"
                d="M14.21,12.41l-2.98-1.49c-.17-.08-.3,.01-.3,.22v2c0,.21,.14,.44,.3,.52l2.99,1.49c.17,.08,.3-.01,.3-.22v-2.01c-.01-.21-.15-.43-.32-.51h0Zm-.68,1.64l-1.9-.95c-.08-.04-.15-.17-.15-.28s.07-.17,.15-.12l1.9,.95c.08,.04,.15,.17,.15,.28s-.07,.17-.15,.12Zm.28-.69l-2.18-1.1c-.08-.04-.15-.17-.15-.28s.07-.17,.15-.12l2.18,1.1c.08,.04,.15,.17,.15,.28,0,.12-.07,.17-.15,.12Z"
              />
            </g>
            <g>
              <path
                className="f"
                d="M19.51,11.01l7.11-3.53,7.11,3.53v.47l-7.11,3.53-7.11-3.52v-.48Z"
              />
              <path
                className="i"
                d="M19.51,11.01l7.11,3.53,7.11-3.53v7.07l-7.13,3.53-7.08-3.53v-7.07Z"
              />
              <path
                className="l"
                d="M26.62,21.61v-7.06l-7.06-3.51h-.04v7.05l7.08,3.53h.02Z"
              />
              <path
                className="n"
                d="M22.41,9.76l6.74,3.35,1.37-.67-6.76-3.35-1.35,.67Z"
              />
              <path
                className="o"
                d="M29.26,13.41l1.44-.71v2.22l-.39-.07-.41,.5-.64-.04v-1.9Z"
              />
              <path
                className="s"
                d="M25.15,17.36l-2.4-1.2c-.13-.07-.24,.01-.24,.18v1.61c0,.17,.11,.36,.24,.42l2.41,1.2c.13,.07,.24-.01,.24-.18v-1.62c-.01-.17-.12-.34-.26-.41h0Zm-.54,1.32l-1.53-.77c-.07-.03-.12-.13-.12-.22s.06-.13,.12-.1l1.53,.77c.07,.03,.12,.13,.12,.22s-.06,.13-.12,.1Zm.22-.56l-1.75-.89c-.07-.03-.12-.13-.12-.22s.06-.13,.12-.1l1.75,.89c.07,.03,.12,.13,.12,.22,0,.1-.06,.13-.12,.1Z"
              />
            </g>
            <g>
              <path
                className="e"
                d="M.15,12.56l6.14-3.05,6.14,3.05v.4l-6.14,3.05L.15,12.97v-.41Z"
              />
              <path
                className="g"
                d="M.15,12.56l6.14,3.05,6.14-3.05v6.11l-6.15,3.05L.15,18.66v-6.11Z"
              />
              <path
                className="d"
                d="M6.29,21.7v-6.1L.19,12.58h-.04v6.09l6.12,3.05h.02Z"
              />
              <path
                className="q"
                d="M2.65,11.48l5.8,2.89,1.18-.58-5.82-2.89-1.16,.58Z"
              />
              <path
                className="p"
                d="M8.61,14.68l1.25-.61v1.92l-.34-.06-.35,.43-.56-.04v-1.64Z"
              />
              <path
                className="s"
                d="M5.02,18.04l-2.07-1.04c-.12-.06-.21,0-.21,.15v1.39c0,.14,.1,.31,.21,.36l2.08,1.04c.12,.06,.21,0,.21-.15v-1.4c0-.14-.11-.3-.22-.35h0Zm-.47,1.14l-1.32-.66c-.06-.03-.11-.12-.11-.19s.05-.12,.11-.09l1.32,.66c.06,.03,.11,.12,.11,.19s-.05,.12-.11,.09Zm.19-.48l-1.51-.77c-.06-.03-.11-.12-.11-.19s.05-.12,.11-.09l1.51,.77c.06,.03,.11,.12,.11,.19,0,.09-.05,.12-.11,.09Z"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Bundle;