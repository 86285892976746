import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  FormControl,
  Grid,
  Link,
  Typography,
  withStyles
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { ArrowBackIos } from '@material-ui/icons';
import ReactCodeInput from 'react-verification-code-input';

import iconOnboarding from '../../../assets/images/icon-onboarding.png';
import onBoardingBG from '../../../assets/images/mimik_onboarding_bg.png';
import ResendLink from './ResendLink';
import clsx from 'clsx';

const styles = {
  onboardingWrapper: {
    width: '100%',
    height: '100%',
    maxWidth: '100% !important',
    padding: '0 !important',
    margin: '0 !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  loginMain: {
    // height: 'calc(100vh - 130px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flexGrow: 1,

    '& > div + div > div': {
      maxWidth: 840,
    },

    '@media (max-width: 1024px)': {
      background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.35) 23.54%, #3EA8B2 100%)',
    },
  },
  cCodeText: {
    fontSize: 14,
    lineHeight: '16px',
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'Roboto',
    '@media (max-width: 767px)': {
      textAlign: 'left'
    },
  },
  loginMainGrid: {
    '@media (max-width: 767px)': {
      width: 'calc(100% - 40px)',
      margin: '0 20px',
    },
  },
  logo: {
    width: '100%',
    maxWidth: 92,
    position: 'absolute',
    top: 30,
    left: 30,
  },
  logofloating: {
    display: 'block',

    '@media screen and (max-width: 1366px) and (max-height: 729px)': {
      display: 'none',
    },
  },
  logoContained: {
    display: 'none',
    padding: '20px 0',
    textAlign: 'center',

    '& img': {
      width: '100%',
      maxWidth: 92,
    },

    '@media screen and (max-width: 1366px) and (max-height: 729px)': {
      display: 'block',
    },
  },
  responsiveGrid: {
    '@media (max-width: 767px)': {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  formContainer: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.12)',
    paddingTop: '4em',
    borderRadius: 55,

    '@media (max-width: 1024px)': {
      backgroundColor: 'rgba(255, 255, 255, 0.76)',
      boxShadow: 'none',
    },

    '@media (max-width: 767px)': {
      paddingTop: '0',
      paddingBottom: '1.25em',
    },
  },
  formUI: {
    maxWidth: '75%',
    margin: '0 auto',

    '@media (max-width: 767px)': {
      maxWidth: '85%',
    },
  },
  formUITextInfo: {
    '@media (max-width: 767px)': {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      textAlign: 'center',
      paddingBottom: '0 !important',


      '& + div': {
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
        paddingTop: '0 !important',
      },
    },
  },
  formControl: {
    display: 'flex',
    margin: '1em 0',

    '&:first-child': {
      margin: '0 0 1em 0',
    },
  },
  outlinedInput: {
    minWidth: '100%',
    maxWidth: '100%',
    '@media (max-width: 985px)': {
      minWidth: 'unset',
    },
  },
  onboardingInfoSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${onBoardingBG})`,
    backgroundRepeat: 'repeat-y',
    backgroundSize: '100%',
    width: 568,
    height: '100%',

    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  infoDisplay: {
    width: '65%',
    height: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 40,
    padding: '0 1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& > ul': {
      listStyleType: 'none',
      paddingLeft: 32,

      '& > li': {
        minHeight: '40px',
        padding: '0 0 0 2.5em',
        backgroundImage: `url(${iconOnboarding})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px',
        backgroundPosition: 'center left',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  },
  button: {
    minWidth: '6em',
    borderRadius: 30,
    height: 50,
    fontSize: 16,
    fontWeight: 400,
    margin: '1em 0',
    '&.MuiButton-contained.Mui-disabled': {
      boxShadow: 'none',
      backgroundColor: 'rgba(62, 168, 178, 0.15)',
      '& span': {
        color: 'rgba(62, 168, 178, 0.6)',
        fontSize: 16
      }
    },
  },
  disclaimerText: {
    maxWidth: '292px',
    margin: '0 auto',

    '@media (max-width: 767px)': {
      padding: '20px 0',
    },
  },
  onboardingFooterText: {
    textAlign: 'center',

    '@media (max-width: 767px)': {
      fontSize: '0.825em',
    },
  },
  enter_code: {
    fontWeight: 400, fontFamily: 'Roboto', paddingTop: 5, paddingBottom: 15,
    '@media (max-width: 767px)': {
      textAlign: 'left'
    },
  },
  timer_text: {
    fontWeight: 700, fontFamily: 'Roboto', whiteSpace: 'nowrap',
    '@media (max-width: 767px)': {
      whiteSpace: 'inherit',
      maxWidth: 186,
      paddingTop: 20,
      paddingBottom: 20,
      margin: '0 auto',
      textAlign: 'center'
    },
  },
  left_back:{
    textAlign: 'left'
  },
  copyLink: {
    color: '#3ea8b2', 
    textDecoration:'underline', 
    whiteSpace: 'nowrap',
    '&:hover':{
      fontWeight:700,
      color: '#3ea8b2', 
      textDecoration:'underline',
    }
  },
  customVerificationCodeInput: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,

    '& > div': {
      width: '100% !important',
    },
    '& > div > div': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& > div > div > input': {
      width: '15% !important',
      height: '48px !important',
      border: 'solid 1px rgba(168, 173, 183, 0.3) !important',
      borderRadius: '10px !important',
      backgroundColor: '#F0F0F0',
    },
  },
  customVerificationFilled: {
    '& > div > div > input': {
      border: 'rgb(62, 168, 178) 1px solid !important'
    },
  },
  customVerCodeInputInvalid: {
    '& > div > div > input': {
      border: 'solid 1px rgba(199, 0, 57, 0.3) !important',
    },
  },
};

const ConfirmationCode = props => {
  const {
    classes,
    value,
    error,
    errors,
    handleCodeInputChange,
    handleVerifyCode,
    userEmail,
    isInvalid,
    isInvalidCode,
    handleAccountCreation,
    onChange,
    resendVerificationLink,
    attempts,
    resendDisable,
    over,
    time,
    statedTime,
    timeUp,
  } = props;

  // Timer function
  const tick = () => {
    if (over) return;

    if (time.min === 0 && time.sec === 0) {
      timeUp(true);
    } else if (time.sec === 0) {
      statedTime({
        min: time.min - 1,
        sec: 59
      });
    } else {
      statedTime({
        min: time.min,
        sec: time.sec - 1
      });
    }
  };

  useEffect(() => {
    let timerID = setInterval(() => tick(), 1000);

    return () => clearInterval(timerID);
  });

  const handleSubmit = (e) => {
    handleVerifyCode(e)
  }

  useEffect(() => {
    if (attempts === 3) {
      statedTime({
        min: 3,
        sec: 0
      });
    }
  }, [attempts]);
  
  return (
    <>
      {attempts === 0 || over
        ? <ResendLink
          value={userEmail}
          handleAccountCreation={handleAccountCreation}
          resendVerificationLink={resendVerificationLink}
          onChange={onChange}
          errors={errors}
          resendDisable={resendDisable}
        />
        : <>
          <Typography variant="body2" className={classes.left_back}>
            <Link href={`/create_account`}>
              <ArrowBackIos fontSize="small" /> Back
            </Link>
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={5}
            style={{ marginTop: 0 }}
          >
            <Grid
              item
              sm={6}
              className={classes.formUITextInfo}
            >
              <Typography variant="h3" style={{ fontWeight: 700, fontSize: 24, fontFamily: 'Roboto' }}>verification code</Typography>
              {
                over && !isInvalid
                  ? <>
                    <Typography variant="body1" style={{ color: red[600], fontWeight: 'bold' }}>Time&#39;s up! Your validation code has expired.</Typography>
                  </>
                  : (attempts < 3) && isInvalidCode
                    ? <>
                      <Typography variant="body1" style={{ color: red[600], fontWeight: 700, fontSize: 17, fontFamily: 'Roboto', paddingTop: 10, paddingBottom: 10 }}>invalid code</Typography>
                      <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {`you have ${attempts} attempts.`}
                      </Typography>
                      <Typography variant="body1" style={{ fontWeight: 'normal' }}>
                        {`you can send another request to your email.`}
                      </Typography>
                    </>
                    : (error && error.description)
                      ? <>
                        <Typography variant="body1" style={{ color: red[600], fontWeight: 'bold' }}>
                          {typeof error.description === "string" ? error.description : ""}
                        </Typography>
                      </>
                      : <>
                        <Typography variant="body1" style={{ color: '#C6C6C6', fontWeight: 700, fontFamily: 'Roboto', paddingTop: 10, paddingBottom: 10 }}>the verification code was sent to:</Typography>
                        <Typography variant="body1" style={{ color: '#3ea8b2', fontWeight: 'bold' }}>{userEmail}</Typography>
                      </>
              }
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Typography variant="body1" className={classes.timer_text}>
                  {`this code will be valid for ${time.min
                    .toString()
                    .padStart(2, "0")}:${time.sec.toString().padStart(2, "0")} minutes.`}
                </Typography>
                <Typography className={classes.enter_code}>enter your code here</Typography>
                <div className={clsx(classes.customVerificationCodeInput, isInvalidCode ? classes.customVerCodeInputInvalid : "", value && value.length == 6 ? classes.customVerificationFilled : "")}>
                  <ReactCodeInput
                    value={value}
                    onChange={handleCodeInputChange}
                    length={6}
                    disabled={over}
                  />
                </div>
                <Typography className={classes.cCodeText}>do not close this window or you will have to request a new code.</Typography>
                <Typography className={classes.cCodeText}>if the code doesn’t arrive, check your spam folder before requesting a new one.</Typography>
              </FormControl>
            </Grid>
          </Grid>

          <Box display="flex" flexDirection="column">
            <Button
              color="primary"
              variant="contained"
              size="large"
              className={classes.button}
              onClick={handleSubmit}
              disabled={!value || value.length != 6}
            >
              {over ? "Please wait" : "Submit"}
            </Button>

            <Typography variant="body2" align="center" className={classes.disclaimerText}>by creating an account with mimik, I agree and understand the <a target='_blank' rel="noreferrer" href="https://developer.mimik.com/developer-agreement/" className={classes.copyLink}>developer license agreement</a></Typography>
          </Box>
        </>
      }
    </>
  );
};

export default connect(state => ({
  error: state.error,
}))(withStyles(styles)(ConfirmationCode));