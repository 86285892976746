import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function EdgeProjectsPanel(props) {
  return (
    <SvgIcon 
    {...props}
    viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.35 33.0501C21.85 33.0501 20.6667 32.5835 19.8 31.6668C18.9333 30.7501 18.5 29.4835 18.5 27.8835C18.5 26.8335 18.7 25.9168 19.1 25.1335C19.5 24.3501 20.05 23.7335 20.75 23.3001C21.45 22.8668 22.2667 22.6501 23.1833 22.6501C24.4833 22.6501 25.5 23.0835 26.2167 23.9335C26.9333 24.7835 27.3 25.9668 27.3 27.4835C27.3 27.8501 27.15 28.0168 26.85 28.0168H19.4167C19.45 29.3668 19.8 30.4001 20.4833 31.1335C21.1667 31.8668 22.1167 32.2335 23.3333 32.2335C23.8833 32.2335 24.4167 32.1668 24.9 32.0168C25.3833 31.8668 25.8667 31.6168 26.3333 31.2335C26.5167 31.1001 26.6833 31.0501 26.8167 31.1168C26.9667 31.1668 27.05 31.2835 27.0833 31.4335C27.1167 31.5835 27.05 31.7335 26.8667 31.8835C26.4333 32.2501 25.9167 32.5335 25.2833 32.7335C24.6667 32.9335 24.0167 33.0501 23.3667 33.0501H23.35ZM23.15 23.4335C22.0333 23.4335 21.15 23.8001 20.5167 24.5168C19.8833 25.2335 19.5167 26.1501 19.4167 27.2501H26.4667C26.4167 26.0668 26.1333 25.1335 25.5667 24.4668C25.0167 23.7835 24.2 23.4501 23.1333 23.4501L23.15 23.4335Z" fill="#ff0000" stroke="#3EA8B2" strokeMiterlimit="10"/>
<path d="M14.5666 30.9834H4.13329C3.41663 30.9834 2.83329 30.3834 2.83329 29.6667L1.16663 9.65005C1.16663 8.55005 2.24996 7.65005 3.34996 7.65005H10.0666C11.05 7.65005 11.95 8.15005 12.5333 8.96672C13.2 9.86672 13.3166 11.3167 15.4333 11.5334H27.8C28.7666 11.5334 29.55 12.3334 29.55 13.3C29.55 13.3 30.0333 21.45 30.2166 21.45C30.3666 21.45 32.8166 6.28338 32.8166 6.28338C32.95 5.30005 32.2833 4.51672 31.3333 4.51672H18.3666C16.2666 4.30005 16.35 3.26672 15.8166 2.36672C15.35 1.56672 14.5166 1.05005 13.5333 1.05005H6.79996C5.71663 1.05005 4.69996 1.95005 4.54996 3.05005V7.36671" stroke="#3EA8B2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
</SvgIcon>
  );
}

export default EdgeProjectsPanel;