import { combineReducers } from 'redux';

import loading from './loading';
import sessionExpiry from './sessionExpiry';
import error from './error';
import toasts from './toasts';
import user from './user';
import projects from './projects';
import currentProject from './currentProject';
import sandboxes from './sandboxes';
import sandboxesStatus from './sandboxesStatus';
import certificate from './certificate';
import currentSandbox from './currentSandbox';
import oauthDeeplink from './oauthDeeplink';
import developerIdToken from './developerIdToken';
import developerLicense from './developerLicense';
import developerSharedIdToken from './developerSharedIdToken';
import setSuccess from './setSuccess';
import sandboxProjects from './sandboxProjects';
import sandboxProjectsStatus from './sandboxProjectsStatus';
import setProfileCurrentTab from './setProfileCurrentTab';
import resetChangePassword from './resetChangePassword';
import sandboxCurrentProject from './sandboxCurrentProject';
import sandboxDeleteStatus from './sandboxDeleteStatus';
import clientId from './clientId';
import mfa from './mfa';
import lms from './lms';
import edgeLicenseExpiry from './edgeLicenseExpiry';


export default combineReducers({
  loading,
  sessionExpiry,
  error,
  toasts,
  user,
  projects,
  currentProject,
  sandboxes,
  sandboxesStatus,
  certificate,
  currentSandbox,
  oauthDeeplink,
  developerIdToken,
  developerLicense,
  developerSharedIdToken,
  setSuccess,
  sandboxProjects,
  sandboxProjectsStatus,
  setProfileCurrentTab,
  resetChangePassword,
  sandboxCurrentProject,
  sandboxDeleteStatus,
  clientId,
  mfa,
  lms,
  edgeLicenseExpiry
});
