import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';

import SEO from '../components/SEO';
import { Button } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },
  icon: {
    width: '2em',
    height: '2em',
    marginRight: '.5em',
    marginLeft: '-.5em',
    fill: theme.palette.secondary.main,
  },
  header: {
    fontSize: '2em',
  },
  subheader: {
    fontSize: '1.1em',
  },
  actionButton: {
    marginTop: '2em',
  },
});

const NotFound = ({ classes, history }) => {
  const [countdown, setCountdown] = useState(3);

  const handleLink = url => history.push(url);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (countdown === 1) return handleLink(`/`);
      setCountdown(countdown - 1);
    }, 1000);
    return () => clearTimeout(timeout);
  });

  return (
    <React.Fragment>
      <SEO title="Not Found" />
      <div className={classes.root}>
        <Typography className={classes.header}>
          <Search className={classes.icon} />404 Not Found
        </Typography>
        <Typography className={classes.subheader}>
          Resource does not exist. Redirecting back in {countdown} seconds.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={() => handleLink(`/hybrid_projects`)}
        >
          Back to Hybrid Projects
        </Button>
      </div>

    </React.Fragment>
  );
};

export default withRouter(withStyles(styles)(NotFound));
