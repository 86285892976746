import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function MyCertificates(props) {
  return (
    <SvgIcon
    {...props}
    x="0px"
    y="0px"
    width="15"
    height="14"
    viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.1097 12.2959L10 12.2423L9.89031 12.2959L8.25 13.0968V10.8204C9.39915 11.1062 10.6009 11.1062 11.75 10.8204V13.0968L10.1097 12.2959ZM10 9.20605C9.0335 9.20605 8.25 8.42255 8.25 7.45605C8.25 6.48956 9.0335 5.70605 10 5.70605C10.9665 5.70605 11.75 6.48956 11.75 7.45605C11.75 8.42255 10.9665 9.20605 10 9.20605ZM13 1.25293H2C1.30964 1.25293 0.75 1.81257 0.75 2.50293V9.50293C0.75 10.1933 1.30964 10.7529 2 10.7529H6.75V11.2529H2C1.0335 11.2529 0.25 10.4694 0.25 9.50293V2.50293C0.25 1.53643 1.0335 0.75293 2 0.75293H13C13.9665 0.75293 14.75 1.53643 14.75 2.50293V9.50293C14.75 10.3845 14.0981 11.1139 13.25 11.2352V10.7279C13.8206 10.6121 14.25 10.1077 14.25 9.50293V2.50293C14.25 1.81257 13.6904 1.25293 13 1.25293Z" stroke="black" strokeWidth="0.5"/>
    </SvgIcon>
  );
}

export default MyCertificates;