import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  withStyles
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

const styles = theme => ({
  root: {
    '& > button': {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: 700,
 

      '& .MuiButton-startIcon': {
        marginRight: 0,
        padding: '4px 0'
      },
    },
    

    '& section': {
      backgroundColor: '#F2F2F2',
      borderRadius: 25,
      padding: '40px 43px',
      '@media (max-width:767px)' : {
        padding: 15
      },

      '& > ul': {
        listStyle: 'none',
        padding: 0,
        margin: 0,

        '& > li': {
          display: 'flex',
          marginBottom: 30,

          '& p': {
            fontSize: 20,
            fontWeight: 400,
            '@media (max-width:767px)' : {
              fontSize:16
            },
          },

          '&::before': {
            content: `'● '`,
            color: theme.palette.primary.main,
          }
        },
      },
    },
  },
  rnVersion: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginBottom: 45,
    marginLeft: 30,
    marginRight: 30,
    fontSize: 18,
    '@media (max-width:767px)' : {
      marginLeft:0,
      marginRight:0,
      marginBottom:30,
      marginTop:10
    }
  },
  rnTitle: {
    color: theme.palette.primary.main,
    margin: '30px',
    fontWeight: 700,
    fontSize: 32,
    '@media (max-width:767px)' : {
      marginLeft:0,
      marginRight:0,
      fontSize: 24
    } 
  },
  rnSubTitle: {
    margin: '30px',
    fontWeight: 500,
    fontSize: 24,
    '@media (max-width:767px)' : {
      marginLeft:0,
      marginRight:0,
      fontSize:20
    }
  },
  rnContentTitle: {
    color: theme.palette.primary.main,
    margin: '0 0 30px',
    fontWeight: 700,
    fontSize:24,
    '@media (max-width:767px)' : {
      fontSize:20
    },
  },
  rnContentDetailsTitle: {
    fontWeight: 700,
    fontSize: 20,
    '@media (max-width:767px)' : {
      fontSize:18
    },
  },
  rnContentDetailsText: {},
  contentDetailsContainer: {
    paddingLeft: '1em',
  },
});

const ReleaseNoteScreen = props => {
  const {
    classes,
    data,
    setView
  } = props;

  return (
    <div className={classes.root}>
      <Button
        startIcon={<ChevronLeft />}
        onClick={setView}
      >
        Back
      </Button>

      <Grid
        container
        justifyContent="center"
      >
        <Grid item xs={12} md={10}>
          {data && <>
            <Typography variant="body1" className={classes.rnVersion}>{data.version}</Typography>

            <Typography variant="h3" className={classes.rnTitle}>{data.title}</Typography>

            {data.subTitle && <Typography variant="h4" className={classes.rnSubTitle}>{data.subTitle}</Typography>}
            
            {data.content && 
              <section>
                <Typography variant="h4" className={classes.rnContentTitle}>{data.content.contentTitle}</Typography>

                <ul>
                  {data.content.contentDetails.map((item, index) => {
                    return (<li key={index}>
                      <Box className={classes.contentDetailsContainer}>
                        <Typography variant="h4" className={classes.rnContentDetailsTitle}>{`${item.contentDetailsTitle}:`}</Typography>
                        <Typography variant="body1" className={classes.rnContentDetailsText}>{item.contentDetailsText}</Typography>
                      </Box>
                    </li>);
                  })}
                </ul>
              </section>
            }
            
          </>}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(ReleaseNoteScreen);
