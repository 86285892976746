import React from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  withStyles
} from '@material-ui/core';

import ImageInputModal from '../Modal/ImageInputModal';
import ImageCropper from '../Account/ImageCropper';
import previewPlaceholder from '../../assets/images/previewPlaceholder.jpg';

const styles = (theme) => ({
  uploadColumn: {
    textAlign: 'center',

    '& > .ReactCrop': {
      minHeight: 400,
    },

    '& + div > div': {
      height: 255,
    },

    '& input[type=file]': {
      display: 'none',
    },
    '& label': {
      cursor: 'pointer',
      width: '100%',
    },

    '& .MuiTypography-alignLeft': {
      textAlign: 'left',
    }
  },
  image: {
    width: '100%',
    maxWidth: '100%',
    borderRadius: 20,
  },
  imagePlaceholder: {
    maxwidth: "400px",
    color: "#4A4A4A",
    borderRadius: 0,
  },
  selectButton: {
    padding: 0,
    width: '100%',
  },
  selectBtnMain: {
    justifyContent: 'flex-start',
  },
  selectBtnRemove: {
    justifyContent: 'flex-end',
  },
  previewThumb: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  criteriaTextContainer: {
    height: 150,

    '& > div': {
      height: '100%',
    },
  },
  criteriaText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  imageLoader: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgba(255, 255, 255, .8)',
  },
});

const imageCrop = props => {
  const {
    classes,
    dialogOpen,
    handleClose,
    onUpload,
    onUploadFile,
    onImgCrop,
    onRemovePhoto,
    imageToCrop,
    croppedImage,
    errorMessage,
    disableSaveBtn
  } = props;

  return (
    <>
      <ImageInputModal
        dialogOpen={dialogOpen}
        handleClose={handleClose}
        disableSaveBtn={disableSaveBtn}
        cropped={croppedImage}
        onUpload={onUpload}
      >
        <Grid item md={7} className={classes.uploadColumn}>
          {imageToCrop
            ? <ImageCropper
                imageToCrop={imageToCrop}
                onImageCropped={onImgCrop}
              />
            : <form >
                <label htmlFor="uploadImg">
                  <img
                    className={clsx(classes.image, classes.imagePlaceholder)}
                    src={previewPlaceholder}
                    alt="Upload Failed"
                  />

                  <input
                    type="file"
                    id="uploadImg"
                    accept="image/png, image/x-png, image/gif, image/jpeg, image/bmp"
                    onChange={onUploadFile}
                  />
                </label>
              </form>
          }

          <Grid container>
            <Grid item xs={8} style={{display: "flex"}}>
                <Typography variant="caption" align="left">
                  Select a photo from your computer
                </Typography>
            </Grid>

            {imageToCrop
              ? <Grid item xs={4}>
                  <Button
                    type="button"
                    color="primary"
                    className={clsx(classes.selectButton, classes.selectBtnRemove)}
                  onClick={onRemovePhoto}
                  >
                    <Typography variant="caption" align="right">
                      Remove Photo
                    </Typography>
                  </Button>
                </Grid>
              : null
            }
          </Grid>
        </Grid>

        <Grid item md={5}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid item>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant="h4">
                    Preview
                  </Typography>

                  <Typography variant="body1">
                    Drag and resize frame to adjust your photo.
                  </Typography>
                </Grid>

                <Grid item xs={4} className={classes.previewThumb}>
                  {/* {processingImage && (
                    <div className={classes.imageLoader}>
                      <Loader variant="spinner" />
                    </div>
                  )} */}
                  <Avatar
                    alt="Cropped photo preview"
                    src={
                      croppedImage
                        ?
                        croppedImage
                        :
                        previewPlaceholder
                    }
                    className={classes.large}
                    variant="rounded"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              className={classes.criteriaTextContainer}
            >
              <Grid container>
                <Grid item xs={12} className={classes.criteriaText}>
                  {
                    errorMessage.showMessage &&
                    <Box color="error.main">
                        <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                          &#9432; {errorMessage.message}
                        </Typography>
                    </Box>
                  }

                  <Typography variant="caption">
                    Supported formats: gif, png, and jpeg /jpg
                  </Typography>

                  <Typography variant="caption">
                    Max width of 1080 pixels, height of 1080 pixels
                  </Typography>

                  <Typography variant="caption">
                    Min width of 100 pixels, height of 100 pixels
                  </Typography>

                  <Typography variant="caption">
                    75 Kb maximum image size
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ImageInputModal>
    </>
  );
};

export default withStyles(styles)(imageCrop);