import React, { useLayoutEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import {
    Box,
    Button,
    Card,
    CardHeader,
    ClickAwayListener,
    IconButton,
    Popper,
    Tooltip,
    Typography
} from '@material-ui/core';
import { Cancel,  VpnKey as Key } from '@material-ui/icons';
import InfoIcon from '../Icons/Info'
import { getSandboxDeveloperIdToken } from '../../actions/index';
import { cyan } from '@material-ui/core/colors';

const styles = (theme) => ({
    icon_info: {
        position: 'relative',
        top: '-10px',
        padding:0,
        marginLeft:5,
        backgroundColor: 'transparent',
        '& svg' : {
          height:20,
          fill: 'transparent'
        }
      },
    button: {
        borderRadius: 30,
    },
    buttonIcon: {
        marginRight: '.25em',
    },
    infoField: {
        flex: 1,
        maxWidth: 500,
        marginBottom: '.5em',
    },
    infoLabel: {
        width: '10em',
        padding: '6px 0',
        fontWeight: 400,
    },
    infoWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        margin: '1em 0',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'unset',
        },
    },
    inputLabelIcon: {
        width: '.7em',
        height: '.7em',
    },
    popperContainer: {
        maxWidth: 320,
        maxHeight: 280,
        overflowY: 'auto',
        backgroundColor: cyan[50],
    },
    popperInfoText: {
        padding: '0 1em 1em 1em',
    },
});

const IdTokenButton = ({ classes, sandbox, project, dispatch }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopper, setOpenPopper] = useState(false);
    const [placement, setPlacement] = useState();

    useLayoutEffect(() => {
        dispatch(getSandboxDeveloperIdToken(undefined));
    }, [sandbox, dispatch]);

    const handleClickTooltip = newPlacement => e => {
        setAnchorEl(e.currentTarget);
        setOpenPopper(prev => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handleClickAway = () => setOpenPopper(false);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box className={classes.infoWrapper}>
                <Button className={classes.button} variant="contained" color="primary" size="large"
                    onClick={() => dispatch(getSandboxDeveloperIdToken(sandbox, project))}>
                    <Key className={classes.buttonIcon} />  Get ID Token
                </Button> <Tooltip
                    title="Click to view"
                    placement="left"
                >
                    <IconButton
                        aria-label="info"
                       
                        type="button"
                        onClick={handleClickTooltip("right-start")}
                        className={classes.icon_info}
                    >
                        <InfoIcon />
                    </IconButton>
                </Tooltip>

                <Popper
                    open={openPopper}
                    anchorEl={anchorEl}
                    placement={placement}
                >
                    <Card className={classes.popperContainer}>
                        <CardHeader
                            title="ID Token"
                            action={
                                <IconButton aria-label="close" onClick={() => setOpenPopper(false)}>
                                    <Cancel />
                                </IconButton>
                            }
                        />
                        <Box className={classes.popperInfoText}>
                            <Typography gutterBottom variant="body1">
                                The Developer ID Token is a unique UUID that identifies the user account within the edgeEngine Runtime environment. You need a Developer ID Token to  get an Access Token for your project.
                            </Typography>
                        </Box>
                    </Card>
                </Popper>
            </Box>
        </ClickAwayListener>
    );
};

export default connect(() => ({}))(withStyles(styles)(IdTokenButton));
