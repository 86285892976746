import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { ArrowBackIos, Error } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

import InputLabel from '../components/FormFields/InputLabel';
import FormErrors from '../components/FormFields/FormErrors';
import ConfirmationCode from '../pages/ConfirmationCode';
import { resetPasswordStep1, resetPasswordStep2 } from '../actions/index';
import { showSuccessToast } from '../actions/toasts';

import logo from '../assets/images/mimik-logo.png';
import { useHistory } from 'react-router-dom';
import { useRef } from 'react';
import iconGraphic from '../assets/images/login_graphic.png';

const styles = {
  onboardingWrapper: {
    width: '100%',
    height: '100%',
    maxWidth: '100% !important',
    padding: '0 !important',
    margin: '0 !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  loginMain: {
    // height: 'calc(100vh - 130px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flexGrow: 1,

    '& > div + div > div': {
      maxWidth: 840,
    },

    '@media (max-width: 1024px)': {
      background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.35) 23.54%, #3EA8B2 100%)',
    },

    '@media (max-width: 767px)': {

      justifyContent: 'inherit'
    },
  },
  loginMainGrid: {
    '@media (max-width: 767px)': {
      width: 'calc(100% - 40px)',
      margin: '0 20px',
    },
  },
  formUI: {
    maxWidth: '75%',
    margin: '0 auto',

    '& > p + div': {
      marginTop: 0
    },

    '@media (max-width: 767px)': {
      maxWidth: '85%',
    },
  },
  disclaimerText: {
    maxWidth: '292px',
    margin: '0 auto',

    '@media (max-width: 767px)': {
      padding: '20px 0',
    },
  },
  formControl: {
    display: 'flex',
    margin: '1em 0',

    '&:first-child': {
      margin: '0 0 1em 0',
    },
  },
  responsiveGrid: {
    '@media (max-width: 767px)': {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  formContainer: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.12)',
    paddingTop: '4em',
    borderRadius: 55,

    '@media (max-width: 1024px)': {
      backgroundColor: 'rgba(255, 255, 255, 0.76)',
      boxShadow: 'none',
    },

    '@media (max-width: 767px)': {
      paddingTop: '0',
      paddingBottom: '0',
    },
  },
  outlinedInput: {
    minWidth: '100%',
    maxWidth: '100%',
    '@media (max-width: 1025px)': {
      minWidth: 'inherit',
    },
  },
  button: {
    minWidth: '6em',
    borderRadius: 30,
    height: 50,
    fontSize: 16,
    fontWeight: 400,
    margin: '1em 0',
    '&.MuiButton-contained.Mui-disabled': {
      boxShadow: 'none',
      backgroundColor: 'rgba(62, 168, 178, 0.15)',
      '& span': {
        color: 'rgba(62, 168, 178, 0.6)',
        fontSize: 16
      }
    },
    '@media (max-width: 750px)': {
      marginTop: 10,
      marginBottom: 0
    },
  },
  imageInput: {
    '@media (max-width: 1150px)': {
      top: '-5em',
    },
    '@media (max-width: 750px)': {
      top: '-2em',
    },
  },
  links: {
    fontSize: '.825em',
  },
  copyLink: {
    color: '#3ea8b2',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    '&:hover': {
      fontWeight: 700,
      color: '#3ea8b2',
      textDecoration: 'underline',
    }
  },
  logo: {
    width: '100%',
    maxWidth: 92,
    position: 'absolute',
    top: 30,
    left: 30,
  },
  logofloating: {
    display: 'block',

    '@media (max-width: 750px)': {
      display: 'none',
    },

    '@media screen and (max-width: 1366px) and (max-height: 729px)': {
      display: 'none',
    },
  },
  logoContained: {
    display: 'none',
    padding: '20px 0',
    textAlign: 'center',

    '& img': {
      width: '100%',
      maxWidth: 92,
    },

    '@media (max-width: 750px)': {
      display: 'block',
    },

    '@media screen and (max-width: 1366px) and (max-height: 729px)': {
      display: 'block',
    },
  },
  formInfo: {
    padding: '1em 2em',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '15px',
    position: 'relative',
  },
  formUITextInfo: {
    '@media (max-width: 767px)': {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      textAlign: 'center',
      paddingBottom: '0 !important',

      '& p + p': {
        display: 'none',
      },

      '& + div': {
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
        paddingTop: '0 !important',
      },
    },
  },
  infoMessageIcon: {
    position: 'absolute',
    top: 'calc(50% - 0.75em)',
    left: '-0.75em',
    width: '1.5em',
    height: '1.5em',
    marginRight: '.75em',
  },
  codeVerificationDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '400px',
    },

    '& form > div': {
      maxWidth: '400px',

      '& > div > div': {
        margin: '3em',
      },
    },
  },
  buttonSection: {
    marginTop: 5,
  },
  onboardingInfoSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    paddingTop: '10%',
    alignItems: 'center',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.35) 21.35%, #3EA8B2 100%)',
    width: 555,
    height: '100%',

    '@media (max-width: 1400px)': {
      paddingTop: '7%',
    },

    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  infoDisplay: {
    width: '70%',
    height: '50%',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0) 14.41%, rgba(255, 255, 255, 0.69) 87.28%)',
    borderRadius: 25,
    padding: '23px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    alignItems: 'top',



    '& > ul': {
      listStyleType: 'none',
      padding: 0,
      margin: 0,

      '&:after': {
        position: 'relative'
      },

      '& > li': {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '10px 0',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',

        '& p': {
          fontFamily: 'Roboto',
          fontSize: 16,
          fontWeight: 300
        }


      },
    },
  },
  onboardingFooterText: {
    textAlign: 'center',

    '@media (max-width: 767px)': {
      fontSize: '0.825em',
      marginTop: 20,
      marginBottom: 20
    },
  },

  charactor_seven: {
    fontSize: 12,
    lineHeight: '14px',
    padding: '10px',
    paddingBottom: 0
  },

  // error styles
  errMessageContainer: {
    maxWidth: '75%',
    margin: '0 auto 1em',
  },
  errorMessage: {
    padding: '1em 2em',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '15px',
    position: 'relative',
    color: red[600],
  },
  forgot_pwd_text: {
    fontWeight: 'bold', 
    fontSize: '24px', 
    fontFamily: 'Roboto', 
    paddingBottom: 10, 
    paddingTop: 40,
    '@media (max-width: 767px)': {
      paddingTop: 10,
      margin:0
    },
  },
  forgot_pwd_desc: {
    fontWeight: 400, 
    fontSize: '16px', 
    fontFamily: 'Roboto', 
    lineHeight: 1.7,
    '@media (max-width: 767px)': {
      lineHeight:1.4,
      fontSize: 15,
      paddingBottom:20
    },
  },
  errorMessageIcon: {
    position: 'absolute',
    top: 'calc(50% - 0.75em)',
    left: '-0.75em',
    width: '1.5em',
    height: '1.5em',
    marginRight: '.75em',
  },
};

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: '15px 14px',
      backgroundColor: '#F0F0F0'
    },
    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:after': {
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      borderRadius: 30,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 30,
      },
      '&:hover fieldset': {
        border: 'rgb(62, 168, 178) 1px solid'
      },
      '&.Mui-focused fieldset': {
      },
    },
  },
})(TextField);

const LoggedIn = () => {
  return sessionStorage.getItem('devportal_access_token') !== null;
}

const ForgotPasswordPage = (props) => {
  const {
    dispatch, classes, loading, error, mfa,
  } = props;

  console.log("isLoading : ", loading);
  const defaultFields = {
    email: '',
    newPassword: '',
    confirmPassword: '',
    code: null,
  };
  const mHistory = useHistory();
  const [view, setView] = useState('codeEntry');
  const [fields, setFields] = useState(defaultFields);
  const [errors, setErrors] = useState({});
  const [errCollapse, setErrCollapse] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [attempts, setAttempts] = useState(3);
  const [over, setOver] = useState(false);
  const [time, setTime] = useState({
    min: 3,
    sec: 0
  });
  const [passErr, setPassErr] = useState(false);
  const [passErrText, setPassErrText] = useState(fields.newPassword);
  const [resendDisable, setResendDisable] = useState(false);

  useEffect(() => {
    errTimeout(error);
  }, [error, setErrCollapse]);

  const emailRegex = /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

  const updateField = (field, value) => {
    setErrCollapse(false)
    const newFields = { ...fields, [field]: value };
    setFields(newFields);
    validateFields(field, newFields);
  };

  const validateFields = (field = null, newFields = fields) => {
    const newErrors = { ...errors };
    setErrors({});

    const validation = {
      email: () => {
        if (!newFields.email || !newFields.email.trim()) return 'Login email is required';
        if (!emailRegex.test(newFields.email)) return 'Login email is invalid';
        return null;
      },
    };

    if (field) {
      const error = validation[field] && validation[field]();
      if (error) {
        newErrors[field] = error;
      } else {
        delete newErrors[field];
      }
    } else {
      Object.keys(validation).forEach((key) => {
        const error = validation[key]();
        if (error) {
          newErrors[key] = error;
        } else {
          delete newErrors[key];
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const comparePassword = (pass, confirmPass) => {
    const passwordRegexLowercase = /(?=.*[a-z])/;
    const passwordRegexUppercase = /(?=.*[A-Z])/;
    const passwordRegexNumeric = /(?=.*[0-9])/;
    const passwordRegexSpecChar = /(?=.*[!@#$%^&*'"])/;
    const passwordRegexLength = /(?=.{7, 128})/;

    if (pass === "") {
      setPassErr(true);
      setPassErrText("Password is required");
    } else if (pass.length < 7) {
      setPassErr(true);
      setPassErrText("Password is too short");
    } else if (pass.length > 128) {
      setPassErr(true);
      setPassErrText("Password is too lengthy");
    } else if (
      !passwordRegexLowercase.test(pass)
      || !passwordRegexUppercase.test(pass)
      || !passwordRegexNumeric.test(pass)
      || !passwordRegexSpecChar.test(pass)
      || passwordRegexLength.test(pass)
    ) {
      setPassErr(true);
      setPassErrText("For your password use 7 or more characters with a mix of capital and small letters, numbers & symbols");
    } else if (pass !== "" && confirmPass === "") {
      setPassErr(true);
      setPassErrText("Confirm Password is required");
    } else if (pass == confirmPass) {
      if (fields.email === "" && !errors) {
        setPassErr(true);
        setPassErrText("Login email is required");
      } else {
        setPassErr(false);
        setPassErrText("");
      }
    } else {
      setPassErr(true);
      setPassErrText("Password and Confirm Password do not match");
    }
  };

  const fetchValue = e => {
    if (e.target.id == "newPassInput") {
      setFields({
        ...fields,
        newPassword: (e.target.value).trim(),
        confirmPassword: fields.confirmPassword
      });
    } else if (e.target.id == "confirmPassInput") {
      setFields({
        ...fields,
        newPassword: fields.newPassword,
        confirmPassword: (e.target.value).trim()
      });
    }
  };

  // For state updates that require
  // checking initial and subsequent renders
  const useDidUpdateEffect = () => {
    const didMountRef = useRef(true);

    useEffect(() => {
      didMountRef.current = false;
    }, []);

    return didMountRef.current;
  };

  const updateEffect = useDidUpdateEffect();

  useEffect(() => {
    if (updateEffect) {
      setPassErr(false);
      setPassErrText("");
    } else if (fields.email === "" && fields.newPassword === "" && fields.confirmPassword === "") {
      setPassErr(false);
      setPassErrText("");
    } else if (fields.email !== "" && fields.newPassword === "" && fields.confirmPassword === "") {
      setPassErr(false);
      setPassErrText("");
    } else {
      comparePassword(fields.newPassword, fields.confirmPassword);
    }
  });

  const handleForgotPassword = () => {
    if (!validateFields()) return;
    dispatch(resetPasswordStep1(fields));
  }

  const handleInputChange = e => {
    if (e.length != 6) {
      setIsInvalidCode(false);
    }
    updateField('code', e);
  };

  const handleEmailChange = e => {
    updateField('email', e);

    if (emailRegex.test(e)) {
      setResendDisable(false);
    } else if (JSON.stringify(errors) !== {}) {
      setResendDisable(true);
    }
  };

  const handleVerifyCode = () => {
    if (!validateFields()) return;
    if (!mfa) {
      setIsInvalid(true);
      return;
    }

    if (attempts !== 0) {
      setAttempts(attempts - 1);
    }

    dispatch(resetPasswordStep2(fields, mfa, mHistory, (isValidCode) => {
      setIsInvalidCode(isValidCode);
    }));
    dispatch(showSuccessToast("Password reset successfully!"));
  }

  const fieldsChanged = () => Object.keys(defaultFields)
    .reduce((acc, key) => (
      acc || JSON.stringify(defaultFields[key]) !== JSON.stringify(fields[key])
    ), false);

  const errTimeout = err => {
    if (err && typeof err.description === "string") {
      setErrCollapse(true);
    }
  };

  if (LoggedIn()) {
    dispatch(showSuccessToast("Successfully Logged in!"));
    mHistory.push("/home");
  }

  if (mfa && mfa.mfa_token && mfa.oob_code) {
    if (view !== 'codeEntry') {
      setView('codeEntry');
    }
  }

  useEffect(() => {
    if (!mfa && view === 'codeEntry') {
      setView('default');
      setIsInvalid(true);
    }
  }, []);

  const resendVerificationLink = () => {
    setIsInvalidCode(false)
    updateField('code', null);
    handleForgotPassword();
    setView('codeEntry');
    setAttempts(3);
    setOver(false);
    setTime({
      min: 3,
      sec: 0
    });
  };
  return (
    <>
      <div className={classes.onboardingWrapper}>
        <div className={classes.loginMain}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className={classes.logofloating}
          >
            <Grid item sm={11} className={classes.responsiveGrid}>
              <img
                src={logo}
                alt="mimik logo"
                className={classes.logo}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className={classes.loginMainGrid}
          >
            <Grid item sm={10} className={classes.responsiveGrid}>
              <Box
                boxShadow={5}
                borderRadius={15}
                pt="1.5em" pb="4em"
                className={classes.formContainer}
              >
                <form className={classes.root}>
                  {
                    (errCollapse)
                      ? <>
                        <Collapse in={true}>
                          <Box className={classes.errMessageContainer}>
                            <Typography className={classes.errorMessage} variant="body1" m="1em">
                              <Error className={classes.errorMessageIcon} />
                              {typeof error.description === "string" ? error.description : ""}
                            </Typography>
                          </Box>
                        </Collapse>
                      </>
                      : ""
                  }

                  <FormErrors errors={errors} />

                  {
                    passErr
                      ?
                      <Collapse in={true}>
                        <Box className={classes.errMessageContainer}>
                          <Typography className={classes.errorMessage} variant="body1" m="1em">
                            <Error className={classes.errorMessageIcon} />
                            {passErrText}
                          </Typography>
                        </Box>
                      </Collapse>
                      :
                      null
                  }

                  <Box className={classes.formUI}>
                    <div className={classes.logoContained}>
                      <img
                        src={logo}
                        alt="mimik logo"
                      />
                    </div>
                    {(() => {
                      switch (view) {
                        case 'codeEntry':
                          return <>
                            <ConfirmationCode
                              over={over}
                              timeUp={b => setOver(b)}
                              time={time}
                              statedTime={o => setTime(o)}
                              value={fields.code}
                              error={error}
                              userEmail={fields.email}
                              attempts={attempts}
                              isInvalid={isInvalid}
                              isInvalidCode={isInvalidCode}
                              handleCodeInputChange={handleInputChange}
                              setIsInvalidCode={setIsInvalidCode}
                              handleVerifyCode={handleVerifyCode}
                              handleForgotPassword={handleForgotPassword}
                              resendVerificationLink={resendVerificationLink}
                              onChange={handleEmailChange}
                              newPass={fields.newPassword}
                              confirmPass={fields.confirmPassword}
                              resendBtnDisable={resendDisable}
                            />
                          </>;
                        default:
                          return (<>
                            <Typography variant="body2">
                              <Link href={`/login`}>
                                <ArrowBackIos fontSize="small" />Back
                              </Link>
                            </Typography>

                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                              spacing={5}
                            >
                              <Grid
                                item
                                sm={5}
                                className={classes.formUITextInfo}
                              >
                                <Typography variant="h3" className={classes.forgot_pwd_text}>forgot password</Typography>
                                <Typography variant="h3" className={classes.forgot_pwd_desc}>this is a 2 step password reset. you will receive a verification code via the email account provided below that needs to be input in the next screen.</Typography>

                              </Grid>

                              <Grid item xs={12} sm={7}>
                                <FormControl className={classes.formControl}>
                                  <InputLabel label="Login email" required />
                                  <CustomTextField
                                    variant="outlined"
                                    className={classes.outlinedInput}
                                    onChange={e => updateField('email', e.target.value)}
                                    value={fields.email}
                                    error={!!errors.email}
                                    type="email"
                                    autoComplete="email"
                                    inputProps={{ placeholder: 'Login email' }}
                                  />
                                </FormControl>

                                <FormControl className={classes.formControl}>
                                  <InputLabel label="New Password" required />
                                  <CustomTextField
                                    id="newPassInput"
                                    variant="outlined"
                                    className={classes.outlinedInput}
                                    onChange={fetchValue}
                                    value={fields.newPassword}
                                    error={passErr}
                                    type="password"
                                    autoComplete="new-password"
                                    inputProps={{ placeholder: 'New Password' }}
                                  />
                                  <Typography className={classes.charactor_seven}>use 7 or more characters with a mix of capital letters, letters, numbers & symbols</Typography>
                                </FormControl>

                                <FormControl className={classes.formControl}>
                                  <InputLabel label="Retype Password" required />
                                  <CustomTextField
                                    id="confirmPassInput"
                                    variant="outlined"
                                    className={classes.outlinedInput}
                                    onChange={fetchValue}
                                    value={fields.confirmPassword}
                                    error={passErr}
                                    type="password"
                                    autoComplete="new-password"
                                    inputProps={{ placeholder: 'Retype Password' }}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>

                            <Box display="flex" flexDirection="column">
                              <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                className={classes.button}
                                onClick={handleForgotPassword}
                                disabled={
                                  Object.keys(errors).length > 0
                                  || !fieldsChanged()
                                  || passErr
                                  || fields.newPassword === ""
                                  || fields.confirmPassword === ""
                                  || fields.newPassword !== fields.confirmPassword
                                  || loading.includes('resetPasswordStep1')
                                }
                              >
                                {loading.includes('resetPasswordStep1')?'Please Wait...':'Submit'}
                              </Button>

                              <Typography variant="body2" align="center" className={classes.disclaimerText}>by creating an account with mimik, I agree and understand the <a target='_blank' rel="noreferrer" href="https://developer.mimik.com/developer-agreement/" className={classes.copyLink}>developer license agreement</a></Typography>
                            </Box>
                          </>);
                      }
                    })()}
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>


          <Typography variant="body1" className={classes.onboardingFooterText}>&copy; mimik technology, Inc. all rights reserved</Typography>
        </div>{/* -- end -- loginMain */}

        <div className={classes.onboardingInfoSection}>
          <Box className={classes.infoDisplay}>
            {(() => {
              switch (view) {

                default:
                  return (
                    <>
                      <Typography variant="body2" style={{ fontWeight: 700, fontSize: 17, paddingBottom: '1em', color: '#3EA8B2' }}>start & continue with your journey ...</Typography>

                      <ul className="dot_signup">
                        <li>
                          <Typography variant="body2">install edgeEngine</Typography>
                        </li>
                        <li>
                          <Typography variant="body2">create projects</Typography>
                        </li>
                        <li>
                          <Typography variant="body2">develop & deploy edge-native applications</Typography>
                        </li>
                        <li>
                          <Typography variant="body2">join the community & get support </Typography>
                        </li>
                        <li>
                          <Typography variant="body2">get access to learning resources</Typography>
                        </li>
                        <li>
                          <Typography variant="body2">track you progress</Typography>
                        </li>
                      </ul>
                    </>
                  );
              }
            })()}
            <Box><img src={iconGraphic} alt='Graphic' /></Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default connect((state) => {
  return ({
    loading: state.loading,
    error: state.error,
    mfa: state.mfa,
  })
})(withStyles(styles)(ForgotPasswordPage));
