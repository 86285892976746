export const defaultState = null;

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'SET_EDGE_LICENSE_EXPIRY_DATE': 
      return action.exp;
    case 'RESET_EDGE_LICENSE_EXPIRY_DATE':
      return defaultState;
    default:
      return state;
  }
}
