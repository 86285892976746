import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid,
  LinearProgress,
  Toolbar,
  Typography
} from '@material-ui/core';

import SEO from '../components/SEO';
import { fetchAllTrainings } from '../actions/lms';
import Loader from '../components/Loader';

const TrainingProgressbar = withStyles(theme => ({
  root: {
    width: '100%',
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  barColorPrimary: {
    background: 'linear-gradient(90deg, rgba(125,255,211,1) 0%, rgba(146,222,227,1) 49%, rgba(183,231,254,1) 100%)',
  },
}))(LinearProgress);

const styles = theme => ({
  toolbar: {
    opacity: 1,
    transition: 'opacity 400ms',
  },
  toolbarHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  titleCategoryNew: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  trainingGridMain: {
    paddingTop: '20px', 
  },
  card: {
    width: 305,
    boxShadow: '0px 8px 16px #0000001f',
  },
  courseTitle: {
    fontWeight: 'bold',
  },
  boxTrainingFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0.5rem 0 0',
  },
  courseProgressbar: {
    height: '5px',
    margin: '5px 0',
  },
  progressChip: {
    padding: '0 12px',
  },
  progressChipCurrent: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
  },
  progressChipFinal: {
    backgroundColor: '#9fca47',
    color: '#FFFFFF',
  },
  button: {
    marginRight: '.5em',
  },
  buttonIcon: {
    marginRight: '.25em',
  },
  buttonIconSmall: {
    height: '.9em',
    width: '.9em',
  },
});

const LoggedIn = () => {
    return sessionStorage.getItem('devportal_access_token') !== null;
  }

const getFormattedDate = dateStr => {
  const date = new Date(dateStr);

  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (isNaN(date.getTime())) {
      return '';
    } else {
      return date.toLocaleDateString();
    }
  }
};

const Training = props => {
  const {
    classes,
    dispatch,
    lms,
  } = props;

  if (!LoggedIn()) {
    return (
      <>
        <Redirect to={`/`} />
      </>
    )
  }

  let timeout;

  window.addEventListener('blur', function (e) {
    e.preventDefault();
    window.clearTimeout(timeout);
  })

  useEffect(() => {
    // console.log(trainings);
    // console.log(trainingProgress)

    dispatch(fetchAllTrainings());
  }, []);

  if (!lms) return <Loader fullPage />;

  const {
    trainings,
    trainingProgress
  } = lms;

  if (!trainings){
    console.log('training not found');
    return (
      <>
        <Typography variant="h1">Training not found</Typography>
      </>
    );
  }

  const getTitle = (item) => {
    const course = trainings.filter((training) => training.id === item.course);
    if (course && course.length > 0) {
      return course[0].title.rendered;
    }
    return;
  }

  return (
    <>
      <SEO title="Training" />
      <Toolbar
        disableGutters
        className={classes.toolbar}
      >
        <Typography variant="h1">Training</Typography>
      </Toolbar>

      <Box sx={{ flexGrow: 1 }} pt={4}>
        {trainingProgress && trainingProgress.length > 0 ? (<Grid container className={classes.trainingGridMain} spacing={2}>
          {
            trainingProgress.map((item, index) => {
              console.log(`Item: ${index}`);
              console.dir(item);
              return (<Grid key={index} item m={4}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardContent>
                      <Typography className={classes.courseTitle} gutterBottom variant="subtitle1" component="div">
                        {getTitle(item)}
                      </Typography>
                      
                      {/* <Typography variant="body2" color="textPrimary">
                        {getTitle(item)}
                      </Typography> */}

                      <TrainingProgressbar
                        className={classes.courseProgressbar}
                        variant="determinate"
                        value={item.steps_total === 0 ? 0 : item.steps_completed * 100 / item.steps_total}
                      />

                      <Typography variant="body1" color="textPrimary">
                        {item.steps_completed}/{item.steps_total}
                      </Typography>

                      <Box className={classes.boxTrainingFooter}>
                        {item.progress_status === "not-started" && <Box>
                          <Chip
                            className={classes.progressChip}
                            label="Not Started"
                            size="small"
                          />
                        </Box>}

                        {item.progress_status === "in-progress" && <Box>
                          <Chip
                            className={clsx(classes.progressChip, classes.progressChipCurrent)}
                            label="In progress"
                            size="small"
                          />
                        </Box>}

                        {item.progress_status === "completed" && <Box>
                          <Chip
                            className={clsx(classes.progressChip, classes.progressChipFinal)}
                            label="Completed"
                            size="small"
                          />
                        </Box>}
                        
                        <Typography variant="body1" color="textPrimary">
                          {getFormattedDate(item.date_started)}
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>);
            })
          }
        </Grid>) : ('')}
      </Box>
    </>
  );
};

const mapStateToProps = state => ({
  lms: state.lms,
});

export default connect(mapStateToProps)(withStyles(styles)(Training));
