// import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      light: '#34bdc5',
      main: '#2faab1',
      dark: '#2a979d',
      contrastText: '#fff',
      opacity: decimal => `rgba(52, 189, 197, ${decimal})`,
    },
    secondary: {
      light: '#000000',
      main: '#000000',
      dark: '#000000',
      contrastText: '#000',
      opacity: decimal => `rgba(255, 210, 63, ${decimal})`,
    },
    green: {
      light: '#4caf50',
      main: '#4caf50',
      dark: '#3FA243',
      contrastText: '#fff',
    },
    red: {
      light: '#DC143C',
      main: '#DC143C',
      dark: '#B22222',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: '"Nunito", Roboto',
    h1: {
      fontWeight: 300,
      fontSize: '2em',
      margin: '5px 0',
    },
    h2: {
      fontWeight: 300,
      fontSize: '1.75em',
      margin: '5px 0',
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.75em',
      margin: '5px 0',
    },
    h4: {
      fontWeight: 300,
      fontSize: '1.125em',
    },
    h5: {
      fontWeight: 300,
      fontSize: '1em',
    },
    h6: {
      fontWeight: 300,
      fontSize: '0.875em',
    },
    subtitle1: {
      margin: '10px 0 15px',
      fontWeight: 300,
      fontSize: '1em',
      lineHeight: 1.3,
    },
    subtitle2: {
      fontWeight: 300,
      fontSize: '0.875em',
      color: '#888',
    },
    button: {
      fontSize: '1em',
      fontWeight: 400,
    },
    body1: {
      fontWeight: 300,
      fontSize: '1em',
    },
    body2: {
      fontWeight: 300,
      fontSize: '0.875em',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 200, // not used
      md: 440,
      lg: 680,
      xl: 1100
    },
  },
  zIndex: {
    permanentDrawer: 1200,
    header: 1201,
    temporaryDrawer: 1202,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 2,
        padding: '4px 15px',
        '&:focus': {
          outline: 'none',
        },
      },
      text: {
        padding: '4px 15px',
      },
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        fontSize: 16,
        fontWeight: 400,
        fontFamily: 'Nunito',
        '&:hover' : {
          boxShadow: 'none',
          fontWeight: 700
        }
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 2,
      },
    },
    MuiRating: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '.8em',
      },
    },
    MuiFab: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(62, 168, 178, 0.25)',
        backdropFilter: "blur(5px)",
      }
    },
    MuiDialogContent: {
      root: {
        overflowY: 'unset',

        '&:first-child': {
          padding: '0',
          paddingTop: '0',
          display: 'flex',
        },
      },
    },
  },
});
