import * as React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

function Triangle(props) {
  const { up } = props;
  return (
    up === 'true' ?
    <SvgIcon
      {...props}
      x="0px" y="0px" width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.19416 0.596662C5.59376 0.0528446 6.40624 0.0528443 6.80584 0.596662L10.8923 6.15786C11.3775 6.81828 10.906 7.75 10.0864 7.75L1.91358 7.75C1.09404 7.75 0.622459 6.81828 1.10774 6.15786L5.19416 0.596662Z" fill="#3EA8B2"/>
    </SvgIcon>
    :
    <SvgIcon
      {...props}
      x="0px" y="0px" width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.30584 7.90334C5.90624 8.44716 5.09376 8.44716 4.69416 7.90334L0.60774 2.34214C0.122459 1.68172 0.594035 0.750001 1.41358 0.750001L9.58642 0.75C10.406 0.75 10.8775 1.68172 10.3923 2.34214L6.30584 7.90334Z" fill="#3EA8B2"/>
    </SvgIcon>
  );
}

Triangle.prototype ={
  up: PropTypes.string.isRequired,
}

export default Triangle;