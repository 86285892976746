import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import {
  CardContent,
  FormControl,
  Button,
  Box,
  Paper,
  TextField,
  Typography,
  Toolbar
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import { red, grey } from '@material-ui/core/colors';

import ImageInput from '../FormFields/ImageInput';
import InputLabel from '../FormFields/InputLabel';
import FormErrors from '../FormFields/FormErrors';
import logiIcon from '../../assets/images/logo_2.png';

import { createProject, updateProject } from '../../actions/sandbox/projects';
import { baseUrl } from '../../configs/config';

const styles = theme => ({
  root: {
    padding: 0,
    '@media (max-width: 750px)': {
      padding: 0,
    },
  },
  paper: {
    position: 'relative',
    padding: '1.75em 2.75em',
    borderRadius: 10,
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',

    '@media (max-width: 750px)': {
      padding: '1em',
    },
  },
  button: {
    borderRadius: 30,
    marginLeft: '.5em',
  },
  submitButton: {
    minWidth: '8em',
  },
  textInput: {
    width: '100%',
    paddingLeft: '3em',

    '@media (max-width: 767px)': {
      paddingLeft: 0,
    },
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    color: red[600],
    fontWeight: 400,
  },
  errorMessageIcon: {
    width: '.8em',
    height: '.8em',
    marginRight: '.25em',
  },
  selectItem: {
    margin: '0'
  },
  toolbar: {
    opacity: 1,
    transition: 'opacity 400ms',
  },
  fontBold: {
    fontWeight: 'bold',
  },
  imageInput: {
    width: '168px',

    '@media (max-width: 767px)': {
      width: '110px',
    },

    '&.MuiPaper-elevation2': {
      boxShadow: 'none',
      border: `1px solid ${grey[400]}`,
      zIndex: 0,

      '@media (max-width: 767px)': {
        alignSelf: 'center',
      },
    },


  },
  inputContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '80%',
    margin: '0 auto',
    '@media (max-width: 1200px) and (min-width:767px)': {
      width: '100%',
    },
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      width: '100%',
      margin: 0,
    },
  },
  inputContentTextfield: {
    flexGrow: 1,
    alignSelf: 'flex-end',
    marginBottom: '1em',

    '@media (max-width: 767px)': {
      width: '100%',
      marginBottom: '2em',
      marginTop: '2em',
    },
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    width: '80%',
    margin: '1em auto',
    '@media (max-width: 1200px) and (min-width:767px)': {
      width: '100%',
    },
    '@media (max-width: 767px)': {
      justifyContent: 'space-between',
    },

    '& .MuiButton-contained.Mui-disabled': {
      color: theme.palette.primary.main,
      boxShadow: 'none',
      backgroundColor: 'rgba(62, 168, 178, 0.15)',
      '& span': {
        color: 'rgba(62, 168, 178, 0.6)',
        fontSize: 16
      }
    },
  },
  inputContentFormError: {
    width: '100%',
    paddingLeft: '3em',

    '& > div > div > div': {
      maxWidth: 'auto',
    },
  },
});

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:after': {
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: 30,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 30,
      },
      '&:hover fieldset': {
        border: 'rgb(62, 168, 178) 1px solid'
      },
      '&.Mui-focused fieldset': {
      },
    },
  },
})(TextField);

const InfoContent = () => {
  return (
    <CardContent bgcolor="info.main">
      <Typography gutterBottom variant="body2">
        The project name will appear on the login screen and should help users identify your application.
      </Typography>
    </CardContent>
  );
};

const CreateProjectForm = (props) => {
  const {
    dispatch, classes, project, projects, loading, onSubmit, onCancel, sandbox, setEditProject
  } = props;
  const defaultProjectName = 'My Project';
  const fallbackLogoUrl = baseUrl + logiIcon

  const defaultFields = project ? {
    logoURL: project.logo_uri,
    projectName: project.client_name,
    appType: project.application_type,
    callbackurlArray: project.redirect_uris.map(url => ({ url, error: false })),
  } : {
    logoURL: '',
    projectName: defaultProjectName,
    appType: 'native',
    callbackurlArray: [],
  };
  const [fields, setFields] = useState(defaultFields);

  const [errors, setErrors] = useState({});
  const [changesSaved, setChangesSaved] = useState(false);
  const resetForm = () => {
    setFields(defaultFields);
    setErrors({});
    setChangesSaved(false);
  };

  const updateFields = (newFields) => {
    setFields(newFields);
    setChangesSaved(false);
  };

  const updateErrors = (newErrors) => {
    const filteredErrors = {};
    Object.keys(newErrors).forEach(key => {
      if (newErrors[key]) filteredErrors[key] = newErrors[key];
    });
    setErrors(filteredErrors);
  };

  const updateProjectName = (e) => {
    const { value } = e.target;
    const newFields = { ...fields, projectName: value };

    updateFields(newFields);
    updateErrors({
      projectName: !value || !value.trim() ? 'Project name is required' : false,
    });
  };

  const getPayload = () => ({
    client_name: fields.projectName.trim(),
    application_type: fields.appType,
    redirect_uris: fields.callbackurlArray
      .filter((callbackurls) => !callbackurls.error && callbackurls.url.trim())
      .map(urls => urls.url),
    logo_uri: fields.logoURL ? fields.logoURL.trim() : '' || fallbackLogoUrl,
  });

  const handleProjectCreate = () => {
    const isUniqueProject = projects.some((e) => e.client_name.trim() === fields.projectName.trim())
    if (isUniqueProject) {
      setErrors({
        msg: 'You have entered duplicate project name'
      });
      return
    }
    handleCreate()
  }

  const handleProjectSave = () => {
    const isUniqueProject = projects.some((e) => e.client_name.trim() === fields.projectName.trim())
    if (isUniqueProject) {
      setErrors({
        msg: 'You have entered duplicate project name'
      });
      return
    }
    handleSave()
  }

  const handleCreate = () => dispatch(createProject(sandbox, getPayload()))
    .then(resetForm)
    .then(() => {
      if (typeof onSubmit === 'function') onSubmit();
      setChangesSaved(true);
    })
    .catch(console.log);

  const handleSave = () => dispatch(updateProject(sandbox, project.client_id, getPayload()))
    .then(() => {
      if (typeof onSubmit === 'function') onSubmit();
      setChangesSaved(true);
    })
    .catch(console.log);

  const handleCancel = () => {
    resetForm();
    if (typeof onCancel === 'function') onCancel();
  };

  const fieldsChanged = () => Object.keys(defaultFields)
    .reduce((acc, key) => (
      acc || JSON.stringify(defaultFields[key]) !== JSON.stringify(fields[key])
    ), false);

  const imageUrl = (url) => {
    return url.split('/').reverse() && url.split('/').reverse().length > 1 && url.split('/').reverse()[1] === 'mimik-devportal-assets.s3-us-west-2.amazonaws.com' ? baseUrl + logiIcon : url
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar
          disableGutters
          className={classes.toolbar}
        >
          <Typography variant="h3" className={classes.fontBold}>{!project ? 'Create a Project' : 'Edit the Project'}</Typography>
        </Toolbar>

        <Box className={classes.inputContent}>
          <ImageInput
            className={classes.imageInput}
            onUpload={url => updateFields({ ...fields, logoURL: url })}
            imageUrl={imageUrl(fields.logoURL)}
            defaultURL={fallbackLogoUrl}
          />

          <Box className={classes.inputContentTextfield}>
            <FormErrors
              errors={errors}
              className={classes.inputContentFormError}
            />

            <FormControl variant="outlined" className={classes.textInput}>
              <InputLabel
                label="Project Name"
                required
                tooltip="Click to view"
                infoTitle="Project Name"
                infoText={InfoContent}
              />
              <CustomTextField
                variant="outlined"
                onChange={updateProjectName}
                value={fields.projectName}
                error={!!errors.projectName}
                inputProps={{ placeholder: 'Project Name' }}
                disabled={project && !project.grant_types.includes('mimik:developer_id_token')}
              />
            </FormControl>
          </Box>
        </Box>

        <Box className={classes.buttonContent}>
          {project ? (
            <Fragment>
              <Button className={classes.button} onClick={()=>setEditProject(true)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={clsx(classes.button, classes.submitButton)}
                onClick={handleProjectSave}
                disabled={loading || Object.keys(errors).length > 0 || !fieldsChanged() || changesSaved}
              >
                {changesSaved && <Check />}
                {changesSaved ? 'Changes Saved' : (loading ? 'Saving...' : 'Save')}
              </Button>
            </Fragment>
          ) : (
            <React.Fragment>
              <Button className={classes.button} onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={clsx(classes.button, classes.submitButton)}
                onClick={handleProjectCreate}
                disabled={loading || Object.keys(errors).length > 0}
              >
                {loading ? 'Creating...' : 'Create'}
              </Button>
            </React.Fragment>
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(CreateProjectForm);
