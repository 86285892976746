import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Box, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { fetchSandboxById } from '../actions/sandboxes';
import { fetchAllProjects, fetchProjectById } from '../actions/sandbox/projects';

import SEO from '../components/SEO';
import Loader from '../components/Loader';
import ProjectInfo from '../components/SandboxDashboard/ProjectInfo';
import CreateProjectForm from '../components/CreateSandboxProjectForm/Index';
import DeleteProjectForm from '../components/SandboxProjectSettings/DeleteProjectForm';
import { useState } from 'react';
import { baseUrl } from '../configs/config';
import logiIcon from '../assets/images/logo_2.png';

const styles = () => ({
  projectView: {
    display: 'flex',
    gap: 20,
    marginBottom: '20px !important'

  },
  projectForm: {
    marginBottom: '20px !important'
  },
  edit_project_btn: {
    color: '#45BBC1',
    cursor: 'pointer'
  },
  project_img: {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',
    padding: 10,
    borderRadius: 10,
    '& img': {
      maxHeight: 110
    }
  },
  projectDashboardToolbar: {
    margin: '0 1em',
  },
  projectDashboardTitle: {
    fontSize: '30px',
    fontWeight: 700,
    padding: '29px 0 0'
  },
  backButton: {
    position: 'absolute',
    top: -42,
    padding: 0,
    border: 0,
    '&:focus': {
      border: 0,
      outline: 0
    },
  },
  bold: {
    fontWeight: 900
  },
  headers: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: 18,
  },
  button: {
    borderRadius: 30,
  },
  buttonIcon: {
    marginRight: '.25em',
  },
  title: {
    flexGrow: 1
  },
});

const ProjectSettingsPage = (props) => {
  const {
    dispatch, match: { params }, currentSandbox, currentProject, history, classes, loading, projects
  } = props;

  const refreshProject = useCallback(() => dispatch(fetchProjectById(currentSandbox, params.id)), [dispatch, params]);

  const [showEditProject, setEditProject] = useState(true)
  const fallbackLogoUrl = baseUrl + logiIcon

  useEffect(() => {
    dispatch(fetchAllProjects(currentSandbox))
  }, [dispatch])


  useEffect(() => {

    refreshProject();
  }, [refreshProject]);

  if (currentSandbox && currentSandbox.status && currentSandbox.status !== 'deployed') return <Loader />;

  if (!currentSandbox) {
    // The delay here is required to ensure that the token is written to session storage
    setTimeout(() => {

      dispatch(fetchSandboxById(params.hpid));
    }, 800);
  }

  if (!currentProject && currentSandbox) {
    // The delay here may be excessive but I needed it locally
    setTimeout(() => {
      dispatch(fetchProjectById(currentSandbox, params.id));
    }, 500);
  }

  const imageUrl = (url) => {
    return url.split('/').reverse() && url.split('/').reverse().length > 1 && url.split('/').reverse()[1] === 'mimik-devportal-assets.s3-us-west-2.amazonaws.com' ? baseUrl + logiIcon : url
  }

  if (!currentProject || !projects) return <Loader fullPage />;

  return (
    <React.Fragment>
      <SEO title={`${currentProject.client_name} Settings`} />
      <Toolbar disableGutters className={classes.projectDashboardToolbar}>
        <IconButton
          className={classes.backButton}
          color="primary"
          size="small"
          aria-label="back"
          onClick={() => history.push(`/hybrid_project/dashboard/${currentSandbox.id}`)}
        >
          <ArrowBackIosIcon />
          Back
        </IconButton>

      </Toolbar>



      {showEditProject ?
        <Box className={classes.projectView}>
          <Box className={classes.project_img}>
            <img src={imageUrl(currentProject.logo_uri) || fallbackLogoUrl} alt={currentProject.client_name} />

          </Box>
          <Box className={classes.project_text}>
            <Typography variant="h1" className={classes.projectDashboardTitle}>{currentProject.client_name}</Typography>
            <Box className={classes.edit_project_btn} onClick={() => setEditProject(false)}>Edit Project</Box>
          </Box>
        </Box>
        :
        <Box className={classes.projectForm}>
          <CreateProjectForm
            setEditProject={setEditProject}
            dispatch={dispatch}
            project={currentProject}
            projects={projects}
            onSubmit={refreshProject}
            loading={loading.includes('updateProject') || loading.includes('fetchProjectById')}
            sandbox={currentSandbox}
          />
        </Box>
      }

      <ProjectInfo
        sandbox={currentSandbox}
        project={currentProject}
        dispatch={dispatch}
        history={history}
      />


      <DeleteProjectForm
        dispatch={dispatch}
        project={currentProject}
        onSubmit={() => history.push(`/hybrid_project/dashboard/${currentSandbox.id}`)}
        sandbox={currentSandbox}
      />

    </React.Fragment>
  );
};

export default connect(state => ({
  loading: state.loading,
  currentSandbox: state.currentSandbox,
  projects: state.sandboxProjects,
  currentProject: state.sandboxCurrentProject,
}))(withStyles(styles)(ProjectSettingsPage));
