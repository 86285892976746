import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function Downloads(props) {
  return (
    <SvgIcon
    {...props}
    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3401_10618)">
        <path d="M10.54 20.3901H4.28C3.85 20.3901 3.5 20.0301 3.5 19.6001L2.5 7.59005C2.5 6.93005 3.15 6.39005 3.81 6.39005H7.84C8.43 6.39005 8.97 6.69005 9.32 7.18005C9.72 7.72005 9.79 8.59005 11.06 8.72005H18.48C19.06 8.72005 19.53 9.20005 19.53 9.78005C19.53 9.78005 19.82 14.6701 19.93 14.6701C20.02 14.6701 21.49 5.57005 21.49 5.57005C21.57 4.98005 21.17 4.51005 20.6 4.51005H12.82C11.56 4.38005 11.61 3.76005 11.29 3.22005C11.01 2.74005 10.51 2.43005 9.92 2.43005H5.88C5.23 2.43005 4.62 2.97005 4.53 3.63005V6.22005" stroke="black" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M15.34 13.88V20.17" stroke="black" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M17.85 18.88L15.34 21.4L12.83 18.88" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3401_10618">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default Downloads;