import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

import SEO from '../components/SEO';
import { useState } from 'react';
import Profile from '../components/Account/Profile';
import Security from '../components/Account/Security/Index';
import TermsOfService from '../components/Account/TermsOfService';
import ReleaseNotes from '../components/Account/ReleaseNotes';

const styles = theme => ({
  root: {
    '& .MuiAccordion-root:before': {
      display: 'none'
    },
    '& .MuiPaper-elevation1': {
      boxShadow: '0px 8px 14px rgba(0, 0, 0, 0.06)',
      justifyContent: 'flex-start',
      marginBottom: 2,
      borderRadius: 10
    },
    '& .MuiAccordionSummary-root': {
      // boxShadow: '0 8px 14px rgba(0, 0, 0, 0.06)',
      boxShadow: '0px 8px 14px rgba(0, 0, 0, 0.06)',
      justifyContent: 'flex-start',
      paddingLeft: 40,
      '@media (max-width:767px)' : {
        paddingLeft:20
      }
    },
    '& .MuiCollapse-entered': {
      padding: '30px 0',
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      //color: theme.palette.primary.main,
    },
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
      width: '14%',
      margin: '17px 0',
      borderRadius: 10,
      '@media (max-width:1024px)': {
        width: '25%'
      },
      '@media (max-width:767px)': {
        width: '100%'
      }
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: '1px 0',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      color: theme.palette.primary.main,
      '& p': {
        fontWeight: 700
      }
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      opacity: 0,
    },
    '@media (max-width:767px)' : {
      '& .MuiAccordionSummary-expandIcon': {
        transform : 'rotate(90deg)'
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        opacity: 1,
        transform : 'rotate(-90deg)'
      },
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
    '@media (max-width: 767px)': {
      fontSize: 18
    }
  },
  profileTopArea: {
    maxWidth: '1150px', 
    padding: '0 1em', 
    margin: '195px auto 0',
    '@media (max-width:767px)': {
      marginTop: 30
    }
  },
  profileSettingsSection: {
    flexDirection: 'column',
  },
  agrementText: {
    position: 'absolute',
    left: 250,
    top: 15,
    color: '#4F4F4F',
    backgroundColor: '#FFD00D',
    borderRadius: 10,
    padding: '4px 18px',
    fontSize: 16,
    '@media (max-width:767px)' : {
      right: 50,
      left: 'inherit'
    }
  },
  agreementsSection: {
    flexDirection: 'column',

    '& h4': {
      width: '75%',
      margin: '30px auto',
      '@media (max-width: 767px)': {
        width: '100%',
        fontSize:20,
        marginTop:10
      },
    },
  },
});

const LoggedIn = () => {
  return sessionStorage.getItem('devportal_access_token') !== null;
};

const AccountPage = props => {
  const {
    dispatch,
    loading,
    user,
    classes,
    error,
    setSuccess,
    developerLicense
  } = props;

  if (!LoggedIn()) {
    return (<><Redirect to={`/`} /></>);
  }

  let timeout;

  window.addEventListener('blur', function (e) {
    e.preventDefault();
    window.clearTimeout(timeout);
  })

  const getProfileCurrentTab = useSelector(state => state.setProfileCurrentTab);


  const [expanded, setExpanded] = useState(getProfileCurrentTab);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <SEO title="My Account" />


      <Box
        className={[classes.root, classes.profileTopArea]}
        sx={{ flexGrow: 1 }} 
      >
        <Accordion
          defaultExpanded={true}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ChevronRight />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Profile Setting</Typography>
          </AccordionSummary>

          <AccordionDetails className={classes.profileSettingsSection}>
            <Profile
              dispatch={dispatch}
              user={user}
              loading={loading.includes('fetchUser') || loading.includes('updateUser')}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded={false}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ChevronRight />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Security</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Security
              dispatch={dispatch}
              user={user}
              error={error}
              setSuccess={setSuccess}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded={false}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ChevronRight />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Agreements</Typography>
            {developerLicense && developerLicense.agrementVerified ? null : <Box className={classes.agrementText}>New Agreement</Box>}
          </AccordionSummary>


          <AccordionDetails className={classes.agreementsSection}>
            <TermsOfService title="Agreements" />

          </AccordionDetails>

        </Accordion>

        <Accordion
          defaultExpanded={false}
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ChevronRight />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Release Notes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ReleaseNotes />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default connect(state => ({
  loading: state.loading,
  user: state.user,
  error: state.error,
  setSuccess: state.setSuccess,
  developerLicense: state.developerLicense
}))(withStyles(styles)(AccountPage));
