import React from 'react';
import { withRouter } from 'react-router';
import {
  IconButton,
  Snackbar,
  withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const styles = () => ({
  root: {
    width: "100%",

    '& .MuiSnackbarContent-root': {
      color: 'maroon',
      backgroundColor: 'mistyrose',
    },
  },
});

const NotificationSnackbar = props => {
  const {
    classes,
    error,
    notifySnackbarOpen,
    handleNotifySnackbarClose,
  } = props;

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{vertical: "top", horizontal: "center"}}
        open={notifySnackbarOpen} 
        onClose={handleNotifySnackbarClose}
        message={typeof error.description === "string" ? error.description : "Something went wrong..." }
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleNotifySnackbarClose}
            >
              <Close />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

export default withRouter(withStyles(styles)(NotificationSnackbar));
