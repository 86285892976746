import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/styles';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputLabel from '../FormFields/InputLabel';
import FormErrors from '../FormFields/FormErrors';
import ErrorModal from '../Modal/ErrorModal';
import { getSandboxAccessToken } from '../../actions/sandboxes';

const styles = {
  formControl: {
    margin: '.5em',
    '@media (max-width: 1150px)': {
      minWidth: 'calc(50% - 1em)',
    },
    '@media (max-width: 460px)': {
      width: 'calc(100% - 1em)',
    },
  },
  outlinedInput: {
    minWidth: 300,
    maxWidth: '100%',
    '@media (max-width: 985px)': {
      minWidth: 'unset',
    },
  },
  button: {
    marginLeft: '.5em',
    minWidth: '6em',
    marginTop: '1.5em',
    borderRadius: 30,
  },
  imageInput: {
    '@media (max-width: 1150px)': {
      top: '-5em',
    },
    '@media (max-width: 750px)': {
      top: '-2em',
    },
  },
  links: {
    fontSize: '.5em',
  }
};

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:after': {
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      borderRadius: 30,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 30,
      },
      '&:hover fieldset': {
        border: 'rgb(62, 168, 178) 1px solid'
      },
      '&.Mui-focused fieldset': {
      },
    },
  },
})(TextField);

const LoggedIn = () => {
  return false; // sessionStorage.getItem('devportal_access_token') !== null;
}

const SandboxLoginAccount = (props) => {
  const {
    classes, sandbox, setToken, error,
  } = props;

  let renderComponent;

  if (error && error.description) {
    renderComponent = <ErrorModal error={error} />;
  } else if (LoggedIn()) {
    return (
      <>
        <Redirect to={`/home`} />
      </>
    )
  }

  const defaultFields = {
    email: '',
    password: '',
  };

  const [fields, setFields] = useState(defaultFields);
  const [errors, setErrors] = useState({});

  const updateField = (field, value) => {
    const newFields = { ...fields, [field]: value };
    setFields(newFields);
    validateFields(field, newFields);
  };

  const validateFields = (field = null, newFields = fields) => {
    const newErrors = { ...errors };
    setErrors({});

    const validation = {
      email: () => {
        const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
        if (!newFields.email || !newFields.email.trim()) return 'Login email is required';
        if (!emailRegex.test(newFields.email)) return 'Login email is invalid';
        return null;
      },
      password: () => {
        if (!newFields.password || !newFields.password.trim()) return 'Password is required';
        if (newFields.password.length < 8) return 'Password is too short.';
        return null;
      },
    };

    if (field) {
      const error = validation[field] && validation[field]();
      if (error) {
        newErrors[field] = error;
      } else {
        delete newErrors[field];
      }
    } else {
      Object.keys(validation).forEach((key) => {
        const error = validation[key]();
        if (error) {
          newErrors[key] = error;
        } else {
          delete newErrors[key];
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = () => {
    if (!validateFields()) return;
    getSandboxAccessToken(sandbox, fields)
    .then((data) => {
      console.dir(data);
      if (data && data.data && data.data.access_token) {
        setToken(data.data.access_token);
      }
    })
    .catch((err) => {
      console.log(`ERROR: ${err}`);
      setErrors({login: err});
    })
  }

  const fieldsChanged = () => Object.keys(defaultFields)
    .reduce((acc, key) => (
      acc || JSON.stringify(defaultFields[key]) !== JSON.stringify(fields[key])
    ), false);

  return (
    <React.Fragment>
      {renderComponent}
      <form className={classes.root}>
        <FormErrors errors={errors} />
        <Box display="flex" flexDirection="column" m=".5em">
          <FormControl className={classes.formControl}>
            <InputLabel label="Login email" required />
            <CustomTextField
              variant="outlined"
              className={classes.outlinedInput}
              onChange={e => updateField('email', e.target.value)}
              value={fields.email}
              error={!!errors.email}
              type="email"
              autoComplete="email"
              inputProps={{ placeholder: 'Login email' }}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel label="Password" required />
            <CustomTextField
              variant="outlined"
              className={classes.outlinedInput}
              onChange={e => updateField('password', e.target.value)}
              value={fields.password}
              error={!!errors.password}
              type="password"
              autoComplete="current-password"
              inputProps={{ placeholder: 'Password' }}
            />
          </FormControl>
          <Box display="flex" justifyContent="flex-start" flexDirection="column" m="3.5em">
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={handleLogin}
              disabled={Object.keys(errors).length > 0 || !fieldsChanged()}
            >
              Login
            </Button>
          </Box>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default connect(() => ({
}))(withStyles(styles)(SandboxLoginAccount));

