import React from 'react';
import clsx from 'clsx'; 
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem'; 
import { getFormattedUserName } from '../../../helpers/index';

import theme from '../../../theme'; 

const styles = {
  drawerToggle: {
    position: 'absolute',
    top: '.3em',
    left: '.5em',
    zIndex: 1202,
    color: '#000000'
  },
  drawerPaper: {
    height: '100%',
    paddingTop: '1em',
    overflow: 'visible',
    '@media (max-width:767px)': {
      width: '100% !important',
      flexDirection: 'column',
      justifyContent: 'left'
    }
  },
  drawerOpen: {
    width: 400,
    '@media (max-width:767px)': {
      width: 300
    },
    '& .MuiBackdrop-root' : {
      background: 'rgba(255,255,255,0.6) !important'
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(10),
  },
  account: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '.5em',
    '& li' : {
      paddingLeft:50
    },
    '@media (max-width:1100px)': {
      marginLeft: '42px',
      paddingTop: 30
    }
  },
  accountData: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width:1100px)': {
      padding: '40px 40px 20px',
      borderBottom: '#BDBDBD 1px solid'
    }
  },
  accountButton: {
    '&:focus': {
      outline: 'none',
    },
  },
  accountAvatar: {
    marginRight: '.5em',
    background: theme.palette.secondary.main,
    '@media (max-width:1100px)' : {
      height: 50,
      width: 50,
      position:'relative'
    }
  },
  accountNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 'calc(100% - 40px - .5em)',
  },
  accountName: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    lineHeight: 1.3,
  },
  accountNameBold: {
    fontWeight: 400,
    lineHeight: 1.5,
    '@media (max-width:1100px)' : {
      fontWeight: 700,
      fontSize: 16,
      paddingBottom:5
    }
  },
  copyright_menu : {
    fontSize: 12,
    textAlign: 'center',
    paddingTop: 20
  },
  account_heading: {
    fontSize: 16,
    fontWeight: '700',
    paddingBottom: 20
  },
  close_arrow: {
    padding: '30px 30px 0 30px',
    color: '#000000'
  },
  logo_drawer: {
    display: 'none',
    textAlign: 'center',
    '& img' : {
      maxHeight: 40
    },
    '@media (max-width:1100px)' : {
      display: 'block'
    }
  }
};

const DrawerComponent = ({ classes, children, history, user, drawerOpen, closeDrawer, toggleDrawer }) => {



  const menuItems = [
    { title: 'Settings', icon: null, url: `/account` },
    { title: 'Logout', icon: null, url: `/logout` },
  ];

  // const handleLink = (url) => {
  //   if (url !== history.location.pathname) history.push(url);
  //   closeDrawer();
  // };
  const handleMenuLink = (url) => {
    if (window.location.pathname === url) return;
    if (url === `/logout`) sessionStorage.clear();
    history.push(url);
    closeDrawer();
  };

   

  const formattedName = getFormattedUserName(user);
  return (
    <React.Fragment>
      <IconButton
        className={classes.drawerToggle}
        onClick={toggleDrawer}
      >
        <Menu fontSize='large'  />
      </IconButton>
      <Drawer
        className={clsx({
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerPaper]: true,
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
        anchor="left"
        open={drawerOpen}
        onClose={closeDrawer}
      >
        <Box className={classes.close_arrow} onClick={closeDrawer}><ArrowBackIcon></ArrowBackIcon></Box>
      
        <div className={classes.accountData}>
          {user?<Avatar src={user.avatar ? `data:image/jpeg;base64,${user.avatar}` : ''} className={classes.accountAvatar}>
            {user.email.slice(0, 1).toUpperCase()}
          </Avatar>:null}
          <div className={classes.accountNameContainer}>
            {formattedName && (
              <Typography className={clsx(classes.accountName, classes.accountNameBold)}>{formattedName}</Typography>
            )}
            <Typography className={clsx(classes.accountName, !formattedName && classes.accountNameBold)}>{user && user.email}</Typography>
          </div>
        </div>

        {children}
        {user && (
          <div className={classes.account}>
            <Typography className={classes.account_heading}>My Account</Typography>
            {menuItems.map((item) => (
              <MenuItem key={item.title} onClick={() => handleMenuLink(item.url)}>
             
                {item.title}
              </MenuItem>
            ))}

          </div>
        )}


        {/* <Typography className={classes.copyright_menu}>© mimik technology, Inc. All rights reserved </Typography> */}

      </Drawer>
    </React.Fragment>
  );
};

export default withStyles(styles)(DrawerComponent);
