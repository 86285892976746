import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function EdgeProjects(props) {
  return (
    <SvgIcon
    {...props}
    width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.81 20.63C12.91 20.63 12.2 20.35 11.68 19.8C11.16 19.25 10.9 18.49 10.9 17.53C10.9 16.9 11.02 16.35 11.26 15.88C11.5 15.41 11.83 15.04 12.25 14.78C12.67 14.52 13.16 14.39 13.71 14.39C14.49 14.39 15.1 14.65 15.53 15.16C15.96 15.67 16.18 16.38 16.18 17.29C16.18 17.51 16.09 17.61 15.91 17.61H11.45C11.47 18.42 11.68 19.04 12.09 19.48C12.5 19.92 13.07 20.14 13.8 20.14C14.13 20.14 14.45 20.1 14.74 20.01C15.03 19.92 15.32 19.77 15.6 19.54C15.71 19.46 15.81 19.43 15.89 19.47C15.98 19.5 16.03 19.57 16.05 19.66C16.07 19.75 16.03 19.84 15.92 19.93C15.66 20.15 15.35 20.32 14.97 20.44C14.6 20.56 14.21 20.63 13.82 20.63H13.81ZM13.69 14.86C13.02 14.86 12.49 15.08 12.11 15.51C11.73 15.94 11.51 16.49 11.45 17.15H15.68C15.65 16.44 15.48 15.88 15.14 15.48C14.81 15.07 14.32 14.87 13.68 14.87L13.69 14.86Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
<path d="M8.54 19.3901H2.28C1.85 19.3901 1.5 19.0301 1.5 18.6001L0.5 6.59005C0.5 5.93005 1.15 5.39005 1.81 5.39005H5.84C6.43 5.39005 6.97 5.69005 7.32 6.18005C7.72 6.72005 7.79 7.59005 9.06 7.72005H16.48C17.06 7.72005 17.53 8.20005 17.53 8.78005C17.53 8.78005 17.82 13.6701 17.93 13.6701C18.02 13.6701 19.49 4.57005 19.49 4.57005C19.57 3.98005 19.17 3.51005 18.6 3.51005H10.82C9.56 3.38005 9.61 2.76005 9.29 2.22005C9.01 1.74005 8.51 1.43005 7.92 1.43005H3.88C3.23 1.43005 2.62 1.97005 2.53 2.63005V5.22005" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
</SvgIcon>

  );
}

export default EdgeProjects;