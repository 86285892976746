import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Delete from '@material-ui/icons/Delete';
import Close from '@material-ui/icons/Close';
import red from '@material-ui/core/colors/red';

import { deleteProject } from '../../actions/sandbox/projects';

const styles = () => ({
  root: {
    margin: '1em',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    marginTop:20,
    justifyContent: 'space-between',
    padding: '1.75em 2.75em',
    borderRadius: 10,
    border: `1px solid ${red[700]}`,
    '@media (max-width: 550px)': {
      flexDirection: 'column',
    },
    '@media (max-width: 750px)': {
      padding: '1em',
    },
  },
  sectionHeader: {
    fontWeight: 700,
    fontSize: '1.1em',
  },
  deleteButton: {
    margin: '2em 0',
    borderRadius: 30,
    background: red[700],
    color: '#fff',
    '&:hover': {
      background: red[800],
    },
    '@media (max-width: 550px)': {
      alignSelf: 'flex-end',
      marginTop: '1em',
    },
  },
  deleteButtonIcon: {
    marginRight: '.25em',
  },
  dialogButton: {
    margin: '0 .25em',
  },
  dialogPaper: {
    position: 'relative',
    minWidth: 500,
    padding: '2em',
  },
  dialogHeader: {
    fontSize: '1.3em',
    fontWeight: 400,
    marginBottom: '.5em',
  },
  dialogTextField: {
    margin: '1em 0',
  },
  dialogClose: {
    position: 'absolute',
    top: '0.1em',
    right: '0.1em',
  },
});

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:after': {
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: 30,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 30,
      },
      '&:hover fieldset': {
        border: 'rgb(62, 168, 178) 1px solid'
      },
      '&.Mui-focused fieldset': {
      },
    },
  },
})(TextField);

const DeleteProjectForm = (props) => {
  const {
    classes, dispatch, sandbox, project, onSubmit,
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTextValue, setDialogTextValue] = useState('');
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const handleDelete = () => dispatch(deleteProject(sandbox, project.client_id))
    .then(() => {
      if (typeof onSubmit === 'function') onSubmit();
    });

  useEffect(() => {
    setDialogTextValue('');
  }, [dialogOpen]);

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={4}>
          <div>
            <Typography className={classes.sectionHeader}>Delete this project</Typography>
            <Typography>
              Once you delete a project, there is no going back. Please be certain.
            </Typography>
          </div>
          <Button
            variant="contained"
            size="large"
            className={classes.deleteButton}
            onClick={openDialog}
          >
            <Delete className={classes.deleteButtonIcon} />Delete
          </Button>
        </Paper>
      </div>

      <Dialog
        open={dialogOpen}
        classes={{ paper: classes.dialogPaper }}
        onClose={closeDialog}
      >
        <IconButton
          className={classes.dialogClose}
          onClick={closeDialog}
        >
          <Close />
        </IconButton>
        <Typography className={classes.dialogHeader}>Confirm Deletion</Typography>
        <Typography>
          Enter the name <strong>{project.client_name}</strong> below to confirm deletion.
        </Typography>
        <CustomTextField
          className={classes.dialogTextField}
          variant="outlined"
          value={dialogTextValue}
          onChange={e => setDialogTextValue(e.target.value)}
        />
        <Box display="flex" justifyContent="flex-end">
          <Button
            className={classes.dialogButton}
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={clsx(classes.dialogButton, classes.deleteButton)}
            disabled={dialogTextValue !== project.client_name}
            onClick={handleDelete}
          >
            <Delete className={classes.deleteButtonIcon} />Confirm
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(DeleteProjectForm);
