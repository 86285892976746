import React from 'react';
import { withStyles } from '@material-ui/styles';

import SandboxTile from './SandboxTile';

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    animation: 'fadeIn 800ms ease-in-out',
    margin: '0 0 0 -2em',

    '@media (max-width: 580px)': {
      display: 'block',
    },
  },
});

const SandboxGrid = ({ classes, data }) => (
  <div className={classes.root}>
    {data.map(item => (
      <SandboxTile key={item.id} item={item} />
    ))}
  </div>
);

export default withStyles(styles)(SandboxGrid);
