import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

const styles = {
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    margin: 'auto',
    animation: 'fadeIn 500ms ease-in-out',
  },
  fullPageLoader: {
    height: 'calc(100vh - 12em)',
  },
  shortLoader: {
    height: 48
  }
};

const MimikLoader = ({
  classes, className, variant, fullPage, short
}) => (
  <div className={clsx(classes.loader, className, fullPage && classes.fullPageLoader, short && classes.shortLoader)}>
    {(!variant || variant === 'loading') && (
      <div className="mimik-loader">
        <div /><div /><div /><div />
      </div>
    )}
    {variant === 'pulse' && (
      <div className="mimik-dot-pulse">
        <div /> <div />
      </div>
    )}
    {variant === 'spinner' && (
      <div className="mimik-dots-spinner">
        <div /><div /><div /><div /><div />
      </div>
    )}
  </div>
);

export default withStyles(styles)(MimikLoader);
