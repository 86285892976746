import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function Home(props) {
  return (
    <SvgIcon
    {...props}
    width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.48 3.99006L11.26 1.54006C11.11 1.42006 10.91 1.42006 10.76 1.54006L1.69998 8.41006C1.30998 8.70006 1.48998 9.40006 1.94998 9.40006H20.05C20.51 9.40006 20.69 8.70006 20.3 8.41006L18.35 6.92006L16.4 5.44006L15.92 5.03006V2.25006C15.92 1.73006 15.56 1.31006 15.11 1.31006H15.01C14.56 1.31006 14.2 1.73006 14.2 2.25006V3.80006" stroke="url(#paint0_linear_3492_5263)" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M17.4399 11.4602V19.5302C17.4399 20.0202 17.1199 20.4202 16.7299 20.4202H13.3999V14.0602C13.3999 13.4502 12.9999 12.9502 12.5099 12.9502H9.95995C9.46995 12.9502 9.06995 13.4502 9.06995 14.0602V20.4202" stroke="url(#paint1_linear_3492_5263)" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M10.77 20.4202H5.17997C4.78997 20.4202 4.46997 20.0202 4.46997 19.5302V9.43018" stroke="url(#paint2_linear_3492_5263)" strokeMiterlimit="10" strokeLinecap="round"/>
<linearGradient id="paint0_linear_3492_5263" x1="11" y1="1.31006" x2="11" y2="9.40006" gradientUnits="userSpaceOnUse">
<stop stopColor="#000000"/>
<stop offset="0.791667" stopColor="#000000" stopOpacity="0.93"/>
<stop offset="1" stopColor="#000000"/>
</linearGradient>
<linearGradient id="paint1_linear_3492_5263" x1="13.2549" y1="11.4602" x2="13.2549" y2="20.4202" gradientUnits="userSpaceOnUse">
<stop stopColor="#000000"/>
<stop offset="0.791667" stopColor="#000000" stopOpacity="0.93"/>
<stop offset="1" stopColor="#000000"/>
</linearGradient>
<linearGradient id="paint2_linear_3492_5263" x1="7.61997" y1="9.43018" x2="7.61997" y2="20.4202" gradientUnits="userSpaceOnUse">
<stop stopColor="#000000"/>
<stop offset="0.791667" stopColor="#000000" stopOpacity="0.93"/>
<stop offset="1" stopColor="#000000"/>
</linearGradient>
</SvgIcon>
  );
}

export default Home;