import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function DrawerLeft(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.0545 0.573272C11.1273 0.645843 11.185 0.732055 11.2244 0.826969C11.2638 0.921884 11.284 1.02364 11.284 1.1264C11.284 1.22916 11.2638 1.33091 11.2244 1.42582C11.185 1.52074 11.1273 1.60695 11.0545 1.67952L2.23108 10.5014L11.0545 19.3233C11.2012 19.47 11.2836 19.6689 11.2836 19.8764C11.2836 20.0839 11.2012 20.2828 11.0545 20.4295C10.9078 20.5762 10.7089 20.6586 10.5014 20.6586C10.2939 20.6586 10.095 20.5762 9.94827 20.4295L0.573272 11.0545C0.500517 10.982 0.442793 10.8957 0.403408 10.8008C0.364023 10.7059 0.34375 10.6042 0.34375 10.5014C0.34375 10.3986 0.364023 10.2969 0.403408 10.202C0.442793 10.1071 0.500517 10.0208 0.573272 9.94827L9.94827 0.573272C10.0208 0.500517 10.1071 0.442793 10.202 0.403408C10.2969 0.364023 10.3986 0.34375 10.5014 0.34375C10.6042 0.34375 10.7059 0.364023 10.8008 0.403408C10.8957 0.442793 10.9819 0.500517 11.0545 0.573272Z" fill="#3EA8B2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.3045 0.573272C17.3773 0.645843 17.435 0.732055 17.4744 0.826969C17.5138 0.921884 17.534 1.02364 17.534 1.1264C17.534 1.22916 17.5138 1.33091 17.4744 1.42582C17.435 1.52074 17.3773 1.60695 17.3045 1.67952L8.48108 10.5014L17.3045 19.3233C17.3772 19.3959 17.4348 19.4821 17.4741 19.577C17.5134 19.672 17.5336 19.7737 17.5336 19.8764C17.5336 19.9791 17.5134 20.0808 17.4741 20.1757C17.4348 20.2707 17.3772 20.3569 17.3045 20.4295C17.2319 20.5022 17.1457 20.5598 17.0507 20.5991C16.9558 20.6384 16.8541 20.6586 16.7514 20.6586C16.6487 20.6586 16.547 20.6384 16.452 20.5991C16.3571 20.5598 16.2709 20.5022 16.1983 20.4295L6.82327 11.0545C6.75052 10.982 6.69279 10.8957 6.65341 10.8008C6.61402 10.7059 6.59375 10.6042 6.59375 10.5014C6.59375 10.3986 6.61402 10.2969 6.65341 10.202C6.69279 10.1071 6.75052 10.0208 6.82327 9.94827L16.1983 0.573272C16.2708 0.500517 16.3571 0.442793 16.452 0.403408C16.5469 0.364023 16.6486 0.34375 16.7514 0.34375C16.8542 0.34375 16.9559 0.364023 17.0508 0.403408C17.1457 0.442793 17.232 0.500517 17.3045 0.573272V0.573272Z" fill="#3EA8B2" />
    </SvgIcon>
  );
}

export default DrawerLeft;
