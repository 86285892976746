import React from 'react';
import {
  Box,
  Button,

  Grid,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';

import InputLabel from '../FormFields/InputLabel';
import iconOnboarding from '../../assets/images/icon-onboarding.png';
import onBoardingBG from '../../assets/images/mimik_onboarding_bg.png';

const styles = {
  onboardingWrapper: {
    width: '100%',
    height: '100%',
    maxWidth: '100% !important',
    padding: '0 !important',
    margin: '0 !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  cCodeText: {
    fontSize:14,
    lineHeight: '16px',
    
    paddingBottom: 10,
    fontFamily: 'Roboto',
    '@media (max-width: 767px)': {
      textAlign: 'left'
    },
  },
  cCodeText_gap: {
    fontSize:14,
    lineHeight: '16px',
    paddingTop: 30,
    paddingBottom: 10,
    fontFamily: 'Roboto',
    '@media (max-width: 767px)': {
      textAlign: 'left'
    },
  },
  loginMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flexGrow: 1,

    '& > div + div > div': {
      maxWidth: 840,
    },

    '@media (max-width: 1024px)': {
      backgroundImage: `url(${onBoardingBG})`,
      backgroundRepeat: 'repeat-y',
      backgroundSize: '100%',
    },
  },
  loginMainGrid: {
    '@media (max-width: 767px)': {
      width: 'calc(100% - 40px)',
      margin: '0 20px',
    },
  },
  formUI: {
    maxWidth: '75%',
    margin: '0 auto',

    '@media (max-width: 767px)': {
      maxWidth: '85%',
      textAlign: 'left'
    },
  },
  disclaimerText: {
    maxWidth: '293px',
    margin: '0 auto',

    '@media (max-width: 767px)': {
      padding: '20px 0',
    },
  },
  formControl: {
    display: 'flex',
    margin: '1em 0',
  },
  responsiveGrid: {
    '@media (max-width: 767px)': {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  formContainer: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.12)',
    paddingTop: '4em',
    borderRadius: 55,

    '@media (max-width: 1024px)': {
      backgroundColor: 'rgba(255, 255, 255, 0.76)',
      boxShadow: 'none',
    },

    '@media (max-width: 767px)': {
      paddingTop: '0',
      paddingBottom: '1.25em',
    },
  },
  outlinedInput: {
    minWidth: '100%',
    maxWidth: '100%',
    '@media (max-width: 767px)': {
      
    },
  },
  button: {
    minWidth: '6em',
    borderRadius: 30,
    margin: '1em 0'
  },
  copyLink: {
    color: '#3ea8b2', 
    textDecoration:'underline', 
    whiteSpace: 'nowrap',
    '&:hover':{
      fontWeight:700,
      color: '#3ea8b2', 
      textDecoration:'underline',
    }
  },
  imageInput: {
    '@media (max-width: 1150px)': {
      top: '-5em',
    },
    '@media (max-width: 750px)': {
      top: '-2em',
    },
  },
  links: {
    fontSize: '.825em',
  },
  logo: {
    width: '100%',
    maxWidth: 92,
    position: 'absolute',
    top: 30,
    left: 30,
  },
  logofloating: {
    display: 'block',

    '@media (max-width: 750px)': {
      display: 'none',
    },
  },
  logoContained: {
    display: 'none',
    padding: '20px 0',
    textAlign: 'center',

    '& img': {
      width: '100%',
      maxWidth: 92,
    },

    '@media (max-width: 750px)': {
      display: 'block',
    },
  },
  formInfo: {
    padding: '1em 2em',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '15px',
    position: 'relative',
  },
  formUITextInfo: {
    '@media (max-width: 767px)': {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      textAlign: 'center',
      paddingBottom: '0 !important',

     

      '& + div': {
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
        paddingTop: '0 !important',
      },
    },
  },
  infoMessageIcon: {
    position: 'absolute',
    top: 'calc(50% - 0.75em)',
    left: '-0.75em',
    width: '1.5em',
    height: '1.5em',
    marginRight: '.75em',
  },
  codeVerificationDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '400px',
    },

    '& form > div': {
      maxWidth: '400px',

      '& > div > div': {
        margin: '3em',
      },
    },
  },
  buttonSection: {
    marginTop: 5,
  },
  onboardingInfoSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${onBoardingBG})`,
    backgroundRepeat: 'repeat-y',
    backgroundSize: '100%',
    width: 568,
    height: '100%',

    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  infoDisplay: {
    width: '65%',
    height: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 40,
    padding: '0 1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& > ul': {
      listStyleType: 'none',
      paddingLeft: 32,

      '& > li': {
        minHeight: '40px',
        padding: '0 0 0 2.5em',
        backgroundImage: `url(${iconOnboarding})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '24px',
        backgroundPosition: 'center left',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  },
  onboardingFooterText: {
    textAlign: 'center',

    '@media (max-width: 767px)': {
      fontSize: '0.825em',
    },
  },

  // error styles
  errMessageContainer: {
    maxWidth: '300px',
    margin: '0 auto 1em',
  },
  errorMessage: {
    padding: '1em 2em',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '15px',
    position: 'relative',
    color: red[600],
  },
  errorMessageIcon: {
    position: 'absolute',
    top: 'calc(50% - 0.75em)',
    left: '-0.75em',
    width: '1.5em',
    height: '1.5em',
    marginRight: '.75em',
  },
};



const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: '15px 14px',
      backgroundColor: '#F0F0F0'
    },
    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:after': {
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      borderRadius: 30,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 30,
      },
      '&:hover fieldset': {
        border: 'rgb(62, 168, 178) 1px solid'
      },
      '&.Mui-focused fieldset': {
      },
    },
  },
})(TextField);

const ResendLink = props => {
  const {
    classes,
    value,
    resendVerificationLink,
    onChange,
    errors,
    resendBtnDisable
  } = props;

  const keyPress = (e) => {    
    if(e.keyCode == 13){
      e.preventDefault();
      resendVerificationLink
    }
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={5}
      >
        <Grid
          item
          sm={6}
          className={classes.formUITextInfo}
        >
          <Typography variant="h3" style={{ fontWeight: 'bold', fontFamily:'Roboto' }}>validate email</Typography>
         
         
        </Grid>

        <Grid item xs={12} sm={6}>
         
            <InputLabel label="Email Address" required />
            <CustomTextField
              variant="outlined"
              className={classes.outlinedInput}
              onChange={e => onChange(e.target.value)}
              onKeyDown={keyPress}
              value={value}
              type="email"
              autoComplete="email"
              inputProps={{ placeholder: 'Login email' }}
              error={errors}
            />
            <Typography className={classes.cCodeText_gap}>do not close this window or you will have to request a new code.</Typography>
                <Typography className={classes.cCodeText}>if the code doesn’t arrive, check your spam folder before requesting a new one.</Typography>

        </Grid>
      </Grid>

      <Box display="flex" flexDirection="column">
        <Button
          color="primary"
          variant="contained"
          size="large"
          className={classes.button}
          onClick={resendVerificationLink}
          disabled={!value || resendBtnDisable ? true : false}
        >
          Submit
        </Button>

        <Typography variant="body2" align="center" className={classes.disclaimerText}>by creating an account with mimik, I agree and understand the <a target='_blank' rel="noreferrer" href="https://developer.mimik.com/developer-agreement/" className={classes.copyLink}>developer license agreement</a></Typography>
      </Box>
    </>
  );
};

export default withStyles(styles)(ResendLink);
