import React, {
  useEffect,
  useState
} from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router';

import { getAgreements } from '../actions/developerLicense';
import SEO from '../components/SEO';
import Loader from '../components/Loader';
import ViewAgreementModal from '../components/Modal/ViewAgreementModal';

const styles = {
  root: {
    width: '100%',
  },
  toolbar: {
    opacity: 1,
    transition: 'opacity 400ms',
  },
  backButton: {
    position: 'absolute',
    top: -42,
    left: 4,
    '&:focus': {
      border:0,
      outline:0
    },
  },
  headers: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: 18,
  },
};

const getFormattedDate = dateStr => {
  const date = new Date(dateStr);

  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (isNaN(date.getTime())) {
      return '';
    } else {
      return date.toLocaleDateString();
    }
  }
};

const Agreements = props => {
  const { developerLicense, classes, dispatch } = props;

  const [open, setOpen] = useState(false);
  const [contentAgreement, setContentAgreement] = useState(null);

  const history = useHistory();

  let timeout;

  window.addEventListener('blur', function (e) {
    e.preventDefault();
    window.clearTimeout(timeout);
  });

  useEffect(() => {
    dispatch(getAgreements());
  }, [dispatch]);

  if (!developerLicense) return <Loader fullpage />

  const {
    agreementList,
    agreementInfo
  } = developerLicense;

  const handleOnClickBackBtn = () => history.push(`/account`);
  const handleCloseDialog = () => setOpen(false);

  let titleEl = document.createElement('html');
  let infoEl = document.createElement('html');

  const getTitle = title => {
    titleEl.innerHTML = title.questions.pages[0].elements[0].html;
    return titleEl.getElementsByTagName('h4')[0].innerText;
  };

  const getInfo = info => {
    infoEl.innerHTML = info.questions.pages[0].elements[0].html;
    return infoEl.getElementsByTagName('body')[0].innerHTML;
  };

  return (
    <>
      <SEO title="Agreements" />
      <Toolbar
        disableGutters
        className={classes.toolbar}
      >
        <IconButton
          className={classes.backButton}
          color="primary"
          size="small"
          aria-label="back"
          onClick={() => handleOnClickBackBtn()}
        >
          <ArrowBackIosIcon />
          Back
        </IconButton>

        <div className={classes.headers}>
          <Typography variant="h1">Terms of Service Agreements</Typography>
        </div>
      </Toolbar>

      {agreementList && agreementList.length > 0 ? (<Card className={classes.root}>
        <CardContent>
          <List component="nav" aria-label="main agreements list">
            {agreementList.map((item, index) => {
              titleEl.innerHTML = item.questions.pages[0].elements[0].html;

              return (<div key={index}>
                <ListItem button onClick={() => {
                    setOpen(true);
                    setContentAgreement(item);
                  }}
                >
                  <ListItemText primary={`${titleEl.getElementsByTagName('h4')[0].innerText} ${item.version}`} />

                  {
                    (typeof agreementInfo[index] !== "undefined" && agreementInfo[index].length > 0)
                      ?
                      <ListItemText style={{ textAlign: 'right' }} secondary={getFormattedDate(agreementInfo[index][0].updatedAt)} />
                      :
                      ""
                  }
                </ListItem>
              </div>);
            })}
          </List>
          
          {contentAgreement && contentAgreement !== null && (
            <ViewAgreementModal
              dialogOpen={open}
              dialogClose={handleCloseDialog}
              title={getTitle(contentAgreement)}
              content={getInfo(contentAgreement)}
              version={contentAgreement.version}
              dispatch={dispatch}
              developerLicense={developerLicense}
            />
          )}          
        </CardContent>
      </Card>) : ""}
    </>
  );
};

export default connect(state => ({
  developerLicense: state.developerLicense,
}))(withStyles(styles)(Agreements));
