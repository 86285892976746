import React, {useState} from "react";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  withStyles
} from "@material-ui/core";

import ReleaseNoteScreen from "./ReleaseNoteScreen";

const releaseNotesData = {
  data: [
    {
      version: "Developer Console 2.4",
      title: "seamless access to the developer resources.",
      subTitle: "get access to the latest versions of edgeEngine repositories, support, documentation, and communities.",
      content: {
        contentTitle: "what are the benefits?",
        contentDetails: [
          {
            contentDetailsTitle: "improved onboarding",
            contentDetailsText: "have a better experience creating accounts, editing user profiles, security, and agreement settings."
          },
          {
            contentDetailsTitle: "improved homepage",
            contentDetailsText: "have seamless access to the main features of the developer console and the related external links."
          },
          {
            contentDetailsTitle: "updated download page",
            contentDetailsText: "get access to the latest versions of edgeEngine for the platform of your choice."
          },
          {
            contentDetailsTitle: "community-enabled support page",
            contentDetailsText: "get access to support through the GitHub community, email, and the documentation site."
          }
        ]
      }
    },
    {
      version: "Developer Console 2.3",
      title: "create and deploy hybrid projects efficiently",
      subTitle: "have a better experience with the deployment of hybrid projects.",
      content: {
        contentTitle: "what are the benefits?",
        contentDetails: [
          {
            contentDetailsTitle: "redesigned hybrid project dashboard",
            contentDetailsText: "have a better experience creating and managing your hybrid projects."
          },
          {
            contentDetailsTitle: "improved hybrid project wizard",
            contentDetailsText: "choose your bundle of choice based on the service offerings."
          }
        ]
      }
    },
    {
      version: "Developer Console 2.2",
      title: "create edge and hybrid projects",
      subTitle: "create edge and hybrid projects using edge or a combination of edge and bundle cloud microservices.",
      content: {
        contentTitle: "what are the benefits?",
        contentDetails: [
          {
            contentDetailsTitle: "seamless onboarding",
            contentDetailsText: "have a better experience with creating accounts, editing user profiles, security, and agreement settings."
          },
          {
            contentDetailsTitle: "mService Bundles",
            contentDetailsText: "leverage the bundle mServices provided by mimik for your hybrid projects."
          }
        ]
      }
    }
  ]
};

const styles = {
  root: {
    width: '100%',

    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  releaseNotes: {
    boxShadow: '0px 8px 14px rgba(0, 0, 0, 0.06)',
    margin: '30px auto',
    padding: '0 20px !important',
    borderRadius: 10
  },
};

const ReleaseNotes = props => {
  const { classes } = props;

  const [view, setView] = useState("default");
  const [data, setData] = useState(null);

  const handleClickListItem = i => {
    setView("releaseNoteScreen");
    setData(releaseNotesData.data[i]);
  };

  return (
    <div className={classes.root}>
      {(() => {
        switch (view) {
          case "releaseNoteScreen":
            return (<ReleaseNoteScreen
              data={data}
              setView={() => setView("default")}
            />);
          default:
            return (<Grid
              container
              direction="row"
              justifyContent="center"
              spacing={5}
            >
              <Grid
                item
                xs={10}
                className={classes.releaseNotes}
              >
                {releaseNotesData.data.map((item, index, arr) => {
                  return (<div key={index}>
                    <List
                      component="nav"
                      aria-label="release notes"

                    >
                      <ListItem
                        button
                        onClick={() => handleClickListItem(index)}
                      >
                        <ListItemText primary={item.version} />
                      </ListItem>
                    </List>

                    {
                      (!((arr.length - 1) === index))
                        ? <Divider />
                        : null
                    }
                  </div>);
                })}
              </Grid>
            </Grid>);
        }
      })()}
    </div>
  );
};

export default withStyles(styles)(ReleaseNotes);