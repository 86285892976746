import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import Logout from '@material-ui/icons/ExitToApp';
import Account from '@material-ui/icons/AccountCircle';
import logo from '../../assets/images/mimik-logo.png';
import { getFormattedUserName } from '../../helpers/index'; 
import Triangle from '../Icons/Triangle'; 
import { useSelector } from 'react-redux'; 


const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.header,
  },
  rootWrapper: {
    paddingRight: '.5em',
    position: 'absolute',
    top: 0,
    right: 0,
    '@media (max-width: 1100px)': {
      width: '100%',
      backgroundColor: '#ffffff',
      textAlign: 'center'
    },
  },
  root: {
    minWidth: 400,
  },
  logo: {
    maxWidth: '5.5em',
    marginRight: '.4em',
    marginTop: '-.2em',
  },
  logoText: {
    marginBottom: '-.2em',
  },
  accountCard: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    margin: 21,
    zIndex: 100,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    boxShadow: '0px 8px 14px rgba(0, 0, 0, 0.06)',
    borderRadius: 10,
    border: 'none',
    '& div': {
      border: 0,
      borderRadius: 10,
    }
  },
  accountCardContent: {
    paddingBottom: '16px !important',
    border: 'none'
  },
  divider: {
    height: '2.5em',
    margin: '0 .5em',
  },
  buttonWrapper: {
    // padding: '.5em 0',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  accountButton: {
    padding: '8px 0 0',
    fontWeight: 700,

    '&:focus': {
      outline: 'none',
    },
  },
  accountButtonIcon: {
    paddingLeft: 12,
    height: 11,
  },
  accountInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 338,
  },
  accountAvatar: {
    background: theme.palette.secondary.main,
    marginLeft: '.5em',
    height: 60,
    width: 60,
  },
  accountNameBold: {
    fontWeight: 400,
  },
  accountNameSmall: {
    lineHeight: 1.3,
  },
  menuPaper: {
    minWidth: 400,
    width:400,
    marginTop: 3,
    borderTop: '#ffffff 12px solid',
    marginLeft: -13,
    boxShadow: '0 7px 10px rgba(0,0,0,0.051) !important',
    borderRadius: '0 0 12px 12px !important', 
    backgroundColor:'#ffffff !important',
    border: 0,
    '& ul li' : {
      borderBottom: '#2faab1 2px solid',
      padding: '8px 15px',
      margin: '0 15px',
      '&:hover' : {
        color: '#8fc8cf !important',
        backgroundColor: 'transparent !important',
      },
      '&:last-child' : {
        borderBottom: 'none'
      }
    }
  },
  logoWrapper: {
    display: 'none',
    '@media (max-width: 1100px)': {
      margin: '0 auto',
      display: 'inline-block',
      padding: 20
    },
  },
  termsIcon: {
    backgroundColor: '#FFD00D',
    width: 24,
    height: 24,
    borderRadius: 24,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    marginRight: 10
  },
  settingTermsIcon : {
    backgroundColor: '#FFD00D',
    width: 24,
    height: 24,
    borderRadius: 24,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    marginLeft: 10,
    textAlign: 'center'
  }
});

const menuItems = [
  { title: 'Settings', icon: Account, url: `/account` },
  { title: 'Logout', icon: Logout, url: `/logout` },
];

const HeaderComponent = (props) => {
  const { classes, user, history } = props;
  const [anchorEl, setAnchorEl] = useState(null);
 
  const developerLicense = useSelector((state)=>state.developerLicense)
 
  const showMenu = e => setAnchorEl(e.target.closest('button'));
  const hideMenu = () => setAnchorEl(null);
  const handleMenuLink = (url) => {
    if (window.location.pathname === url) return;
    if (url === `/logout`) sessionStorage.clear();
    history.push(url);
    hideMenu();
  };

 

  let formattedName;

  if (user) {
    formattedName = getFormattedUserName(user);
  }

  return (
    <div className={classes.rootWrapper}>
      {user && (<div className={classes.logoWrapper} >
        <Link to={`/`}>
          <Box display="flex" alignItems="flex-end" color="#000" mb="-5px">
            <img
              src={logo}
              alt="mimik logo"
              className={classes.logo}
            />
          </Box>
        </Link>
      </div>)}
      {user && (
        <div className={classes.accountCard}>
          <Card className={classes.root} variant="outlined">
            <CardContent className={classes.accountCardContent}>
              <Grid container>
                <Grid item xs={9}>
                  <Box>
                    
                    {!formattedName ? <Typography className={!formattedName ? classes.accountNameBold : classes.accountNameSmall} component='div'>{user.email}</Typography>: <Typography className={classes.accountNameBold} component='div'>{formattedName}</Typography>}
                  </Box>

                  <Button onClick={showMenu} className={classes.accountButton}>
                  {developerLicense && developerLicense.agrementVerified?null:<Box className={classes.termsIcon}>1</Box>}
                    My account <Triangle
                      up={Boolean(anchorEl).toString()}
                      className={classes.accountButtonIcon}
                    />
                  </Button>
                </Grid>

                <Grid item xs={3}>
                  <Avatar src={user.avatar ? user.avatar : ''} className={classes.accountAvatar}>
                    {user.email.slice(0, 1).toUpperCase()}
                  </Avatar>
                </Grid>
              </Grid>

              <Menu
                classes={{ paper: classes.menuPaper }}
                anchorEl={anchorEl}
                keepMounted={process.env.NODE_ENV !== 'test'}
                open={Boolean(anchorEl)}
                onClose={hideMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                MenuListProps={{
                  onMouseLeave: hideMenu,
                }}
              >
                {menuItems.map((item) => (
                  <MenuItem key={item.title} onClick={() => handleMenuLink(item.url)}>
                    {item.title}  {item.title == 'Settings' && !(developerLicense && developerLicense.agrementVerified)?<Box className={classes.settingTermsIcon}>1</Box>:null}
                  </MenuItem>
                ))}
              </Menu>
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  )
};

export default withRouter(withStyles(styles)(HeaderComponent));
