import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function HybridProjects(props) {
  return (
    <SvgIcon
    {...props}
     viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.41 20.7399C11.24 20.7399 11.15 20.6499 11.15 20.4699V12.1299C11.15 11.9499 11.24 11.8599 11.41 11.8599C11.58 11.8599 11.67 11.9499 11.67 12.1299V15.7599C11.87 15.3599 12.15 15.0699 12.52 14.8699C12.89 14.6699 13.31 14.5699 13.77 14.5699C15.2 14.5699 15.91 15.3299 15.91 16.8499V20.4699C15.91 20.6499 15.82 20.7399 15.65 20.7399C15.48 20.7399 15.39 20.6499 15.39 20.4699V16.8999C15.39 16.2599 15.26 15.7999 15.01 15.4999C14.75 15.1999 14.34 15.0499 13.77 15.0499C13.13 15.0499 12.62 15.2499 12.24 15.6399C11.86 16.0299 11.66 16.5599 11.66 17.2199V20.4699C11.66 20.6499 11.57 20.7399 11.4 20.7399H11.41Z"  stroke="black" strokeWidth="0.7" strokeMiterlimit="10"/>
<path d="M8.54 19.3899H2.28C1.85 19.3899 1.5 19.0299 1.5 18.5999L0.5 6.58993C0.5 5.92993 1.15 5.38993 1.81 5.38993H5.84C6.43 5.38993 6.97 5.68993 7.32 6.17993C7.72 6.71993 7.79 7.58993 9.06 7.71993H16.48C17.06 7.71993 17.53 8.19993 17.53 8.77993C17.53 8.77993 17.82 13.6699 17.93 13.6699C18.02 13.6699 19.49 4.56993 19.49 4.56993C19.57 3.97993 19.17 3.50993 18.6 3.50993H10.82C9.56 3.37993 9.61 2.75993 9.29 2.21993C9.01 1.73993 8.51 1.42993 7.92 1.42993H3.88C3.23 1.42993 2.62 1.96993 2.53 2.62993V5.21993" stroke="black" />
</SvgIcon>
  );
}

export default HybridProjects;
