import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
 
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
 

import { 
  getOauthDeeplink, 
  clearDeveloperIdTokenAndClientId,
 } from '../../actions';
import { fetchAllProjects, resetCurrentProject } from '../../actions/sandbox/projects';

import SEO from '../SEO';
import Loader from '../Loader';
import CreateProjectForm from '../CreateSandboxProjectForm/Index';
import ProjectGrid from '../SandboxProjects/ProjectGrid/Index';

const styles = {
  toolbar: {
    opacity: 1,
    transition: 'opacity 400ms',
  },
  fontBold: {
    fontWeight: 'bold',
  },
  toolbarHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
 
  button: {
    marginRight: '.5em',
    borderRadius: '30px',

    '&.MuiButton-containedSizeLarge': {
      padding: '10px 44px',
    },
  },
  buttonIcon: {
    marginRight: '.25em',
  },
  buttonIconSmall: {
    height: '.9em',
    width: '.9em',
  },
  projectGridContainer: {
    padding: '1em 0',
    '& > div' : {
      '@media (max-width:767px)' :{
        display: 'flex',
        gap: '2%'
      }
    }
  },
};

const SandboxProjects = (props) => {
  const {
    dispatch, classes, projects, loading, sandbox,
  } = props; 
  const [createFormVisible, setCreateFormVisible] = useState(false);

  let timeout;

  window.addEventListener('blur', function (e) {
    e.preventDefault();
    window.clearTimeout(timeout);
  })
 
  const showForm = () =>setCreateFormVisible(true);
  const hideForm = () => {
    setCreateFormVisible(false);
    dispatch(fetchAllProjects(sandbox));
  };
  
  useEffect(() => {
    dispatch(fetchAllProjects(sandbox));
    dispatch(resetCurrentProject());
    dispatch(getOauthDeeplink());
    dispatch(clearDeveloperIdTokenAndClientId());
  }, [dispatch]);


  if (sandbox && sandbox.status && sandbox.status !== 'deployed') return null;

  if (!projects) return <Loader fullPage />;

  return (
    <React.Fragment>
      <SEO title="Projects" />

      {createFormVisible ? '' : (<Toolbar
        disableGutters
        className={classes.toolbar}
      >
        <Typography variant="h1" className={classes.fontBold}>Your Project</Typography>
      </Toolbar>)}

      {(!projects || projects.length === 0) && !createFormVisible
        ? (<Box>
            <Typography variant="h4" style={{marginBottom: 15}}>
              You don&apos;t have any active projects. Create a new project to continue.
            </Typography>
          </Box>)
        : ""
      }

      {/* <Collapse in={!createFormVisible}>
        <Toolbar
          disableGutters
          className={classes.toolbar}
        >
          {projects && projects.length == 0 && (<Button
            onClick={showForm}
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
          >
            <Add className={classes.buttonIcon} />
            <span>Create New Project</span>
          </Button>)}
        </Toolbar>
      </Collapse> */}

      <Collapse in={createFormVisible}>
        <CreateProjectForm
          dispatch={dispatch}
          onCancel={hideForm}
          onSubmit={hideForm}
          projects={projects}
          loading={loading.includes('createProject') || loading.includes('fetchProjectById')}
          sandbox={sandbox}
          createFormVisible={createFormVisible}
        />
      </Collapse>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifySelf="center"
        maxWidth={1250}
        className={classes.projectGridContainer}
      >
          
        {projects  && (                    
          <ProjectGrid sandbox={sandbox} data={projects} showForm={showForm} createFormVisible={createFormVisible}  />                
        )}
      </Box>

    </React.Fragment>
  );
};

export default connect(state => ({
  loading: state.loading,
  projects: state.sandboxProjects,
  oauthDeeplink: state.oauthDeeplink,
}))(withStyles(styles)(SandboxProjects));
