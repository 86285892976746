import React from "react";
import { SvgIcon } from "@material-ui/core";

function Support(props) {
  return (
    <SvgIcon 
    {...props}
    width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.05 12.34C17.18 14.32 15.5 15.86 13.44 16.56" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M3.20996 8.55994C3.53996 4.56994 6.87996 1.43994 10.95 1.43994C15.02 1.43994 18.38 4.58994 18.69 8.58994" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M7.1 9.19996C6.8 9.19996 6.58 8.93996 6.62 8.63996C6.89 6.48996 8.73 4.82996 10.96 4.82996C13.19 4.82996 15.02 6.48996 15.3 8.63996C15.34 8.93996 15.11 9.19996 14.82 9.19996H7.12H7.1Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M17.98 20.3799H19.61C20.08 20.3799 20.45 20.0099 20.4 19.5699C19.96 15.1199 15.89 11.6399 10.95 11.6399C6.00999 11.6399 1.93999 15.1199 1.49999 19.5699C1.45999 19.9999 1.81999 20.3799 2.28999 20.3799H14.39" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M1.82001 9.85999C1.82001 9.17999 2.38001 8.62 3.06001 8.62C3.74001 8.62 4.30001 9.17999 4.30001 9.85999L4.27001 11.5C4.27001 11.99 3.87001 12.39 3.38001 12.39C3.08001 12.39 2.81001 12.24 2.65001 12.01C2.65001 12.01 1.83001 11.14 1.83001 9.85999H1.82001Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M20.07 9.85999C20.07 9.17999 19.51 8.62 18.83 8.62C18.15 8.62 17.59 9.17999 17.59 9.85999L17.62 11.5C17.62 11.99 18.02 12.39 18.51 12.39C18.81 12.39 19.08 12.24 19.24 12.01C19.24 12.01 20.06 11.14 20.06 9.85999H20.07Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M12.75 16.73C13.4348 16.73 13.99 16.1748 13.99 15.49C13.99 14.8052 13.4348 14.25 12.75 14.25C12.0652 14.25 11.51 14.8052 11.51 15.49C11.51 16.1748 12.0652 16.73 12.75 16.73Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
</SvgIcon>

  );
}

export default Support;
