import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function Bundle(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 17.94 17.84"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>
          {
            ".xd{fill:#90ced4;}.xe,.xf{fill:#59bac1;}.xe,.xg{stroke-width:.3px;}.xe,.xg,.xh{stroke:#010101;stroke-linecap:round;stroke-linejoin:round;}.xg{fill:#0aa8af;}.xh{fill:#0e9ba2;stroke-width:.3px;}.xi{fill:#f2f2f2;}"
          }
        </style>
      </defs>
      <g id="x" />
      <g id="y">
        <g id="z">
          <g>
            <path
              className="xe"
              d="M.15,4.53L8.97,.15l8.82,4.38v.58l-8.82,4.38L.15,5.12v-.59Z"
            />
            <path
              className="xg"
              d="M.15,4.53l8.82,4.38,8.82-4.38V13.31l-8.85,4.38L.15,13.31V4.53Z"
            />
            <path
              className="xh"
              d="M8.97,17.68V8.91L.21,4.56h-.06V13.31l8.79,4.38h.03Z"
            />
            <path
              className="xd"
              d="M4.02,2.85L12.4,7.02l1.7-.84L5.69,2.01l-1.68,.84Z"
            />
            <path
              className="xf"
              d="M12.58,7.36l1.79-.88v2.76l-.48-.08-.51,.62-.8-.06v-2.36Z"
            />
            <path
              className="xi"
              d="M7.15,12.41l-2.98-1.49c-.17-.08-.3,.01-.3,.22v2c0,.21,.14,.44,.3,.52l2.99,1.49c.17,.08,.3-.01,.3-.22v-2.01c-.01-.21-.15-.43-.32-.51h0Zm-.68,1.64l-1.9-.95c-.08-.04-.15-.17-.15-.28s.07-.17,.15-.12l1.9,.95c.08,.04,.15,.17,.15,.28s-.07,.17-.15,.12Zm.28-.69l-2.18-1.1c-.08-.04-.15-.17-.15-.28s.07-.17,.15-.12l2.18,1.1c.08,.04,.15,.17,.15,.28,0,.12-.07,.17-.15,.12Z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Bundle;