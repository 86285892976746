import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function PencilSquare(props) {
  return (
    <SvgIcon
      {...props}
      x="0px"
      y="0px"
      width="17px"
      height="17px"
      viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4706 2.06118C16.5698 2.16076 16.6256 2.29563 16.6256 2.43624C16.6256 2.57685 16.5698 2.71172 16.4706 2.8113L15.3624 3.92055L13.2374 1.79555L14.3456 0.686305C14.4452 0.586711 14.5803 0.530762 14.7212 0.530762C14.862 0.530762 14.9971 0.586711 15.0968 0.686305L16.4706 2.06012V2.06118ZM14.6112 4.67068L12.4862 2.54568L5.24739 9.78555C5.18891 9.84402 5.14489 9.91533 5.11882 9.9938L4.26351 12.5587C4.248 12.6054 4.2458 12.6556 4.25715 12.7035C4.2685 12.7515 4.29296 12.7953 4.3278 12.8301C4.36264 12.865 4.40647 12.8894 4.45441 12.9008C4.50235 12.9121 4.5525 12.9099 4.59926 12.8944L7.16414 12.0391C7.24251 12.0134 7.31381 11.9697 7.37239 11.9116L14.6112 4.67174V4.67068Z" fill="#2faab1" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.0625 14.3438C1.0625 14.7664 1.23041 15.1718 1.5293 15.4707C1.82818 15.7696 2.23356 15.9375 2.65625 15.9375H14.3438C14.7664 15.9375 15.1718 15.7696 15.4707 15.4707C15.7696 15.1718 15.9375 14.7664 15.9375 14.3438V7.96875C15.9375 7.82785 15.8815 7.69273 15.7819 7.5931C15.6823 7.49347 15.5471 7.4375 15.4062 7.4375C15.2654 7.4375 15.1302 7.49347 15.0306 7.5931C14.931 7.69273 14.875 7.82785 14.875 7.96875V14.3438C14.875 14.4846 14.819 14.6198 14.7194 14.7194C14.6198 14.819 14.4846 14.875 14.3438 14.875H2.65625C2.51535 14.875 2.38023 14.819 2.2806 14.7194C2.18097 14.6198 2.125 14.4846 2.125 14.3438V2.65625C2.125 2.51535 2.18097 2.38023 2.2806 2.2806C2.38023 2.18097 2.51535 2.125 2.65625 2.125H9.5625C9.7034 2.125 9.83852 2.06903 9.93815 1.9694C10.0378 1.86977 10.0938 1.73465 10.0938 1.59375C10.0938 1.45285 10.0378 1.31773 9.93815 1.2181C9.83852 1.11847 9.7034 1.0625 9.5625 1.0625H2.65625C2.23356 1.0625 1.82818 1.23041 1.5293 1.5293C1.23041 1.82818 1.0625 2.23356 1.0625 2.65625V14.3438Z" fill="#2faab1" />
    </SvgIcon>
  );
}

export default PencilSquare;