import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import logiIcon from '../../../assets/images/logo_2.png';
import { baseUrl } from '../../../configs/config';

const styles = () => ({
  card: {
    width: '23.5%',  
    margin: '1em 0',
    transition: 'width 400ms',
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.12)',
    borderRadius:10,
    '@media (max-width: 1245px)': {
      width: '32%',
    },
    '@media (max-width: 1010px)': {
      width: '32%',
    },
    '@media (max-width: 610px)': {
      width: '49%',
    },
  },
  media: {
    height: 150,
    margin: '1em',
    marginBottom: 0,
    backgroundSize: 'contain',
    '@media (max-width: 610px)': {
      margin: 0,
    },
  },
});

const ProjectTile = ({ classes, item, history, data }) => {
  const imageUrl = (url) => {
    return url.split('/').reverse() && url.split('/').reverse().length > 1 && url.split('/').reverse()[1] === 'mimik-devportal-assets.s3-us-west-2.amazonaws.com'? baseUrl + logiIcon : url
  }

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={() => history.push({
        pathname : `/project/dashboard/${item.client_id}`,
        state: {
          projects: data
        }
      })}>
        { !item.grant_types.includes('mimik:developer_id_token') &&
          <div className="ribbon ribbon-top-left">
            <span>
              deprecated
          </span>
          </div>
        }
        <CardMedia
          className={classes.media}
          image={imageUrl(item.logo_uri)}
          title={item.client_name}
          classes={{ media: 'fadeIn' }}
        /> 
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {item.client_name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
};

export default withRouter(withStyles(styles)(ProjectTile));
