import axios from 'axios';
import jwt from 'jsonwebtoken';
import queryString from 'query-string';
import {
  v4 as uuidv4
} from 'uuid';
import {
  fetchUser
} from './user';
import {
  lmsLogin
} from './lms';
import {
  isLatestLicenseAccepted
} from './developerLicense';
// import { push } from 'react-router-redux';


import {
  ssoMidUrl,
  ssoScope,
  devportalMidUrl,
  ssoClientId,
  devportalScope,
  developerIdTokenExpireTime,
  developerSharedIdTokenExpireTime,
  enableLms,
  devConsoleClientId,
} from '../configs/config';

export function startLoading(value) {
  return {
    type: 'START_LOADING',
    value
  };
}

export function stopLoading(value) {
  return {
    type: 'STOP_LOADING',
    value
  };
}

export function setError(description, error) {
  return (dispatch) => {
    if (error && error.status === 403) {
      window.open(error.data.redirect || `/logout`, '_self');
    } else {
      dispatch({
        type: 'SET_ERROR',
        description,
        error
      });
    }
  };
}

export function resetError() {
  return {
    type: 'RESET_ERROR'
  };
}

export function setOauthDeeplink(value) {
  return {
    type: 'SET_OAUTH_DEEPLINK',
    value
  }
}

export function setDeveloperIdToken(value) {
  return {
    type: 'SET_DEVELOPER_ID_TOKEN',
    value
  }
}

export function setDeveloperSharedIdToken(value) {
  return {
    type: 'SET_DEVELOPER_SHARED_ID_TOKEN',
    value
  }
}

export function setClientId(value) {
  return {
    type: 'SET_CLIENT_ID',
    value
  }
}

export function resetDeveloperIdToken() {
  return {
    type: 'RESET_DEVELOPER_ID_TOKEN'
  }
}

export function resetDeveloperSharedIdToken() {
  return {
    type: 'RESET_DEVELOPER_SHARED_ID_TOKEN'
  }
}

export function resetClientId() {
  return {
    type: 'RESET_CLIENT_ID'
  }
}

export function resetExpirySession() {
  return {
    type: 'RESET_SESSION_EXPIRY'
  }
}

export function resetSandboxes() {
  return {
    type: 'RESET_SANDBOXES'
  }
}

export function resetProjects() {
  return {
    type: 'RESET_PROJECTS'
  }
}

export function setSuccessMessage(data) {
  return { type: 'SET_SUCCESS_MESSAGE', data};
}

export function resetSuccessMessage() {
  return {type: 'RESET_SUCCESS_MESSAGE'};
}

export function logoutReset() {
  return (dispatch) => {
    dispatch(resetClientId());
    dispatch(resetExpirySession());
    dispatch(resetSandboxes());
    dispatch(resetProjects());
    dispatch(resetDeveloperIdToken());
    dispatch(resetDeveloperSharedIdToken());
    sessionStorage.removeItem('session_id');
  };
}

export function setSessionExpiry() {
  return (dispatch) => {
    try {
      const token = sessionStorage.getItem('devportal_access_token');
      const sessionData = jwt.decode(token);
      if (sessionData) {
        const {
          exp
        } = sessionData;
        dispatch({
          type: 'SET_SESSION_EXPIRY',
          value: exp * 1000
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export function getSessionId() {
  let session = sessionStorage.getItem('session_id');
  if (session) {
    return session;
  } else {
    session = uuidv4();
    sessionStorage.setItem('session_id', session);
    return session;
  }
}

function postDevConsoleIdTokenSignIn(idToken) {
  const idTokenFormData = {
    grant_type: 'id_token_signin',
    client_id: devConsoleClientId.trim(),
    scope: devportalScope,
    id_token: idToken
  };
  return axios.post(`${devportalMidUrl}/token`, queryString.stringify(idTokenFormData), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function getAccessToken(data) {
  return (dispatch) => {
    dispatch(startLoading('getAccessToken'));
    // dispatch(resetError());
    const {
      email,
      password
    } = data;
    const formDataIdTokenSignIn = {
      grant_type: 'mimik:email_password',
      email,
      password,
      client_id: ssoClientId.trim(),
      scope: ssoScope,
      action: 'login',
      web_flow: true
    };

    return axios.post(`${ssoMidUrl}/token`, queryString.stringify(formDataIdTokenSignIn), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((ssoRes) => {
        postDevConsoleIdTokenSignIn(ssoRes.data.id_token)
          .then((devportalRes) => {
            sessionStorage.setItem('devportal_access_token', devportalRes.data.access_token);
            sessionStorage.setItem('devportal_refresh_token', devportalRes.data.refresh_token);
            dispatch(isLatestLicenseAccepted());
            dispatch(fetchUser());
            dispatch(setSessionExpiry());
            if (enableLms) {
              dispatch(lmsLogin(data));
            }
            dispatch(stopLoading('getAccessToken'));
          });
      })
      .catch((devportalErr) => {
        dispatch(stopLoading('getAccessToken'));
        dispatch(setError('You have entered an invalid username or password', devportalErr.response))
      });
  };
}

export function createAccountStep1(fields) {
  return (dispatch) => {
    dispatch(startLoading('createAccountStep1'));
    dispatch(resetError());
    const {
      newEmail
    } = fields;
    const formDataIdTokenSignIn = {
      grant_type: 'mimik:email_password',
      email: newEmail,
      client_id: ssoClientId.trim(),
      action: 'register',
      web_flow: true
    };
    console.log(`${ssoMidUrl}/token`);
    console.log(formDataIdTokenSignIn);

    return axios.post(`${ssoMidUrl}/token`, queryString.stringify(formDataIdTokenSignIn), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(() => {
      dispatch(stopLoading('createAccountStep1'));
    }).catch((devportalErr) => {
      if (devportalErr.response.status === 401) {
        dispatch({
          type: 'SET_MFA_TOKEN',
          value: devportalErr.response.data
        });
        dispatch(stopLoading('createAccountStep1'));
      } else {
        dispatch(stopLoading('createAccountStep1'));
        dispatch(setError('Unable to create account, please check provided email id.', devportalErr.response));
      }
    });
  };
}

export function createAccountStep2(fields, mfa, history, setIsValidCode) {
  return (dispatch) => {
    dispatch(startLoading('createAccountStep2'));
    dispatch(resetError());
    // dispatch({
    //   type: 'RESET_MFA_TOKEN'
    // });
    const {
      newPassword,
      code: bindingCode
    } = fields;
    const {
      mfa_token: mfaToken,
      oob_code: oobCode
    } = mfa;
    const formDataIdTokenSignIn = {
      grant_type: 'mimik:email_password',
      password: newPassword,
      client_id: ssoClientId.trim(),
      mfa_token: mfaToken,
      oob_code: oobCode,
      binding_code: bindingCode,
      scope: ssoScope,
      web_flow: true
    };

    return axios.post(`${ssoMidUrl}/token`, queryString.stringify(formDataIdTokenSignIn), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((ssoRes) => {
      postDevConsoleIdTokenSignIn(ssoRes.data.id_token)
        .then((devportalRes) => {
          sessionStorage.setItem('devportal_access_token', devportalRes.data.access_token);
          sessionStorage.setItem('devportal_refresh_token', devportalRes.data.refresh_token);
          dispatch(isLatestLicenseAccepted());
          dispatch(fetchUser());
          dispatch(setSessionExpiry());
          dispatch(stopLoading('createAccountStep2'));
          history.push("/home")
        });
    }).catch((devportalErr) => {
      if (devportalErr.response.status === 400) {
        setIsValidCode(true);
      }       
    });
  };
}

export function resetPasswordStep1(fields) {
  return (dispatch) => {
    dispatch(startLoading('resetPasswordStep1'));
    dispatch(resetError());
    const {
      email
    } = fields;
    const formDataIdTokenSignIn = {
      grant_type: 'mimik:email_password',
      email,
      client_id: ssoClientId.trim(),
      action: 'reset_password',
      web_flow: true
    };

    return axios.post(`${ssoMidUrl}/token`, queryString.stringify(formDataIdTokenSignIn), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(() => {
      dispatch(stopLoading('resetPasswordStep1'));
    }).catch((devportalErr) => {
      if (devportalErr.response.status === 401) {
        dispatch({
          type: 'SET_MFA_TOKEN',
          value: devportalErr.response.data
        });
        dispatch(stopLoading('resetPasswordStep1'));
      } else {
        dispatch(setError('Unable to send Verification Code, please check provided email.', devportalErr.response));
      }
    });
  };
}

export function resetPasswordStep2(fields, mfa, history, setIsValidCode) {
  return (dispatch) => {
    dispatch(startLoading('resetPasswordStep2'));
    dispatch(resetError());
    // dispatch({
    //   type: 'RESET_MFA_TOKEN
    // });
    const {
      newPassword,
      code: bindingCode
    } = fields;
    const {
      mfa_token: mfaToken,
      oob_code: oobCode
    } = mfa;
    const formDataIdTokenSignIn = {
      grant_type: 'mimik:email_password',
      new_password: newPassword,
      client_id: ssoClientId.trim(),
      mfa_token: mfaToken,
      oob_code: oobCode,
      binding_code: bindingCode,
      scope: ssoScope,
      action: 'reset_password_apply',
      web_flow: true
    };

    return axios.post(`${ssoMidUrl}/token`, queryString.stringify(formDataIdTokenSignIn), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((ssoRes) => {
      postDevConsoleIdTokenSignIn(ssoRes.data.id_token)
        .then((devportalRes) => {
          sessionStorage.setItem('devportal_access_token', devportalRes.data.access_token);
          sessionStorage.setItem('devportal_refresh_token', devportalRes.data.refresh_token);
          dispatch(fetchUser());
          dispatch(setSessionExpiry());
          dispatch(stopLoading('resetPasswordStep2'));
          history.push("/console/home");
        });
    }).catch((devportalErr) => {
      if (devportalErr.response.status === 400) {
        setIsValidCode(true);
      }
    
      // dispatch(setError('Unable to change your account password, please try again.', devportalErr.response));
      // dispatch(showSuccessToast('Unable to change your account password, please try again.', devportalErr.response));
    });
  };
}

export function changePassword(fields) {
  return (dispatch) => {
    dispatch(startLoading('changePassword'));
    dispatch(resetError());
    const {
      email,
      password,
      newPassword
    } = fields;
    const formDataIdTokenSignIn = {
      grant_type: 'mimik:email_password',
      client_id: ssoClientId.trim(),
      email,
      password,
      new_password: newPassword,
      scope: ssoScope,
      action: 'change_password',
      web_flow: true
    };

    return axios.post(`${ssoMidUrl}/token`, queryString.stringify(formDataIdTokenSignIn), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((ssoRes) => {
      postDevConsoleIdTokenSignIn(ssoRes.data.id_token)
          .then((devportalRes) => {
            sessionStorage.setItem('devportal_access_token', devportalRes.data.access_token);
            sessionStorage.setItem('devportal_refresh_token', devportalRes.data.refresh_token);
            dispatch(fetchUser());
            dispatch(setSessionExpiry());
            dispatch(stopLoading('changePassword'));

            dispatch(setSuccessMessage({
              message: 'Your password has been reset successfully.'
            }));

           

            setTimeout(() => {       
              dispatch(resetSuccessMessage());
              dispatch({
                type: 'SET_PROFILE_CURRENT_TAB_OPEN',
                data: 'panel2'
              })
              dispatch({
                type: 'RESET_CHANGE_PASSWORD_FEILDS'
              })
            }, 2000);
          });
    }).catch((devportalErr) => {
      dispatch(setError('Current password is incorrect, please try again.', devportalErr.response));
    });
  };
}

export function refreshDevportalToken() {
  return (dispatch) => {
    dispatch(startLoading('refreshDevportalToken'));
    dispatch(resetError());

    const refreshTokenFormData = {
      grant_type: 'refresh_token',
      client_id: devConsoleClientId.trim(),
      refresh_token: sessionStorage.getItem('devportal_refresh_token')
    };

    return axios.post(`${devportalMidUrl}/token`, queryString.stringify(refreshTokenFormData), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((devportalRes) => {
        sessionStorage.setItem('devportal_access_token', devportalRes.data.access_token);
        sessionStorage.setItem('devportal_refresh_token', devportalRes.data.refresh_token);
        dispatch(setSessionExpiry());
        dispatch(stopLoading('refreshDevportalToken'));
      })
      .catch((devportalRes) => dispatch(setError('We were unable to renew your token.', devportalRes.response)));
  };
}

export function getOauthDeeplink() {
  return (dispatch) => {
    dispatch(startLoading('getOauthDeeplink'));
    dispatch(resetError());
    dispatch(setOauthDeeplink(`edgesdk-oauth-tool://${sessionStorage.getItem('devportal_access_token')}`));
    dispatch(stopLoading('getOauthDeeplink'));
  };
}

export function getDeveloperIdToken(clientId) {
  return (dispatch) => {

    if (!clientId) {
      dispatch(setDeveloperIdToken(null));
      return null;
    }

    dispatch(startLoading('getDeveloperIdToken'));
    dispatch(resetError());
    const formData = {
      client_id: clientId,
      expires_in: developerIdTokenExpireTime,
      access_token: sessionStorage.getItem('devportal_access_token'),
      grant_type: 'mimik:developer_id_token'

    }
    return axios.post(`${devportalMidUrl}/token`, queryString.stringify(formData))
      .then((res) => {
        dispatch(stopLoading('getDeveloperIdToken'));
        dispatch(setDeveloperIdToken(res.data.id_token));
      }).catch((err) => dispatch(setError('We were unable to retrieve the token.', err.response)));
  }
}

export function getDeveloperSharedIdToken(clientId) {
  return (dispatch) => {

    if (!clientId) {
      dispatch(setDeveloperSharedIdToken(null));
      return null;
    }

    dispatch(startLoading('getDeveloperSharedIdToken'));
    dispatch(resetError());
    const formData = {
      client_id: clientId,
      expires_in: developerSharedIdTokenExpireTime,
      access_token: sessionStorage.getItem('devportal_access_token'),
      grant_type: 'mimik:developer_id_token',
      shared_aud: true
    }
    return axios.post(`${devportalMidUrl}/token`, queryString.stringify(formData))
      .then((res) => {
        dispatch(stopLoading('getDeveloperSharedIdToken'));
        dispatch(setDeveloperSharedIdToken(res.data.id_token));
      }).catch((err) => dispatch(setError('We were unable to retrieve the token.', err.response)));
  }
}

const getMidTokenUrl = (sandbox) => {
  const Urls = sandbox.services.filter((service) => service.name === 'mid').map((service) => service.url.replace('/mID/v1', ''));
  return `${Urls[0]}/token`;
}

export function getSandboxDeveloperIdToken(sandbox, project) {
  return (dispatch) => {
    if (!sandbox || !sandbox.id) {
      dispatch(setDeveloperIdToken(null));
      return null;
    }
    const {
      client_id
    } = project;

    dispatch(startLoading('getDeveloperIdToken'));
    dispatch(resetError());
    const formData = {
      client_id,
      expires_in: developerIdTokenExpireTime,
      access_token: sessionStorage.getItem('federated_access_token'),
      grant_type: 'mimik:developer_id_token'

    }
    return axios.post(getMidTokenUrl(sandbox), queryString.stringify(formData))
      .then((res) => {
        dispatch(stopLoading('getDeveloperIdToken'));
        dispatch(setDeveloperIdToken(res.data.id_token));
      }).catch((err) => dispatch(setError('We were unable to retrieve the token.', err.response)));
  }
}

export function getSandboxDeveloperSharedIdToken(sandbox, project) {
  return (dispatch) => {
    if (!sandbox || !sandbox.id) {
      dispatch(setDeveloperSharedIdToken(null));
      return null;
    }
    const {
      client_id
    } = project;

    dispatch(startLoading('getDeveloperSharedIdToken'));
    dispatch(resetError());
    const formData = {
      client_id,
      expires_in: developerSharedIdTokenExpireTime,
      access_token: sessionStorage.getItem('federated_access_token'),
      grant_type: 'mimik:developer_id_token',
      shared_aud: true
    }
    return axios.post(getMidTokenUrl(sandbox), queryString.stringify(formData))
      .then((res) => {
        dispatch(stopLoading('getDeveloperSharedIdToken'));
        dispatch(setDeveloperSharedIdToken(res.data.id_token));
      }).catch((err) => dispatch(setError('We were unable to retrieve the token.', err.response)));
  }
}

export function getDeveloperIdTokenWithSharedIdToken(sharedIdToken) {
  return (dispatch) => {

    if (!sharedIdToken || sharedIdToken === '') {
      dispatch(resetDeveloperIdToken());
      dispatch(resetClientId());
      return null;
    }

    dispatch(startLoading('getDeveloperIdToken'));
    dispatch(resetError());
    const decodedToken = jwt.decode(sharedIdToken);
    if(!decodedToken) {
      dispatch(setError('We were unable to retrieve this token.'))
      return
    }
    const {
      aud: clientId
    } = decodedToken;
    dispatch(setClientId(clientId || null));
    const formData = {
      client_id: clientId,
      expires_in: developerIdTokenExpireTime,
      access_token: sessionStorage.getItem('devportal_access_token'),
      shared_id_token: sharedIdToken,
      grant_type: 'mimik:developer_id_token'

    }
    return axios.post(`${devportalMidUrl}/token`, queryString.stringify(formData))
      .then((res) => {
        dispatch(stopLoading('getDeveloperIdToken'));
        dispatch(setDeveloperIdToken(res.data.id_token));
      })
      .catch((err) => dispatch(setError('We were unable to retrieve the token.', err.response)));
  }
}

export function clearDeveloperIdTokenAndClientId() {
  return (dispatch) => {
    dispatch(resetDeveloperIdToken());
    dispatch(resetDeveloperSharedIdToken());
    dispatch(resetClientId());
  }
}

export function logoutUser() {
  return (dispatch) => {
    sessionStorage.clear();
    dispatch(logoutReset());
    sessionStorage.clear();
    window.open(`/logout`, '_self');
  }
}