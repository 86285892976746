import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  Box,
  Button,
  CardActions,
  Dialog,
  IconButton,
  Popover,
  TextField
} from '@material-ui/core';
import { Close, Delete, MoreVert } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

import {
  deleteSandbox,
  setSandboxDeleteStatus,
  fetchSandboxById,
  fetchAllSandboxes
} from '../../../actions/sandboxes';
// import Loader from '../../Loader';

const styles = (theme) => ({
  card: {
    minHeight: 300,
    width: 'calc(25% - 2em)',
    minWidth: 560,
    margin: '1em',
    transition: 'width 400ms',
    position: 'relative',

    

    '@media (max-width: 1366px)': {
      maxWidth: '100%',
      width: '320px !important',
      minWidth: 'auto',
      minHeight: '240px',
    },

    '@media (max-width: 1245px)': {
      width: 'calc(33% - 2em)',
    },
    '@media (max-width: 1010px)': {
      width: 'calc(50% - 2em)',
    },
    '@media (max-width: 610px)': {
      width: 'calc(100% - 2em)',
    },
    '@media (max-width: 580px)': {
      maxWidth: '100%',
      width: '100% !important',
      minWidth: 'auto',
      height: '187px',
      minHeight: 'auto',
    },
    background: 'linear-gradient(129deg, rgba(62,168,178,1) 7%, rgba(182,237,242,1) 44%, rgba(62,168,178,1) 97%)',

    borderRadius: 10,
    filter: 'drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.12))',
    cursor: 'pointer',
  },
  inactiveCard: {
    background: 'linear-gradient(129deg, rgba(62,168,178,1) 7%, rgba(182,237,242,1) 44%, rgba(62,168,178,1) 97%)',
    cursor: 'unset',
  },
  media: {
    margin: 0,
    height: 218,
    backgroundSize: 'contain',
  },
  label: {
    color: theme.palette.secondary.contrastText,
    fontSize: 32,
    fontWeight: 500,
    marginBottom: '1em',

    '& span' : {
      color: 'red',
      fontSize: 14
    },

    '@media (max-width: 1366px)': {
      fontSize: 20,
      marginBottom: 0,
    },

    '@media (max-width: 580px)': {
      fontSize: 'inherit',
    },
  },
  label2: {
    position: 'absolute',
    top: 35,
    right: 10,
    color: theme.palette.secondary.contrastText,
    fontSize: 12,
    padding: '12px 24px',
  },
  tileActionArea: {
    height: '100%',
    alignItems: 'flex-end',
  },
  btnMoreVert: {
    position: 'absolute',
    top: '0.5em',
    right: '0.5em',

    '&:focus': {
      outline: 'none',
    },
  },
  popoverContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
 

    '& .MuiButton-root:hover': {
      color: theme.palette.primary.main,
    },
  },
  // popoverText: {
  //   padding: 12,
  // },
  semiTransparentIcon: {
    color: '#C4C4C4',
  },

  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.75em 2.75em',
    '@media (max-width: 550px)': {
      flexDirection: 'column',
    },
    '@media (max-width: 750px)': {
      padding: '1em',
    },
  },
  deleteButton: {
    width: '8em',
    background: red[700],
    color: '#fff',

    borderRadius: 30,
    '&:hover': {
      background: red[800],
    },
    '@media (max-width: 550px)': {
      alignSelf: 'flex-end',
      marginTop: '1em',
    },
  },
  dialogButton: {
    margin: '0 .25em',
  },
  dialogPaper: {
    position: 'relative',
    minWidth: 500,
    padding: '2em',

    '@media (max-width: 550px)': {
      minWidth: 350,
    },
  },
  dialogHeader: {
    fontSize: '1.3em',
    fontWeight: 400,
    marginBottom: '.5em',
  },
  dialogTextField: {
    margin: '1em 0',
  },
  dialogClose: {
    position: 'absolute',
    top: '0.1em',
    right: '0.1em',
  },
  strong: {
    fontWeight: 900
  },
  expired_card: {
    background: 'linear-gradient(106.67deg, #F1F1F1 -18.76%, #EFEFEF 52.81%, #B3E1E6 89.16%)',
    border: '2px solid #3EA8B2',
    cursor: 'default'
  },
  expiry_text: {
    position: 'absolute',
    left:0,
    top:0,
    border: '2px solid #EA4537',
    borderRadius: 8,
    padding: 12,
    margin:30,
    marginRight: 100,
    fontFamily: 'Nunito',
    fontSize: 14,
    '& strong':{
      fontWeight: 900,
      fontSize: 16,
      display: 'block'
    },
    '@media (max-width:767px)' : {
      padding: 10,
      marginRight: 50
    }
  },
  button_popup: {
    '& .MuiPaper-root': {
      borderRadius: 10,
      border: '#ffffff 2px solid',
      minWidth: 125,
      '&:hover button': {
        fontWeight: 700,
        background: 'transparent',
        color: '#2faab1'
      }
    }
  }
});

const isDeployed = (item) => {
  return item && item.status && item.status === 'deployed';
}

const isDeployedOrFailed = (item) => {
  return item && item.status && ['deployed', 'failed'].includes(item.status);
}

const SandboxTile = props => {
  const {
    classes,
    dispatch,
    item,
    history,
    sandboxDeleteStatus,
    currentSandbox
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(true);
  const [dialogTextValue, setDialogTextValue] = useState('');

  useEffect(() => {
    dispatch(fetchSandboxById(item.id));
  }, [dispatch, item.id]);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const handleClick = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleClickDelete = () => {
    openDialog();
    handleClose();
  }

  const getProjectExpiry = () => {
   return currentSandbox?new Date(currentSandbox.expiresAt) > new Date():true
  }

  const handleDelete = () => {
    dispatch(setSandboxDeleteStatus());
    history.push(`/hybrid_projects`);
    dispatch(deleteSandbox(item.id));
    dispatch(fetchAllSandboxes());
    closeDialog();
    setIsDeleteVisible(false);
  };

  const handleClickTile = () => {
    if (isDeleteVisible && getProjectExpiry()) {
      if (isDeployedOrFailed(item)) history.push(`/hybrid_project/dashboard/${item.id}`)
    }
    return;
  };

  const onInactiveCard = () => {
    return !isDeployedOrFailed(item) || sandboxDeleteStatus;
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Card className={clsx(classes.card, onInactiveCard() && classes.inactiveCard, !getProjectExpiry() && classes.expired_card)}>
      <CardActions
        onClick={() => handleClickTile()}
        className={classes.tileActionArea}
      >
        <CardContent>
        
          <Typography className={classes.label} gutterBottom variant="h5" component="h2">
            {item.clientName} 
          </Typography>

          {!getProjectExpiry()?<Typography className={classes.expiry_text}><strong>Your Hybrid Project has expired.</strong>Please delete the expired project before creating a new project.</Typography>:null}

          {
            !isDeleteVisible && sandboxDeleteStatus
              ? <Typography className={classes.label2} gutterBottom variant="h5" component="h3">deleting</Typography>
              : !isDeployed(item)
                ? <Typography className={classes.label2} gutterBottom variant="h5" component="h3">{item.status}</Typography>
                : ""
          }          
        </CardContent>
      </CardActions>

      {isDeleteVisible && (item.status !== 'deploying' && item.status !== 'deleting') && (
      <IconButton
        aria-label="settings"
        onClick={handleClick}
        className={classes.btnMoreVert}
      >
        <MoreVert />
      </IconButton>
      )}

      <Popover
        id={id}
        className={classes.button_popup}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Box className={classes.popoverContent}>
          <Button onClick={handleClickDelete} >
            Delete
          </Button>
        </Box>
      </Popover>

      <Dialog
        open={dialogOpen}
        classes={{ paper: classes.dialogPaper }}
        onClose={closeDialog}
      >
        <IconButton
          className={classes.dialogClose}
          onClick={closeDialog}
        >
          <Close />
        </IconButton>
        <Typography className={classes.dialogHeader}>Confirm Deletion</Typography>
        <Typography>
          Enter the name <span className={classes.strong}>{item.clientName}</span> below to confirm deletion.
        </Typography>
        <TextField
          className={classes.dialogTextField}
          variant="outlined"
          value={dialogTextValue}
          onChange={e => setDialogTextValue(e.target.value)}
        />
        <Box display="flex" justifyContent="flex-end">
          <Button
            className={classes.dialogButton}
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={clsx(classes.dialogButton, classes.deleteButton)}
            disabled={dialogTextValue !== item.clientName}
            onClick={handleDelete}
          >
            <Delete className={classes.deleteButtonIcon} />Confirm
          </Button>
        </Box>
      </Dialog>
    </Card>
  )
};

export default withRouter(connect(state => ({
  currentSandbox: state.currentSandbox,
  sandboxDeleteStatus: state.sandboxDeleteStatus
}))(withStyles(styles)(SandboxTile)));