import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Delete from '@material-ui/icons/Delete';
import Close from '@material-ui/icons/Close';
import red from '@material-ui/core/colors/red';

import { deleteSandbox, setSandboxDeleteStatus } from '../../actions/sandboxes';

const styles = theme => ({
  root: {
    maxWidth: 1085,
    margin: '1em auto 1em',
    '@media (max-width: 750px)': {
      padding: 0,
    },
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // padding: '1.75em 2.75em',
    '@media (max-width: 550px)': {
      flexDirection: 'column',
    },
    '@media (max-width: 750px)': {
      padding: '1em',
    },
  },
  sectionHeader: {
    fontWeight: 400,
    fontSize: '1.1em',
  },
  deleteButton: {
    width: '8em',
    background: red[700],
    color: '#fff',
    borderRadius: 30,

    '&.MuiButton-contained.Mui-disabled': {
      color: '#FFFFFF',
      backgroundColor: red[200],
    },

    '&:hover': {
      background: red[800],
    },
    '@media (max-width: 550px)': {
      alignSelf: 'flex-end',
      marginTop: '1em',
    },
  },
  deleteButtonIcon: {
    marginRight: '.25em',
  },
  dialogButton: {
    margin: '0 .25em',
    width: '40%',
  },
  dialogPaper: {
    position: 'relative',
    minWidth: 500,
    padding: '2em',
    borderRadius: 10,

    '@media (max-width: 767px)': {
      minWidth: '90%',
    },
  },
  dialogHeader: {
    fontSize: '1.3em',
    fontWeight: 600,
    marginBottom: '.5em',
  },
  dialogTextField: {
    margin: '1em 0',

    '& .MuiOutlinedInput-root': {
      borderRadius: 30,
    },

    '& .MuiInputBase-input': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  dialogClose: {
    position: 'absolute',
    top: '0.1em',
    right: '0.1em',
  },
  strong: {
    fontWeight: 900
  },
  fontBold: {
    fontWeight: 'bold',
  },
});

const DeleteSandboxForm = (props) => {
  const {
    classes, dispatch, sandbox, onSubmit,
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTextValue, setDialogTextValue] = useState('');
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const handleDelete = () => {
    if (typeof onSubmit === 'function') onSubmit();
    dispatch(setSandboxDeleteStatus())
    dispatch(deleteSandbox(sandbox.id));
  };

  useEffect(() => {
    setDialogTextValue('');
  }, [dialogOpen]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.paper}>
          <div>
            <Typography className={clsx(classes.sectionHeader, classes.fontBold)}>Delete this Hybrid Project</Typography>
            <Typography>
              Once you delete the Hybrid Project, there is no going back. Please be certain.
            </Typography>
          </div>
          <Button
            variant="contained"
            className={classes.deleteButton}
            onClick={openDialog}
            size="large"
          >
            <Delete className={classes.deleteButtonIcon} />Delete
          </Button>
        </div>
      </div>

      <Dialog
        open={dialogOpen}
        classes={{ paper: classes.dialogPaper }}
        onClose={closeDialog}
      >
        <IconButton
          className={classes.dialogClose}
          onClick={closeDialog}
        >
          <Close />
        </IconButton>
        <Typography className={classes.dialogHeader}>Confirm Deletion</Typography>
        <Typography>
          Enter the name <span className={classes.strong}>{sandbox.clientName}</span> below to confirm deletion.
        </Typography>
        <TextField
          className={classes.dialogTextField}
          variant="outlined"
          value={dialogTextValue}
          onChange={e => setDialogTextValue(e.target.value)}
        />
        <Box display="flex" justifyContent="space-between">
          <Button
            className={classes.dialogButton}
            onClick={closeDialog}
            size="large"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={clsx(classes.dialogButton, classes.deleteButton)}
            disabled={dialogTextValue !== sandbox.clientName}
            onClick={handleDelete}
            size="large"
          >
            <Delete className={classes.deleteButtonIcon} />Delete
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(DeleteSandboxForm);
