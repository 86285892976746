import React, { useState, useEffect } from 'react';
import Jimp from 'jimp';
import { withStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';

import InputLabel from './InputLabel';
import Loader from '../Loader';

import placeholder from '../../assets/images/placeholder.jpg';

const styles = () => ({
  root: {
    position: 'absolute',
    top: '1.75em',
    left: '2.75em',
    padding: '.25em',
    zIndex: 3,
    background: '#fff',
    borderRadius: 20,
    transition: 'all 400ms',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '10em',
    height: '10em',
    '@media (max-width: 750px)': {
      width: '6em',
      height: '6em',
    },
    borderRadius: 20,
    overFlow: 'none'
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
    borderRadius: 20,
  },
  imageLoader: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgba(255, 255, 255, .8)',
  },
  cancelButton: {
    borderRadius: 30,
  },
  editButton: {
    // display: 'none',
    position: 'absolute',
    right: '-1em',
    bottom: '-1em',
    background: '#fff',
    '&:hover': {
      background: '#efefef',
    },
  },
  saveButton: {
    borderRadius: 30,
    width: '6em',
    marginTop: '.5em',
    alignSelf: 'flex-end',
  },
  dialogPaper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 500,
    padding: '2em',
  },
  dialogClose: {
    position: 'absolute',
    top: '0.1em',
    right: '0.1em',
  },
});

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:after': {
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: 30,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 30,
      },
      '&:hover fieldset': {
      },
      '&.Mui-focused fieldset': {
      },
    },
  },
})(TextField);

const compressImage = (dataURI, square = 300) => {
  const [, base64] = dataURI.split(',');
  const buffer = Buffer.from(base64, 'base64');
  if (buffer.byteLength <= 75000) return Promise.resolve(base64);

  return Jimp.read(Buffer.from(base64, 'base64'))
    .then((img) => {
      const minSide = Math.min(img.bitmap.width, img.bitmap.height);
      const factor = square / minSide;
      return img
        .resize(
          minSide === img.bitmap.width ? square : factor * img.bitmap.width,
          minSide === img.bitmap.height ? square : factor * img.bitmap.height,
        )
        .crop(
          0,0,
          square, 
          square
          )
        .quality(75);
    })
    .then(img => img.getBase64Async(Jimp.AUTO))
    .then(data => compressImage(data))
    .catch(console.log);
};

const ImageInput = (props) => {
  const {
    classes, onUpload, imageToCrop:imageUrl , defaultURL, fileUpload, dialogOpen, handleClose
  } = props;
 
  const [previewImg, setPreviewImg] = useState(imageUrl || defaultURL);
  const [error, setError] = useState(false);
  const [processingImage, setProcessingImage] = useState(false);

  const closeDialog = () => handleClose(false);

  const updateImageURL = () => {
    if (typeof onUpload === 'function') onUpload(previewImg);
    closeDialog();
  };

  const updatePreviewURL = (event) => {
    const { files, value } = event.target;
    if (fileUpload) {
      if (files && files[0]) {
        setProcessingImage(true);
        const reader = new FileReader();
        reader.onload = e => compressImage(e.target.result)
          .then(setPreviewImg)
          .then(() => setError(false))
          .then(() => setProcessingImage(false));
        reader.readAsDataURL(files[0]);
      }
    } else {
      setPreviewImg(value);
      setError(!!value && !(/\.(gif|jpg|jpeg|svg|png)$/i).test(value));
    }
  };

  const openImageDialog = () => {
    document.getElementById('file-input').click();
  };

  useEffect(() => {
    setError(false);
    setPreviewImg(imageUrl);
  }, [dialogOpen, imageUrl]);

  return (
    <React.Fragment>
      <Dialog
        open={dialogOpen}
        classes={{ paper: classes.dialogPaper }}
        onClose={closeDialog}
      >
        <IconButton
          className={classes.dialogClose}
          onClick={closeDialog}
        >
          <Close />
        </IconButton>
        <InputLabel label="Preview" />
        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="10em"
          height="10em"
          border="1px solid #ccc"
          borderRadius={10}
          padding=".25em"
        >
          {processingImage && (
            <div className={classes.imageLoader}>
              <Loader variant="spinner" />
            </div>
          )}
          <img
            className={classes.image}
            alt="Upload Failed"
            onError={() => setError(true)}
            src={error
              ? placeholder
              : (fileUpload && previewImg
                ? `data:image/jpeg;base64,${previewImg}`
                : previewImg || defaultURL || placeholder
              )}
          />
        </Box>

        {fileUpload ? (
          <Box display="flex" flexDirection="column" alignItems="center" mt=".25em" width="100%">
            <input
              id="file-input"
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              onChange={updatePreviewURL}
              style={{ display: 'none' }}
            />
            <Button
              color="primary"
              className={classes.cancelButton}
              onClick={openImageDialog}
            >
              Select a photo from your computer
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.saveButton}
              onClick={updateImageURL}
              disabled={error}
            >
              Upload
            </Button>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" justifyContent="flex-start" mt="1em" width="100%">
            <InputLabel label="Logo URL" />
            <FormControl variant="outlined" fullWidth>
              <CustomTextField
                variant="outlined"
                defaultValue={previewImg}
                onChange={updatePreviewURL}
                inputProps={{ placeholder: 'Logo URL' }}
                margin="dense"
                error={error}
              />
              <FormHelperText>Supported formats: .png, .jpg, .gif</FormHelperText>
            </FormControl>

            <Button
              variant="outlined"
              color="primary"
              className={classes.saveButton}
              onClick={updateImageURL}
              disabled={error}
            >
              Upload
            </Button>
          </Box>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default withStyles(styles)(ImageInput);
