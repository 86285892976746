import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import {
  Check,
  InfoOutlined
} from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import ImageInput from '../FormFields/ImageInput';
import InputLabel from '../FormFields/InputLabel';

import placeholder from '../../assets/images/placeholder.jpg';
import industryOptions from '../../assets/data/industry-name.json';

import PhoneIcon from '../Icons/Phone'

import { updateUser } from '../../actions/user';
import { getAttribute, getNameParts } from '../../helpers/index';

const styles = theme => ({
  form: {
    margin: '.5em 0',
    transition: 'all 400ms',
    paddingTop: 30,
    '@media (max-width: 750px)': {
      paddingTop: 10,
    },

    '& .PhoneInput': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 30,
      backgroundColor: '#F2F2F2',
      paddingLeft: 15, 

      '&:hover': {
        border: 'rgb(62, 168, 178) 1px solid'
      },

      '&:focus-within': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },

    '& .PhoneInputInput': {
      border: 'none',
      height: '54px',
      padding: '18.5px 14px',
      backgroundColor: '#F2F2F2',
      outline: 'none',
      maxWidth: 204,
    },
  },
  profile_button : {
    '@media (max-width:767px)' : {
      flexDirection: 'column-reverse',
      margin: 0,
      '& button:first-child' : {
          border: '#3EA8B2 1px solid'
      }
    },
  },
  formControl: {
    margin: '.5em 0',
    width: '100%',
    '@media (max-width: 1150px)': {
      minWidth: 'calc(50% - 1em)',
    },
    '& .phone_icon': {
      position: 'absolute',
      top: '47px',
      left: '14px',
      'z-index': 10,
      '& svg' : {
        fill :'#43b3b7'
      }
    }
  },
  outlinedInput: {
    minWidth: 260,
    maxWidth: '100%',
    '@media (max-width: 1025px)': {
      minWidth: 'inherit',
    },
  },
  customSelect: {
    borderRadius: 30,

    '& > div': {
      background: 'rgba(0, 0, 0, 0.06)',
    },
    '&:hover fieldset': {
      border: 'rgb(62, 168, 178) 1px solid !important'
    },

    '& .MuiSelect-outlined.MuiSelect-outlined': {
      borderRadius: 30,
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1.2em',
      marginTop: '-3px',
    },
  },
  button: {
    marginLeft: '.5em',
    minWidth: '6em',
    borderRadius: 30,
    '@media(max-width:767px)' : {
      height: 50,
      marginLeft:0,
      width: '100%',
      marginTop: 10
    }
  },
  phoneInput: {
    '& .PhoneInputCountryIcon': {
      '& svg': {
        display: 'none',
      }
    },
    '& > div > div > div > div': {
      background: 'none',
      borderRadius: 0,
      boxShadow: 'none',
      position :'relative',
      'z-index': 20,
      '& img' : {
        borderTop: '#F2F2F2 5px solid',
        borderBottom: '#F2F2F2 5px solid',
        'box-sizing':'content-box',
        marginTop: '-5px'
      }
    },

    '& .MuiFormHelperText-root': {
      marginLeft: 10,
      marginRight: 0,
      fontStyle: 'italic',
      color: theme.palette.error.main,

      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: '1.2em',
        marginTop: '-3px',
      },
    },
  },
  invalidPhoneError: {
    border: `1px solid ${theme.palette.error.main} !important`,
  },
  imageInputWrapper: {
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',

    '& h3': {
      padding: '0 30px',
      '@media(max-width:767px)' : {
        padding: '0 10px',
        fontSize: 20
      }
    },
  },
});

const CustomTextField = withStyles({
  root: {
    '& input': {
      backgroundColor: '#F2F2F2',
    },
    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:after': {
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      borderRadius: 30,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 30,
      },
      '&:hover fieldset': {
        border: 'rgb(62, 168, 178) 1px solid'
      },
      '&.Mui-focused fieldset': {
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      marginRight: 0,
      fontStyle: 'italic',

      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: '1.2em',
        marginTop: '-3px',
      },
    },
  },
})(TextField);

const CustomSelect = withStyles({
  root: {
    borderRadius: 30,
  },
})(Select);

const industryList = industryOptions.industry;

const Profile = (props) => {
  const {
    dispatch, classes, user, loading,
  } = props;

  const defaultFields = {
    avatar: user && user.avatar ? user.avatar : '',
    first: getNameParts(user, 'first') || '',
    last: getNameParts(user, 'last') || '',
    company: getAttribute(user, 'companyName') || '',
    industry: getAttribute(user, 'industry') || '',
    phone: getAttribute(user, 'companyPhone') || '',
  };

  const [fields, setFields] = useState(defaultFields);
  const [errors, setErrors] = useState({});
  const [changesSaved, setChangesSaved] = useState(false);
  const [phoneNumberInputValue, setPhoneNumberInputValue] = useState("");
  const [handlePhoneEmpty, setHandlePhoneEmpty] = useState(true);

  useEffect(() => {
    setPhoneNumberInputValue(user && user.attributes && user.attributes.length != 0 ? user.attributes[2].value : '')
  }, [user])

  const resetFields = () => {
    setFields(defaultFields);
    setErrors({});
    setChangesSaved(false);
  };

  const updateField = (field, value) => {
    const newFields = { ...fields, [field]: value };
    setFields(newFields);
    validateFields(field, newFields);
    setChangesSaved(false);
  }; 

  const validateFields = (field = null, newFields = fields) => {
    const newErrors = { ...errors };

    const validation = {
      first: () => {
        const firstNameNoSpaceRegex = /^[A-Za-z0-9!@#$%^&*'")(+=.,:;_-]+(?: +[A-Za-z0-9!@#$%^&*'")(+=.,:;_-]+)*$/;
        const firstNameNoIntRegex = /^[^0-9][a-zA-Z0-9 !@#$%^&*'")(+=.,:;_-]+$/;
        const firstNameInvalidCharacterRegex = /^[a-zA-Z0-9 !@#$%^&*'")(+=.,:;_-]+$/;
        if (!newFields.first || !newFields.first.trim()) return (<><InfoOutlined fontSize="small" /> This field is required</>);
        if (newFields.first.length >= 21 || newFields.first.length < 2) return (<><InfoOutlined fontSize="small" /> Must be between 2 to 20 characters</>);
        if (!firstNameInvalidCharacterRegex.test(newFields.first)) return (<><InfoOutlined fontSize="small" /> Invalid character entered.</>);
        if (!firstNameNoSpaceRegex.test(newFields.first)) return (<><InfoOutlined fontSize="small" /> Cannot start or end with space</>);
        if (!firstNameNoIntRegex.test(newFields.first)) return (<><InfoOutlined fontSize="small" /> Cannot start with an integer</>);
        return null;
      },
      last: () => {
        const lastNameNoSpaceRegex = /^[A-Za-z0-9!@#$%^&*'")(+=.,:;_-]+(?: +[A-Za-z0-9!@#$%^&*'")(+=.,:;_-]+)*$/;
        const lastNameNoIntRegex = /^[^0-9][a-zA-Z0-9 !@#$%^&*'")(+=.,:;_-]+$/;
        const nameInvalidCharacterRegex = /^[a-zA-Z0-9 !@#$%^&*'")(+=.,:;_-]+$/;
        if (!newFields.last || !newFields.last.trim()) return (<><InfoOutlined fontSize="small" /> This field is required</>);
        if (newFields.last.length >= 36 || newFields.last.length < 2) return (<><InfoOutlined fontSize="small" /> Must be between 2 to 35 characters</>);
        if (!nameInvalidCharacterRegex.test(newFields.last)) return (<><InfoOutlined fontSize="small" /> Invalid character entered.</>);
        if (!lastNameNoSpaceRegex.test(newFields.last)) return (<><InfoOutlined fontSize="small" /> Cannot start or end with space</>);
        if (!lastNameNoIntRegex.test(newFields.last)) return (<><InfoOutlined fontSize="small" /> Cannot start with an integer</>);
        return null;
      },
      company: () => {
        if (!newFields.company || !newFields.company.trim()) return (<><InfoOutlined fontSize="small" /> This field is required</>);
        if (newFields.company.length >= 36 || newFields.company.length < 1) return (<><InfoOutlined fontSize="small" /> Must be between 1 to 35 characters</>);
        return null;
      },
      industry: () => {
        if (!newFields.industry || !newFields.industry.trim()) return (<><InfoOutlined fontSize="small" /> This field is required</>);
      },
      phone: () => {
        return null;
      },
    };

    if (field) {
      const error = validation[field] && validation[field]();
      if (error) {
        newErrors[field] = error;
      } else {
        delete newErrors[field];
      }
    } else {
      Object.keys(validation).forEach((key) => {
        const error = validation[key]();
        if (error) {
          newErrors[key] = error;
        } else {
          delete newErrors[key];
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (!validateFields()) return;
    dispatch(updateUser({
      name: {
        givenName: fields.first,
        familyName: fields.last,
      },
      attributes: [
        { name: 'companyName', value: fields.company },
        { name: 'industry', value: fields.industry },
        { name: 'companyPhone', value: fields.phone },
        { name: 'image', value: fields.avatar !== placeholder ? fields.avatar : '' },
      ],
    })).then(() => setChangesSaved(true));
  };

  const fieldsChanged = () => Object.keys(defaultFields).reduce((acc, key) => (
    acc || JSON.stringify(defaultFields[key]) !== JSON.stringify(fields[key])
  ), false);



  useEffect(() => {
    resetFields();
  }, [user]);

  const submitButtonData = {
    icon: changesSaved ? Check : null,
    message: changesSaved ? 'Changes Saved' : (loading ? 'Saving...' : 'Save Changes'),
  };

  const onPhoneChanged = (e) => {
 
    setPhoneNumberInputValue(e);
    if (e == undefined) {
      updateField('phone', '');
    } else {
      updateField('phone', e);
    }
    if (e !== undefined && e.length >= 2) setHandlePhoneEmpty(false);
    if (e == undefined) setHandlePhoneEmpty(true)
  };

  const onValidatePhone = () => {
    return phoneNumberInputValue
      ? isValidPhoneNumber(phoneNumberInputValue.toString()) ? true : false
      : false;
  };

  return (
    <React.Fragment>
      <div className={classes.imageInputWrapper}>
        <ImageInput
          fileUpload
          dialogType={'crop'}
          className={classes.imageInput}
          onUpload={url => updateField('avatar', url)}
          imageUrl={fields.avatar}
        />

        <h3>{`${fields.first} ${fields.last}`}</h3>
      </div>
      <Box display="flex" flexDirection="column" className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl className={classes.formControl}>
              <InputLabel label="First name" required />
              <CustomTextField
                id="firstNameInput"
                variant="outlined"
                className={classes.outlinedInput}
                onChange={e => updateField('first', e.target.value)}
                value={fields.first}
                error={!!errors.first}
                inputProps={{ placeholder: 'First name' }}
                helperText={errors.first}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl className={classes.formControl}>
              <InputLabel label="Last name" required />
              <CustomTextField
                variant="outlined"
                className={classes.outlinedInput}
                onChange={e => updateField('last', e.target.value)}
                value={fields.last}
                error={!!errors.last}
                inputProps={{ placeholder: 'Last name' }}
                helperText={errors.last}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} item md={6} lg={4}>
            <FormControl className={classes.formControl}>
              <InputLabel label="Company name" required />
              <CustomTextField
                variant="outlined"
                className={classes.outlinedInput}
                onChange={e => updateField('company', e.target.value)}
                value={fields.company}
                error={!!errors.company}
                inputProps={{ placeholder: 'Company name' }}
                helperText={errors.company}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} item md={6} lg={4}>
            <FormControl className={classes.formControl} error={!!errors.industry}>
              <InputLabel label="Industry" required />
              <CustomSelect
                variant="outlined"
                className={clsx(classes.outlinedInput, classes.customSelect)}
                onChange={e => updateField('industry', e.target.value)}
                value={fields.industry}
                displayEmpty
              >
                <MenuItem value="" selected disabled>-- Select --</MenuItem>
                {industryList.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
              </CustomSelect>

              <FormHelperText>{errors.industry}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid xs={12} item md={6} lg={4} className={classes.phoneInput}>
            <FormControl className={classes.formControl}>
              <InputLabel label="Phone Number" />
              <PhoneInput
                placeholder={"Enter Phone Number"}
                value={phoneNumberInputValue}
                onChange={onPhoneChanged}
                className={
                  onValidatePhone() || handlePhoneEmpty ? '' : classes.invalidPhoneError
                }
              />
              <div className='phone_icon'><PhoneIcon /></div>
            </FormControl>
            {!onValidatePhone() && !handlePhoneEmpty && (
              <FormHelperText>
                <InfoOutlined fontSize="small" /> Phone number is invalid.
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Box display="flex" className={classes.profile_button} justifyContent="flex-end" m=".5em">
          <Button
            className={classes.button}
            onClick={() => { resetFields(); }}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={handleSave}
            disabled={
              loading
              || Object.keys(errors).length > 0
              || !fieldsChanged() || changesSaved || (handlePhoneEmpty ? false : !onValidatePhone())
            }
          >
            {submitButtonData.icon && <submitButtonData.icon />}
            {submitButtonData.message}
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default withStyles(styles)(Profile);