import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Fab,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import placeholder from '../../assets/images/placeholder.jpg';
import PencilSquare from '../Icons/PencilSquare';
import ImageInputUrl from './ImageInputUrl';
import ImageCrop from './ImageCrop';

const styles = () => ({
  root: {
    top: '1.75em',
    left: '2.75em',
    padding: '.25em',
    zIndex: 3,
    background: '#fff',
    borderRadius: 20,
    transition: 'all 400ms',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '10em',
    height: '10em',
    '@media (max-width: 750px)': {
      width: '6em',
      height: '6em',
    },
    borderRadius: 20,
    overFlow: 'none'
  },
  image: {
    width: '100%',
    maxWidth: '100%',
    borderRadius: 20,
  },
  editButton: {
    position: 'absolute',
    right: '-1em',
    bottom: '-1em',
    background: '#fff',
    '&:hover': {
      background: '#efefef',
    },
  },
});

const ImageInput = props => {
  const {
    classes,
    className,
    onUpload,
    fileUpload,
    imageUrl,
    defaultURL,
    dialogType
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const [imageToCrop, setImageToCrop] = useState(imageUrl || defaultURL);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    showMessage: false,
  });

  const handleClose = () => {
    setErrorMessage({
      message: ``,
      showMessage: false
    });
    setImageToCrop(undefined);
    setCroppedImage(placeholder);
    setDisableSaveBtn(true);
    setDialogOpen(false);
  };
  const handleOpen = () => setDialogOpen(true);

  const onUploadFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      const allowedExt = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      const uploadedFile = document.getElementById('uploadImg');
      const selectedImgPath = uploadedFile.value;
      const selectedImgSize = uploadedFile.files[0].size;

      if (!allowedExt.exec(selectedImgPath)) {
        setErrorMessage({
          message: `File ${selectedImgPath} could not be uploaded.
                    Please check the criteria and try again.`,
          showMessage: true
        });
        setDisableSaveBtn(true);
        return false;
      } else if (selectedImgSize > 650000) {
        setErrorMessage({
          message: `File size should be maximum 500kb.
                    Please check the criteria and try again.`,
          showMessage: true
        });
        setDisableSaveBtn(true);
      } else {
        
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = function (e) {
          const image = new Image();

          image.src = e.target.result;

          image.onload = function () {
            let height = this.height;
            let width = this.width;
            if (height > 1080 || width > 1080) {
              setErrorMessage({
                message: `Height and Width must not exceed 1080px.
                    Please check the criteria and try again.`,
                showMessage: true
              });
              setDisableSaveBtn(true);
              return false;
            } else if (height < 100 || width < 100) {
              setErrorMessage({
                message: `Height and Width must be minimum 100px.
                    Please check the criteria and try again.`,
                showMessage: true
              });
              setDisableSaveBtn(true);
              return false;
            } else {
              setErrorMessage({
                message: ``,
                showMessage: false
              });
              setDisableSaveBtn(true);
              return true;
            }
          };

          var base64data = reader.result;
          setImageToCrop(base64data);
        };
      }
    }
  };

 

  const onImgCrop = (croppedImg) => {
    setCroppedImage(croppedImg);

    if (errorMessage.message === "") {
      setDisableSaveBtn(false);
    }
  }

  const onRemovePhoto = () => {
    setImageToCrop(undefined);
    setCroppedImage(placeholder);
    setErrorMessage({
      message: "",
      showMessage: false,
    });
  };

  return (
    <>
      <Paper
        className={clsx(classes.root, className || '')}
        elevation={2}
      >
        <div className={classes.imageWrapper}>
          <img
            className={clsx(classes.image, 'fadeIn')}
            alt=""
            src={fileUpload && imageUrl
              ? imageUrl
              : imageUrl || defaultURL || placeholder}
          />

          <Fab
            size="small"
            className={classes.editButton}
            onClick={() => handleOpen()}
          >
            <PencilSquare />
          </Fab>
        </div>
      </Paper>

      {dialogType == 'crop'?
            <ImageCrop
              dialogOpen={dialogOpen}
              handleClose={handleClose}
              onUpload={onUpload}
              onUploadFile={onUploadFile}
              onImgCrop={onImgCrop}
              onRemovePhoto={onRemovePhoto}
              imageToCrop={imageToCrop}
              croppedImage={croppedImage}
              errorMessage={errorMessage}
              disableSaveBtn={disableSaveBtn}
            /> 
            : 
            <ImageInputUrl
            dialogOpen={dialogOpen}
            handleClose={handleClose}
            onUpload={onUpload}
            onUploadFile={onUploadFile}
            onImgCrop={onImgCrop}
            onRemovePhoto={onRemovePhoto}
            imageToCrop={imageToCrop}
            croppedImage={croppedImage}
            errorMessage={errorMessage}
            disableSaveBtn={disableSaveBtn}
          />}
    </>
  );
};

export default withStyles(styles)(ImageInput);