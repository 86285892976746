import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Link, Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import SEO from '../components/SEO';
import EdgeProjectsPanel from '../components/Icons/EdgeProjectsPanel';
import MyHybridProjectsPanel from '../components/Icons/HybridProjects';
import SupportPanelIcon from '../components/Icons/SupportPanel';
import MyTrainingsPanel from '../components/Icons/MyTrainingsPanel';
import MyCertificatesPanel from '../components/Icons/MyCertificatesPanel';
import DownloadsPanel from '../components/Icons/Downloads';
import ArrowRight from '../components/Icons/ArrowRight';
import { enableLms } from '../configs/config';
import { version } from '../../package.json';
import { getLatestAgreement } from '../actions/developerLicense';

const styles = theme => ({
  toolbar: {
    opacity: 1,
    transition: 'opacity 400ms',
  },
  toolbarHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  button: {
    marginRight: '.5em',
  },
  buttonIcon: {
    marginRight: '.25em',
  },
  buttonIconSmall: {
    height: '.9em',
    width: '.9em',
  },
  icons: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
    height: 120,
    width: 120,

  },
  card: {
    background: 'none',
    borderRadius: 10,
    boxShadow: '0 0 20px rgba(0,0,0,0.07)',
    '& button': {
      height: 310,
      width: '100%',
      padding: 30,
      borderRadius: 10,
      backgroundColor: '#ffffff',
      border: '#ffffff 2px solid',
      '@media (max-width:767px)' : {
        width: '100%',
        padding:18,
        height: 'auto'
      }
      
    },
    '& button div > svg path': {
      stroke: '#3EA8B2'
    },
    '&  button:hover': {
      borderColor: '#3EA8B2',
      backgroundColor: '#ffffff',
      '& div > svg path': {
        stroke: '#ffffff'
      },
      '&  div:first-child': {
        backgroundColor: '#3EA8B2'
      }
    },
    '& .MuiCardContent-root': {
      padding: 0
    },
    '& h5': {
      fontSize: 20,
      fontWeight: 700,
      '@media (max-width:767px)' : {
        position: 'absolute',
        left:92,
        top:30
      }
    }
  },
  media: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(62, 168, 178, 0.2)',
    height: 56,
    marginBottom: 15,
    width: 56,
    borderRadius: 56,
    alignItems: 'center',
    '& svg': {
      height: 36,
      width: 36,
      fill: 'none'
    },
    '& img': {
      maxHeight: 36,
      maxWidth: 36,
    }
  },
  iconHoverColor: {
    'path': {
      fill: 'red'
    }
  },
  layoutContainer: {
    maxWidth: '1150px', 
    padding: '0 1em', 
    margin: '0 auto'
  },
  subtitle: {
    marginBottom: '3rem',
  },
  heading: {
    fontWeight: 700,
    fontSize: 36,
    color: '#333333',
    fontFamily: 'Nunito',
    paddingTop:5
  },
  arrowRight: {
    marginLeft: 10
  },
  sub_heading: {
    fontWeight: 700,
    fontSize: 20,
    fontFamily: 'Nunito'
  },
  textLink: {
    textAlign: 'right',
    color: '#3EA8B2',
    fontSize: 16,
    paddingTop:78,
    '@media (max-width:767px)' : {
      paddingTop: 10,
      textAlign: 'left'
    }
  },
  actionArea: {
    "&:hover $focusHighlight": {
      opacity: 0
    }
  },
  focusHighlight: {},
  textSecondary: {
    fontSize: 16,
    color: '#000000'
  }
});

const LoggedIn = () => {
  return sessionStorage.getItem('devportal_access_token') !== null;
}


// const Item = styled(Paper)(({ theme }) => ({
//     // ...theme.typography.body1,
//     fontSize: '1.5em',
//     fontWeight:'400',
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//     minHeight: 140,
//   }));


const Home = (props) => {
  const {
    classes,
    dispatch
  } = props;

  if (!LoggedIn()) {
    return (
      <>
        <Redirect to={`/`} />
      </>
    )
  }

  useEffect(()=>{
    dispatch(getLatestAgreement())
  },[dispatch])

  let timeout;

  window.addEventListener('blur', function (e) {
    e.preventDefault();
    window.clearTimeout(timeout);
  })


  const getTrainingCard = () => {
    if (enableLms === true) {
      return <Grid item m={4}>
        <Link to={`/training`}>
          <Card className={classes.card}>
            <CardActionArea classes={{
              root: classes.actionArea,
              focusHighlight: classes.focusHighlight
            }}>
              <CardContent className={clsx(classes.media, classes.training)}>
                <MyTrainingsPanel className={classes.trainingImage} />
              </CardContent>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h5">
                  My training
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  You are able to find the status of the training courses that you have taken here.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Link>
      </Grid>;
    } else {
      return '';
    }
  }
  const getCertificateCard = () => {
    if (enableLms === true) {
      return <Grid item m={4}>
        <Link to={`/certificates`}>
          <Card className={classes.card}>
          <CardActionArea classes={{
              root: classes.actionArea,
              focusHighlight: classes.focusHighlight
            }}>
              <CardContent className={clsx(classes.media, classes.certificates)}>
                <MyCertificatesPanel className={classes.certificatesImage} />
              </CardContent>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h5">
                  My certificates
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  By using mimik developer portal and mimik developer&apos;s app, your certifications always are with you.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Link>
      </Grid>;
    } else {
      return '';
    }
  }

  return (
    <React.Fragment>
      <SEO title="Home" />
      <Toolbar
        disableGutters
        className={classes.toolbar}
      >
        <Typography variant="h1"></Typography>
      </Toolbar>

      <Box sx={{ flexGrow: 1 }} style={{  }}>
        <h2 className={classes.heading}>home</h2>
        <h3 className={classes.sub_heading}>welcome to developer console</h3>
        <Typography className={classes.subtitle} variant="h4" color="primary">developer console {version}</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <Link to={`/projects`}>
              <Card className={classes.card}>
              <CardActionArea classes={{
              root: classes.actionArea,
              focusHighlight: classes.focusHighlight
            }}>
                  <CardContent className={clsx(classes.media, classes.projects)}>
                    <EdgeProjectsPanel />
                  </CardContent>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h5">
                      edge projects
                    </Typography>
                    <Typography variant="body2" className={classes.textSecondary}>
                      create your new edge projects and  get your ID tokens.
                    </Typography>
                    <Typography variant="body2" className={classes.textLink}>let’s go to edge projects <ArrowRight className={classes.arrowRight} /></Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <Link to={`/hybrid_projects`}>
              <Card className={classes.card}>
              <CardActionArea classes={{
              root: classes.actionArea,
              focusHighlight: classes.focusHighlight
            }}>
                  <CardContent className={clsx(classes.media, classes.sandboxes)}>
                    <MyHybridProjectsPanel />
                  </CardContent>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h5">
                      hybrid projects
                    </Typography>
                    <Typography variant="body2" className={classes.textSecondary}>
                      create your hybrid project. select and deploy a bundle of microservices.
                    </Typography>
                    <Typography variant="body2" className={classes.textLink}>let’s go to hybrid projects <ArrowRight className={classes.arrowRight} /></Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          {getTrainingCard()}

          {getCertificateCard()}



          <Grid item xs={12} md={6} xl={4}>
            <Link to={`/support`}>
              <Card className={classes.card}>
              <CardActionArea classes={{
              root: classes.actionArea,
              focusHighlight: classes.focusHighlight
            }}>
                  <CardContent className={clsx(classes.media, classes.training)}>
                    <SupportPanelIcon />
                  </CardContent>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h5">
                      support
                    </Typography>
                    <Typography variant="body2" className={classes.textSecondary}>
                      get access to support and community  channels for your projects.
                    </Typography>
                    <Typography variant="body2" className={classes.textLink}>let’s go to support <ArrowRight className={classes.arrowRight} /></Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <Link to={`/downloads`}>
              <Card className={classes.card}>
              <CardActionArea classes={{
              root: classes.actionArea,
              focusHighlight: classes.focusHighlight
            }}>
                  <CardContent className={clsx(classes.media, classes.download)}>
                    <DownloadsPanel />
                  </CardContent>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h5">
                      downloads
                    </Typography>
                    <Typography variant="body2" className={classes.textSecondary}>
                      download and install the latests editions of edgeEngine.
                    </Typography>
                    <Typography variant="body2" className={classes.textLink}>let’s go to downloads <ArrowRight className={classes.arrowRight} /></Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </Box>

    </React.Fragment>
  );
};

export default connect(() => ({

}))(withStyles(styles)(Home));
