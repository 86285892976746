import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputLabel from '../FormFields/InputLabel';
import FormErrors from '../FormFields/FormErrors';
import ErrorModal from '../Modal/ErrorModal';
import { createSandboxAccountStep1, createSandboxAccountStep2 } from '../../actions/sandboxes';


const styles = {
  formControl: {
    margin: '.5em',
    '@media (max-width: 1150px)': {
      minWidth: 'calc(50% - 1em)',
    },
    '@media (max-width: 460px)': {
      width: 'calc(100% - 1em)',
    },
  },
  outlinedInput: {
    minWidth: 300,
    maxWidth: '100%',
    '@media (max-width: 985px)': {
      minWidth: 'unset',
    },
  },
  button: {
    marginLeft: '.5em',
    minWidth: '6em',
    marginTop: '1.5em',
    borderRadius: 30,
  },
  imageInput: {
    '@media (max-width: 1150px)': {
      top: '-5em',
    },
    '@media (max-width: 750px)': {
      top: '-2em',
    },
  },
};

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:after': {
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      borderRadius: 30,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 30,
      },
      '&:hover fieldset': {
        border: 'rgb(62, 168, 178) 1px solid'
      },
      '&.Mui-focused fieldset': {
      },
    },
  },
})(TextField);

const LoggedIn = () => {
  return false; // sessionStorage.getItem('devportal_access_token') !== null;
}

const SandboxCreateAccount = (props) => {
  const {
    classes, sandbox, setToken, error,
  } = props;

  let renderComponent;

  if (error && error.description) {
    renderComponent = <ErrorModal error={error} />;
  } else if (LoggedIn()) {
    return (
      <>
        <Redirect to={`/hybrid_projects`} />
      </>
    )
  }

  const defaultFields = {
    newEmail: '',
    newPassword: '',
    confirmPassword: '',
    code: null,
  };


  const [view, setView] = useState('default');
  const [fields, setFields] = useState(defaultFields);
  const [errors, setErrors] = useState({});
  const [mfa, setMfa] = useState(null);

  const updateField = (field, value) => {
    const newFields = { ...fields, [field]: value };
    setFields(newFields);
    validateFields(field, newFields);
  };

  const validateFields = (field = null, newFields = fields) => {
    const newErrors = { ...errors };
    setErrors({});

    const validation = {
      newEmail: () => {
        const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
        if (!newFields.newEmail || !newFields.newEmail.trim()) return 'Login email is required';
        if (!emailRegex.test(newFields.newEmail)) return 'Login email is invalid';
        return null;
      },
      newPassword: () => {
        if (!newFields.newPassword || !newFields.newPassword.trim()) return 'Password is required';
        if (newFields.newPassword.length < 8) return 'Password is too short.';
        return null;
      },
      confirmPassword: () => {
        if (!newFields.confirmPassword || !newFields.confirmPassword.trim()) return 'Confirm password is required';
        if (newFields.confirmPassword.length < 8) return 'Confirm password is too short.';
        if (newFields.newPassword !== newFields.confirmPassword) return 'Password and Confirm password do not match.';
        return null;
      },
    };

    if (field) {
      const error = validation[field] && validation[field]();
      if (error) {
        newErrors[field] = error;
      } else {
        delete newErrors[field];
      }
    } else {
      Object.keys(validation).forEach((key) => {
        const error = validation[key]();
        if (error) {
          newErrors[key] = error;
        } else {
          delete newErrors[key];
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAccountCreation = () => {
    if (!validateFields()) return;
    createSandboxAccountStep1(sandbox, fields)
      .then((data) => {
        console.dir(data);
        if (data && data.data && data.data.mfa_token && data.data.oob_code) {
          setMfa(data.data);
        }
      })
      .catch((err) => {
        console.log(`ERROR: ${err}`);
      })
  }

  const handleVerifyCode = () => {
    if (!validateFields()) return;
    if (!mfa) return;
    createSandboxAccountStep2(sandbox, fields, mfa)
      .then((data) => {
        console.dir(data);
        if (data && data.data && data.data.access_token) {
          setToken(data.data.access_token);
        }
      })
      .catch((err) => {
        console.log(`ERROR: ${err}`);
      })
  }

  const fieldsChanged = () => Object.keys(defaultFields)
    .reduce((acc, key) => (
      acc || JSON.stringify(defaultFields[key]) !== JSON.stringify(fields[key])
    ), false);



  if (mfa && mfa.mfa_token && mfa.oob_code) {
    if (view !== 'codeEntry') {
      setView('codeEntry');
    }
  }

  if (!mfa && view === 'codeEntry') {
    setView('default');
  }

  switch (view) {
    case 'codeEntry':
      return (
        <React.Fragment>
          <form className={classes.root}>
            {renderComponent}
            <FormErrors errors={errors} />
            <Box className={classes.infoWrapper}>

              <Box display="flex" flexDirection="column" m="1em">
                <Typography variant="h1" m="1em">
                  Enter your Verification Code from the email account here.
                </Typography>
                <FormControl className={classes.formControl}>
                  <InputLabel label="Verification Code" required />
                  <CustomTextField
                    variant="outlined"
                    className={classes.outlinedInput}
                    onChange={e => updateField('code', e.target.value)}
                    value={fields.code}
                    error={!!errors.code}
                    type="number"
                    inputProps={{ placeholder: 'Verification Code' }}
                  />
                </FormControl>

                <Box display="flex" justifyContent="flex-start" flexDirection="column" m="3.5em">
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={handleVerifyCode}
                  >
                    Confirm code
                  </Button>
                </Box>
              </Box>

            </Box>
          </form>
        </React.Fragment >
      );

    default:
      return (
        <React.Fragment>
          <form className={classes.root}>
            {renderComponent}
            <FormErrors errors={errors} />
            <Box className={classes.infoWrapper}>

              <Box display="flex" flexDirection="column" m="1em">
                <Typography variant="h1" m="1em">
                  This is a 2 step account creation. You will receive a Verification Code via the email account provided below that needs to be input in the next screen.
                </Typography>
                <FormControl className={classes.formControl}>
                  <InputLabel label="Login email" required />
                  <CustomTextField
                    variant="outlined"
                    className={classes.outlinedInput}
                    onChange={e => updateField('newEmail', e.target.value)}
                    value={fields.newEmail}
                    error={!!errors.newEmail}
                    type="email"
                    autoComplete="email"
                    inputProps={{ placeholder: 'Login email' }}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <InputLabel label="Password" required />
                  <CustomTextField
                    variant="outlined"
                    className={classes.outlinedInput}
                    onChange={e => updateField('newPassword', e.target.value)}
                    value={fields.newPassword}
                    error={!!errors.newPassword}
                    type="password"
                    autoComplete="new-password"
                    inputProps={{ placeholder: 'Password' }}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <InputLabel label="Confirm password" required />
                  <CustomTextField
                    variant="outlined"
                    className={classes.outlinedInput}
                    onChange={e => updateField('confirmPassword', e.target.value)}
                    value={fields.confirmPassword}
                    error={!!errors.confirmPassword}
                    type="password"
                    autoComplete="new-password"
                    inputProps={{ placeholder: 'Confirm password' }}
                  />
                </FormControl>

                <Box display="flex" justifyContent="flex-start" flexDirection="column" m="3.5em">
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={handleAccountCreation}
                    disabled={Object.keys(errors).length > 0 || !fieldsChanged()}
                  >
                    Create account
                  </Button>
                </Box>
              </Box>

            </Box>
          </form>
        </React.Fragment>
      );
  }

};

export default connect(() => ({

}))(withStyles(styles)(SandboxCreateAccount));
