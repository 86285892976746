import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Snackbar } from '@material-ui/core';
import { CheckCircleOutline, Close } from '@material-ui/icons';

import { clearToast } from '../actions/toasts';

const Toast = () => {
  const dispatch = useDispatch();
  const successToastText =  useSelector(state => state.successToastText);
  const successToastOpen =  useSelector(state => state.successToastOpen);
  const handleClose = () => {
    dispatch(clearToast());
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={successToastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        aria-describedby="app-toast"
        message={
          <span id="app-toast">
            <CheckCircleOutline fontSize="medium" />
            {successToastText}
          </span>
        }
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

export default Toast;
