export const defaultState = [];

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'START_LOADING': {
      const newState = [...state];
      if (!newState.includes(action.value)) newState.push(action.value);
      return newState;
    }
    case 'STOP_LOADING': {
      let newState = [...state];
      if (!action.value) {
        newState = [];
      } else {
        const index = newState.indexOf(action.value);
        if (index > -1) newState = [...newState.slice(0, index), ...newState.slice(index + 1)];
      }
      return newState;
    }
    default:
      return state;
  }
}
