import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { fetchProjectById } from '../actions/projects';

import SEO from '../components/SEO';
import Loader from '../components/Loader';
import CreateProjectForm from '../components/CreateProjectForm/Index';
import DeleteProjectForm from '../components/ProjectSettings/DeleteProjectForm';

const styles = () => ({
  backButton: {
    position: 'absolute',
    top: -42,
    left: 4,
    '&:focus': {
      border:0,
      outline:0
    },
  },
  bold: {
    fontWeight: 900
  },
  headers: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: 18,
  },
  button: {
    borderRadius: 30,
  },
  buttonIcon: {
    marginRight: '.25em',
  },
  title: {
    flexGrow: 1
  },
});

const ProjectSettingsPage = (props) => {
  const {
    dispatch, match: { params }, currentProject, loading, history, classes
  } = props;

  const refreshProject = useCallback(() => dispatch(fetchProjectById(params.id)), [dispatch, params]);

  useEffect(() => {
    refreshProject();
  }, [refreshProject]);

  if (!currentProject) return <Loader fullPage />;
  return (
    <React.Fragment>
      <SEO title={`${currentProject.client_name} Settings`} />
      <Toolbar disableGutters>
        <IconButton
          className={classes.backButton}
          color="primary"
          size="small"
          aria-label="back"
          onClick={() => history.push(`/project/dashboard/${currentProject.client_id}`)}
        >
          <ArrowBackIosIcon />
          Back
        </IconButton>
        <Typography variant="h1">{currentProject.client_name}</Typography>
      </Toolbar>

      <CreateProjectForm
        dispatch={dispatch}
        project={currentProject}
        onSubmit={refreshProject}
        loading={loading.includes('updateProject') || loading.includes('fetchProjectById')}
      />

      <DeleteProjectForm
        dispatch={dispatch}
        project={currentProject}
        onSubmit={() => history.push(`/projects`)}
      />

    </React.Fragment>
  );
};

export default connect(state => ({
  loading: state.loading,
  currentProject: state.currentProject,
}))(withStyles(styles)(ProjectSettingsPage));
