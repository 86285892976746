import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { fetchSandboxById } from '../actions/sandboxes';

import SEO from '../components/SEO';
import Loader from '../components/Loader';
import CreateSandboxForm from '../components/CreateSandboxForm/Index';
import DeleteSandboxForm from '../components/SandboxSettings/DeleteSandboxForm';

const styles = {};

const SandboxSettingsPage = (props) => {
  const {
    dispatch, match: { params }, currentSandbox, loading, history,
  } = props;

  const refreshSandbox = useCallback(() => dispatch(fetchSandboxById(params.id)), [dispatch, params]);

  useEffect(() => {
    refreshSandbox();
  }, [refreshSandbox]);

  const handleSubmit = () => {
    history.push(`/hybrid_project`);
  };

  if (!currentSandbox) return <Loader fullPage />;
  return (
    <React.Fragment>
      <SEO title={`${currentSandbox.client_name} Settings`} />
      <Toolbar disableGutters>
        <Typography variant="h1">{currentSandbox.client_name}</Typography>
      </Toolbar>

      <CreateSandboxForm
        dispatch={dispatch}
        sandbox={currentSandbox}
        onSubmit={refreshSandbox}
        loading={loading.includes('updateSandbox') || loading.includes('fetchSandboxById')}
      />

      <DeleteSandboxForm
        dispatch={dispatch}
        sandbox={currentSandbox}
        onSubmit={handleSubmit}
      />

    </React.Fragment>
  );
};

export default connect(state => ({
  loading: state.loading,
  currentSandbox: state.currentSandbox,
}))(withStyles(styles)(SandboxSettingsPage));
