import React from "react";
import { SvgIcon } from "@material-ui/core";

function Learn(props) {
  return (
    <SvgIcon
      {...props}
      x="0px"
      y="0px"
      width="24" height="24" viewBox="0 0 24 24" 
       fill="none" xmlns="http://www.w3.org/2000/svg"
      style={{color: "rgba(0, 0, 0, 0)"}}
    >
<path d="M5.79001 8.75C5.60001 8.75 5.45001 8.9 5.45001 9.09V14.82C5.45001 15.45 5.93001 16.19 6.50001 16.46C8.24001 17.29 9.13001 17.35 10.95 17.4" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M18.49 11.9701V9.13011C18.49 8.98011 18.34 8.86011 18.15 8.86011" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M21.35 7.34997L12.44 11.12C12.11 11.22 11.76 11.22 11.44 11.12L2.65001 7.30997C2.47001 7.24997 2.47001 7.00997 2.65001 6.93997L11.37 2.62997C11.74 2.49997 12.14 2.49997 12.5 2.62997L21.36 6.97997C21.53 7.03997 21.53 7.28997 21.36 7.34997H21.35Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M3.34003 7.67993V11.8299" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M3.30002 14.0899C3.9738 14.0899 4.52002 13.5437 4.52002 12.8699C4.52002 12.1961 3.9738 11.6499 3.30002 11.6499C2.62623 11.6499 2.08002 12.1961 2.08002 12.8699C2.08002 13.5437 2.62623 14.0899 3.30002 14.0899Z" fill="black" className="fill_colour"  stroke="black"   />
<path d="M16.5 21.48C18.7091 21.48 20.5 19.6891 20.5 17.48C20.5 15.2708 18.7091 13.48 16.5 13.48C14.2909 13.48 12.5 15.2708 12.5 17.48C12.5 19.6891 14.2909 21.48 16.5 21.48Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M18.26 17.18L16.23 15.44C16 15.31 15.71 15.47 15.71 15.74V19.22C15.71 19.49 16 19.66 16.23 19.52L18.26 17.78C18.49 17.65 18.49 17.31 18.26 17.18Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round"/>

    </SvgIcon>
  );
}

export default Learn;
