import axios from 'axios';
import {
  v4 as uuidv4
} from 'uuid';
import { startLoading, stopLoading, setError, resetError } from './../index';

// import { devportalMidUrl, userId } from '../../configs/config';

export function setProjects(data) {
  return { type: 'SET_SANDBOX_PROJECTS', data };
}

export function resetProjects() {
  return { type: 'RESET_SANDBOX_PROJECTS' };
}

export function setProjectsStatus(data) {
  return { type: 'SET_SANDBOX_PROJECTS_STATUS', data };
}

export function resetProjectsStatus() {
  return { type: 'RESET_SANDBOX_PROJECTS_STATUS' };
}

export function setCurrentProject(data) {
  return { type: 'SET_SANDBOX_CURRENT_PROJECT', data };
}

export function resetCurrentProject() {
  return { type: 'RESET_SANDBOX_CURRENT_PROJECT' };
}

export function resetSandboxProjects() {
  return (dispatch) => {
    sessionStorage.removeItem('federated_access_token');
    dispatch(resetCurrentProject());
    dispatch(resetProjectsStatus());
    dispatch(resetProjects());
  };
}


const getMidTokenUrl = (sandbox) => {
  const Urls = sandbox.services.filter((service) => service.name === 'mid');
  return Urls[0].url;
}

export function fetchAllProjects(sandbox) {
  return (dispatch) => {
    if (!sandbox || (sandbox && sandbox.status && sandbox.status !== 'deployed') ) {
      return null;
    }
    dispatch(startLoading('fetchProjects'));
    dispatch(resetError());
    return axios.get(`${getMidTokenUrl(sandbox)}/users/me/apps`, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('federated_access_token')
      }
    })
      .then((res) => {
        dispatch(setProjects(res.data.data.reverse()));
        dispatch(stopLoading('fetchProjects'));
      })
      .catch(err => dispatch(setError('We were unable to fetch your projects.', err.response)));
  };
}

export function fetchProjectById(sandbox, appId) {
  return (dispatch) => {
    if (!sandbox || (sandbox && sandbox.status && sandbox.status !== 'deployed') ) {
      return null;
    }
    dispatch(startLoading('fetchProjectById'));
    dispatch(resetError());
    return  axios.get(`${getMidTokenUrl(sandbox)}/users/me/apps/${appId}`, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('federated_access_token')
      }
    })
      .then((res) => {
        dispatch(setCurrentProject(res.data.data));
        dispatch(stopLoading('fetchProjectById'));
      })
      .catch(err => dispatch(setError('We were unable to fetch your project.', err.response)));
  };
}

export function createProject(sandbox, payload) {

  payload.client_uri = 'https://example.com/';
  payload.redirect_uris = [`com.example.${uuidv4()}://callback`];  // appeasing validation gods -- won't be used anyways
  
  return (dispatch) => {
    dispatch(startLoading('createProject'));
    dispatch(resetError());
    return axios.post(`${getMidTokenUrl(sandbox)}/users/me/apps`, payload, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('federated_access_token')
      }
    })
      .then(() => dispatch(fetchAllProjects()))
      .then(() => dispatch(stopLoading('createProject')))
      .catch(err => dispatch(setError('We were unable to create your project.', err.response)));
  };
}

export function updateProject(sandbox, appId, payload) {
  return (dispatch) => {
    dispatch(startLoading('updateProject'));
    dispatch(resetError());
    return axios.put(`${getMidTokenUrl(sandbox)}/users/me/apps/${appId}`, payload, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('federated_access_token')
      }
    })
      .then(() => {
        dispatch(stopLoading('updateProject'));
      })
      .catch(err => dispatch(setError('We were unable to update your project.', err.response)));
  };
}

export function deleteProject(sandbox, appId) {
  return (dispatch) => {
    dispatch(startLoading('deleteProject'));
    dispatch(resetError());
    return axios.delete(`${getMidTokenUrl(sandbox)}/users/me/apps/${appId}`, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('federated_access_token')
      }
    })
      .then(() => {
        dispatch(stopLoading('deleteProject'));
      })
      .catch(err => dispatch(setError('We were unable to delete your project.', err.response)));
  };
}
