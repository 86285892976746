import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Add, Cancel, Info, PeopleAltTwoTone as ThirdParty,Close } from '@material-ui/icons';
import {
  Box,
  Button,
  Card,
  Grid,
  CardHeader,
  ClickAwayListener,
  Collapse,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Popper,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { red, cyan } from '@material-ui/core/colors';

import {
  getOauthDeeplink,
  clearDeveloperIdTokenAndClientId,
  logoutUser,
} from '../actions';
import { postLicenseResponse } from '../actions/developerLicense';
import { fetchAllProjects, resetCurrentProject } from '../actions/projects';
import { getLatestAgreement } from '../actions/developerLicense';
import SEO from '../components/SEO';
import Loader from '../components/Loader';
import CreateProjectForm from '../components/CreateProjectForm/Index';
import SharedIdProjectForm from '../components/SharedIdProjectForm/Index';
import ProjectGrid from '../components/Projects/ProjectGrid/Index'; 
import CopyButton from '../components/ProjectDashboard/CopyButton';
import { devportalMidUrl, projectsEdgeLicense } from '../configs/config';

const styles = theme => ({
  toolbar: {
    opacity: 1,
    transition: 'opacity 400ms',
    margin: '0',
    '& button' : {
      padding: '12px 60px'
    },
    '@media (max-width:767px)' : {
      flexDirection : 'column',
      '& button' : {
        width: '100%',
        margin: '0 0 20px 0'
      }
    }
  },
  heading_pg: {
    fontWeight: 700,
    fontFamily: 'Nunito',
    paddingBottom: 5,
    fontSize: 32,
    '@media (min-width: 768px)': {
      marginTop: 68
    },
    '@media (max-width: 768px)': {
      width: '100%'
    }
  },
  toolbarHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  button: {
    marginRight: '.5em',
    borderRadius: '30px',
  },
  button_outlined: {
    marginRight: '.5em',
    borderRadius: '30px',
    border: '#000000 1px solid'
  },
  buttonIcon: {
    marginRight: '.25em'
  },
  buttonIconSmall: {
    height: '.9em',
    width: '.9em',
  },
  projectGridContainer: {
    padding: '1em 0 !important',
  },
  infoBox: {
    backgroundColor: red[100],
    color: red[900],
    border: `1px solid ${theme.palette.error.light}`,
    borderRadius: 8,
    padding: '12px 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: 470,

    '& > p': {
      paddingLeft: 15,

      '& > a': {
        color: red[500],
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
    },
  },
  infoContainer: {
    margin: '1em auto !important',
    borderRadius: 10,
    padding: '1em 2em !important',
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  infoField: {
    flex: 1,
    marginBottom: '.5em',
  },
  infoLabel: {
    width: '10em',
    padding: '6px 0',
    fontWeight: 400,
  },
  infoLabel2: {
    width: 'auto',
  },
  inputLabelIcon: {
    width: '.7em',
    height: '.7em',
  },
  popperContainer: {
    maxWidth: 320,
    maxHeight: 280,
    overflowY: 'auto',
    backgroundColor: cyan[50],
  },
  no_project: {
    color:'#4F4F4F',
    marginBottom: '20px !important',
    fontSize: 20
  },
  popperInfoText: {
    padding: '0 1em 1em 1em',
  },
  developersLisenseAgreementContainer: {
    padding: 24,
    margin: '155px 0 0',
  },
  devLiseAgreementContent: {
    height: 'calc(100vh - 40vh)',
    overflowY: 'scroll',
    fontFamily: 'Nunito', 
    fontSize: '16px',
    lineHeight: '1.7',
    '& h4' : {
      fontSize: 30,
      fontWeight: 700
    },
    '& h3' : {
      fontSize: 24,
      paddingTop: 15,
      fontWeight: 700
    }
  },
  btnAcceptLisense: {
    padding: '7px 60px',
    marginRight: '.5em',
    borderRadius: 30,
  },
  acceptLisenseToolbar: {
    paddingTop: 50,
  },
  notAcceptedPopupTitle: {
    fontSize: 24,
    textAlign: 'left',
  },
  notAcceptPopupCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  notAcceptPopupActions: {
    padding: '15px 0',
    justifyContent: 'center',
  }
});

const ProjectsPage = (props) => {
  const {
    dispatch, classes, projects, loading, developerLicense, edgeLicenseExpiry,
  } = props;
  const [createFormVisible, setCreateFormVisible] = useState(false);
  const [useSharedIdFormVisible, setUseSharedIdFormVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [placement, setPlacement] = useState();
  const [openNotAcceptedPopup, setOpenNotAcceptedPopup] = useState(false);

  let timeout;

  window.addEventListener('blur', function (e) {
    e.preventDefault();
    window.clearTimeout(timeout);
  })
 
  const showForm = () => setCreateFormVisible(true);
  const hideForm = () => setCreateFormVisible(false);
  const showSharedIdForm = () => {
    dispatch(clearDeveloperIdTokenAndClientId());
    setUseSharedIdFormVisible(true);
  }
  const hideSharedIdForm = () => setUseSharedIdFormVisible(false);

  const agree = () => dispatch(postLicenseResponse(developerLicense, true));

  const logout = () => dispatch(logoutUser());

  const handleOpenNotAcceptedPopup = () => {
    setOpenNotAcceptedPopup(true);
  };
  const handleCloseNotAcceptedPopup = () => {
    setOpenNotAcceptedPopup(false);
  };

 

  useEffect(() => {
    dispatch(getLatestAgreement());
    dispatch(fetchAllProjects());
    dispatch(resetCurrentProject());
    dispatch(getOauthDeeplink());
    dispatch(clearDeveloperIdTokenAndClientId());
  }, [dispatch]);

 

  if (developerLicense
    && developerLicense.questions
    && developerLicense.questions.pages
    && developerLicense.questions.pages.length > 0
    && developerLicense.questions.pages[0].elements
    && developerLicense.questions.pages[0].elements.length > 1
    && developerLicense.questions.pages[0].elements[0].html) {
    // console.log(developerLicense.questions.pages[0].elements[0].html);
    return (
      <>
        <Box>
          <Paper
            elevation={3}
            className={classes.developersLisenseAgreementContainer}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid item sm={12}>
                <Box className={classes.devLiseAgreementContent}>
                  <div dangerouslySetInnerHTML={{ __html: developerLicense.questions.pages[0].elements[0].html }} />
                </Box>
                <Toolbar
                  disableGutters
                  className={clsx(classes.toolbar, classes.acceptLisenseToolbar)}
                >
                  <Button
                    onClick={agree}
                    variant="contained"
                    color="primary"
                    className={classes.btnAcceptLisense}
                  >
                    Agree
                  </Button>

                  <Button
                    onClick={handleOpenNotAcceptedPopup}
                    variant="outlined"
                    color="default"
                    className={classes.btnAcceptLisense}
                  >
                    Disagree
                  </Button>
                </Toolbar>
              </Grid>
            </Grid>
          </Paper>
        </Box>

        <Dialog
          onClose={handleCloseNotAcceptedPopup}
          aria-labelled-by="not-accepted-popup-title"
          open={openNotAcceptedPopup}
          maxWidth="md"
        >
          <DialogTitle id="not-accepted-popup-title">
            <Typography className={classes.notAcceptedPopupTitle}>You have not accepted the agreement.</Typography>

            <IconButton
              aria-label="close"
              className={classes.notAcceptPopupCloseButton}
              onClick={handleCloseNotAcceptedPopup}
            >
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Typography variant="body1">
              By not accepting the agreement, you cannot use our services and you will be logged out of the developer console. In order to stay on the platform and using the services, please select Continue and accept the agreement.
            </Typography>
          </DialogContent>

          <DialogActions className={classes.notAcceptPopupActions}>
            <Button
              onClick={logout}
              variant="outlined"
              color="default"
              className={classes.btnAcceptLisense}
            >
              Logout
            </Button>

            <Button
              onClick={handleCloseNotAcceptedPopup}
              variant="contained"
              color="primary"
              className={classes.btnAcceptLisense}
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  const handleClickTooltip = newPlacement => e => {
    setAnchorEl(e.currentTarget);
    setOpenPopper(prev => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClickAway = () => setOpenPopper(false);

  if (!projects) return <Loader fullPage />;
  if (!developerLicense || developerLicense && !developerLicense.agrementVerified) return <Loader fullPage />;
  
  return (
    <React.Fragment>
      <SEO title="Projects" />

      <Toolbar
        disableGutters
        className={classes.toolbar}
      >
        <Typography variant="h1" className={classes.heading_pg}>{createFormVisible ? 'Create a project' : 'Choose a project'}</Typography>
      </Toolbar>
      <Box className={classes.no_project} hidden={projects && projects.length !== 0 || createFormVisible || useSharedIdFormVisible}>You don’t have any active projects. Create a new project to continue</Box>


      <Collapse in={!(createFormVisible || useSharedIdFormVisible)}>
        <Toolbar
          disableGutters
          className={classes.toolbar}
        >
          <Button
            onClick={showForm}
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
          >
            <Add className={classes.buttonIcon} />Create New Project
          </Button>

          <Button
            onClick={showSharedIdForm}
            variant="outlined"
            color="default"
            size="large"
            className={classes.button_outlined}
          >
            <ThirdParty className={classes.buttonIcon} />Use 3rd party Project
          </Button>
        </Toolbar>
      </Collapse>

      <Collapse in={createFormVisible}>
        <CreateProjectForm
          dispatch={dispatch}
          projects={projects}
          onCancel={hideForm}
          onSubmit={hideForm}
          loading={loading.includes('createProject') || loading.includes('fetchProjectById')}
        />
      </Collapse>

      <Collapse in={useSharedIdFormVisible}>
        <SharedIdProjectForm
          dispatch={dispatch}
          onCancel={hideSharedIdForm}
          onSubmit={hideSharedIdForm}
          loading={loading.includes('createProject') || loading.includes('fetchProjectById')}
        />
      </Collapse>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifySelf="center"
        maxWidth={1250}
        className={classes.projectGridContainer}
      >
        {projects && projects.length > 0 ? (
          <ProjectGrid data={projects} />
        ) : (
          null
        )}
      </Box>

      <Paper className={classes.infoContainer} elevation={4}>
        <Typography className={clsx(classes.infoLabel, classes.infoLabel2)}>Identity server (mID)</Typography>
        <Box display="flex" alignItems="flex-start" flex={1}>
          <TextField
            defaultValue={devportalMidUrl}
            className={classes.infoField}
            disabled
          />
          <CopyButton copyString={devportalMidUrl} />
        </Box>
      </Paper>

      <Paper className={classes.infoContainer} elevation={4}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Typography className={clsx(classes.infoLabel, classes.infoLabel2)}>{`Edge License - Expires at: ${edgeLicenseExpiry}`}<Tooltip
            title="Click to view"
            placement="left"
          >
            <IconButton
              aria-label="info"
              color="primary"
              type="button"
              onClick={handleClickTooltip("right-start")}
            >
              <Info
                fontSize="small"
                className={classes.inputLabelIcon}
              />
            </IconButton>
          </Tooltip>

            <Popper
              open={openPopper}
              anchorEl={anchorEl}
              placement={placement}
            >
              <Card className={classes.popperContainer}>
                <CardHeader
                  title="Edge License"
                  action={
                    <IconButton aria-label="close" onClick={() => setOpenPopper(false)}>
                      <Cancel />
                    </IconButton>
                  }
                />
                <Box className={classes.popperInfoText}>
                  <Typography gutterBottom variant="body1">
                    You need the edge license to run edgeEngine, deploy edge microservices and authenticate users.
                  </Typography>
                </Box>
              </Card>
            </Popper>
          </Typography>
        </ClickAwayListener>

        <Box display="flex" alignItems="flex-start" flex={1}>
          <TextField
            defaultValue={projectsEdgeLicense}
            className={classes.infoField}
            disabled
            multiline
            rows={6}
          />
          <CopyButton copyString={projectsEdgeLicense} />
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default connect(state => ({
  loading: state.loading,
  projects: state.projects,
  oauthDeeplink: state.oauthDeeplink,
  developerLicense: state.developerLicense,
  edgeLicenseExpiry: state.edgeLicenseExpiry,
}))(withStyles(styles)(ProjectsPage));
