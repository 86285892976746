import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function MyTraining(props) {
  return (
    <SvgIcon
      {...props}
      x="0px"
      y="0px"
      width="14"
      height="14"
      viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.25 0.875H10.5V7C10.5 7.07741 10.4795 7.15343 10.4405 7.22032C10.4016 7.2872 10.3456 7.34256 10.2782 7.38072C10.2109 7.41889 10.1346 7.4385 10.0572 7.43756C9.97979 7.43662 9.90403 7.41516 9.83763 7.37538L7.875 6.19763L5.91238 7.37625C5.84591 7.41608 5.77007 7.43754 5.69259 7.43844C5.61511 7.43934 5.53879 7.41965 5.47141 7.38138C5.40404 7.34311 5.34804 7.28764 5.30914 7.22063C5.27024 7.15362 5.24983 7.07748 5.25 7V0.875Z" fill="black" />
      <path d="M2.625 0H11.375C11.8391 0 12.2842 0.184374 12.6124 0.512563C12.9406 0.840752 13.125 1.28587 13.125 1.75V12.25C13.125 12.7141 12.9406 13.1592 12.6124 13.4874C12.2842 13.8156 11.8391 14 11.375 14H2.625C2.16087 14 1.71575 13.8156 1.38756 13.4874C1.05937 13.1592 0.875 12.7141 0.875 12.25V11.375H1.75V12.25C1.75 12.4821 1.84219 12.7046 2.00628 12.8687C2.17038 13.0328 2.39294 13.125 2.625 13.125H11.375C11.6071 13.125 11.8296 13.0328 11.9937 12.8687C12.1578 12.7046 12.25 12.4821 12.25 12.25V1.75C12.25 1.51794 12.1578 1.29538 11.9937 1.13128C11.8296 0.967187 11.6071 0.875 11.375 0.875H2.625C2.39294 0.875 2.17038 0.967187 2.00628 1.13128C1.84219 1.29538 1.75 1.51794 1.75 1.75V2.625H0.875V1.75C0.875 1.28587 1.05937 0.840752 1.38756 0.512563C1.71575 0.184374 2.16087 0 2.625 0V0Z" fill="black" />
      <path d="M0.875 4.375V3.9375C0.875 3.82147 0.921094 3.71019 1.00314 3.62814C1.08519 3.54609 1.19647 3.5 1.3125 3.5C1.42853 3.5 1.53981 3.54609 1.62186 3.62814C1.70391 3.71019 1.75 3.82147 1.75 3.9375V4.375H2.1875C2.30353 4.375 2.41481 4.42109 2.49686 4.50314C2.57891 4.58519 2.625 4.69647 2.625 4.8125C2.625 4.92853 2.57891 5.03981 2.49686 5.12186C2.41481 5.20391 2.30353 5.25 2.1875 5.25H0.4375C0.321468 5.25 0.210188 5.20391 0.128141 5.12186C0.0460936 5.03981 0 4.92853 0 4.8125C0 4.69647 0.0460936 4.58519 0.128141 4.50314C0.210188 4.42109 0.321468 4.375 0.4375 4.375H0.875ZM0.875 7V6.5625C0.875 6.44647 0.921094 6.33519 1.00314 6.25314C1.08519 6.17109 1.19647 6.125 1.3125 6.125C1.42853 6.125 1.53981 6.17109 1.62186 6.25314C1.70391 6.33519 1.75 6.44647 1.75 6.5625V7H2.1875C2.30353 7 2.41481 7.04609 2.49686 7.12814C2.57891 7.21019 2.625 7.32147 2.625 7.4375C2.625 7.55353 2.57891 7.66481 2.49686 7.74686C2.41481 7.82891 2.30353 7.875 2.1875 7.875H0.4375C0.321468 7.875 0.210188 7.82891 0.128141 7.74686C0.0460936 7.66481 0 7.55353 0 7.4375C0 7.32147 0.0460936 7.21019 0.128141 7.12814C0.210188 7.04609 0.321468 7 0.4375 7H0.875ZM0.875 9.625V9.1875C0.875 9.07147 0.921094 8.96019 1.00314 8.87814C1.08519 8.79609 1.19647 8.75 1.3125 8.75C1.42853 8.75 1.53981 8.79609 1.62186 8.87814C1.70391 8.96019 1.75 9.07147 1.75 9.1875V9.625H2.1875C2.30353 9.625 2.41481 9.67109 2.49686 9.75314C2.57891 9.83519 2.625 9.94647 2.625 10.0625C2.625 10.1785 2.57891 10.2898 2.49686 10.3719C2.41481 10.4539 2.30353 10.5 2.1875 10.5H0.4375C0.321468 10.5 0.210188 10.4539 0.128141 10.3719C0.0460936 10.2898 0 10.1785 0 10.0625C0 9.94647 0.0460936 9.83519 0.128141 9.75314C0.210188 9.67109 0.321468 9.625 0.4375 9.625H0.875Z" fill="black" />
    </SvgIcon>
  );
}

export default MyTraining;