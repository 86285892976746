import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { VpnKey as Key } from '@material-ui/icons';
import { OutlinedInput } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { connect } from 'react-redux';

import CopyButton from '../SandboxDashboard/CopyButton';
import InputLabel from '../FormFields/InputLabel';
import { getDeveloperIdTokenWithSharedIdToken, clearDeveloperIdTokenAndClientId } from '../../actions/index';

const styles = (theme) => ({
  root: {
    maxWidth: 1085,
    margin: '1em auto',
    padding: '0 1em',
    '@media (max-width: 750px)': {
      padding: 0,
    },
  },
  paper: {
    position: 'relative',
    padding: '1.75em 2.75em',
    borderRadius: 3,
    '@media (max-width: 750px)': {
      padding: '1em',
    },
  },
  button: {
    marginLeft: '.5em',
    borderRadius: 30,
  },
  submitButton: {
    minWidth: '8em',
  },
  textInput: {
    width: '100%',
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    color: red[600],
    fontWeight: 400,
  },
  errorMessageIcon: {
    width: '.8em',
    height: '.8em',
    marginRight: '.25em',
  },
  selectItem: {
    margin: '0'
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '1em 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  infoLabel: {
    width: '10em',
    padding: '6px 0',
    fontWeight: 400,
  },
  infoFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  infoField: {
    flex: 1,
    // maxWidth: 500,
    marginBottom: '.5em',
  },
  copyButton: {
    marginLeft: '.5em',
  }
});

const SharedIdSandboxForm = (props) => {
  const {
    dispatch, classes, developerIdToken, clientId, onCancel,
  } = props;
  const defaultFields = {
    sharedIdToken: '',
    clientId: ''
  };
  const [fields, setFields] = useState(defaultFields);

  const resetForm = () => {
    setFields(defaultFields);
    dispatch(clearDeveloperIdTokenAndClientId());
  };

  const updateFields = (newFields) => {
    setFields(newFields);
  };

  const updateSharedIdToken = (e) => {
    const { value: token } = e.target;
    const newFields = { ...fields, sharedIdToken: token };

    updateFields(newFields);
  };

  const handleCancel = () => {
    resetForm();
    if (typeof onCancel === 'function') onCancel();
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        {/* <FormErrors errors={errors} /> */}

        <Box m="1em 0">
          <FormControl variant="outlined" className={classes.textInput}>
            <InputLabel
              label="Shared Id Token"
              required
              tooltip="This is a token that was provided to you by the owner of the sandbox."
            />
            <OutlinedInput
              onChange={updateSharedIdToken}
              value={fields.sharedIdToken}
            />
          </FormControl>
          <Box className={classes.infoWrapper}>
            <Button className={classes.button} variant="contained" color="primary" size="large"
              onClick={() => dispatch(getDeveloperIdTokenWithSharedIdToken(fields.sharedIdToken))}>
              <Key className={classes.buttonIcon} />  Get ID Token
            </Button>
          </Box>
          {
            developerIdToken &&
            <>
              <Box className={classes.infoWrapper}>
                <Typography className={classes.infoLabel}>Client ID</Typography>
                <Box display="flex" alignItems="flex-start" flex={1}>
                  <TextField
                    value={clientId}
                    className={classes.infoField}
                    disabled
                  />
                  <CopyButton copyString={clientId} />
                </Box>
              </Box>
              <Box className={classes.infoWrapper}>
                <Typography className={classes.infoLabel}>ID Token</Typography>
                <Box display="flex" alignItems="flex-start" flex={1}>
                  <TextField
                    value={developerIdToken}
                    className={classes.infoField}
                    helperText={"The ID token will expire within 1 month."}
                    disabled
                  />
                  <CopyButton copyString={developerIdToken} />
                </Box>
              </Box>
            </>
          }
        </Box>
        <Box display="flex" justifyContent="flex-end" m="2em 0 1em">
          <Button className={classes.button} onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </Paper>
    </div>
  );
};

export default connect((state) => ({
  developerIdToken: state.developerIdToken,
  clientId: state.clientId,
}))(withStyles(styles)(SharedIdSandboxForm));
