import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

const styles = () => ({
  listItemText: {
    fontWeight: 400,
    fontSize:16,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: 'inherit',
  },
  listItemIcon: {
    marginLeft: '25px',
    '& svg': {
      fill: 'none'
    }
  },
  listItemSelected: {
    borderColor: '#2faab1 !important',
    background: '#2faab1 !important',
    color: '#FFFFFF !important',

    '&:hover': {
      background: 'rgba(47, 170, 177, 1)',
      '& svg path': {
        stroke: '#ffffff !important',
      }
    },

    '&:hover h5': {
      color: '#FFFFFF',
    },
    '& svg': {
      background: 'none',

      '& path': {
        stroke: '#ffffff',
      },
    },
    '&:hover svg': {
      background: 'none',

      '& path': {
        stroke: '#ffffff',
      },
    },
    
  },
  tooltipHidden: {
    display: 'none',
  },
});

const MenuItem = ({
  classes, item, variant, selected, onClick,
}) => (
  <Tooltip
    key={item.title}
    title={item.title}
    placement="right"
    classes={{ tooltip: clsx({ [classes.tooltipHidden]: variant === 'full' }) }}
  >
    <ListItem
      button
      className={clsx({ [classes.listItemSelected]: selected })}
      onClick={onClick}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <item.icon
          className={clsx({ [classes.listItemSelected]: selected })}
          height="20"
          width="20"
        />
      </ListItemIcon>
      <ListItemText
        primary={(
          <Typography
            variant="h5"
            className={classes.listItemText}
            style={{ width: variant === 'full' ? 'auto' : 0 }}
          >
            {item.title}
          </Typography>
        )}
        disableTypography
      />
    </ListItem>
  </Tooltip>
);

export default withStyles(styles)(MenuItem);
