import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function SupportPanel(props) {
  return (
    <SvgIcon 
    {...props}
    viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.1627 19.1216C26.7852 22.2566 24.1252 24.6949 20.8635 25.8032" stroke="#3EA8B2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M4.66602 13.1366C5.18852 6.81911 10.4768 1.86328 16.921 1.86328C23.3652 1.86328 28.6852 6.85078 29.176 13.1841" stroke="#3EA8B2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M10.8251 14.1499C10.3501 14.1499 10.0017 13.7382 10.0651 13.2632C10.4926 9.85905 13.4059 7.23071 16.9367 7.23071C20.4676 7.23071 23.3651 9.85905 23.8084 13.2632C23.8717 13.7382 23.5076 14.1499 23.0484 14.1499H10.8567H10.8251Z" stroke="#3EA8B2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M28.0518 31.8515H30.6326C31.3768 31.8515 31.9626 31.2657 31.8834 30.569C31.1868 23.5232 24.7426 18.0132 16.9209 18.0132C9.09926 18.0132 2.65509 23.5232 1.95842 30.569C1.89509 31.2498 2.46509 31.8515 3.20926 31.8515H22.3676" stroke="#3EA8B2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M2.46509 15.195C2.46509 14.1184 3.35175 13.2317 4.42842 13.2317C5.50509 13.2317 6.39175 14.1184 6.39175 15.195L6.34425 17.7917C6.34425 18.5675 5.71092 19.2009 4.93509 19.2009C4.46009 19.2009 4.03259 18.9634 3.77925 18.5992C3.77925 18.5992 2.48092 17.2217 2.48092 15.195H2.46509Z" stroke="#3EA8B2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M31.361 15.195C31.361 14.1184 30.4743 13.2317 29.3977 13.2317C28.321 13.2317 27.4343 14.1184 27.4343 15.195L27.4818 17.7917C27.4818 18.5675 28.1152 19.2009 28.891 19.2009C29.366 19.2009 29.7935 18.9634 30.0468 18.5992C30.0468 18.5992 31.3452 17.2217 31.3452 15.195H31.361Z" stroke="#3EA8B2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M19.771 26.0724C20.8553 26.0724 21.7343 25.1934 21.7343 24.1091C21.7343 23.0248 20.8553 22.1458 19.771 22.1458C18.6866 22.1458 17.8076 23.0248 17.8076 24.1091C17.8076 25.1934 18.6866 26.0724 19.771 26.0724Z" stroke="#3EA8B2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
</SvgIcon>

  );
}

export default SupportPanel;
