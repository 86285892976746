import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { MdContentCopy } from 'react-icons/md';
import copy from 'copy-to-clipboard';

const styles = {
  button: {
    marginLeft: '.5em',
  },
};

const CopyButton = ({ classes, copyString }) => {
  const [copyText, setCopyText] = useState('Copy');

  const handleCopy = () => {
    copy(copyString);
    setCopyText('Copied to clipboard!');
    setTimeout(() => setCopyText('Copy'), 1500);
  };

  return (
    <Tooltip title={copyText} placement="right">
      <IconButton
        color="primary"
        size="small"
        className={classes.button}
        onClick={handleCopy}
      >
        <MdContentCopy />
      </IconButton>
    </Tooltip>
  );
};

export default withStyles(styles)(CopyButton);