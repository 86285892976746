import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Toolbar,
  Typography
} from '@material-ui/core';

import SEO from '../components/SEO';
// Download image icons
import iconAndroid from '../assets/images/downloadsIcons/iconDownloadAndroid.svg';
import iconIOS from '../assets/images/downloadsIcons/iconDownloadiOS.svg';
import iconWin from '../assets/images/downloadsIcons/iconDownloadWin.svg';
import iconMac from '../assets/images/downloadsIcons/iconDownloadMac.svg';
import iconRasp from '../assets/images/downloadsIcons/iconDownloadRaspberry.svg';
import iconLin from '../assets/images/downloadsIcons/iconDownloadLinux.svg'; 
// import iconQNX from '../assets/images/downloadsIcons/iconDownloadGNX.svg';

const styles = theme => ({
  toolbar: {
    opacity: 1,
    transition: 'opacity 400ms',
  },
  top_gapping : {
    paddingTop: '47px !important',
    '@media (max-width: 767px)': {
      paddingTop: '0 !important',
    },
  },
  toolbarHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  tab_heading: {
    fontWeight: 500,
    fontSize: 24,
    '@media (max-width: 768px)': {
      fontSize: 22
    },
  },
  gap_mainchild: {
    marginTop: 30,
    '@media (max-width:767px)': {
      marginTop: 0,
      marginBottom: 20
    }
  },
  button: {
    marginRight: '.5em',
  },
  buttonIcon: {
    marginRight: '.25em',
  },
  buttonIconSmall: {
    height: '.9em',
    width: '.9em',
  },
  gap_bottom: {
    marginBottom: 30,
    '@media (max-width: 768px)': {
      marginBottom: 10
    }
  },
  hoverMedia: {
    position: 'absolute',
    left: 12,
    top: 12,
    bottom: 12,
    display: 'none',
    fontSize: 16,
    lineHeight: '22px',
    right: 12,
    padding: 10,
    borderRadius: 10,
    boxShadow: '1.79545px 3.59091px 5.38636px rgba(67, 93, 88, 0.25)',
    backgroundColor: '#FAFBF8',
    textAlign: 'center',
    '&:hover': {
      boxShadow: '1.79545px 3.59091px 3.59091px rgba(62, 168, 178, 0.4)',
    },
    '& a': {
      marginTop: 15,
      display: 'inline-block',
      textDecoration: 'underline',
      color: '#3EA8B2',
      '&:hover': {
        color: '#F6911E',
      }
    }
  },
  relMedia: {
    position: 'relative',
    '&:hover .MuiBox-root': {
      display: 'block'
    }
  },
  title_head: {
    fontWeight: 700,
    fontSize: 36,
    fontFamily: 'Nunito',
    paddingBottom: 30,
    '@media (min-width: 768px)': {
      marginTop: 68
    },
    '@media (max-width:767px)': {
      fontSize: 24,
      paddingTop: 40,
      paddingBottom: 20
    }
  },
  tab_subheading: {
    fontWeight: 600,
    fontSize: 20,
    color: '#3EA8B2',
    '@media (max-width: 768px)': {
      fontSize: 18
    },
  },
  downloadsAppbar: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    marginTop: 45,
  },
  tabsContent: {
    '& > div > div': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    '& > div > div + span': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    }
  },
  box_direction: {
    flexDirection: 'row-reverse',
    '@media (max-width:767px)' : {
      flexDirection: 'column-reverse'
    }
  },
  tabBtn: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    marginLeft: 12,
    maxWidth: '100%',
    textTransform: 'none',
  },
  tabPanelContent: {
    padding: 0,
  },
  mediaContainer: {
    borderRadius: 9,
    position: 'relative',
    boxShadow: '1.79545px 3.59091px 5.38636px rgba(67, 93, 88, 0.25)',


  },
  media: {
    height: 158,
    backgroundSize: 'inherit'
  },
});

const LoggedIn = () => {
  return sessionStorage.getItem('devportal_access_token') !== null;
}

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`downloads-tabpanel-${index}`}
      aria-labelledby={`downloads-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "24px 0" }}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const mobileOperating = [
  { name: "Android for ARM", icon: iconAndroid, url: "https://github.com/edgeEngine/edgeEngine-SE-Android" },
  { name: "iOS for iPhone", icon: iconIOS, url: "https://github.com/edgeEngine/edgeEngine-SE-iOS" },
];

const highLevelOperating = [
  { name: "Linux for x86", icon: iconLin, url: "https://github.com/edgeEngine/edgeEngine-SE-Linux", subLinks: [{ name: "Linux for ARM", url: "https://github.com/edgeEngine/edgeEngine-SE-Linux" }] },
  { name: "macOS for x86", icon: iconMac, url: "https://github.com/edgeEngine/edgeEngine-SE-macOS" },
  { name: "Raspbian for ARM", icon: iconRasp, url: "https://github.com/edgeEngine/edgeEngine-SE-Raspbian" },
  // { name: "QNS for ARM", icon: iconQNX, url: "https://github.com/edgeEngine/edgeEngine-SE-QNX" },
  { name: "Windows for x86", icon: iconWin, url: "https://github.com/edgeEngine/edgeEngine-SE-Windows" },
];
 

const getSubLinks = (subLinks) => {
  if (subLinks) {
    return subLinks.map((eachSublink, i) => {
      return (<a key={'sublinks' + i} href={eachSublink.url} target="_blank" rel="noreferrer">{eachSublink.name}</a>)
    })
  }
}

const Downloads = (props) => {
  const {
    classes,
  } = props;

  const [value] = useState(0);


  if (!LoggedIn()) {
    return (
      <>
        <Redirect to={`/`} />
      </>
    )
  }

  let timeout;

  window.addEventListener('blur', function (e) {
    e.preventDefault();
    window.clearTimeout(timeout);
  })

  return (
    <React.Fragment>
      <SEO title="Downloads" />
      <Toolbar
        disableGutters
        className={classes.toolbar}
      >
        <Typography variant="h1" className={classes.title_head}>download edgeEngine</Typography>
      </Toolbar>

      <Grid container className={classes.box_direction}  spacing={2}>
 

 
        <Grid item xs={12} md={6} className={classes.top_gapping}>
          <Typography className={classes.tab_subheading}>high level operating system</Typography>
          <TabPanel value={value} index={0}>
            <Box className={classes.tabPanelContent}>
              <Grid container spacing={3}>{highLevelOperating.map((item, index) => (
                <Grid key={index} className={classes.relMedia} item xs={6} xl={4}>
                  <Card className={classes.mediaContainer}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image={item.icon}
                        title={item.name}
                      />
                    </CardActionArea>
                  </Card>
                  <Box className={classes.hoverMedia}>
                    <Typography>Click the link to download</Typography>
                    <a href={item.url} target="_blank" rel="noreferrer">{item.name}</a>
                    {getSubLinks(item.subLinks)}
                  </Box>
                </Grid>))}
              </Grid>
            </Box>
          </TabPanel>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography className={classes.tab_heading}>edgeEngine (standard edition)</Typography>
          <Typography className={classes.tab_subheading}>mobile operating system</Typography>
          <TabPanel value={value} index={0} className={classes.gap_bottom}>
            <Box className={classes.tabPanelContent}>
              <Grid container spacing={3}>{mobileOperating.map((item, index) => (
                <Grid key={index} className={classes.relMedia} item xs={6} xl={4}>
                  <Card className={classes.mediaContainer}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image={item.icon}
                        title={item.name}
                      />
                    </CardActionArea>
                  </Card>
                  <Box className={classes.hoverMedia}>
                    <Typography>Click the link to download</Typography>
                    <a href={item.url} target="_blank" rel="noreferrer">{item.name}</a>
                  </Box>
                </Grid>))}
              </Grid>
            </Box>
          </TabPanel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default connect(() => ({

}))(withStyles(styles)(Downloads));
