import axios from 'axios';
import queryString from 'query-string';

import {
  startLoading,
  stopLoading,
  setError,
  resetError
} from './index';
import {
  lmsBaseUrl
} from '../configs/config';

export function setTrainings(data) {
  return {
    type: 'SET_TRAININGS',
    data
  };
}

export function setTrainingProgress(data) {
  return {
    type: 'SET_TRAINING_PROGRESS',
    data
  };
}

export function setCertificates(data) {
  return { type: 'SET_CERTIFICATE', data };
}

export function lmsLogin(data) {
  return (dispatch) => {
    const {
      email,
      password
    } = data;
    dispatch(startLoading('lmsLogin'));
    dispatch(resetError());

    const formData = {
      username: email,
      password: password
    }
    return axios.post(`${lmsBaseUrl}/wp-json/jwt-auth/v1/token`, queryString.stringify(formData), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((lmsToken) => {
        if (lmsToken && lmsToken.status && lmsToken.status === 200) {
          sessionStorage.setItem('lms_token', lmsToken.data.token);
          return axios.post(`${lmsBaseUrl}/wp-json/buddybossthemechild/v1/loggedinuser`, null, {
            headers: {
              'Authorization': `Bearer ${lmsToken.data.token}`
            }
          })
          .then((userData) => {
            sessionStorage.setItem('lms_id', userData.data.uid);
            dispatch(stopLoading('lmsLogin'));
          })
        }
      })
      .catch((lmsErr) => {
        console.log(`Error: ${lmsErr}`);
        dispatch(setError('We were unable to log you in.', lmsErr.response));
      });
  }
}

export function fetchAllTrainings() {
  const token = sessionStorage.getItem('lms_token');
  const userId = sessionStorage.getItem('lms_id');

  const trainingsData = axios.get(`${lmsBaseUrl}/wp-json/ldlms/v2/users/${userId}/courses`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });
  const trainingDetailsData = axios.get(`${lmsBaseUrl}/wp-json/ldlms/v2/users/${userId}/course-progress`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  return (dispatch) => {
    dispatch(startLoading('fetchAllTrainings'));
    dispatch(resetError());

    return axios.all([trainingsData, trainingDetailsData])
      .then(
        axios.spread((...allData) => {
          const trainings = allData[0].data;
          const trainingProgress = allData[1].data;

          dispatch(setTrainings({
            trainings,
            trainingProgress
          }));
          dispatch(stopLoading('fetchAllTrainings'));
        })
      )
      .catch(err => dispatch(setError('We were unable to fetch your trainings.', err.response)))
  };
}

export function fetchCertificates() {
  const token = sessionStorage.getItem('lms_token');
  const userId = sessionStorage.getItem('lms_id');

  return dispatch => {
    dispatch(startLoading('fetchCertificates'));
    dispatch(resetError());

    return axios.get(`${lmsBaseUrl}/wp-json/buddybossthemechild/v1/certificates/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => {
        // console.dir(res.data);
        if (res && res.data && res.data[914]) {
          const certObj = res.data;
          // console.log(certObj[914]);
  
          dispatch(setCertificates(certObj[914]));
        }
        dispatch(stopLoading('fetchCertificates'));
      })
      .catch(err => dispatch(setError('We were unable to fetch your certificates.', err.response)));
  };
}
