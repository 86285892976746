module.exports = {
    /* #################################################

    NOTE: All process.env variables must be preceded by
    REACT_APP_

    ################################################# */

    ssoMidUrl: process.env.REACT_APP_SSO_MID_URL,
    devportalMidUrl: process.env.REACT_APP_DEVPORTAL_MID_URL,
    // devportalSSOUrl: process.env.REACT_APP_DEVPORTAL_SSO_URL,
    devportalMpoUrl: process.env.REACT_APP_DEVPORTAL_MPO_URL,
    devportalAssessmentUrl: process.env.REACT_APP_DEVPORTAL_ASSESSMENT_URL,
    developerIdTokenExpireTime: parseInt(process.env.REACT_APP_DEVELOPER_ID_TOKEN_EXPIRE_TIME, 10) || 2592000,
    developerSharedIdTokenExpireTime: parseInt(process.env.REACT_APP_DEVELOPER_SHARED_ID_TOKEN_EXPIRE_TIME, 10) || 86400,
    // redirectUri: process.env.REACT_APP_REDIRECT_URI,
    ssoClientId: process.env.REACT_APP_SSO_CLIENT_ID || '1ce9f50c-6b1f-4be6-b478-86db6eb429be',
    devConsoleClientId: process.env.REACT_APP_DEVPORTAL_CLIENT_ID || '41fa6fbb-9cea-4256-b688-78173c5ae115',

    // For local development only. Don't come crying to me if you break things by using this.
    userId: process.env.REACT_APP_MID_USER_ID || 'me',
    ssoScope: 'openid email',
    devportalScope: 'openid create:app delete:app read:app read:me update:me read:assessment create:assessmentResponse readByUserId:assessmentResponse email read:sandboxes read:sandbox create:sandbox update:sandbox delete:sandbox',
    // appId: process.env.REACT_APP_DEVCON_APP_ID || 'e6318cf1-7340-41e6-bdd9-2ff7220043ff',
    // appSecret: process.env.REACT_APP_DEVCON_APP_SECRET, // || 'baeadf03-bf80-43c2-8659-960d6abd3325',
    // redirectUri: process.env.REACT_APP_DEVCON_REDIRECT_URI, // || ['http://localhost:8042/console/oauthcallback'],

    sandboxerUrl: process.env.REACT_APP_SANDBOXER_URL,
    lmsBaseUrl: process.env.REACT_APP_LMS_BASE_URL,
    enableLms: process.env.REACT_APP_ENABLE_LMS_INTEGRATION ? String(process.env.REACT_APP_ENABLE_LMS_INTEGRATION).toLowerCase() !== 'false' : true,
    maxSandboxes: process.env.REACT_APP_MAX_SANDBOX_COUNT || 1,
    policyId: process.env.REACT_APP_POLICY_ID,
    projectsEdgeLicense: process.env.REACT_APP_EDGE_LIC,
    swaggerhubUrl: process.env.REACT_APP_SWAGGERHUB_URL,
    devportalPublicUrl: process.env.REACT_APP_DEV_PORTAL_PUBLIC_URL,
    baseUrl: process.env.REACT_APP_PLATFORM_URL,
    google_tag: process.env.REACT_APP_GOOGLE_TAG || null,
    google_tag_manager: process.env.REACT_APP_GOOGLE_TAG_MANAGER || null,
}