import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import LearnIcon from '../Icons/Learn';
import DeveloperIcon from '../Icons/Developer';
import DeveloperDocsIcon from '../Icons/DeveloperDocs';
import InfoLinks from './InfoLinks';
import { Box, List } from '@material-ui/core'
import { devportalPublicUrl } from '../../configs/config';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '2em',
    padding: '.3em',
    background: theme.palette.background,
    // color: '#fff',
    '@media (max-width: 725px)': {
      flexDirection: 'column',
      padding: '30px 0 ',
    },
  },
  itemWrapper: {
    display: 'flex',
    '@media (max-width: 725px)': {
      flexWrap: 'wrap',
    },
  },
  item: {
    margin: '0 1em',
    fontSize: 15,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    '@media (max-width: 725px)': {

      margin: 0,
      textAlign: 'center',
      fontSize: 14,
      width: '100%',
      clear: 'both'
    },
  },
  icon: {
    width: '.6em',
    height: '.6em',
    marginRight: '.25em',
    marginBottom: '.1em',
  },
  extraList: {
    borderTop: '#ffffff 6px solid',
    borderBottom: '#ffffff 6px solid',
    paddingTop: 6,
    paddingBottom: 6,
    backgroundColor: '#E8E8E8',
    '& div:hover': {
      color: 'rgba(47, 170, 177, 1)',
      background: 'none',
      '& svg path': {
        stroke: 'rgba(47, 170, 177, 1)',
      }
    }
  },
  border_outer: {
    marginTop: 50,
    display: 'none',
    marginBottom: 50,
    borderTop: '#3EA8B2 2px solid',
    borderBottom: '#3EA8B2 2px solid',
    '@media (max-width: 767px)': {
      display: 'block',
      marginBottom:0
    },
  },
});

const FooterComponent = ({ classes }) => {
  const extraLinks = [
    { title: 'Developer Portal', url: `${devportalPublicUrl}`, icon: DeveloperIcon, external: true },
    { title: 'Developer Docs', url: `https://devdocs.mimik.com/`, icon: DeveloperDocsIcon, external: true },
    { title: 'Learn', url: `${devportalPublicUrl}/complementary-courses/`, icon: LearnIcon, external: true }
  ];
  const handleLink = (url) => {
    window.open(url, '_blank');
  };
  return (
    <Fragment>
      <Box className={classes.border_outer}>
        <List className={classes.extraList}>
          {extraLinks.map(
            extraLink => (
              <InfoLinks
                key={extraLink.url}
                item={extraLink}
                onClick={() => handleLink(extraLink.url, extraLink.external)}
                custom={extraLink.custom}
                className={classes.extraItem}
              />
            )
          )}
        </List>
      </Box>
      <div className={classes.root}>      
      <div className={classes.itemWrapper}>
        <Typography className={classes.item}>
          &copy; mimik technology, Inc. all rights reserved
        </Typography>
      </div>
    </div>
    </Fragment>
    
  )
}

export default withStyles(styles)(FooterComponent);
