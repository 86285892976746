import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import logiIcon from '../../../assets/images/logo_2.png';
import { baseUrl } from '../../../configs/config';

const styles = () => ({
  card: {
    width: 'calc(20% - 2em)',
    minWidth: 140,
    transition: 'width 400ms',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',
    marginRight: '20px',
    marginTop: 20,
    borderRadius:10,

    '& h2': {
      textAlign: 'center',
    },

    '@media (max-width: 1080px)': {
      width: 'calc(24% - 0.75em)',
      marginRight: 15
    },
    '@media (max-width: 768px)': {
      width: '49%',
      margin: '5px 0'
    },
  },
  media: {
    height: 100,
    margin: '1em',
    marginBottom: 0,
    backgroundSize: 'contain',
  },
});

const ProjectTile = ({ classes, sandbox, item, history }) => {
  const imageUrl = (url) => {
    return url.split('/').reverse() && url.split('/').reverse().length > 1 && url.split('/').reverse()[1] === 'mimik-devportal-assets.s3-us-west-2.amazonaws.com'? baseUrl + logiIcon : url
  }
 
  return (
  <Card className={classes.card}>
    <CardActionArea onClick={() => history.push(`/hybrid_project/project/settings/${sandbox.id}/${item.client_id}`)}>
      <CardMedia
        className={classes.media}
        image={imageUrl(item.logo_uri)}
        title={item.client_name}
        classes={{ media: 'fadeIn' }}
      /> 
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {item.client_name}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
)};

export default withRouter(withStyles(styles)(ProjectTile));
