import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

import InputLabel from '../components/FormFields/InputLabel';
import FormErrors from '../components/FormFields/FormErrors';
import { getAccessToken } from '../actions/index';
import { showSuccessToast } from '../actions/toasts';
import logo from '../assets/images/mimik-logo.png';
import iconGraphic from '../assets/images/login_graphic.png';
 

const styles = () => ({
  onboardingWrapper: {
    width: '100%',
    height: '100%',
    minHeight: 600,
    maxWidth: '100% !important',
    padding: '0 !important',
    margin: '0 !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflow: 'hidden'
  },
  loginMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flexGrow: 1,

    '& > div + div > div': {
      maxWidth: 840,
    },

    '@media (max-width: 1024px)': {
      background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.35) 23.54%, #3EA8B2 100%)',
    },

    '@media (max-width: 767px)': {
      padding: '2em 0',
      justifyContent: 'inherit'
    },
  },
  loginMainGrid: {
    '@media (max-width: 767px)': {
      width: 'calc(100% - 40px)',
      margin: '0 20px',
    },
  },
  formUI: {
    maxWidth: '75%',
    margin: '0 auto',

    '@media (max-width: 767px)': {
      maxWidth: '85%',
    },
  },
  disclaimerText: {
    maxWidth: '292px',
    margin: '0 auto',

    '@media (max-width: 767px)': {
      padding: '20px 0',
    },
  },
  formControl: {
    display: 'flex',
    margin: '1em 0',

    '&:first-child': {
      margin: '0 0 1em 0',
    },
  },
  responsiveGrid: {
    '@media (max-width: 767px)': {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',
      textAlign: 'center'
    },
  },
  formContainer: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.12)',
    paddingTop: '4em',
    borderRadius: 55,

    '@media (max-width: 1024px)': {
      backgroundColor: 'rgba(255, 255, 255, 0.76)',
      boxShadow: 'none',
    },

    '@media (max-width: 767px)': {
      paddingTop: '0',
      paddingBottom: '20px',

      '& > form > div:first-child > div > div > .MuiBox-root': {
        margin: '2em 1.5em 1em',
      },
    },
  },
  outlinedInput: {
    minWidth: 300,
    maxWidth: '100%',
    '@media (max-width: 1025px)': {
      minWidth: 'inherit',
    },
  },

  button: {
    minWidth: '6em',
    borderRadius: 30,
    height: 50,
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeight: 400,
    margin: '1em 0',
    '&.MuiButton-contained.Mui-disabled': {
      boxShadow: 'none',
      backgroundColor: 'rgba(62, 168, 178, 0.15)',
      '& span': {
        color: 'rgba(62, 168, 178, 0.6)',
        fontSize: 16
    }
    },
    '&.MuiButton-outlined': {
      border: '#3EA8B2 1px solid',
      color: '#3EA8B2',
      background: 'none',
      '@media (max-width: 767px)': {
        backgroundColor: '#ffffff'
      },
      '&:hover': {
        fontWeight: 700,
        borderWidth: 2
      }
    },
    '@media (max-width: 767px)': {
      marginTop: 20,
      marginBottom: 0
    }

  },
  imageInput: {
    '@media (max-width: 1150px)': {
      top: '-5em',
    },
    '@media (max-width: 750px)': {
      top: '-2em',
    },
  },
  links: {
    fontSize: '14px',
    fontFamily: 'Nunito',
    color: '#3EA8B2',
    '&:hover': {
      color: '#3EA8B2',
      fontWeight: 700,
      textDecoration: 'underline'
    },
    '@media (max-width: 750px)': {
      marginRight: 10
    }
  },
  logo: {
    width: '100%',
    maxWidth: 92,
    position: 'absolute',
    top: 30,
    left: 30,
    '@media (max-width: 767px)': {
      left: 'inherit',
      top: 'inherit',
      position: 'relative'
    },
  },
  logofloating: {
    display: 'block',

    '@media screen and (max-width: 1366px) and (max-height: 729px)': {
      display: 'none',
    },
  },
  logoContained: {
    display: 'none',
    padding: '20px 0',
    textAlign: 'center',

    '& img': {
      width: '100%',
      maxWidth: 92,
    },

    '@media screen and (max-width: 1366px) and (max-height: 729px)': {
      display: 'block',
    },
  },
  formInfo: {
    padding: '1em 2em',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '15px',
    position: 'relative',
  },
  formUITextInfo: {
    '@media (max-width: 767px)': {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      textAlign: 'center',
      paddingBottom: '0 !important',
      marginBottom: 20,


      '& h3': {
        marginTop: 20
      },

      '& p + p': {
        display: 'none',
      },

      '& + div': {
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
        paddingTop: '0 !important',
      },
    },
  },
  infoMessageIcon: {
    position: 'absolute',
    top: 'calc(50% - 0.75em)',
    left: '-0.75em',
    width: '1.5em',
    height: '1.5em',
    marginRight: '.75em',
  },
  copyLink: {
    color: '#3ea8b2',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    '&:hover': {
      fontWeight: 700,
      color: '#3ea8b2',
      textDecoration: 'underline',
    }
  },
  buttonSection: {
    marginTop: 5,

    '@media (max-width: 750px)': {
      flexDirection: 'column',
      padding: '0 20px',
      marginTop: 0,

      '& > div': {
        padding: '0 !important',
        flexGrow: 0,
        width: '100%',
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
  },
  onboardingInfoSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    paddingTop: '10%',
    alignItems: 'center',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.35) 21.35%, #3EA8B2 100%)',
    width: 555,
    height: '100%',

    '@media (max-width: 1400px)': {
      paddingTop: '7%',
    },

    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  infoDisplay: {
    width: '70%',
    height: '50%',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0) 14.41%, rgba(255, 255, 255, 0.69) 87.28%)',
    borderRadius: 25,
    padding: '23px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    alignItems: 'top',



    '& > ul': {
      listStyleType: 'none',
      padding: 0,
      margin: 0,

      '&:after': {
        position: 'relative'
      },

      '& > li': {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '10px 0',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',

        '& p': {
          fontFamily: 'Roboto',
          fontSize: 16,
          fontWeight: 300
        }


      },
    },
  },
  onboardingFooterText: {
    textAlign: 'center',

    '@media (max-width: 767px)': {
      fontSize: '0.825em',
      marginTop: 20,
      marginBottom: 20
    },
  },
  errMessageContainer: {
    maxWidth: '75%',
    margin: '0 auto 1em',

    '@media (max-width: 767px)': {
      margin: '0 1.5em 1em',
      maxWidth: '300px',
    },
  },
  errorMessage: {
    padding: '1em 2em',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '15px',
    position: 'relative',
    color: red[600],
  },
  errorMessageIcon: {
    position: 'absolute',
    top: 'calc(50% - 0.75em)',
    left: '-0.75em',
    width: '1.5em',
    height: '1.5em',
    marginRight: '.75em',
  },
});

const CustomTextField = withStyles({
  root: {



    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:after': {
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: 30,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 30,
      },
      '&:hover fieldset': {
        border: 'rgb(62, 168, 178) 1px solid'
      },
      '&.Mui-focused fieldset': {
      },
    },
  },
})(TextField);

const LoggedIn = () => {
  return sessionStorage.getItem('devportal_access_token') !== null;
}

const LoginPage = (props) => {
  const {
    dispatch, classes, loading, error,
  } = props;

  const defaultFields = {
    email: '',
    password: '',
  };

  const [fields, setFields] = useState(defaultFields);
  const [errors, setErrors] = useState({});
  const [errCollapse, setErrCollapse] = useState(false);

  useEffect(() => {
    errTimeout(error);
  }, [error, setErrCollapse]);

  const updateField = (field, value) => {
    setErrCollapse(false)
    const newFields = { ...fields, [field]: value };
    setFields(newFields);
    validateFields(field, newFields);
  };

  const validateFields = (field = null, newFields = fields) => {
    const newErrors = { ...errors };
    setErrors({});

    const validation = {
      email: () => {
        const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
        if (!newFields.email || !newFields.email.trim()) return 'Login email is required';
        if (!emailRegex.test(newFields.email)) return 'Login email is invalid';
        return null;
      },
      password: () => {
        if (!newFields.password || !newFields.password.trim()) return 'Password is required';
        if (newFields.password.length > 128) return 'Password is too lengthy.';
        if (newFields.password.length < 7) return 'Password is too short.';
        return null;
      },
    };

    if (field) {
      const error = validation[field] && validation[field]();
      if (error) {
        newErrors[field] = error;
      } else {
        delete newErrors[field];
      }
    } else {
      Object.keys(validation).forEach((key) => {
        const error = validation[key]();
        if (error) {
          newErrors[key] = error;
        } else {
          delete newErrors[key];
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = () => {
    if (!validateFields()) return;
    dispatch(getAccessToken(fields));
  }

  const fieldsChanged = () => Object.keys(defaultFields)
    .reduce((acc, key) => (
      acc || JSON.stringify(defaultFields[key]) !== JSON.stringify(fields[key])
    ), false);

  const errTimeout = err => {
    if (err && typeof err.description === "string") {
      setErrCollapse(true);
    }
  };

 

  if (LoggedIn()) {
    dispatch(showSuccessToast("Successfully Logged in!"));
    return (
      <>
        <Redirect to={`/home`} />
      </>
    )
  }

  return (
    <React.Fragment>
      <div className={classes.onboardingWrapper}>
        <div className={classes.loginMain}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className={classes.logofloating}
          >
            <Grid item sm={11} className={classes.responsiveGrid}>
              <img
                src={logo}
                alt="mimik logo"
                className={classes.logo}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className={classes.loginMainGrid}
          >
            <Grid item sm={10} className={classes.responsiveGrid}>
              <Box
                boxShadow={5}
                borderRadius={15}
                pt="1.5em" pb="4em"
                className={classes.formContainer}
              >
                <form className={classes.root} onSubmit={e => {
                  e.preventDefault();
                  handleLogin();
                }}>
                  {
                    (errCollapse)
                      ? <>
                        <Collapse in={true}>
                          <Box className={classes.errMessageContainer}>
                            <Typography className={classes.errorMessage} variant="body1" m="1em">
                              <Error className={classes.errorMessageIcon} />
                              {typeof error.description === "string" ? error.description : ""}
                            </Typography>
                          </Box>
                        </Collapse>
                      </>
                      : ""
                  }

                  <FormErrors errors={errors} />

                  <Box className={classes.formUI}>
                    <div className={classes.logoContained}>
                      <img
                        src={logo}
                        alt="mimik logo"
                      />
                    </div>

                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={5}
                    >
                      <Grid
                        item
                        sm={12}
                        className={classes.formUITextInfo}
                      >
                        <Typography variant="h3" style={{ fontWeight: 700, color: '#000000', fontFamily: 'Roboto' }}>login</Typography>
                        <Typography variant="body1" style={{ color: '#C6C6C6', fontFamily: 'Roboto', fontWeight: 700 }}>enter your credentials to access your developer console</Typography>
                      </Grid>

                      <Grid item sm={12}>
                        <FormControl className={classes.formControl}>
                          <InputLabel label="Email Address" required />
                          <CustomTextField
                            variant="outlined"
                            className={classes.outlinedInput}
                            onChange={e => updateField('email', e.target.value)}
                            value={fields.email}
                            error={!!errors.email}
                            type="email"
                            autoComplete="email"
                            inputProps={{ placeholder: 'user@example.com' }}
                            autoFocus={true}
                          />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <InputLabel label="Password" required />
                          <CustomTextField
                            variant="outlined"
                            className={classes.outlinedInput}
                            onChange={e => updateField('password', e.target.value)}
                            value={fields.password}
                            error={!!errors.password}
                            type="password"
                            autoComplete="current-password"
                            inputProps={{ placeholder: '******' }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box
                      display="flex"
                      flexDirection="row-reverse"
                    >
                      <Link
                        className={classes.links}
                        href={`/forgot_password`}
                        color="inherit"
                        underline="always"
                      >
                        Forgot Password?
                      </Link>
                    </Box>

                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={5}
                      className={classes.buttonSection}
                    >
                      <Grid item sm={6}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth={true}
                          size="large"
                          className={classes.button}
                          disabled={Object.keys(errors).length > 0 || !fieldsChanged() || !loading || loading.includes('getAccessToken')}
                        >
                          {loading.includes('getAccessToken')?'Please Wait ...':'Sign In'}
                        </Button>
                      </Grid>

                      <Grid item sm={6}>
                        <Button
                          className={classes.button}
                          variant="outlined"
                          fullWidth={true}
                          size="large"
                          href={`/create_account`}
                        >
                          Create Account
                        </Button>
                      </Grid>

                      <Typography variant="body2" align="center" className={classes.disclaimerText}>by creating an account with mimik, I agree and understand the <a target='_blank' rel="noreferrer" className={classes.copyLink} href="https://developer.mimik.com/developer-agreement/">developer license agreement</a></Typography>

                    </Grid>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>

          <Typography variant="body1" className={classes.onboardingFooterText}>&copy; mimik technology, Inc. all rights reserved</Typography>
        </div>

        <div className={classes.onboardingInfoSection}>
          <Box className={classes.infoDisplay}>
            <Typography variant="body2" style={{ fontWeight: 700, fontSize: 17, paddingBottom: '1em', color: '#3EA8B2' }}>start & continue with your journey ...</Typography>

            <ul className="dot_signup">
              <li>
                <Typography variant="body2">install edgeEngine</Typography>
              </li>
              <li>
                <Typography variant="body2">create projects</Typography>
              </li>
              <li>
                <Typography variant="body2">develop & deploy edge-native applications</Typography>
              </li>
              <li>
                <Typography variant="body2">join the community & get support </Typography>
              </li>
              <li>
                <Typography variant="body2">get access to learning resources</Typography>
              </li>
              <li>
                <Typography variant="body2">track you progress</Typography>
              </li>
            </ul>
            <Box><img src={iconGraphic} alt='Graphic' /></Box>
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect((state) => ({
  loading: state.loading,
  error: state.error,
  sessionExpiry: state.sessionExpiry,
}))(withStyles(styles)(LoginPage));

