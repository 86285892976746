import React from 'react';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Add } from '@material-ui/icons';
import ProjectTile from './ProjectTile';

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    animation: 'fadeIn 800ms ease-in-out',
    margin: '0 1em',
  },
  buttonIcon_square : {
    borderRadius: '10px',
    paddingTop: 0,
    marginTop:20,
    height: 175,
    marginRight: 20,
    width: 198,
    '@media (max-width:1100px)' : {
      width: 170,
    },
    '@media (max-width:767px)' : {
      marginTop:0,
      marginRight:0,
      width: '49%'
    },
    '& span' : {
      display: 'block',
      fontSize: 18,
   
    },
    '& span:first-child': {
      fontSize: 30,
    },
    
  }
});

const ProjectGrid = ({ classes, sandbox, data, showForm, createFormVisible }) => (
  <div className={classes.root}>
          <Button
            onClick={showForm}
            variant="contained"
            color="primary"
            size="large"
            hidden={createFormVisible}
            className={classes.buttonIcon_square}
          >
            <span><Add /></span>
            <span>Create A</span>
            <span>New Project</span>
          </Button>
    {data.map(item => (
      <ProjectTile
        key={item.client_id}
        sandbox={sandbox}
        item={item}
      />
    ))}
  </div>
);

export default withStyles(styles)(ProjectGrid);
