const defaultState = 0;

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'RESET_CHANGE_PASSWORD_FEILDS':
      return state + 1
    default:
      return state;
  }
}
