import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { VpnKey as Key } from '@material-ui/icons';
import {
  Box,
  Button,
  CardContent,
  FormControl,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import red from '@material-ui/core/colors/red';

import { connect } from 'react-redux';

import CopyButton from '../ProjectDashboard/CopyButton';
import InputLabel from '../FormFields/InputLabel';
import { getDeveloperIdTokenWithSharedIdToken, clearDeveloperIdTokenAndClientId } from '../../actions/index';

const styles = (theme) => ({
  root: {
    '@media (max-width: 750px)': {
      padding: 0,
    },
  },
  paper: {
    position: 'relative',
    padding: '46px 60px',
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    '@media (max-width: 750px)': {
      padding: '30px 10px 50px 10px',
    },
  },
  heading_1 : {
    fontWeight: 600,
    fontSize: 22,
    paddingBottom: 40,
    '@media (max-width: 750px)': {
      paddingBottom: 30,
      paddingTop: 20
    },
  },
  button: {
    marginRight: '40px',
    borderRadius: 40,
    padding: '0 20px'
  },
  submitButton: {
    minWidth: '8em',
  },
  textInput: {
    width: '100%'
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    color: red[600],
    fontWeight: 400,
  },
  errorMessageIcon: {
    width: '.8em',
    height: '.8em',
    marginRight: '.25em',
  },
  selectItem: {
    margin: '0'
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'end',
    margin: '2em 0 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  buttonWrapper : {
    display: 'flex',
    justifyContent: 'end',
    margin: '2em 0 0'
  },
  button_outlined: {
    padding: '12px 40px',
    borderRadius: '30px',
    border: '#000000 1px solid',
    '&:hover' : {
      border: 'rgb(62, 168, 178) 1px solid',
      color: 'rgb(62, 168, 178)',
      fontWeight: 600,
      backgroundColor: '#ffffff'
    },
    '@media(max-width:767px)' : {
      padding: '12px 20px'
    }
  },
  infoLabel: {
    width: '10em',
    padding: '6px 0',
    fontWeight: 400,
  },
  infoFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  form_width: {
    maxWidth: 900,
    margin: '0 auto'
  },
  buttonIcon: {
    marginRight: 8,
    '@media (max-width:767px)' : {
      display : 'none'
    }
  },
  infoField: {
    flex: 1,
    // maxWidth: 500,
    marginBottom: '.5em',
  },
  copyButton: {
    marginLeft: '.5em',
  }
});

const CustomTextField = withStyles({
  root: {
    padding: '0',
    '& input': {
      backgroundColor: '#F2F2F2',
      padding: '20px 40px',
      border: 'rgba(0, 0, 0, 0.23) 1px solid',
      '&:hover' : {
        border: 'rgb(62, 168, 178) 1px solid'
      },
      '&:focus' : {
        border: 'rgb(62, 168, 178) 2px solid'
      }
    },
    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:before': {
      display: 'none'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      borderRadius: 30,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 30,
      },
      '&:hover fieldset': {
        border: 'rgb(62, 168, 178) 1px solid'
      },
      '&.Mui-focused fieldset': {
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      marginRight: 0,
      fontStyle: 'italic',

      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: '1.2em',
        marginTop: '-3px',
      },
    },
  },
})(TextField);

const InfoContent = () => {
  return (
    <CardContent bgcolor="info.main">
      <Typography gutterBottom variant="body2">
        This is a token that has been provided to you by the owner of the project.
      </Typography>
    </CardContent>
  );
};

const SharedIdProjectForm = (props) => {
  const {
    dispatch, classes, developerIdToken, clientId, onCancel,
  } = props;
  const defaultFields = {
    sharedIdToken: '',
    clientId: ''
  };
  const [fields, setFields] = useState(defaultFields);

  const resetForm = () => {
    setFields(defaultFields);
    dispatch(clearDeveloperIdTokenAndClientId());
  };

  const updateFields = (newFields) => {
    setFields(newFields);
  };

  const updateSharedIdToken = (e) => {
    const { value: token } = e.target;
    const newFields = { ...fields, sharedIdToken: token };

    updateFields(newFields);
  };

  const handleCancel = () => {
    resetForm();
    if (typeof onCancel === 'function') onCancel();
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        {/* <FormErrors errors={errors} /> */}
        <Typography variant='h2' className={classes.heading_1}>Use 3rd party project</Typography>
        <Box className={classes.form_width}>
          <FormControl variant="outlined" className={classes.textInput}>
            <InputLabel
              label="Shared Id Token"
              required
              tooltip="Click to view"
              infoTitle="Shared Id Token"
              infoText={InfoContent}
            />
            <CustomTextField
              onChange={updateSharedIdToken}
              value={fields.sharedIdToken}
            />
          </FormControl>
          <Box className={classes.buttonWrapper}>
          <Button className={classes.button} onClick={handleCancel}>
            Cancel
          </Button>
            <Button variant="outlined" color="default" size="large"
              onClick={() => dispatch(getDeveloperIdTokenWithSharedIdToken(fields.sharedIdToken))}
              disabled={!fields.sharedIdToken}
              className={classes.button_outlined}
              >
              <Key className={classes.buttonIcon} />  Get Shared ID Token
            </Button>
          </Box>
          {
            developerIdToken &&
            <>
              <Box className={classes.infoWrapper}>
                <Typography className={classes.infoLabel}>Client ID</Typography>
                <Box display="flex" alignItems="flex-start" flex={1}>
                  <TextField
                    value={clientId}
                    className={classes.infoField}
                    disabled
                  />
                  <CopyButton copyString={clientId} />
                </Box>
              </Box>
              <Box className={classes.infoWrapper}>
                <Typography className={classes.infoLabel}>ID Token</Typography>
                <Box display="flex" alignItems="flex-start" flex={1}>
                  <TextField
                    value={developerIdToken}
                    className={classes.infoField}
                    helperText={"The ID token will expire within 1 month."}
                    disabled
                  />
                  <CopyButton copyString={developerIdToken} />
                </Box>
              </Box>
            </>
          }
        </Box>
      
      </Paper>
    </div>
  );
};

export default connect((state) => ({
  developerIdToken: state.developerIdToken,
  clientId: state.clientId,
}))(withStyles(styles)(SharedIdProjectForm));
