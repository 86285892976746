import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  withStyles
} from '@material-ui/core';
 
import { postLicenseProfileResponse } from '../../actions/developerLicense';
const styles = {
  agreementDialog: {
    '& > div:nth-child(3) > div': {
      maxWidth: 1150,
      fontSize: '16px',
      lineHeight: '1.7',
      '@media (max-width:767px)' :{
        top: 50,
        height: 'auto'
      },
      '& h4' : {
        fontSize: 24,
        fontWeight: 700
      },
      '& h3' : {
        fontSize: 20,
        paddingTop: 15,
        fontWeight: 700
      },
    },
    '& > div + div + div > div > div + div': {
      overflow: 'auto',
    },
   
  },
  dialogActions: {
    justifyContent: 'flex-start',
    padding: 24,

    '& button': {
      borderRadius: '50px',
      padding: '7px 55px',
    },
  },
  dialogContent: {
    maxHeight: 400,
  },
  disagreeButton : {
    border: '#000000 1px solid !important'
  }
};

const ViewAgreementModal = props => {
  const {classes, dialogOpen, dialogClose, title, content, version, dispatch, developerLicense } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const developerLicenseAgree= developerLicense && developerLicense.agreementItem  && (developerLicense.agreementItem).length !== 0? developerLicense.agreementItem[0] : {} 
  const agree = () => {
    dispatch(postLicenseProfileResponse(developerLicenseAgree, true, dialogClose))
  };
 
 
  return (
    <>
      {title && (
      <Dialog
        fullScreen={fullScreen}
        onClose={dialogClose}
        aria-labelledby="agreement-dialog-title"
        open={dialogOpen}
        scroll="paper"
        className={classes.agreementDialog}
      >
        <DialogTitle id="agreement-dialog-title">{`${title} ${version}`}</DialogTitle>
        <DialogContent dividers={true} className={classes.dialogContent}>
          <section dangerouslySetInnerHTML={{ __html: content }} />
        </DialogContent>
        {!developerLicense.agrementVerified?<DialogActions className={classes.dialogActions}>
          <Button
            autoFocus
            onClick={agree}
            color="primary"
            variant="contained"
          >
              Agree
          </Button>
          <Button
            className={classes.disagreeButton}
            autoFocus
            onClick={dialogClose}
            variant="outlined"
          >
              Disagree
          </Button>
        </DialogActions>:
        <DialogActions className={classes.dialogActions}>
          <Button
            autoFocus
            onClick={dialogClose}
            color="primary"
            variant="contained"
          >
              Close
          </Button>
        </DialogActions>}
      </Dialog>
      )}
    </>
  );
};
 
export default (withStyles(styles)(ViewAgreementModal));
