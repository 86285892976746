import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function Bundle(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 26.82 21.77"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>
          {
            ".dd{fill:#90ced4;}.ee{fill:#cfdd84;}.ff,.gg{fill:#b8d053;}.gg,.hh,.ii,.jj,.kk,.ll{stroke:#010101;stroke-linecap:round;stroke-linejoin:round;}.gg,.hh,.ii,.jj,.ll{stroke-width:.3px;}.hh,.mm{fill:#59bac1;}.ii{fill:#a5c721;}.jj{fill:#0aa8af;}.kk{fill:#0e9ba2;stroke-width:.3px;}.nn{fill:#f2f2f2;}.ll{fill:#99b91e;}"
          }
        </style>
      </defs>
      <g id="aa" />
      <g id="bb">
        <g id="cc">
          <g>
            <g>
              <path
                className="hh"
                d="M.15,4.53L8.97,.15l8.82,4.38v.58l-8.82,4.38L.15,5.12v-.59Z"
              />
              <path
                className="jj"
                d="M.15,4.53l8.82,4.38,8.82-4.38V13.31l-8.85,4.38L.15,13.31V4.53Z"
              />
              <path
                className="kk"
                d="M8.97,17.68V8.91L.21,4.56h-.06V13.31l8.79,4.38h.03Z"
              />
              <path
                className="dd"
                d="M4.04,2.86L12.4,7.02l1.7-.84L5.72,2.02l-1.67,.84Z"
              />
              <path
                className="mm"
                d="M12.58,7.36l1.79-.88v2.76l-.48-.08-.51,.62-.8-.06v-2.36Z"
              />
              <path
                className="nn"
                d="M7.15,12.41l-2.98-1.49c-.17-.08-.3,.01-.3,.22v2c0,.21,.14,.44,.3,.52l2.99,1.49c.17,.08,.3-.01,.3-.22v-2.01c-.01-.21-.15-.43-.32-.51h0Zm-.68,1.64l-1.9-.95c-.08-.04-.15-.17-.15-.28s.07-.17,.15-.12l1.9,.95c.08,.04,.15,.17,.15,.28s-.07,.17-.15,.12Zm.28-.69l-2.18-1.1c-.08-.04-.15-.17-.15-.28s.07-.17,.15-.12l2.18,1.1c.08,.04,.15,.17,.15,.28,0,.12-.07,.17-.15,.12Z"
              />
            </g>
            <g>
              <path
                className="gg"
                d="M12.45,11.01l7.11-3.53,7.11,3.53v.47l-7.11,3.53-7.11-3.52v-.48Z"
              />
              <path
                className="ii"
                d="M12.45,11.01l7.11,3.53,7.11-3.53v7.07l-7.13,3.53-7.08-3.53v-7.07Z"
              />
              <path
                className="ll"
                d="M19.56,21.61v-7.06l-7.06-3.51h-.04v7.05l7.08,3.53h.02Z"
              />
              <path
                className="ee"
                d="M15.35,9.76l6.74,3.35,1.37-.67-6.76-3.35-1.35,.67Z"
              />
              <path
                className="ff"
                d="M22.21,13.41l1.44-.71v2.22l-.39-.07-.41,.5-.64-.04v-1.9Z"
              />
              <path
                className="nn"
                d="M18.1,17.36l-2.4-1.2c-.13-.07-.24,.01-.24,.18v1.61c0,.17,.11,.36,.24,.42l2.41,1.2c.13,.07,.24-.01,.24-.18v-1.62c-.01-.17-.12-.34-.26-.41h0Zm-.54,1.32l-1.53-.77c-.07-.03-.12-.13-.12-.22s.06-.13,.12-.1l1.53,.77c.07,.03,.12,.13,.12,.22s-.06,.13-.12,.1Zm.22-.56l-1.75-.89c-.07-.03-.12-.13-.12-.22s.06-.13,.12-.1l1.75,.89c.07,.03,.12,.13,.12,.22,0,.1-.06,.13-.12,.1Z"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Bundle;