export const getAttribute = (user, attrName) => {
  const attr = user && user.attributes && user.attributes.find(attr => attr.name === attrName);
  return attr ? attr.value : null;
};

export const getNameParts = (user, namePart) => {
  const parts = ['first', 'middle', 'last'];
  if (!user || ! user.name) return '';
  if (parts.indexOf(namePart) < 0) return '*';
  switch (namePart) {
    case parts[0]:
      return user.name.givenName || '';
    case parts[1]:
      return user.name.middleName || '';
    case parts[2]:
      return user.name.familyName || '';
  
    default:
      break;
  }
}

export const getFormattedUserName = (user) => {
  if (!user || ! user.name) return '';
  const { name } = user;
  const { givenName, familyName } = name;
  const firstName = givenName || '';
  const lastName = familyName || '';
  return [firstName, lastName].join(' ').trim();
};