import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';

import { fetchSandboxById } from '../actions/sandboxes';

import SEO from '../components/SEO';
import Loader from '../components/Loader';
import SandboxProjects from '../components/SandboxDashboard/SandboxProjects';
import SandboxInfo from '../components/SandboxDashboard/SandboxInfo';
 
 

const styles = () => ({
  backButton: {
    position: 'absolute',
    top: -42,
    left: 0,
    border:0,

    '&:focus': {
      border:0,
      outline:0
    },

  },
  bold: {
    fontWeight: 900
  },
  fontBold: {
    fontWeight: 'bold',
  },
  header_margin: {
    margin:5,
    '@media (max-width:767px)' : {
      margin: 0 
    }
  },
  headers: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginBottom: '1em',
  }
});

const SandboxDashboardPage = (props) => {
  const {
    dispatch, match: { params }, currentSandbox, loading, history, classes,
  } = props; 
  useEffect(() => {
    dispatch(fetchSandboxById(params.id));
  }, [dispatch, params.id]);

  const getNiceDate = (strDate) => {
    return new Date(strDate).toLocaleDateString('en-US', {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    });
  } 
  if (loading.includes('fetchSandboxById')) return <Loader fullPage />;
  if (!currentSandbox) return null;
  return (
    <React.Fragment>
      <SEO title={`${currentSandbox.clientName} Dashboard`} />
      <Toolbar disableGutters>
        <IconButton
          className={classes.backButton}
          color="primary"
          size="small"
          aria-label="back"
          onClick={() => history.push(`/hybrid_projects`)}
        >
          <ArrowBackIosIcon />
          Back
        </IconButton>

        <div className={classes.headers}>
          <Typography variant="h1" className={[classes.fontBold, classes.header_margin]}>{currentSandbox.clientName} <span className={classes.bold}>{currentSandbox && currentSandbox.status && currentSandbox.status !== 'deployed' ? '[' + currentSandbox.status + ']' : ''}</span></Typography>

          {currentSandbox && currentSandbox.status && currentSandbox.status === 'deployed' ?
            <>
              <Typography variant="body1">This Hybrid Project will automatically get expired after:</Typography>
              <Typography variant="body1">{getNiceDate(currentSandbox.expiresAt)} UTC</Typography>
            </>
            : ''}
        </div>

      </Toolbar>

      <SandboxProjects sandbox={currentSandbox} dispatch={dispatch} history={history} />
      
      <SandboxInfo sandbox={currentSandbox} dispatch={dispatch} history={history} />

    </React.Fragment>
  );
};

export default connect(state => ({
  loading: state.loading,
  currentSandbox: state.currentSandbox,
}))(withStyles(styles)(SandboxDashboardPage));
