import axios from 'axios';
import { startLoading, stopLoading, setError, resetError  } from './index';

import { devportalMpoUrl, userId } from '../configs/config';



const LoggedIn = () => {
  return sessionStorage.getItem('devportal_access_token') !== null;
}

export function setUser(data) {
  return { type: 'SET_USER', data };
}

export function resetUser(data) {
  return { type: 'RESET_USER', data };
}

export function fetchUser() {
  return (dispatch) => {
    dispatch(startLoading('fetchUser'));
    dispatch(resetError());
    if (!LoggedIn()) {
      dispatch(setUser(null));
      dispatch(stopLoading('fetchUser'));
      return null;
    }
    return axios.get(`${devportalMpoUrl}/mPO/v1/users/${userId}`, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
      }
    })
      .then(getUserThumbnail)
      .then((data) => {
        
        dispatch(setUser(data));
        dispatch(stopLoading('fetchUser'));
      })
      .catch(err => dispatch(setError('We were unable to fetch your profile.', err.response)));
  };
}

export function updateUser(payload) {
  return (dispatch) => {
    dispatch(startLoading('updateUser'));
    dispatch(resetError());
    return axios.put(`${devportalMpoUrl}/mPO/v1/users/${userId}`, payload, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('devportal_access_token')
      }
    })
      .then(() => {
        dispatch(stopLoading('updateUser'));
        return dispatch(fetchUser());
      })
      .catch(err => dispatch(setError('We were unable to update your profile.', err.response)));
  };
}

function getUserThumbnail(userResponse) {
  return new Promise((resolve, reject) => {
    if (!userResponse || !userResponse.data || !userResponse.data.data) {
      reject(new Error('Unable to fetch thumbnail. Invalid user response.'));
    }
    const { data } = userResponse.data;
    if (!data.avatar) resolve(data);
    const url = data.avatar;

    axios.get(url)
      .then(res => {
        
        resolve({ ...data, avatar: res.data.data.image })
      })
      .catch(reject);
  });
}
