import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { applyMiddleware, createStore, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { push } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import { Helmet } from 'react-helmet';

import * as serviceWorker from './serviceWorker';
import reducers from './reducers';

import Layout from './components/Layout/Index';

import Home from './pages/Home';
import Projects from './pages/Projects';
import ProjectDashboard from './pages/ProjectDashboard';
import ProjectSettings from './pages/ProjectSettings';
import HybridProjects from './pages/Sandboxes';
import Training from './pages/Training';
import MyCertificates from './pages/MyCertificates';
import Downloads from './pages/Downloads';
import Support from './pages/SupportMain';
import Login from './pages/Login';
// import Login from './pages/SSOLogin';
import ForgotPassword from './pages/ForgotPassword';
import HybridProjectDashboard from './pages/SandboxDashboard';
import HybridProjectSettings from './pages/SandboxSettings';
import HybridProjectProjectSettings from './pages/SandboxProjectSettings';
import CreateAccount from './pages/CreateAccount';
import Account from './pages/Account';
import Agreements from './pages/Agreements';
import LogoutPage from './pages/Logout';
import HealthCheck from './pages/healthcheck';
import NotFound from './pages/404';

import 'antd/dist/antd.css';
import './index.css';
import Toast from './components/Toast';
import { google_tag, google_tag_manager } from './configs/config';


let enhancer;

if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(thunk);
} else {
  // Redux Configuration
  const middleware = [];

  // Thunk Middleware
  middleware.push(thunk);

  // Logging Middleware
  const logger = createLogger({ level: 'info', collapsed: true });
  middleware.push(logger);

  // Redux DevTools Configuration
  const actionCreators = { push };

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionCreators })
    : compose;

  enhancer = composeEnhancers(applyMiddleware(...middleware));
}

const store = createStore(reducers, enhancer);

ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      {/* <!-- Google Tag Manager --> */}
      {google_tag_manager ? <script>{`
      (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${google_tag_manager}');
`}</script> : null}
      {/* <!-- End Google Tag Manager --> */}

      {/* <!-- Google tag (gtag.js) -->  */}
      {google_tag ? <script async src={`https://www.googletagmanager.com/gtag/js?id=${google_tag}`}></script> : null}
      {google_tag ? <script>{`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${google_tag}');
  `}</script> : null}
      {/* <!-- End of Google tag (gtag.js) -->  */}
    </Helmet>
    <Toast />
    <Router>
      <Switch>
        <Layout fixed>
          <Switch>
            <Route path={`/`} exact component={Login} />
            <Route path={`/login`} exact component={Login} />
            <Route path={`/create_account`} component={CreateAccount} />
            <Route path={`/forgot_password`} component={ForgotPassword} />
            <Route path={`/logout`} exact component={LogoutPage} />

            <Route path={`/home`} exact component={Home} />
            <Route path={`/projects`} exact component={Projects} />
            <Route path={`/hybrid_projects`} exact component={HybridProjects} />
            <Route path={`/training`} exact component={Training} />
            <Route path={`/certificates`} exact component={MyCertificates} />
            <Route path={`/downloads`} exact component={Downloads} />
            <Route path={`/support`} exact component={Support} />
            <Route path={`/project/dashboard/:id`} component={ProjectDashboard} />
            <Route path={`/project/settings/:id`} component={ProjectSettings} />
            <Route path={`/hybrid_project/dashboard/:id`} component={HybridProjectDashboard} />
            <Route path={`/hybrid_project/settings/:id`} component={HybridProjectSettings} />
            <Route path={`/hybrid_project/project/settings/:hpid/:id`} component={HybridProjectProjectSettings} />
            <Route path={`/account`} component={Account} />
            <Route path={`/agreements`} component={Agreements} />
            <Route path={`/healthcheck`} component={HealthCheck} />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
