import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  List,
  ListItem,
  ListItemText,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { getLatestAgreement } from '../../actions/developerLicense';
import ViewAgreementModal from '../Modal/ViewAgreementModal';
import Loader from '../Loader';

const styles = theme => ({
  agrement_btn : {
    backgroundColor: '#FFD00D',
    borderRadius: 10,
    fontSize: 16,
    fontWeight: 400,
    color: '#000000',
    padding: '4px 18px',
    '&:hover' : {
      fontWeight: 400
    },
    '@media (max-width:767px)' : {
      marginTop: 20,
      marginBottom: 10
    }
  },
  listTextItem: {
    textAlign: 'right',
    '@media (max-width:767px)' : {
      textAlign: 'left',
      width: '100%',
      '& p' : {
        color: '#000000'
      }
    }
  },
  root: {
    width: '75%',
    margin: '0 auto',
    '@media (max-width: 985px)': {
      width: '100%',
    },
    '& .MuiListItem-root': {
      padding: 10,
      border: '1px solid #E0E0E0',
      borderRadius: 10,
      boxShadow: '0 8px 14px rgba(0, 0, 0, 0.06)',
      '@media (max-width:767px)' : {
         flexDirection: 'column',
         padding: 15
      }
    },

    '& .MuiListItem-button:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: 'rgba(0, 0, 0, 0) !important',
    },

    '& .MuiTouchRipple-child': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const getFormattedDate = dateStr => {
  const date = new Date(dateStr);

  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (isNaN(date.getTime())) {
      return '';
    } else {
      return date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }
  }
};

const AgreementListItem = props => {
  const {
    item,
    index,
    agreementInfo,
    titleEl,
    setOpen,
    classes,
    setContentAgreement
  } = props;

  return (<div>
    <ListItem
      button
      onClick={() => {
        setOpen(true);
        setContentAgreement(item);
      }}
    >
      <ListItemText primary={`${titleEl.getElementsByTagName('h4')[0].innerText} ${item.version}`} />

      {
        (typeof agreementInfo[index] !== "undefined" && agreementInfo[index].length > 0)
          ?
          <ListItemText className={classes.listTextItem} secondary={`Signed on ${getFormattedDate(agreementInfo[index][0].updatedAt)}`} />
          :
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.agrement_btn}
          >Please sign the new agreement</Button>
      }
    </ListItem>
  </div>)
};


const TermsOfService = props => {
  const {
    classes,
    developerLicense,
    dispatch
  } = props;

  const [open, setOpen] = useState(false);
  const [contentAgreement, setContentAgreement] = useState(null);

  let timeout;

  window.addEventListener('blur', function (e) {
    e.preventDefault();
    window.clearTimeout(timeout);
  });

  useEffect(() => {
    dispatch(getLatestAgreement());
  }, [dispatch]);

  if (!developerLicense ) return <Loader fullpage />

  const {
    agreementItem,
    agreementInfo
  } = developerLicense;

 

  const handleCloseDialog = () => setOpen(false);

  let titleEl = document.createElement('html');
  let infoEl = document.createElement('html');

  const getTitle = title => {
    titleEl.innerHTML = title.questions.pages[0].elements[0].html;
    return titleEl.getElementsByTagName('h4')[0].innerText;
  };

  const getInfo = info => {
    infoEl.innerHTML = info.questions.pages[0].elements[0].html;
    return infoEl.getElementsByTagName('body')[0].innerHTML;
  };

  if (agreementItem) {
    titleEl.innerHTML = agreementItem[0].questions.pages[0].elements[0].html
  }


  return (
    <>
      <h4>Terms of Service Agreements</h4>
      {agreementItem && agreementItem.length > 0 ? (<div className={classes.root}>
        <List component="nav" aria-label="main agreements list">
          <AgreementListItem
            item={agreementItem[0]}
            index={0}
            classes={classes}
            agreementInfo={agreementInfo}
            setContentAgreement={setContentAgreement}
            titleEl={titleEl}
            setOpen={setOpen}
          />
        </List>

        {contentAgreement && contentAgreement !== null && (
          <ViewAgreementModal
            dialogOpen={open}
            dialogClose={handleCloseDialog}
            title={getTitle(contentAgreement)}
            content={getInfo(contentAgreement)}
            version={contentAgreement.version}
            dispatch={dispatch}
            developerLicense={developerLicense}
          />
        )}
      </div>) : ""}
    </>
  );
};

export default connect(state => ({
  developerLicense: state.developerLicense,
}))(withStyles(styles)(TermsOfService));
