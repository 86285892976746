export const defaultState = null;

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'SET_TRAININGS':
      return action.data;
    case 'SET_TRAINING_PROGRESS':
      return action.data;
    default:
      return state;
  }
}
