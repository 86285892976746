import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withStyles, useTheme } from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Add, ArrowBackIos, Close, InfoOutlined } from '@material-ui/icons';
import { amber } from '@material-ui/core/colors';
import clsx from 'clsx';

import {
  getOauthDeeplink,
  clearDeveloperIdTokenAndClientId,
  logoutUser,
} from '../actions';
import { postLicenseResponse } from '../actions/developerLicense';
import {
  fetchAllSandboxes,
  createSandbox,
  resetCurrentSandbox,
} from '../actions/sandboxes';
import { resetSandboxProjects } from '../actions/sandbox/projects';
import { getLatestAgreement } from '../actions/developerLicense';

import SEO from '../components/SEO';
import Loader from '../components/Loader';
import InputLabel from '../components/FormFields/InputLabel';
import SharedIdSandboxForm from '../components/SharedIdSandboxForm/Index';
import SandboxGrid from '../components/Sandboxes/SandboxGrid/Index';

import Bundle1 from '../components/Icons/Bundle1';
import Bundle2 from '../components/Icons/Bundle2';
import Bundle3 from '../components/Icons/Bundle3';
import { maxSandboxes } from '../configs/config';

import headerBanner from '../assets/images/header-banner-stroke.png';
import bgMoon from '../assets/images/bg-moon-resized.png';
import imi from '../assets/images/GIF-imi.gif'
import imi2 from '../assets/images/GIF-imi-loading.gif'
import imi3 from '../assets/images/GIF-imi-hat.gif'

const styles = (theme) => ({
  toolbar: {
    opacity: 1,
    transition: 'opacity 400ms',
  },
  heading_pg: {
    fontWeight: 700,
    fontFamily: 'Nunito',
    fontSize: 36,
    '@media (min-width: 768px)': {
      marginTop:68
    },
    '@media (max-width: 768px)': { 
      fontSize: 24,
      borderTop: '#3EA8B2 1px dashed',
      width: '100%',
      paddingTop: 10,
      paddingBottom:10
    },
  },
  toolbarHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  formControl: {
    width: '100%',
    '@media (max-width: 1150px)': {
      minWidth: 'calc(50% - 1em)',
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },

    '& .MuiInputBase-input': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  outlinedInput: {
    minWidth: 260,
    maxWidth: '100%',
    '@media (max-width: 1025px)': {
      minWidth: 'inherit',
    },
  },
  button: {
    marginRight: '.5em',
    borderRadius: 30,
  },
  buttonIcon: {
    marginRight: '.25em',
  },
  buttonIconSmall: {
    height: '.9em',
    width: '.9em',
  },
  wizardFinalBtn: {
    minWidth: '25%',

    '@media (max-width: 768px)': {
      minWidth: '40%',
    },

    '@media (max-width: 580px)': {
      minWidth: '90%',
    },
  },
  wizardFinalMessage: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  wizardFinalMessageError: {
    color: theme.palette.error.main
  },
  maxSandboxes: {
    padding: '12px 24px',
    backgroundColor: amber[300],
    color: theme.palette.grey.main,
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
    border: 'none',
    borderRadius: 10,
    width: '100%',

    '@media (max-width: 580px)': {
      fontSize: '14px',
    },
  },
  paperScrollPaper: {
    minHeight: 570,
    minWidth: 930,
    padding: '0 24px',
    borderRadius: 10,

    '& .MuiDialogContent-root': {
      padding: '8px 24px 0',

      '@media (max-width: 580px)': {
        padding: '24px 0',
      },
    },

    '@media (max-width: 580px)': {
      maxHeight: 'calc(100vh - 60px)',
      margin: '300px 30px 30px',
    },

    '@media (max-width: 768px)': {
      height: 'auto',
      margin: '30px',
      padding: '24px 0',
    },

    '@media (max-width: 1080px)': {
      minWidth: 'auto',
    },
  },
  dialogContent: {
    minHeight: 570,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',

    '@media (max-width: 768px)': {
      minHeight: 'auto',
    },
  },
  dialogContentInit: {
    '@media (max-width: 580px)': {
      flexDirection: 'row',
    },

    '@media (max-width: 768px)': {
      width: 'auto',
      margin: 0,
    },

    '& > div': {
      '@media (max-width: 580px)': {
        maxWidth: '100%',
        flexBasis: '100%',
      }
    },

    '& > div > img': {
      '@media (max-width: 580px)': {
        width: '150px',
        height: 'auto',
      }
    },

    '& > div + div > div': {
      '@media (max-width: 580px)': {
        paddingRight: 20,
      }
    },
  },
  dialogContents: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  wizardContent:{
    display:'flex',
    padding: '0px 0px 0px 30px',
  },
  dialogContentContainer: {
    minHeight: 570,
    height: '100%',

    '@media (max-width: 768px)': {
      flexDirection: 'row',
      width: '98%',
      marginLeft: '1%',
    },

    '@media (max-width: 767px)': {
      width: '100%',
      marginLeft: '0',
    },
  },
  dialogContentResponsiveMedia: {
    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  dialogFigureContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',

    '@media (max-width: 768px)': {
      flexDirection: 'row',
    },

    '@media (max-width: 580px)': {
      flexDirection: 'row',
    },
  },
  dialogFigureContentWizard: {
    backgroundColor: 'linear-gradient(0deg, rgba(235, 235, 235, 0) 0%, rgba(235, 235, 235, 1) 100%)',
    backgroundImage: `url(${bgMoon})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom center',

    '@media (max-width: 580px)': {
      textAlign: 'center',
      backgroundPosition: 'bottom center',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  bundlesDialogFigureContentWizard: {
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      textAlign: 'left',
      backgroundPosition: 'bottom left',
      justifyContent: 'space-between',
    },

    '@media (max-width: 767px)': {
      justifyContent: 'center',
      backgroundPosition: 'bottom center',
    },
  },
  dialogFormContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '@media (max-width: 580px)': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  bundlesDialogFormContent: {
    '@media (max-width: 768px)': {
      margin: '0 auto',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  academyOfferImage: {
    width: 316,
    height: 357,
    alignSelf: 'center',

    '@media (max-width: 1080px)': {
      height: 'auto',
      width: '100%',
    },

    '@media (max-width: 768px)': {
      marginLeft: 30,
    },
  },
  academyOfferContentWrapper: {
    paddingRight: 60,
    paddingLeft: 20,

    '@media (max-width: 580px)': {
      paddingRight: 20,
    },
  },
  offerButton: {
    width: '100%',
    borderRadius: 30,
    margin: '4px 0',
  },
  wizardHeader: {
    fontFamily: '"Nunito", Helvetica',
    fontSize: 30,
    fontStyle: 'normal',
    fontWeight: 700,
    height: 'auto',

    '@media (max-width: 768px)': {
      fontSize: 24,
    },

    '@media (max-width: 580px)': {
      fontSize: 18,
    },
  },
  wizardHeaderShortened: {
    width: '60%',

    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  wizardBodyBold: {
    fontFamily: '"Nunito", Helvetica',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 700,
    padding:'70px 0px 0px',
  },
  wizardBody1: {
    fontFamily: '"Nunito", Helvetica',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    paddingBottom: 15,
    paddingTop: 15,

    '@media (max-width: 580px)': {
      fontSize: 14,
    },
  },
  wizardBodyXSpace: {
    paddingRight: 15,
  },
  wizardBody2: {
    fontFamily: '"Nunito", Helvetica',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#3ea8b2',
    paddingBottom: 30,

    '@media (max-width: 580px)': {
      fontSize: 14,
    },
  },
  academyOfferBodySubtle: {
    fontFamily: '"Nunito", Helvetica',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#c4c4c4',
    textAlign: 'center',

    '@media (max-width: 580px)': {
      fontSize: 16,
    },
  },
  bundlesImageWrapper: {
    alignSelf: 'center',
    marginBottom: '42%',

    '@media (max-width: 580px)': {
      width: 'auto',
      display: 'block',
    },

    '@media (max-width: 768px)': {
      minWidth: '240px',
      maxWidth: '240px',
      display: 'flex',
      justifyContent: 'center',
    },

    '@media (max-width: 767px)': {
      minWidth: 'auto',
      maxWidth: 'auto',
    },
  },
  bundlesImage: {
    width: 188,
    height: 'auto',
    alignSelf: 'center',

    '@media (max-width: 580px)': {
      maxWidth: 94,
    },
  },
  bundlesContentWrapper: {
    paddingLeft: 20,

    '& .MuiGrid-spacing-xs-2': {
      width: '100%',
      margin: '0 0 30px',
    },
  },
  bundlesSaaS: {
    width: '80%',
    margin: '0 auto',
    paddingLeft: 0,

    '@media (max-width: 580px)': {
      width: '100%',
      margin: 0,
    },

    '& > div + ul': {
      paddingLeft: 18,

      '@media (max-width: 580px)': {
        maxHeight: 300,
        overflow: 'auto',
      },
    }
  },
  bundlesSaaSWrapper: {
    paddingTop: 30,

    '@media (max-width: 580px)': {
      padding: '20px 0 30px',
    },
  },
  card: {
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',

    '& > h2': {
      minHeight: 70,

      '@media (max-width: 1080px)': {
        minHeight: 85,
      },

      '@media (max-width: 580px)': {
        minHeight: 'auto',
      },

      '& > span:last-child': {
        color: theme.palette.primary.main,
      }
    },

    '@media (max-width: 580px)': {
      height: 'auto',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
  },
  wizardBodyHighlighted: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontFamily: 'Nunito'
  },
  bundlesIconsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    minWidth: 100,
    margin: '0 auto 30px',
    boxShadow: '0 6px 11px rgba(0, 0, 0, 0.06)',
    flexGrow: 1,

    '@media (max-width: 580px)': {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  bundlesIcons: {
    fontSize: 32,
    justifySelf: 'center'
  },
  bundlesIcons2: {
    fontSize: 48,
  },
  bundlesIcons3: {
    fontSize: 64,
  },
  bundlesButton: {
    width: '60%',
    borderRadius: 30,
    margin: '0 auto',

    '@media (max-width: 580px)': {
      width: '50%',
      display: 'flex',
      flexGrow: 2,
      maxHeight: 45,
    },
  },
  center: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: 10,
    marginLeft: -7,
    marginRight: 16,

    '@media (max-width: 580px)': {
      flexDirection: 'column',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  cardSmall: {
    height: 120,
    width: 120
  },
  bottomButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',

    '@media (max-width: 580px)': {
      flexDirection: 'column-reverse',
    },

    '& .Mui-disabled': {
      backgroundColor: 'rgba(47, 171, 177, 0.2)',
      color: theme.palette.primary.main,
    },

    '& .MuiButtonBase-root': {
      '@media (max-width: 580px)': {
        marginBottom: 15,
        marginRight: 0,
      },
    },
    '& .MuiButton-contained.Mui-disabled': {
      color: theme.palette.primary.main,
      boxShadow: 'none',
      backgroundColor: 'rgba(62, 168, 178, 0.15)',
      '& span': {
        color: 'rgba(62, 168, 178, 0.6)',
        fontSize: 16
    }
    },
  },
  buttonSandboxForm: {
    minWidth: 168,
  },
  progressImage: {
    width: 188,
    height: 223,
    alignSelf: 'center'
  },
  progressImageWrapper: {
    paddingLeft: 20,
  },
  progressContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 360,
    maxWidth: '100%',
    padding: '30px 30px 0',

    '@media (max-width: 985px)': {
      minWidth: 300,
    },

    '@media (max-width: 580px)': {
      padding: '0 30px',
    },
  },
  progContent: {
    padding: '0',
  },
  progressContent: {
    '@media (max-width: 580px)': {
      margin: '30px 0',
    },
  },
  progressContentText1: {
    color: theme.palette.primary.main,

    '@media (max-width: 768px)': {
      margin: '30px 0',
    },

    '@media (max-width: 767px)': {
      margin: '0',
    },

    '@media (max-width: 580px)': {
      fontSize: 14,
    },
  },
  progressContentText2: {
    fontWeight: 'bold',

    '@media (max-width: 768px)': {
      margin: '30px 0',
    },

    '@media (max-width: 767px)': {
      margin: '0',
    },

    '@media (max-width: 580px)': {
      fontSize: 14,
    },
  },
  verticalCenter: {
    alignSelf: 'center',
    width: '100%',

    '@media (max-width: 580px)': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  progress: {
    margin: '40px 0 10px',

    '& + div': {
      justifyContent: 'center',
    },
  },
  finishContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 300,
    minWidth: 360,
    maxWidth: '100%',
    '@media (max-width: 985px)': {
      minWidth: 300,
    },
  },
  bundlesServicesList: {
    paddingLeft: 15,
    fontSize: '0.875em',

    '@media (max-width: 580px)': {
      paddingLeft: 45,
    },
  },
  bundlesServicesListContainer: {
    flexGrow: 1,
    minHeight: 105,

    '@media (max-width: 1080px)': {
      minHeight: 175,
    },

    '@media (max-width: 768px)': {
      minHeight: 160,
    },

    '@media (max-width: 580px)': {
      minHeight: 105,
    },
  },
  packageDetailContent: {
    '& h2': {
      '@media (max-width: 580px)': {
        width: '100%',
      },
    },

    '@media (max-width: 580px)': {
      maxWidth: '100%',
      flexBasis: '100%',
    },

    '& > div': {
      '@media (max-width: 580px)': {
        width: '100%',
        height: 'auto',
      }
    },

    '&:nth-child(2) svg': {
      fontSize: 48,
    },
    '&:nth-child(3) svg': {
      fontSize: 64,
    },
  },
  secondaryLabel: {
    fontSize: '0.785rem',
    textTransform: 'none',
    marginBottom: 15,
    color: theme.palette.text.secondary,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogCustomBackBtn: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    padding: 12,
  },
  
  individualBundleButtonsContainer: {

    '@media (max-width: 1080px)': {
      flexDirection: 'column-reverse',
    },

    '& > div': {
      maxWidth: '100%',

      '@media (max-width: 768px)': {
        width: '100%',
        flexBasis: 'auto',
      },
    },
  },
  indivdualBundleTitleArea: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    paddingBottom: 10,

    '& > div': {
      fontSize: 22,

      '@media (max-width: 580px)': {
        textAlign: 'center',
      },
    },

    '@media (max-width: 580px)': {
      alignItems: 'center',
      alignSelf: 'center',
    },
  },
  wizardDialogMain: {
    '&  .MuiDialogContent-root' : {
      padding: '0 24px !important'
    },
    '& .MuiDialog-paper': {
      borderRadius: 10,
      minWidth: 930,
      minHeight: 570,

      '@media (max-width: 1024px)': {
        minWidth: '90%',
      },

      '@media (max-width: 580px)': {
        margin: 0,
      },
    },
  },
  developersLisenseAgreementContainer: {
    padding: 24,
    margin: '155px 0 0',
  },
  devLiseAgreementContent: {
    height: 'calc(100vh - 40vh)',
    overflowY: 'scroll',
    fontFamily: 'Nunito', 
    fontSize: '16px',
    lineHeight: '1.7',
    '& h4' : {
      fontSize: 30,
      fontWeight: 700
    },
    '& h3' : {
      fontSize: 24,
      paddingTop: 15,
      fontWeight: 700
    }
  },
  btnAcceptLisense: {
    padding: '7px 60px',
    marginRight: '.5em',
    borderRadius: 30,
  },
  acceptLisenseToolbar: {
    paddingTop: 50,
  },
  notAcceptedPopupTitle: {
    fontSize: 24,
    textAlign: 'left',
  },
  notAcceptPopupCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  notAcceptPopupActions: {
    padding: '15px 0',
    justifyContent: 'center',
  },
  bundlesContentText1: {
    display: 'none',

    '@media (max-width: 768px)': {
      display: 'flex',
      width: '50%',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },

    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  bundlesContentText2: {
    display: 'block',

    '@media (max-width: 768px)': {
      display: 'none',
    },

    '@media (max-width: 767px)': {
      display: 'block',
    },
  },
  dialogFigureHorizontalRule: {
    '@media (max-width: 768px)': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },

    '@media (max-width: 767px)': {
      borderBottom: 'none',
    },
  },
});

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
    },
    '& .MuiInput-underline:after': {
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      borderRadius: 30,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 30,
      },
      '&:hover fieldset': {
        border: 'rgb(62, 168, 178) 1px solid'
      },
      '&.Mui-focused fieldset': {
      },
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
      marginRight: 0,
      fontStyle: 'italic',
      display: 'flex',
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1.2em',
      margin: '3px',
    },
    '& + .MuiFormHelperText-root': {
      marginLeft: 0,
      marginRight: 0,
      fontStyle: 'italic',
      display: 'flex',
      color: '#f44336',

      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: '1.2em',
        margin: '3px',
      },
    },
  },
})(TextField);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 25,
    borderRadius: 30,
    width: '100%',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 100 : 700]
  },
  bar: {
    borderRadius: 5,
    background: "linear-gradient(0.25turn, #B8E7FF, #88FFD1)",
  }
}))(LinearProgress);

const SandboxesPage = (props) => {
  const {
    dispatch, classes, sandboxes, currentSandbox, loading, developerLicense,
  } = props;

  const WizardScreens = {
    Offer: 'Offer',
    HybridProjectName: 'Hybrid Project Name',
    Bundles: 'Bundles',
    ConfirmBundle: 'Confirm Bundle',
    Progress: 'Progress',
    Finish: 'Finish',
    Failed: 'Deployment Failed'
  };

  // NOTE: Do not change these unless in coordination with devops team 
  const Bundles = {
    Package1: 'package1',
    Package2: 'package2',
    Package3: 'package3'
  };


  const defaultFields = {
    hybridProjectName: '',
  };

  const ref = useRef();

  const [fields, setFields] = useState(defaultFields);
  const [errors, setErrors] = useState({});
  const [createFormVisible, setCreateFormVisible] = useState(false);
  const [useSharedIdFormVisible, setUseSharedIdFormVisible] = useState(false);
  const [screenView, setScreenView] = useState(WizardScreens.Offer);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [createBtnDisabled, setCreateBtnDisabled] = useState(true);
  const [openToast, setOpenToast] = useState(false);
  const [deployStart, setDeployStart] = useState(false);
  const [mount, setMount] = useState(false);
  const [openNotAcceptedPopup, setOpenNotAcceptedPopup] = useState(false);

  let timeout;

  window.addEventListener('blur', function (e) {
    e.preventDefault();
    window.clearTimeout(timeout);
  })

  useEffect(() => {
    dispatch(getLatestAgreement());
  }, []);

  const showForm = () => {
    setCreateFormVisible(true)
  };
  const hideForm = () => {
    setCreateFormVisible(false);
    setSelectedBundle(null);
    setFields(defaultFields);
    setErrors({});
    setScreenView(WizardScreens.Offer);
  };

  const handlePick = (pick) => {
    if (createFormVisible && currentSandbox) {
      const activeSandbox = sandboxes.filter((proj) => proj.id === currentSandbox.id);

      if (activeSandbox && activeSandbox.length > 0 && ['deploying'].includes(activeSandbox[0].status)) {
        setCreateBtnDisabled(true);
        setCreateFormVisible(false);
        setOpenToast(true);
      } else {
        setSelectedBundle(pick);
        setScreenView(WizardScreens.ConfirmBundle);
        setMount(!mount);
      }
    } else {
      setSelectedBundle(pick);
      setScreenView(WizardScreens.ConfirmBundle);
      setMount(!mount);
    }
  };

  const handleCloseToast = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const getPayload = () => ({
    packageName: selectedBundle,
    clientName: fields.hybridProjectName,
  });

  const startDeployment = () => {
    if (createFormVisible && currentSandbox) {
      const activeSandbox = sandboxes.filter((proj) => proj.id === currentSandbox.id);

      if (activeSandbox && activeSandbox.length > 0 && ['deploying'].includes(activeSandbox[0].status)) {
        setCreateBtnDisabled(true);
        setCreateFormVisible(false);
        setOpenToast(true);
      } else {
        setTimeout(function () {
          setScreenView(WizardScreens.Progress);
          setDeployStart(false);
        }, 5000);
        // Call deployment API
        dispatch(createSandbox(getPayload()));
        // Monitor for Jenkins callback
        setDeployStart(true);
      }
    } else {
      setTimeout(function () {
        setScreenView(WizardScreens.Progress);
        setDeployStart(false);
      }, 5000);
      // Call deployment API
      dispatch(createSandbox(getPayload()));
      // Monitor for Jenkins callback
      setDeployStart(true);
    }
  };




  const updateField = (field, value) => {
    const newFields = { ...fields, [field]: value };
    setFields(newFields);
    validateFields(field, newFields);
  };

  const validateFields = (field = null, newFields = fields) => {
    const newErrors = { ...errors };

    const validation = {
      hybridProjectName: () => {
        const hybridProjectNameRegex = /^[a-zA-Z0-9]*$/; // ASCII Alphanumeric only
        if (!newFields.hybridProjectName || !newFields.hybridProjectName.trim()) return (<><InfoOutlined fontSize="small" /> Hybrid project name is required.</>);
        if (!hybridProjectNameRegex.test(newFields.hybridProjectName) || newFields.hybridProjectName.length > 36 || newFields.hybridProjectName.length < 6) return (<><InfoOutlined fontSize="small" /> Please check the criteria and try again.</>);
        return null;
      },
    };

    if (field) {
      const error = validation[field] && validation[field]();
      if (error) {
        newErrors[field] = error;
      } else {
        delete newErrors[field];
      }
    } else {
      Object.keys(validation).forEach((key) => {
        const error = validation[key]();
        if (error) {
          newErrors[key] = error;
        } else {
          delete newErrors[key];
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (!validateFields()) return;
    setScreenView(WizardScreens.Bundles);
  };

  const fieldsChanged = () => Object.keys(defaultFields)
    .reduce((acc, key) => (
      acc || JSON.stringify(defaultFields[key]) !== JSON.stringify(fields[key])
    ), false);

  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const hideSharedIdForm = () => setUseSharedIdFormVisible(false);

  const agree = () => dispatch(postLicenseResponse(developerLicense, true));

  const logout = () => dispatch(logoutUser());

  const getBundleName = () => {
    switch (selectedBundle) {
      case Bundles.Package1:
        return 'Registration and Peer Association';

      case Bundles.Package2:
        return 'Survey';


      case Bundles.Package3:
        return 'Notification';
      default:
        break;
    }
  }

  // TOGO: this really needs to be optimized
  const getBundleDescription = () => {
    switch (selectedBundle) {
      case Bundles.Package1:
        return <ul>
          <li>
            An Identity Service which is fully compliant with Oauth 2.0.
          </li>
          <li>
            A Profile Service handles user profile data management.
          </li>
          <li>
            Peer Association Service provides the ability to support peer networks and relationships in your applications.
          </li>
        </ul>;

      case Bundles.Package2:
        return <ul>
          <li>
            An Identity Service that is fully compliant with Oauth 2.0.
          </li>
          <li>
            A Profile Service handles user profile data management.
          </li>
          <li>
            Peer Association Service provides the ability to support peer networks and relationships in your applications.
          </li>
          <li>
            Assessment Service allows surveys and questionnaires to be created, presented, and responses stored.
          </li>
          <li>
            Trackers Service makes it easy to get recurring user inputs on various topics.
          </li>
        </ul>;


      case Bundles.Package3:
        return <ul>
          <li>
            An Identity Service that is fully compliant with Oauth 2.0.
          </li>
          <li>
            A Profile Service handles user profile data management.
          </li>
          <li>
            Peer Association Service provides the ability to enable Peer networks and relationships in your applications.
          </li>
          <li>
            Assessment Service allows surveys and questionnaires to be created, presented, and responses stored.
          </li>
          <li>
            Trackers Service makes it easy to get recurring user inputs on various topics.
          </li>
          <li>
            Notification Service simplifies the process of sending SMS, email, or Push notifications to users.
          </li>
          <li>
            Event Scheduler Service enables recurring/delayed notifications or other repetitive tasks.
          </li>
        </ul>;
      default:
        break;
    }
  }

  const getBundleIcon = () => {
    switch (selectedBundle) {
      case Bundles.Package1:
        return <Bundle1 className={clsx(classes.bundlesIcons, classes.bundlesIcons1)} textcolor='transparent' />;

      case Bundles.Package2:
        return <Bundle2 className={clsx(classes.bundlesIcons, classes.bundlesIcons2)} textcolor='transparent' />;

      case Bundles.Package3:
        return <Bundle3 className={clsx(classes.bundlesIcons, classes.bundlesIcons3)} textcolor='transparent' />;
      default:
        break;
    }
  }

  const handleOpenNotAcceptedPopup = () => {
    setOpenNotAcceptedPopup(true);
  };
  const handleCloseNotAcceptedPopup = () => {
    setOpenNotAcceptedPopup(false);
  };

  useEffect(() => {
    dispatch(resetSandboxProjects());
    dispatch(fetchAllSandboxes());
    dispatch(resetCurrentSandbox());
    dispatch(getOauthDeeplink());
    dispatch(clearDeveloperIdTokenAndClientId());
  }, [dispatch]);

  const useInterval = (callback, delay) => {
    const intervalRef = useRef(null);
    const savedCallback = useRef(callback);
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      const tick = () => savedCallback.current();
      if (typeof delay === 'number') {
        intervalRef.current = window.setInterval(tick, delay);
        return () => window.clearInterval(intervalRef.current);
      }
    }, [delay]);
    return intervalRef;
  }

  useInterval(() => {
    dispatch(fetchAllSandboxes());
  }, createFormVisible ? 5000 : 60000);

  useEffect(() => {
    if (createFormVisible && currentSandbox && sandboxes) {
      const activeSandbox = sandboxes.filter((proj) => proj.id === currentSandbox.id);
      if (activeSandbox && activeSandbox.length > 0 && ['deployed'].includes(activeSandbox[0].status)) {
        setScreenView(WizardScreens.Finish);
        dispatch(resetCurrentSandbox());
      }
      if (activeSandbox && activeSandbox.length > 0 && ['failed'].includes(activeSandbox[0].status)) {
        setScreenView(WizardScreens.Failed);
        dispatch(resetCurrentSandbox());
      }
    }

    if (currentSandbox && sandboxes) {
      const activeSandbox = sandboxes.filter((proj) => proj.id === currentSandbox.id);

      if (activeSandbox && activeSandbox.length > 0 && ['deploying'].includes(activeSandbox[0].status)) {
        setCreateBtnDisabled(true);
      }
    } else {
      setTimeout(() => {
        setCreateBtnDisabled(false);
      }, 3000);
      return;
    }
  }, [sandboxes, currentSandbox]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 100;
    }
  }, [createFormVisible, mount]);

  if (developerLicense
    && developerLicense.questions
    && developerLicense.questions.pages
    && developerLicense.questions.pages.length > 0
    && developerLicense.questions.pages[0].elements
    && developerLicense.questions.pages[0].elements.length > 1
    && developerLicense.questions.pages[0].elements[0].html) {

    return (
      <>
        <Box>
          <Paper
            elevation={3}
            className={classes.developersLisenseAgreementContainer}
          >
          <Grid
            container
            spacing={3}
          >
            <Grid item sm={12}>
              <Box className={classes.devLiseAgreementContent}>
                <div dangerouslySetInnerHTML={{ __html: developerLicense.questions.pages[0].elements[0].html }} />
              </Box>
              <Toolbar
                disableGutters
                className={clsx(classes.toolbar, classes.acceptLisenseToolbar)}
              >
                <Button
                  onClick={agree}
                  variant="contained"
                  color="primary"
                  className={classes.btnAcceptLisense}
                >
                  Agree
                </Button>

                <Button
                  onClick={handleOpenNotAcceptedPopup}
                  variant="outlined"
                  color="default"
                  className={classes.btnAcceptLisense}
                >
                  Disagree
                </Button>
              </Toolbar>
            </Grid>
          </Grid>
          </Paper>
        </Box>

        <Dialog
          onClose={handleCloseNotAcceptedPopup}
          aria-labelled-by="not-accepted-popup-title"
          open={openNotAcceptedPopup}
          maxWidth="md"
        >
          <DialogTitle id="not-accepted-popup-title">
            <Typography className={classes.notAcceptedPopupTitle}>You have not accepted the agreement.</Typography>

            <IconButton
              aria-label="close"
              className={classes.notAcceptPopupCloseButton}
              onClick={handleCloseNotAcceptedPopup}
            >
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Typography variant="body1">
              By not accepting the agreement, you cannot use our services and you will be logged out of the developer console. In order to stay on the platform and using the services, please select Continue and accept the agreement.
            </Typography>
          </DialogContent>

          <DialogActions className={classes.notAcceptPopupActions}>
            <Button
              onClick={logout}
              variant="outlined"
              color="default"
              className={classes.btnAcceptLisense}
            >
              Logout
            </Button>

            <Button
              onClick={handleCloseNotAcceptedPopup}
              variant="contained"
              color="primary"
              className={classes.btnAcceptLisense}
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  const getFormContent = () => {
    switch (screenView) {
      case WizardScreens.Offer:
        return <div className={classes.dialogContent}>
          <Grid container spacing={3} className={classes.dialogContentInit}>
            <Grid
              item
              xs={5}
              className={classes.dialogFigureContent}
            >
              <img
                className={classes.academyOfferImage}
                src={headerBanner}
                title="mimik academy"
              />
            </Grid>
            <Grid item xs={7}>
              <div className={classes.academyOfferContentWrapper}>
                <div className={classes.wizardHeader}>Exclusive Offer for Developers</div>
                <div className={classes.wizardBody1}>Experience a <span className={classes.wizardBodyBold}>30-Day</span> Trial of the World&apos;s Premier Digital Library for the Hybrid EdgeCloud Platform.</div>
                <div className={classes.wizardBody2}>Discover the extensive range of resources available on the mimik Developer Portal, including exclusive training materials and comprehensive documentation, all at your fingertips.</div>
                <div>
                  <Button
                    className={classes.offerButton}
                    variant="contained"
                    color="primary"
                    onClick={() => setScreenView(WizardScreens.HybridProjectName)}
                    size="large"
                  >
                    Activate Your 30-Day Trial Now
                  </Button>
                </div>
                <div>
                  <Button
                    className={classes.offerButton}
                    variant="outlined"
                    onClick={hideForm}
                    size="large"
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.academyOfferBodySubtle}>Don&apos;t forget, you have the flexibility to cancel your trial at any time, hassle-free.</div>
              </div>
            </Grid>
          </Grid>
        </div>;

      case WizardScreens.HybridProjectName:
        return <div className={classes.dialogContent}>
          <Grid container className={classes.dialogContentContainer}>
            <Grid
              item
              xs={4}
              className={clsx(classes.dialogFigureContent, classes.dialogFigureContentWizard)}
            >
              <div className={classes.bundlesImageWrapper}>
                <img
                  src={imi}
                  alt=""
                  className={classes.bundlesImage}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={8}
              className={classes.dialogFormContent}
            >
              <div className={classes.academyOfferContentWrapper}>
                <div className={classes.wizardHeader}>Name your Hybrid Project</div>
                <div className={classes.wizardBody1}>
                  <FormControl className={classes.formControl}>
                    <InputLabel label="Type a name for your Hybrid Project" required />

                    <CustomTextField
                      variant="outlined"
                      fullWidth
                      className={classes.outlinedInput}
                      onChange={e => updateField('hybridProjectName', e.target.value)}
                      value={fields.hybridProjectName}
                      error={!!errors.hybridProjectName}
                      inputProps={{ placeholder: '' }}
                      helperText={errors.hybridProjectName}
                    />

                    <Typography className={classes.secondaryLabel}>Please use between 6 to 36 characters, alphabets and/or numbers with no spacing.</Typography>
                  </FormControl>
                </div>

                <div className={classes.bottomButtons}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="flex-end"
                    className={classes.individualBundleButtonsContainer}
                  >
                    <Grid item xs={4}>
                      <Button
                        className={clsx(classes.button, classes.buttonSandboxForm)}
                        variant="outlined"
                        size="large"
                        fullWidth={true}
                        onClick={hideForm}
                      >
                        Cancel
                      </Button>
                    </Grid>

                    <Grid item xs={4}>
                      <Button
                        className={clsx(classes.button, classes.buttonSandboxForm)}
                        variant="contained"
                        size="large"
                        fullWidth={true}
                        color="primary"
                        onClick={handleSave}
                        disabled={Object.keys(errors).length > 0 || !fieldsChanged()}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>;

      case WizardScreens.Bundles:
        return <div className={classes.dialogContent}>
          <Grid container className={clsx(classes.dialogContentContainer, classes.dialogContentResponsiveMedia)}>
            <Grid
              item
              xs={3}
              className={
                clsx(
                  classes.dialogFigureContent,
                  classes.dialogFigureContentWizard,
                  classes.dialogFigureHorizontalRule,
                  classes.bundlesDialogFigureContentWizard
                )
              }
            >
              <div className={classes.bundlesImageWrapper}>
                <img
                  src={imi}
                  alt=""
                  className={classes.bundlesImage}
                />
              </div>

              <div className={classes.bundlesContentText1}>
                <div className={classes.wizardHeader}>
                  Hybrid Project Setup Wizard
                </div>
                <div className={clsx(classes.wizardBody1, classes.wizardBodyXSpace)}>
                  <span>mimik provides you with a setup wizard for deploying a Hybrid Project.<br /></span>
                  <span>In this stage, you can select a mService Bundle for the development of your apps.<br /></span>
                  {/* <span>Please choose one of the following bundles</span> */}
                </div>
                <div className={classes.wizardBody2}>
                  Please choose one of the following bundles to be enabled in your Hybrid Project.
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={9}
              className={clsx(classes.dialogFormContent, classes.bundlesDialogFormContent)}
            >
              <div className={clsx(classes.bundlesContentWrapper, classes.bundlesSaaSWrapper)}>
                <div className={classes.bundlesContentText2}>
                  <div className={classes.wizardHeader}>
                    Hybrid Project Setup Wizard
                  </div>
                  <div className={clsx(classes.wizardBody1, classes.wizardBodyXSpace)}>
                    <span>mimik provides you with a setup wizard for deploying a Hybrid Project.<br /></span>
                    <span>In this stage, you can select a mService Bundle for the development of your apps.<br /></span>
                    {/* <span>Please choose one of the following bundles</span> */}
                  </div>
                  <div className={classes.wizardBody2}>
                    Please choose one of the following bundles to be enabled in your Hybrid Project.
                  </div>
                </div>

                <Grid container spacing={2}>
                  <Grid item m={3} sm={4} xs={12} className={classes.packageDetailContent}>
                    <Card className={classes.card} elevation={3}>
                      <CardContent className={classes.cardContent}>
                        <Typography variant="h5" component="h2" align="center">
                          <span className={classes.wizardBodyBold}>Registration and Peer Association</span><br />
                          <span className={classes.wizardBodyBold}>Bundle</span>
                        </Typography>
                        
                        <div className={classes.bundlesIconsWrapper}>
                          <Bundle1 className={classes.bundlesIcons} />
                        </div>
                        <div className={classes.bundlesServicesListContainer}>
                          <ul className={classes.bundlesServicesList}>
                            <li>
                              Identity Service
                            </li>
                            <li>
                              Profile Service
                            </li>
                            <li>
                              Peer Association Service
                            </li>
                          </ul>
                        </div>
                        <Button className={classes.bundlesButton} variant="contained" color="primary" onClick={() => handlePick(Bundles.Package1)}>
                          Pick
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item m={3} sm={4} xs={12} className={classes.packageDetailContent}>
                    <Card className={classes.card} elevation={3}>
                      <CardContent className={classes.cardContent}>
                        <Typography variant="h5" component="h2" align="center">
                          <span className={classes.wizardBodyBold}>Survey</span><br />
                          <span className={classes.wizardBodyBold}>Bundle</span>
                        </Typography>

                        <div className={classes.bundlesIconsWrapper}>
                          <Bundle2 className={classes.bundlesIcons} />
                        </div>
                        <div className={classes.bundlesServicesListContainer}>
                          <ul className={classes.bundlesServicesList}>
                            <li>
                              <span className={classes.wizardBody}>Registration and Peer Association</span>
                              <span className={classes.wizardBody2}> Plus</span>
                            </li>
                            <li>
                              Assessment Service
                            </li>
                            <li>
                              Trackers Service
                            </li>
                          </ul>
                        </div>
                        <Button className={classes.bundlesButton} variant="contained" color="primary" onClick={() => handlePick(Bundles.Package2)}>
                          Pick
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item m={3} sm={4} xs={12} className={classes.packageDetailContent}>
                    <Card className={classes.card} elevation={3}>
                      <CardContent className={classes.cardContent}>
                        <Typography variant="h5" component="h2" align="center">
                          <span className={classes.wizardBodyBold}>Notification</span><br />
                          <span className={classes.wizardBodyBold}>Bundle</span>
                        </Typography>

                        <div className={classes.bundlesIconsWrapper}>
                          <Bundle3 className={classes.bundlesIcons} />
                        </div>  
                        <div className={classes.bundlesServicesListContainer}>
                          <ul className={classes.bundlesServicesList}>
                            <li>
                              <span className={classes.wizardBody}>Survey</span>
                              <span className={classes.wizardBody2}> Plus</span>
                            </li>
                            <li>
                              Notification Service
                            </li>
                            <li>
                              Event Scheduler Service
                            </li>
                          </ul>
                        </div>
                        <Button className={classes.bundlesButton} variant="contained" color="primary" onClick={() => handlePick(Bundles.Package3)}>
                          Pick
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>

      case WizardScreens.ConfirmBundle:
        return <div className={classes.dialogContent} ref={ref}>
          <Grid container className={clsx(classes.dialogContentContainer, classes.dialogContentResponsiveMedia)}>
            <Grid
              item
              xs={3}
              className={clsx(classes.dialogFigureContent, classes.dialogFigureContentWizard)}
            >
              <div className={classes.bundlesImageWrapper}>
                <img
                  src={imi}
                  alt=""
                  className={classes.bundlesImage}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={9}
              className={classes.dialogFormContent}
            >
              <div className={clsx(classes.bundlesContentWrapper, classes.bundlesSaaS)}>
                <div className={classes.center}>
                  <Box className={classes.indivdualBundleTitleArea}>
                    <div className={classes.wizardBodyBold}>
                      {getBundleName()}
                    </div>
                    <div className={classes.wizardBodyHighlighted}>
                      Bundle
                    </div>
                  </Box>

                  <div className={classes.center}>
                    <Card className={classes.cardSmall} elevation={0}>
                      <CardContent>
                        <div className={classes.bundlesIconsWrapper}>
                          {getBundleIcon()}
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>

                <div className={classes.wizardBody1} style={{ color: theme.palette.primary.main }}>
                  You have selected the {getBundleName()} bundle
                </div>

                {getBundleDescription()}
                <div className={classes.bottomButtons}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="flex-end"
                    className={classes.individualBundleButtonsContainer}
                  >
                    <Grid item xs={4}>
                      <Button
                        className={classes.button}
                        variant="outlined"
                        color="default"
                        size="large"
                        fullWidth={true}
                        onClick={() => setScreenView(WizardScreens.Bundles)}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        className={classes.button}
                        variant="contained"
                        size="large"
                        fullWidth={true}
                        color="primary"
                        onClick={() => startDeployment()}
                        disabled={deployStart}
                      >
                        {deployStart ? "Initializing..." : "Deploy"}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

      case WizardScreens.Progress:
        return <div className={classes.dialogContent}>
          <Grid container className={clsx(classes.dialogContentContainer, classes.dialogContentResponsiveMedia)}>
            <Grid
              item
              xs={3}
              className={
                clsx(
                  classes.dialogFigureContent,
                  classes.dialogFigureContentWizard,
                  classes.dialogFigureHorizontalRule,
                  classes.bundlesDialogFigureContentWizard
                )
              }
            >
              <div className={clsx(classes.progressImageWrapper, classes.bundlesImageWrapper)}>
                <img
                  src={imi2}
                  alt=""
                  className={classes.bundlesImage}
                />
              </div>

              <div className={classes.bundlesContentText1}>
                <div className={clsx(classes.progressContentWrapper, classes.progContent)}>
                  <div className={classes.center}>
                    <Box className={classes.indivdualBundleTitleArea}>
                      <div className={classes.wizardBodyBold}>
                        {getBundleName()}
                      </div>

                      <div className={classes.wizardBodyHighlighted}>
                        Bundle
                      </div>
                    </Box>

                    <div className={classes.center}>
                      <Card className={classes.cardSmall} elevation={0}>
                        <CardContent>
                          <div className={classes.bundlesIconsWrapper}>
                            {getBundleIcon()}
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </div>

                <div className={classes.progressContent}>
                  <Typography variant="body1" className={classes.progressContentText1}>Please be patient, it will take a few minutes to deploy all the required microservices.</Typography>
                  <Typography variant="body1" className={classes.progressContentText2}>This screen will let you know once the deployment has concluded.</Typography>
                </div>
              </div>
            </Grid>

            <Grid
              item
              xs={9}
              className={clsx(classes.dialogFormContent, classes.bundlesDialogFormContent)}
            >
              <div className={classes.bundlesContentText2}>
                <div className={classes.progressContentWrapper}>
                  <div className={classes.center}>
                    <Box className={classes.indivdualBundleTitleArea}>
                      <div className={classes.wizardBodyBold}>
                        {getBundleName()}
                      </div>

                      <div className={classes.wizardBodyHighlighted}>
                        Bundle
                      </div>
                    </Box>

                    <div className={classes.center}>
                      <Card className={classes.cardSmall} elevation={0}>
                        <CardContent>
                          <div className={classes.bundlesIconsWrapper}>
                            {getBundleIcon()}
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={classes.progressContentWrapper}>
                <div className={classes.verticalCenter}>
                  <div className={classes.bundlesContentText2}>
                    <div className={classes.progressContent}>
                      <Typography variant="body1" className={classes.progressContentText1}>Please be patient, it will take a few minutes to deploy all the required microservices.</Typography>
                      <Typography variant="body1" className={classes.progressContentText2}>This screen will let you know once the deployment has concluded.</Typography>
                    </div>
                  </div>
                  
                  <div>
                    <BorderLinearProgress className={classes.progress} />
                    <div className={classes.center}>Loading...</div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

      case WizardScreens.Finish:

        return <div className={classes.dialogContent}>
          <div className={classes.finishContentWrapper}>
            <img
              src={imi3}
              alt=""
              className={classes.bundlesImage}
            />

            <div className={classes.wizardFinalMessage}>
              <Typography variant="body1" className={classes.progressContentText2}>Everything is ready.</Typography>
            </div>
            
            <Button className={clsx(classes.button, classes.wizardFinalBtn)} variant="contained" color="primary" size="large" onClick={() => hideForm()}>
              Continue
            </Button>
          </div>
        </div>
      
      case WizardScreens.Failed:
        return (<div className={classes.dialogContent}>
          <div className={classes.finishContentWrapper}>
            <img
              src={imi3}
              alt=""
              className={classes.bundlesImage}
            />

            <Typography className={clsx(classes.wizardFinalMessage, classes.wizardFinalMessageError)}>Something Went Wrong</Typography>
            <Button
              className={clsx(classes.button, classes.wizardFinalBtn)}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => hideForm()}
            >Try Again</Button>
          </div>
        </div>);

      // default:
      //   break;
    }
  }

  if (!sandboxes) return <Loader fullPage />;
  if (!developerLicense || developerLicense && !developerLicense.agrementVerified) return <Loader fullPage />;
  return (
    <React.Fragment>
      <SEO title="Hybrid projects" />
      <Toolbar
        disableGutters
        className={classes.toolbar}
      >
        <Typography variant="h1" className={classes.heading_pg}>{createFormVisible ? 'create a hybrid project' : 'your hybrid projects'}</Typography>
      </Toolbar>

      <Collapse in={!(createFormVisible || useSharedIdFormVisible)}>
        <Toolbar
          disableGutters
          className={classes.toolbar}
        >
          {!sandboxes || sandboxes.length < maxSandboxes
            ? sandboxes.length === 0
              ? (<Box>
                  <Typography style={{ margin: '1em 0', textAlign: 'left' }}>
                    You don&apos;t have any active projects.
                    <br />
                    Create a new project to continue.
                  </Typography>

                  <Button
                    onClick={showForm}
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    disabled={createBtnDisabled}
                  >
                    <Add className={classes.buttonIcon} />Deploy a New Project
                  </Button>
                </Box>)
              : (<Typography className={classes.maxSandboxes}>
                Maximum Number of Hybrid Projects Created.
              </Typography>)
            : (<Typography className={classes.maxSandboxes}>
              Maximum Number of Hybrid Projects Created.
            </Typography>)
          }
        </Toolbar>
      </Collapse>

      <Collapse in={useSharedIdFormVisible}>
        <SharedIdSandboxForm
          dispatch={dispatch}
          onCancel={hideSharedIdForm}
          onSubmit={hideSharedIdForm}
          loading={loading.includes('createSandbox') || loading.includes('fetchSandboxById')}
        />
      </Collapse>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifySelf="center"
        maxWidth={1250}
        minHeight={400}
        p="1em"
      >
        {sandboxes && sandboxes.length > 0 ? (
          <SandboxGrid data={sandboxes} />
        ) : (
          ""
        )}
      </Box>

      <Dialog
        classes={{
          paperScrollPaper: classes.paperScrollPaper,
        }}
        className={classes.wizardDialogMain}
        maxWidth="xl"
        open={createFormVisible}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            hideForm
          }
        }}
        aria-labelledby="responsive-dialog-title"
        disableEscapeKeyDown={true}
        scroll="body"
      >
        {
          screenView === WizardScreens.Bundles && (
            <Button
              className={classes.dialogCustomBackBtn}
              color="primary"
              onClick={() => setScreenView(WizardScreens.HybridProjectName)}
            >
              <ArrowBackIos /> Back
            </Button>
          )
        }
        {
          (screenView !== WizardScreens.Offer && screenView !== WizardScreens.Progress && screenView !== WizardScreens.Finish && screenView !== WizardScreens.HybridProjectName ) && (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={hideForm}
            >
              <Close />
            </IconButton>
          )
        }
          
        <DialogContent>
          {getFormContent(classes, hideForm)}
        </DialogContent>
      </Dialog>
      
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openToast}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        message="Another Hybrid Project creation process is already running, please wait till it finishes."
        action={
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      />
    </React.Fragment>
  );
};

export default connect(state => ({
  loading: state.loading,
  error: state.error,
  sandboxes: state.sandboxes,
  currentSandbox: state.currentSandbox,
  oauthDeeplink: state.oauthDeeplink,
  developerLicense: state.developerLicense,
}))(withStyles(styles)(SandboxesPage))