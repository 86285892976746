import React, { useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Check from '@material-ui/icons/Check';
import red from '@material-ui/core/colors/red';

import ImageInput from '../FormFields/ImageInput';
import InputLabel from '../FormFields/InputLabel';
import FormErrors from '../FormFields/FormErrors';

import { createSandbox, updateSandbox } from '../../actions/sandboxes';
import { baseUrl } from '../../configs/config';
import logiIcon from '../../assets/images/logo_2.png';

const styles = () => ({
  root: {
    maxWidth: 1085,
    margin: '1em auto',
    padding: '0 1em',
    '@media (max-width: 750px)': {
      padding: 0,
    },
  },
  paper: {
    position: 'relative',
    padding: '1.75em 2.75em',
    borderRadius: 3,
    '@media (max-width: 750px)': {
      padding: '1em',
      margin: '20px 0 10px'
    },
  },
  button: {
    marginLeft: '.5em',
  },
  submitButton: {
    minWidth: '8em',
  },
  textInput: {
    width: 'calc(100% - 6.3em)',
    '@media (max-width: 1470px)': {
      width: 'calc(100% - 11em)',
    },
    '@media (max-width: 750px)': {
      width: '100%',
    },
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    color: red[600],
    fontWeight: 400,
  },
  errorMessageIcon: {
    width: '.8em',
    height: '.8em',
    marginRight: '.25em',
  },
  selectItem: {
    margin: '0'
  }
});

const CreateSandboxForm = (props) => {
  const {
    dispatch, classes, sandbox, loading, onSubmit, onCancel,
  } = props;
  const defaultSandboxName = 'My Sandbox';
  const fallbackLogoUrl = baseUrl + logiIcon;
  const defaultFields = sandbox ? {
    logoURL: sandbox.logo_uri,
    sandboxName: sandbox.client_name,
    appType: sandbox.application_type,
    callbackurlArray: sandbox.redirect_uris.map(url => ({ url, error: false })),
  } : {
      logoURL: '',
      sandboxName: defaultSandboxName,
      appType: 'native',
      callbackurlArray: [],
    };
  const [fields, setFields] = useState(defaultFields);
  const [errors, setErrors] = useState({});
  const [changesSaved, setChangesSaved] = useState(false);

  const resetForm = () => {
    setFields(defaultFields);
    setErrors({});
    setChangesSaved(false);
  };

  const updateFields = (newFields) => {
    setFields(newFields);
    setChangesSaved(false);
  };

  const updateErrors = (newErrors) => {
    const filteredErrors = {};
    Object.keys(newErrors).forEach(key => {
      if (newErrors[key]) filteredErrors[key] = newErrors[key];
    });
    setErrors(filteredErrors);
  };

  const updateSandboxName = (e) => {
    const { value } = e.target;
    const newFields = { ...fields, sandboxName: value };

    updateFields(newFields);
    updateErrors({
      sandboxName: !value || !value.trim() ? 'Sandbox name is required' : false,
    });
  };

  const getPayload = () => ({
    client_name: fields.sandboxName,
    application_type: fields.appType,
    redirect_uris: fields.callbackurlArray
      .filter((callbackurls) => !callbackurls.error && callbackurls.url.trim())
      .map(urls => urls.url),
    logo_uri: fields.logoURL.trim() || fallbackLogoUrl,
  });

  const handleCreate = () => dispatch(createSandbox(getPayload()))
    .then(resetForm)
    .then(() => {
      if (typeof onSubmit === 'function') onSubmit();
      setChangesSaved(true);
    })
    .catch(console.log);

  const handleSave = () => dispatch(updateSandbox(sandbox.client_id, getPayload()))
    .then(() => {
      if (typeof onSubmit === 'function') onSubmit();
      setChangesSaved(true);
    })
    .catch(console.log);

  const handleCancel = () => {
    resetForm();
    if (typeof onCancel === 'function') onCancel();
  };

  const fieldsChanged = () => Object.keys(defaultFields)
    .reduce((acc, key) => (
      acc || JSON.stringify(defaultFields[key]) !== JSON.stringify(fields[key])
    ), false);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <ImageInput
          onUpload={url => updateFields({ ...fields, logoURL: url })}
          imageUrl={fields.logoURL}
          defaultURL={fallbackLogoUrl}
        />

        <FormErrors errors={errors} />

        <Box m="1em 0">
          <FormControl variant="outlined" className={classes.textInput}>
            <InputLabel
              label="Sandbox Name"
              required
              tooltip="The sandbox name will appear on the login screen and should help users identify your application."
            />
            <OutlinedInput
              onChange={updateSandboxName}
              value={fields.sandboxName}
              error={!!errors.sandboxName}
              inputProps={{ placeholder: 'Sandbox Name' }}
              disabled={ sandbox && !sandbox.grant_types.includes('mimik:developer_id_token') }
            />
          </FormControl>
        </Box>


        <Box display="flex" justifyContent="flex-end" m="2em 0 1em">
          {sandbox ? (
            <Button
              variant="contained"
              color="primary"
              className={clsx(classes.button, classes.submitButton)}
              onClick={handleSave}
              disabled={loading || Object.keys(errors).length > 0 || !fieldsChanged()}
            >
              {changesSaved && <Check />}
              {changesSaved ? 'Changes Saved' : (loading ? 'Saving...' : 'Save')}
            </Button>
          ) : (
              <React.Fragment>
                <Button className={classes.button} onClick={handleCancel}>
                  Cancel
              </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={clsx(classes.button, classes.submitButton)}
                  onClick={handleCreate}
                  disabled={loading || Object.keys(errors).length > 0}
                >
                  {loading ? 'Creating...' : 'Create'}
                </Button>
              </React.Fragment>
            )}
        </Box>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(CreateSandboxForm);
