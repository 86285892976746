import React from 'react';
import { withStyles } from '@material-ui/styles';

import ProjectTile from './ProjectTile';

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '2%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    animation: 'fadeIn 800ms ease-in-out',
    
  },
  root_gap : {
    width: '100%',
    '@media (max-width:767px)': {
      padding: 10
    }
  }
});

const ProjectGrid = ({ classes, data }) => (
  <div className={classes.root_gap}>
    <div className={classes.root}>
      {data.map(item => (
        <ProjectTile key={item.client_id} item={item} data={data} />
      ))}
    </div>
  </div>
);

export default withStyles(styles)(ProjectGrid);
