export const defaultState = null;

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'SET_SANDBOXES_STATUS':
      return action.data;
    case 'RESET_SANDBOXES_STATUS':
      return defaultState;
    default:
      return state;
  }
}
