import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function PhoneIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="cls-1" d="m19.97,6.51s0-.03-.02-.04c-.01-.02-.03-.03-.04-.04-1.75-2.49-4.64-4.12-7.91-4.12s-6.16,1.63-7.91,4.12c-.01.01-.03.02-.04.04,0,.01,0,.02-.02.04-.22.31-.41.64-.59.98.28-.12.58-.2.89-.2.34,0,.67.08.97.22.4.2.73.45,1.02.73.33.12.67.23,1.02.33-.05.29-.09.59-.13.89.17.33.28.61.33.76.24.7.13,1.48-.3,2.09l-.2.29c0,.23.03.45.04.68.42.76.83,1.37,1.22,1.87-.15-.83-.25-1.73-.27-2.66h3.46v2.33c-1.11.03-2.17.14-3.17.35.28.35.55.64.81.89.76-.12,1.55-.21,2.36-.24v.82c.32-.24.66-.4,1-.46v-.36c1.04.03,2.04.14,2.97.33-.09.33-.18.65-.29.95.29.22.56.47.79.75.18-.46.34-.94.48-1.46.9.25,1.7.58,2.37.97-.63.79-1.4,1.45-2.25,1.99,0,.13.02.26,0,.39-.04.27-.13.59-.29.92,1.46-.72,2.72-1.8,3.65-3.12.01-.01.03-.02.03-.03,0-.01,0-.03.02-.04,1.08-1.56,1.71-3.45,1.71-5.48s-.63-3.93-1.71-5.49Zm-12.41,1.09c-.9-.25-1.7-.58-2.37-.97,1.07-1.35,2.53-2.38,4.21-2.91-.79.93-1.43,2.27-1.84,3.88Zm3.94,3.9h-3.46c.03-.94.12-1.85.28-2.69,1,.21,2.08.33,3.18.36v2.33Zm0-3.33c-1.04-.03-2.04-.14-2.97-.33.61-2.36,1.71-4.07,2.97-4.44v4.77Zm1-4.77c1.26.37,2.36,2.08,2.97,4.44-.93.19-1.93.3-2.97.33V3.4Zm3.18,11.79c-1-.21-2.08-.33-3.18-.36v-2.33h3.46c-.03.94-.12,1.84-.28,2.69Zm-3.18-3.69v-2.33c1.11-.03,2.18-.15,3.18-.36.16.84.25,1.75.28,2.69h-3.46Zm3.94-3.9c-.41-1.62-1.05-2.96-1.84-3.88,1.68.53,3.14,1.56,4.21,2.91-.67.39-1.47.72-2.37.97Zm2.94-.15c.74,1.19,1.19,2.57,1.28,4.05h-3.7c-.03-1.03-.13-2.01-.3-2.92,1.03-.29,1.95-.67,2.72-1.13Zm0,9.1c-.77-.46-1.69-.84-2.72-1.13.17-.91.28-1.89.3-2.92h3.7c-.08,1.48-.54,2.86-1.28,4.05Z" />
      <path className="cls-1" d="m4.86,8.41c-.39-.19-.87-.15-1.24.08-.72.45-1.79,1.33-1.62,2.42.27,1.74,1.45,4.1,4.02,6.97,1.77,1.98,4.65,3.97,7.11,3.97.29,0,.67-.1.96-.27.53-.32,1.3-.92,1.47-1.98.18-1.13-1.4-2.09-2.41-2.41-.48-.15-.96.12-1.31.49l-.22.23c-.23.24-.57.35-.9.26-.95-.25-2.94-1.23-4.84-5-.15-.3-.04-.57.15-.85l.39-.56c.24-.35.31-.79.17-1.2-.22-.64-.77-1.66-1.74-2.14Z" />
    </SvgIcon>

  );
}

export default PhoneIcon;
